import React from 'react'
// translation pakage
import { useTranslation } from 'react-i18next'

const Error = ({ message = null }) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <div className="mt-3 mb-3 text-center">
        <i className="ri-plug-line display-5 text-danger"></i>
        <div className="mt-2 pt-2 fs-15 mx-4 mx-sm-5">
          {message ? (
            <h4>{message.toString()}</h4>
          ) : (
            <>
              <h4>{t('Error in server conection')}</h4>
              <p className="text-muted mx-4 mb-0">
                {t('Please check your internet and try again')}
              </p>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Error
