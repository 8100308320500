import { withErrorBoundary } from 'react-error-boundary'
import CreateOrderView from './CreateOrderView'

const CreateOrder = () => {
  document.title = 'Create Order | Sallatk'

  return <CreateOrderView />
}

export default withErrorBoundary(CreateOrder, {
  fallback: <div className="page-content">Something went wrong</div>
})
