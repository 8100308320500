import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import CardItem from './CardItem'
import StatusBadge from 'pages/Transactions/list/StatusBadge'
import { useTranslation } from 'react-i18next'
import PopUp from 'Components/PopUp'
import { Select, Submit } from 'Components/Form'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import linkGenerator from 'helpers/linkGenerator'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import showError from 'helpers/ErorrNotfication'

function DetailsCard({
  data,
  fetch,
  isFetching
}: {
  data: {
    id: number
    payment_id: string | null
    // user: User;
    total: number
    currency: string
    status: 'paid' | string
    created_at: string
    order: number
    // payment_method: PaymentMethod;
    transaction_type: 'refund' | string
    reference: string | null
    invoice_id: string | null
  }
  fetch: () => void
  isFetching: boolean
}) {
  const { t } = useTranslation()
  const [showPopUp, setShowPopUp] = useState(false)
  if (!data) return null
  return (
    <>
      <Card>
        <CardHeader>
          <h5 className="card-title mb-0">
            <i className="ri-bank-card-line me-1 text-muted"></i> {t('Transaction Details')}
          </h5>
        </CardHeader>
        <CardBody>
          <CardItem title={t('Id')} value={data?.id} />
          <CardItem title={t('Reference')} value={data?.reference} />
          <CardItem title={t('Invoice Id')} value={data?.invoice_id} />
          <CardItem title={t('Payment Id')} value={data?.payment_id} />
          <CardItem title={t('Transaction Type')} value={data?.transaction_type} />
          <CardItem title={t('Total')} value={data?.total + t('KWD')} />
          <div className="d-flex align-items-center gap-2">
            <CardItem title={t('Status')} Component={() => <StatusBadge status={data?.status} />} />
            <Button
              color="primary"
              size="sm"
              onClick={() => setShowPopUp(true)}
              disabled={isFetching}>
              {t('Change')}
            </Button>
          </div>

          <CardItem title={t('Created at')} value={data?.created_at} />
        </CardBody>
      </Card>
      <ChangeStatusModal
        close={() => setShowPopUp(false)}
        show={showPopUp}
        onSuccess={() => {
          fetch()
          setShowPopUp(false)
        }}
      />
    </>
  )
}

export default DetailsCard

const ChangeStatusModal = ({ close, show, onSuccess }: any) => {
  const { t } = useTranslation()
  const { id } = useParams()

  const { mutate, isPending } = useMutation({
    mutationFn: async (data) => {
      const response = await axios.put(linkGenerator(`/transactions/${id}`), data)
      if (response.data.status === 'success') return response.data
    },
    onSuccess: () => {
      onSuccess()
    },
    onError: () => {
      showError(t('Failed to change status. Please try again.'))
    }
  })

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const submit = (data) => {
    mutate(data)
  }

  return (
    <PopUp title={t('Change Status')} show={show} close={close}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={12}>
              <Select
                label={t('Status')}
                placeholder={`${t('Select')} ${t('Status')}`}
                options={[
                  {
                    label: t('Paid'),
                    value: 'paid'
                  },
                  {
                    label: t('Refused'),
                    value: 'refused'
                  }
                ]}
                model={register('status', {
                  required: `${t('Status')} ${t('is required')}`
                })}
                error={errors.status}
              />
            </Col>
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={isPending} label={t('Change')} />
        </div>
      </form>
    </PopUp>
  )
}
