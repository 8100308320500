import { Button } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import PopUp from 'Components/PopUp'

import FilterComponents from '../FilterComponents'
import { useProductContext } from '../../Context'

const Filter = ({ close, show }: { close: any; show: any }) => {
  const { t } = useTranslation()
  const { filters } = useProductContext()
  return (
    <PopUp title={t('Filter')} show={show} close={close} loading={filters?.loading}>
      <FilterComponents />
      <br />

      <div className="hstack gap-2 justify-content-end">
        <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
          <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
        </Button>
        {/* <Button className="btn btn-link btn-secondary fw-medium">
          <i className="ri-close-line me-1 align-middle"></i> {t('Apply')}
        </Button> */}
      </div>
    </PopUp>
  )
}
export default Filter
