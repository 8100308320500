import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Input, Label, Tooltip, UncontrolledCollapse } from 'reactstrap'

type TransformedFeature = {
  feature_id: number
  feature_title: string
  options: {
    id: number
    type: string
    title: string
    color: string | null
    total: number | null
  }[]
}
function transformFeatures(data) {
  return data.reduce((acc, { feature_id, feature_name, color, ...rest }) => {
    let feature = acc.find((f) => f.feature_id === feature_id)
    if (!feature) {
      feature = {
        id: feature_id,
        title: feature_name,
        isColor: color ? true : false,
        options: []
      }
      acc.push(feature)
    }

    feature.options.push({ ...rest, color })

    return acc
  }, [])
}

function Features({ data }) {
  const { t } = useTranslation()
  if (!data) return null

  console.log(transformFeatures(data))
  return (
    <Col sm={6}>
      <div className="mt-3">
        <h5 className="fs-14">Features :</h5>
        <ul className="list-unstyled">
          {Array.isArray(data) && data.length > 0 ? (
            transformFeatures(data).map((feature, index) => {
              if (feature?.isColor) {
                return (
                  <Col xl={6}>
                    <div className=" mt-4">
                      <h5 className="fs-14">{feature?.title} :</h5>
                      <div className="d-flex flex-wrap gap-2">
                        {feature?.options.map((option) => (
                          <ColorOption data={option} />
                        ))}
                      </div>
                    </div>
                  </Col>
                )
              } else {
                return (
                  <Col xl={6}>
                    <div className=" mt-4">
                      <h5 className="fs-14">{feature?.title} :</h5>
                      {feature?.options.map((option) => (
                        <NonColorOption data={option} />
                      ))}
                    </div>
                  </Col>
                )
              }
            })
          ) : (
            <p className="text-muted">No features</p>
          )}
        </ul>
      </div>
    </Col>
  )
}

export default Features

const NonColorOption = ({ data }) => {
  return (
    <div className="d-flex flex-wrap gap-2">
      <Input type="radio" className="btn-check" name="productsize-radio" />
      <Label
        className="btn btn-soft-primary avatar-xs rounded-circle p-0 d-flex justify-content-center align-items-center"
        id="TooltipSSize">
        {data?.title}
      </Label>
    </div>
  )
}

const ColorOption = ({ data }) => {
  return (
    <div
      data-bs-toggle="tooltip"
      data-bs-trigger="hover"
      data-bs-placement="top"
      title={data?.title}>
      <button
        type="button"
        className="btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20"
        style={{
          color: data?.color
        }}>
        <i className="ri-checkbox-blank-circle-fill"></i>
      </button>
    </div>
  )
}
