import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router-dom'
// bootstrap elements
import { Col, Container, Row, Form, Accordion, AccordionItem } from 'reactstrap'
// reqired componnent
import BreadCrumb from 'Components/Common/BreadCrumb'
import { Submit } from 'Components/Form'
import UpdateHock from 'helpers/UpdateHock'
import Loader from 'Components/IndexTable/partials/Loader'
import Error from 'Components/IndexTable/partials/Error'

import MainDetails from './components/MainDetails'
import MainPrice from './components/MainPrice'
import Gallery from './components/Gallery'
import Specification from './components/Specification'
import OrderForm from './components/OrderForm'
import ProductDetails from './ProductDetails'
import FetchHock from 'helpers/FetchHock'

const Product = () => {
  document.title = 'Update product | Sallatk'

  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()

  // Convert 'id' to a number if needed
  const numericId: number = id as unknown as number

  type readyProduct = {
    title: string
    slug: string
    short_description: string
    description: string
    sku: string
    MPN: string
    GTIN: string
    points: number | null
    brand_id: number | null
    published: number
    cost: number | null
    price: number | null
    weight_unit: string
    weight: number | null
    quantity: number | null
    badge_id: number | null
    categories: number[]
    fees: number[]
    main_image: string
    images: string[]
    labels: number[]
    form_id: number | null
    discount: number | null
    discount_type: string
    is_free_shipping: number | null
    cod_allowed: 0 | 1
    stock_min_alert: number | null
    shipping_allowed: 0 | 1
    ready_in: number | null
    ready_in_type: string
    similar_products: number[]
    meta: {
      title: ''
      keywords: ''
      description: ''
    }
  }

  // prepare hock
  // const {
  //   register,
  //   handleSubmit,
  //   errors,
  //   update,
  //   loading,
  //   closeUploading,
  //   openUploading,
  //   setValue,
  //   getValues,
  //   getLoading,
  //   control
  // } = UpdateHock<readyProduct>(
  //   '/ready_product',
  //   {
  //     title: '',
  //     slug: '',
  //     short_description: '',
  //     description: '',
  //     sku: '',
  //     MPN: '',
  //     GTIN: '',
  //     points: null,
  //     brand_id: null,
  //     published: 1,
  //     cost: null,
  //     price: null,
  //     weight_unit: '',
  //     weight: null,
  //     quantity: null,
  //     badge_id: null,
  //     categories: [],
  //     fees: [],
  //     main_image: '',
  //     images: [],
  //     labels: [],
  //     form_id: null,
  //     discount: null,
  //     discount_type: '',
  //     is_free_shipping: null,
  //     cod_allowed: 1,
  //     stock_min_alert: null,
  //     shipping_allowed: 1,
  //     ready_in: null,
  //     ready_in_type: '',
  //     similar_products: [],
  //     seo_title: '',
  //     seo_description: '',
  //     seo_keywords: ''
  //   },
  //   numericId
  // )

  const { result, loading, error } = FetchHock<any>({
    path: `/ready_product/${numericId}/edit`
  })

  const [opend, setOpen] = useState<string>('')
  const selectLable = (label: string) => setOpen(label)

  // const submit = async (data: readyProduct) => {
  //   const response = await update(data)
  //   if (response.status) navigate('/products/list')
  // }

  if (loading) {
    return (
      <div className="page-content">
        <Loader />
      </div>
    )
  }
  if (error) {
    return (
      <div className="page-content">
        <Error />
      </div>
    )
  }

  return (
    // <div className="page-content">
    //   <Container fluid>

    //     {getLoading && <Loader />}
    //     {!getLoading && (
    //       <Row>
    //         <Col lg={8}>
    //           <Form onSubmit={handleSubmit(submit)}>
    //             <MainDetails
    //               register={register}
    //               errors={errors}
    //               setValue={setValue}
    //               getValues={getValues}
    //             />
    //             <MainPrice register={register} errors={errors} getValues={getValues} />
    //             <Gallery
    //               register={register}
    //               errors={errors}
    //               openUploading={openUploading}
    //               closeUploading={closeUploading}
    //               getValues={getValues}
    //             />

    //             <Accordion
    //               toggle={() => {}}
    //               className="lefticon-accordion custom-accordionwithicon accordion-border-box"
    //               open="test">
    //               <AccordionItem>
    //                 <Specification
    //                   isOpen={opend == 'spicifcation'}
    //                   toggleItem={() => selectLable('spicifcation')}
    //                   register={register}
    //                   getValues={getValues}
    //                 />
    //               </AccordionItem>
    //               <AccordionItem>
    //                 <OrderForm
    //                   isOpen={opend == 'orderForm'}
    //                   toggleItem={() => selectLable('orderForm')}
    //                   register={register}
    //                   errors={errors}
    //                   getValues={getValues}
    //                 />
    //               </AccordionItem>
    //             </Accordion>
    //             <div className="text-end mb-3 m-2">
    //               <Submit loading={loading} label={t('Save')} />
    //             </div>
    //           </Form>
    //         </Col>

    //         <Col lg={4}>
    //           <Details register={register} errors={errors} getValues={getValues} />
    //           <Stock register={register} errors={errors} />
    //           <Similar register={register} errors={errors} getValues={getValues} />
    //           <Shipping register={register} errors={errors} getValues={getValues} />
    //           <Info register={register} errors={errors} />
    //           <Seo register={register} errors={errors} />
    //         </Col>
    //       </Row>
    //     )}
    //   </Container>
    // </div>
    <ProductDetails data={result} />
  )
}

export default Product
