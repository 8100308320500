import { UseProfile } from 'helpers/UserHooks'

function DefaultCurrency() {
  const { userProfile } = UseProfile()
  return <>{userProfile?.settings.default_currency?.code}</>
}

export const useDefaultCurrency = () => {
  const { userProfile } = UseProfile()
  return userProfile?.settings.default_currency?.code
}

export default DefaultCurrency
