// react elements
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
// botstrab elements
import { Card, CardBody, CardHeader, Row } from 'reactstrap'
import SimpleBar from 'simplebar-react'
import { useFormContext } from 'react-hook-form'

const Features = () => {
  const { t } = useTranslation()
  const { register } = useFormContext()

  const model = register('productCards')

  // options logic
  const [cardsList, updateCardsList] = useState<string[]>([''])

  const updateValue = (index: number, value: string) => {
    const oldCards = [...cardsList]
    oldCards[index] = value
    updateCardsList(oldCards)
  }

  const addCard = () => {
    const oldCards = [...cardsList]
    oldCards.push('')
    updateCardsList(oldCards)
  }

  const removeCard = (index: number) => {
    const oldCards = [...cardsList]
    oldCards.splice(index, 1)
    updateCardsList(oldCards)
  }

  useEffect(() => {
    const name = model?.name
    model.onChange({
      target: {
        name,
        value: cardsList
      }
    })
  }, [cardsList])

  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          {/* card title */}
          <div className="col-sm">
            <h5 className="card-title">{t('Cards')}</h5>
          </div>
          {/* space auto */}
          <div className="col-sm-auto">
            <button type="button" className="btn btn-secondary" style={{ position: 'relative' }}>
              <input
                type="file"
                style={{
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  top: 0,
                  left: 0,
                  opacity: 0
                }}
                onChange={(event) => {
                  const reader = new FileReader()
                  reader.onload = (event2) => {
                    // @ts-ignore
                    const codes = event2.target?.result?.split('\n')
                    updateCardsList(codes)
                  }
                  // @ts-ignore
                  reader.readAsText(event.target.files[0])
                  event.target.value = ''
                }}
              />
              <i className="ri-file-upload-line align-bottom"></i> {t('Import')}
            </button>
          </div>
        </Row>
      </CardHeader>
      <CardBody className="p-2">
        <SimpleBar className="p-2" style={{ maxHeight: '300px' }}>
          {cardsList.map((card, index) => (
            <div className={'d-flex mb-2'} key={index}>
              <input
                className={'form-control'}
                placeholder={t('Enter card')}
                onChange={(event) => {
                  updateValue(index, event.target.value)
                }}
                value={card}
              />
              <button
                className="btn btn-outline-danger ms-2"
                type="button"
                disabled={cardsList.length <= 1}
                onClick={() => removeCard(index)}>
                <i className="ri-close-line align-middle"></i>
              </button>
            </div>
          ))}
        </SimpleBar>
        <div className="p-2">
          <button className="btn btn-secondary w-100" type="button" onClick={addCard}>
            <i className="ri-add-line align-middle"></i> {t('Add card')}
          </button>
        </div>
      </CardBody>
    </Card>
  )
}

export default Features
