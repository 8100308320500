// react imports
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
// bootstrap elements
import { Button, Col, Container, Row } from 'reactstrap'
// form hock
import CreateHook from 'helpers/CreateHook'
// requierd components
import { Input, Submit, Select, Switch, TimePicker } from 'Components/Form'
import PopUp from 'Components/PopUp'

const CreateModal = ({ close, show, onSuccess }: any) => {
  const params = useParams()
  const { t } = useTranslation()

  const days = [
    {
      label: t('Saturday'),
      value: 'saturday'
    },
    {
      label: t('Sunday'),
      value: 'sunday'
    },
    {
      label: t('Monday'),
      value: 'monday'
    },
    {
      label: t('Tuesday'),
      value: 'tuesday'
    },
    {
      label: t('Wednesday'),
      value: 'wednesday'
    },
    {
      label: t('Thursday'),
      value: 'thursday'
    },
    {
      label: t('Friday'),
      value: 'friday'
    }
  ]

  type branchstype = {
    id?: number
    branch_id: number
    day: string
    from_time: string
    to_time: string
    status: boolean
  }

  // prepare hock
  const { register, handleSubmit, errors, create, loading } = CreateHook<branchstype>(
    '/branch/workingHours',
    {
      branch_id: parseInt(params.id!),
      day: '',
      from_time: '',
      to_time: '',
      status: true
    }
  )

  const submit = async (data: branchstype) => {
    const response = await create(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  return (
    <PopUp title={t('Add work time')} show={show} close={close}>
      <form onSubmit={handleSubmit(submit)}>
        <Row>
          <Col md={12}>
            <Select
              label={t('Day')}
              placeholder={t('Enter day')}
              options={days}
              model={register('day', {
                required: t('Day is required')
              })}
              error={errors.day}
            />
          </Col>
          <Col xs={6}>
            <TimePicker
              label={t('Start time')}
              placeholder={t('Enter start time')}
              model={register('from_time', {
                required: t('Start time is required')
              })}
              error={errors.from_time}
            />
          </Col>
          <Col xs={6}>
            <TimePicker
              label={t('End time')}
              placeholder={t('Enter end time')}
              model={register('to_time', {
                required: t('End time is required')
              })}
              error={errors.to_time}
            />
          </Col>

          <Col md={6}>
            <Switch
              label={t('Status')}
              model={register('status')}
              error={errors.status}
              defaultChecked={true}
            />
          </Col>
        </Row>

        <div className="hstack gap-2 justify-content-end mt-3">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Create')} />
        </div>
      </form>
    </PopUp>
  )
}
export default CreateModal
