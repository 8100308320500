import { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import Masonry from 'react-masonry-component'
import { Link } from 'react-router-dom'
import { Button, Card, Col } from 'reactstrap'
import DeleteModal from './Common/DeleteModal'

function GalleryGrid({ data, onClickImage, selectedGalleryImage = '', onDelete = null }) {
  const [visibleItems, setVisibleItems] = useState(10)

  const [deleteId, setDeleteId] = useState(null)

  const { ref } = useInView({
    threshold: 1.0,
    onChange(inView) {
      if (inView) {
        setVisibleItems((prev) => prev + 10)
      }
    }
  })

  return (
    <>
      <Masonry className="row gallery-wrapper">
        {data?.slice(0, visibleItems)?.map(({ url, path, created_at }, key) => (
          <Col
            xxl={3}
            xl={3}
            lg={4}
            sm={6}
            className="element-item project designing development"
            key={key}>
            <Card
              className="gallery-box border bg-light"
              style={{
                backgroundColor: selectedGalleryImage === url ? '#25a0e2' : ''
              }}>
              <div className="gallery-container border">
                <Link
                  type="button"
                  className="image-popup d-block"
                  to="#"
                  title={path}
                  onClick={(e) => {
                    e.preventDefault()
                    onClickImage({
                      key,
                      path,
                      url
                    })
                  }}>
                  <img
                    loading="lazy"
                    className="gallery-img img-fluid mx-auto w-100"
                    src={url}
                    alt={path || 'Gallery Image'}
                    // style={{
                    //   aspectRatio: '1 / 1',
                    //   objectFit: 'contain'
                    // }}
                  />
                  <div className="gallery-overlay">
                    <h5
                      className="overlay-caption "
                      style={{
                        overflow: 'hidden'
                      }}>
                      {path}
                    </h5>
                  </div>
                </Link>
              </div>
              {onDelete && (
                <div className="box-content">
                  <div className="d-flex align-items-center mt-1">
                    <div className="flex-grow-1 text-muted">
                      <a
                        className="text-dark text-truncate"
                        href="/velzon/react-ts/minimal/pages-gallery">
                        {created_at}
                      </a>
                    </div>
                    <div className="flex-shrink-0">
                      <Button
                        onClick={() => setDeleteId(path)}
                        className="btn-link"
                        outline
                        size="sm"
                        color="danger"
                        type="button">
                        <i className="ri-delete-bin-line"></i>{' '}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </Card>
          </Col>
        ))}
      </Masonry>
      <div ref={ref} className="text-center mb-5 bg-white"></div>
      {deleteId && (
        <DeleteModal
          show={Boolean(deleteId)}
          recordId={deleteId}
          onDeleteClick={() => {
            onDelete(deleteId)
            setDeleteId(null)
          }}
          onCloseClick={() => {
            setDeleteId(null)
          }}
        />
      )}
    </>
  )
}

export default GalleryGrid
