import { useDefaultCurrency } from 'Components/DefaultCurrency'
import { Input, Select } from 'Components/Form'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Label, Row } from 'reactstrap'

const DiscountInput = () => {
  const { t } = useTranslation()
  const {
    register,
    formState: { errors }
  } = useFormContext()
  const defaultCurrency = useDefaultCurrency()
  return (
    <div className="col-6">
      <Label className="form-label" htmlFor="product-title-input">
        {t('Discount')}
      </Label>
      <div className="d-flex">
        <Select
          placeholder={t('Type')}
          className="rounded-start-1"
          options={[
            {
              label: '%',
              value: 'perc'
            },
            {
              label: defaultCurrency,
              value: 'net'
            }
          ]}
          model={register('discount_type')}
          error={errors.discount_type}
        />
        <Input
          placeholder={t('Enter discount')}
          className="rounded-end-1"
          type="number"
          model={register('discount')}
          error={errors.discount}
        />
      </div>
      {/* <Row>
      <Input
          label={t('Discount')}
          placeholder={t('Enter discount')}
          className="rounded-end-1 "
          type="number"
          model={register(`discount`)}
          wrapperClassName="col-6"
        />
        <Select
          label={t('Discount Type')}
          placeholder={t('Type')}
          wrapperClassName="col-6"
          className="rounded-start-1"
          options={[
            {
              label: '%',
              value: 'perc'
            },
            {
              label: const defaultCurrency = useDefaultCurrency(),
              value: 'net'
            }
          ]}
          model={register(`discount_type`)}
          value={getValues(`discount_type`)}
        />

       
      </Row> */}
    </div>
  )
}

export default DiscountInput
