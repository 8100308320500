// react includes
import { useState } from 'react'
// botstrap elemnts
import { Modal, ModalBody } from 'reactstrap'
// translation pakage
import { useTranslation } from 'react-i18next'

const DeleteModal = ({ show, onDeleteClick, onCloseClick }: any) => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState<boolean>(false)

  const deleteItem = (): void => {
    setLoading(true)
    onDeleteClick()
  }
  return (
    <Modal isOpen={!!show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <i className="ri-delete-bin-line display-5 text-danger"></i>
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>{t('Are you sure ?')}</h4>
            <p className="text-muted mx-4 mb-0">
              {t('Are you sure you want to remove this record ?')}
            </p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button
            type="button"
            className="btn w-sm btn-light"
            data-bs-dismiss="modal"
            onClick={onCloseClick}>
            {t('Close')}
          </button>
          {!loading && (
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={deleteItem}>
              {t('Yes, Delete It!')}
            </button>
          )}
          {loading && (
            <button type="button" className="btn w-sm btn-danger " id="delete-record" disabled>
              <i className="mdi mdi-spin mdi-loading"></i>
            </button>
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}

export default DeleteModal
