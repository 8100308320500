import { useQuery } from '@tanstack/react-query'
import http from 'services/axios'

const getPaymentMethods = async () => {
  try {
    const res = await http.get('/payments')

    return res.data.result
  } catch (error) {
    console.error('Error fetching payment methods:', error)
  }
}

export const usePOSGetPaymentMethods = () => {
  return useQuery({
    queryKey: ['payments'],
    queryFn: getPaymentMethods
  })
}
