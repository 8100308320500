import { useTranslation } from 'react-i18next'

import { Card, CardBody, CardHeader } from 'reactstrap'
import { Input } from 'Components/Form'
import { useFormContext } from 'react-hook-form'

const Info = () => {
  const { t } = useTranslation()
  const {
    register,
    formState: { errors }
  } = useFormContext()

  return (
    <Card>
      <CardHeader className="">
        <h5 className="card-title mb-0">{t('Info')}</h5>
      </CardHeader>
      <CardBody>
        <Input
          label={t('Points')}
          placeholder={t('Enter points')}
          type="number"
          model={register('points')}
          error={errors.points}
          atBeginIcon="mdi mdi-star-four-points"
        />
        <Input
          label={t('SKU')}
          placeholder={t('Enter SKU')}
          model={register('sku')}
          error={errors.sku}
          atBeginIcon="mdi mdi-barcode"
        />
        <Input
          label={t('MPN')}
          placeholder={t('Enter MPN')}
          model={register('MPN')}
          error={errors.MPN}
          atBeginIcon="mdi mdi-barcode"
        />
        <Input
          label={t('GTIN')}
          placeholder={t('Enter GTIN')}
          model={register('GTIN')}
          error={errors.GTIN}
          atBeginIcon="mdi mdi-barcode"
        />
      </CardBody>
    </Card>
  )
}

export default Info
