// botstrap elemnts
import { CardBody, Row, Col, UncontrolledCollapse } from 'reactstrap'
// flat picker
import Flatpickr from 'react-flatpickr'
// select input
import Select from 'react-select'
import AsyncReactSelect from 'react-select/async'
// translation pakage
import { useTranslation } from 'react-i18next'
// filter item type
import FilterItem from 'Types/FilterItem'
// fetch hock logic
import linkGenerator from 'helpers/linkGenerator'
import axios from 'axios'
import { debounce } from 'lodash'

const Filter = ({ filterItems }: { filterItems: FilterItem[] }) => {
  const { t } = useTranslation()

  return (
    <Row className="mb-3">
      <UncontrolledCollapse toggler="#filter-toogle">
        <CardBody className="border border-dashed border-end-0 border-start-0 no-side-border">
          <form>
            <Row className="g-3">
              {filterItems?.map((filterItem, index) => {
                switch (filterItem.type) {
                  case 'search':
                    return (
                      <Col key={index} sm={filterItem.col ? filterItem.col : 3}>
                        <div className="search-box mb-2 d-inline-block w-100">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder={
                              filterItem.placeholder ? filterItem.placeholder : t('Search for Item')
                            }
                            onChange={(event) => filterItem.action(event.target.value)}
                          />
                          <i className="bx bx-search-alt search-icon"></i>
                        </div>
                      </Col>
                    )
                  case 'date':
                    return (
                      <Col key={index} sm={filterItem.col ? filterItem.col : 3}>
                        <div>
                          <Flatpickr
                            className="form-control"
                            placeholder={
                              filterItem.placeholder ? filterItem.placeholder : t('Select date')
                            }
                            options={{
                              altInput: true,
                              altFormat: 'F j, Y',
                              mode: 'single',
                              dateFormat: 'd.m.y'
                            }}
                            onChange={(value) => filterItem.action(value)}
                          />
                        </div>
                      </Col>
                    )
                  case 'select':
                    return (
                      <Col key={index} sm={filterItem.col ? filterItem.col : 3}>
                        <div className="form-control p-0">
                          <Select
                            options={filterItem.options}
                            isClearable
                            name="choices-single-default"
                            placeholder={
                              filterItem.placeholder ? filterItem.placeholder : t('Select ...')
                            }
                            onChange={(
                              value: {
                                key: string
                                value: string
                              } | null
                            ) => {
                              if (value) filterItem.action(value.value)
                              else filterItem.action('')
                            }}
                          />
                        </div>
                      </Col>
                    )
                  case 'asyncSelect':
                    // options logic
                    const loadOptions = (
                      inputValue: string,
                      callback: (options: { label: string; value: string }[]) => void
                    ) => {
                      if (filterItem.isDisabled) {
                        callback([])
                        return
                      }

                      axios
                        .get(linkGenerator('/' + filterItem.path), {
                          params: {
                            term: inputValue,
                            page: 1,
                            limit: 10,
                            sort_direction: 'desc',
                            sort_by: 'id'
                          }
                        })
                        .then((response) => {
                          const items = response?.data?.result?.data ?? response?.data?.result
                          callback(
                            items.map((item: any) => {
                              return {
                                label: item.name ?? item.title ?? item.id,
                                value: item.id
                              }
                            })
                          )
                        })
                        .catch(() => {
                          callback([])
                        })
                    }

                    const SelectComponent = (
                      <div id={filterItem.id}>
                        <AsyncReactSelect
                          key={filterItem.path}
                          loadOptions={debounce(loadOptions, 500)}
                          isClearable
                          placeholder={filterItem.placeholder}
                          className="form-control p-0"
                          onChange={(
                            value: {
                              label: string
                              value: string
                            } | null
                          ) => {
                            if (value) filterItem.action(value.value)
                            else filterItem.action('')
                          }}
                          defaultOptions
                          isDisabled={filterItem?.isDisabled}
                        />
                      </div>
                    )

                    return (
                      <Col key={index} sm={filterItem.col ? filterItem.col : 3}>
                        <div className="search-box mb-2 d-inline-block w-100">
                          {filterItem.renderItem
                            ? filterItem.renderItem(SelectComponent)
                            : SelectComponent}
                        </div>
                      </Col>
                    )
                  case 'reset':
                    return (
                      <Col key={index} sm={filterItem.col ? filterItem.col : 3}>
                        <div>
                          <button type="button" className="btn btn-soft-primary w-100">
                            <i className="ri-equalizer-fill me-1 align-bottom"></i>
                            {filterItem.placeholder ? filterItem.placeholder : t('Reset filter')}
                          </button>
                        </div>
                      </Col>
                    )
                  case 'custom':
                    const Component = filterItem?.Component ?? null
                    if (!Component) return null
                    return (
                      <Col key={index} sm={filterItem?.col ? filterItem.col : 3}>
                        <Component {...filterItem?.props} />
                      </Col>
                    )
                }
              })}
            </Row>
          </form>
        </CardBody>
      </UncontrolledCollapse>
    </Row>
  )
}

export default Filter
