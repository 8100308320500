import { InputObject, Items, TransformedObject } from "../models/types"

function transformToKeyValue(data: Items[]) {
    const transformedObject = data.reduce((acc: any, item) => {
      acc[item.key] = item.value
      return acc
    }, {})
    return transformedObject
  }
  
  function removeEmptyValues(obj: any) {
    const filteredEntries = Object.entries(obj).filter(([, v]) => {
      return v !== null && v !== undefined && v !== '' && !Number.isNaN(v)
    })
    return filteredEntries.length > 0 ? Object.fromEntries(filteredEntries) : null
  }
  
  function transformDataBeforeSubmit(inputObj: InputObject): TransformedObject {
    const transformedData = Object.keys(inputObj).map((key) => ({
      key,
      value: inputObj[key]
    }))
    return { data: transformedData }
  }

  export { transformToKeyValue, removeEmptyValues, transformDataBeforeSubmit }