import { DatePicker, ImageUpload, Input, Select, Submit, Switch } from 'Components/Form'
import CreateHook from 'helpers/CreateHook'
import { useTranslation } from 'react-i18next'
import { Button, Col, Container, Row } from 'reactstrap'
import PopUp from '../../../Components/PopUp'
import PhoneField from './PhoneField'

type CreateCustomer = Omit<Customers, 'id'>

const CreateModal = ({ close, show, onSuccess }: any) => {
  const { t } = useTranslation()

  // prepare hock
  const {
    register,
    handleSubmit,
    errors,
    create,
    loading,
    closeUploading,
    openUploading,
    getValues
  } = CreateHook<CreateCustomer>('/customers', {
    password: '',
    name: '',
    lastname: '',
    email: '',
    dialing_code: '',
    phone: '',
    gender: null,
    is_active: 0,
    image: '',
    web_image: '',
    birthdate: null
  })

  const submit = async (data: CreateCustomer) => {
    const response = await create(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  return (
    <PopUp title={t('Create new customer')} show={show} close={close}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={6}>
              <Input
                label={t('Name')}
                model={register('name', {
                  required: t('Name is required')
                })}
                error={errors.name}
              />
            </Col>
            <Col md={6}>
              <Input
                label={t('Last Name')}
                model={register('lastname', {
                  required: t('Last name is required')
                })}
                error={errors.lastname}
              />
            </Col>
            <Col md={12}>
              <Input
                label={t('Email')}
                model={register('email', {
                  required: t('Email is required'),
                  pattern: {
                    value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                    message: t('Invalid email format')
                  }
                })}
                error={errors.email}
              />
            </Col>
            <Col md={12}>
              <Input
                type="password"
                label={t('Password')}
                model={register('password', {
                  required: t('Password is required')
                })}
                error={errors.password}
              />
            </Col>
            <PhoneField register={register} errors={errors} getValues={getValues}>
              <Col md={6}>
                <PhoneField.Code />
              </Col>
              <Col md={6}>
                <PhoneField.Number />
              </Col>
            </PhoneField>
            <Col md={6}>
              <Select
                label={t('Gender')}
                placeholder={`${t('Enter')} ${t('Gender')}`}
                model={register('gender')}
                error={errors.gender}
                options={[
                  { value: 'male', label: t('Male') },
                  { value: 'female', label: t('Female') }
                ]}
              />
            </Col>
            <Col md={6}>
              <DatePicker
                label={t('Birthdate')}
                placeholder={`${t('Enter')} ${t('Birthdate')}`}
                model={register('birthdate')}
                error={errors.birthdate}
              />
            </Col>

            <Col md={12}>
              <ImageUpload
                label={t('Image')}
                model={register('image', {
                  required: t('Image is required')
                })}
                error={errors.image}
                onUpload={openUploading}
                onFinishUpload={closeUploading}
              />
            </Col>
            <Col md={12}>
              <Switch label={t('Active')} model={register('is_active')} />
            </Col>
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Create')} />
        </div>
      </form>
    </PopUp>
  )
}
export default CreateModal
