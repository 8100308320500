import clsx from 'clsx'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import { TabsData } from '../models/types'
import DynamicSettingsForm from './DynamicSettingsForm'

function SettingsTabs({ data }: { data: TabsData[] }) {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(data[0]?.group || '')

  if (!data || data.length < 1) return null
  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  return (
    <Row>
      <Col lg={12}>
        <Nav pills fill tabs color="secondary">
          {data.map((item, index) => (
            <NavItem key={index}>
              <NavLink
                color="secondary"
                className={clsx(
                  activeTab === item.group && 'active bg-secondary',
                  'cursor-pointer'
                )}
                onClick={() => toggleTab(item.group)}>
                {t(item?.group || '⚠️')}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <Container fluid>
          <TabContent activeTab={activeTab} className="pt-3">
            {data.map((item, index) => (
              <TabPane key={index} tabId={item.group}>
                <DynamicSettingsForm key={index} fields={item.items} />
              </TabPane>
            ))}
          </TabContent>
        </Container>
      </Col>
    </Row>
  )
}

export default SettingsTabs
