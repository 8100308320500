import { Card, CardBody, Input, Label } from 'reactstrap'

function ProfilePhotoCard({ profile }: any) {
  console.log('profile', profile)
  return (
    <Card className="mt-n5">
      <CardBody className="p-4">
        <div className="text-center">
          <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
            <img
              src={profile?.image}
              className="rounded-circle avatar-xl img-thumbnail user-profile-image"
              alt="user-profile"
            />
            <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
              <Input id="profile-img-file-input" type="file" className="profile-img-file-input" />
              <Label htmlFor="profile-img-file-input" className="profile-photo-edit avatar-xs">
                <span className="avatar-title rounded-circle bg-light text-body">
                  <i className="ri-camera-fill"></i>
                </span>
              </Label>
            </div>
          </div>
          <h5 className="fs-16 mb-1">{profile?.name}</h5>
          <p className="text-muted mb-0">{profile?.email}</p>
        </div>
      </CardBody>
    </Card>
  )
}

export default ProfilePhotoCard
