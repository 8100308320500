import GridBlock from './GridBlock/Config'

const config = [
  {
    title: 'Grid block',
    key: 'GridBlock',
    config: GridBlock
  }
]

export default config
