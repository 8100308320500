import { Link } from 'react-router-dom'
// botstrap elemnts
import {
  CardHeader,
  Row,
  ButtonGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
// translation pakage
import { useTranslation } from 'react-i18next'
// react link
import MultiRoutes from 'Types/MultiRoutes'

const TableHeader = ({
  title,
  showExportCSV,
  CSVLink,
  addRoute,
  addString,
  addAction,
  showFillter,
  addMultiRoutes
}: {
  title?: string
  showExportCSV: () => void
  CSVLink?: string
  addRoute?: string
  addString?: string
  addAction?: () => void
  showFillter: boolean
  addMultiRoutes?: MultiRoutes[]
}) => {
  const { t } = useTranslation()

  return (
    <CardHeader className="card-header border-0">
      <Row className="align-items-center gy-3 mb-3">
        {/* card title */}
        <div className="col-sm">
          <h5 className="card-title mb-0">{title}</h5>
        </div>
        {/* space auto */}
        <div className="col-sm-auto">
          {/* action buttons */}
          <div className="d-flex gap-1 flex-wrap">
            {/* toggle filter button */}
            {showFillter && (
              <button type="button" className="btn btn-secondary" id="filter-toogle">
                <i className="ri-filter-line"></i>
              </button>
            )}
            {/* add link */}
            {addMultiRoutes?.length && (
              <ButtonGroup>
                <UncontrolledDropdown>
                  <DropdownToggle tag="button" className="btn btn-secondary add-btn">
                    <i className="ri-add-circle-line align-middle me-1"></i>{' '}
                    {addString ? addString : t('Add')}
                  </DropdownToggle>
                  <DropdownMenu>
                    {addMultiRoutes.map((el, i) => (
                      <DropdownItem key={i} tag={Link} to={el.route}>
                        {el.title}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </ButtonGroup>
            )}
            {addRoute && (
              <Link to={addRoute} type="button" className="btn btn-secondary add-btn">
                <i className="ri-add-circle-line align-middle me-1"></i>{' '}
                {addString ? addString : t('Add')}
              </Link>
            )}
            {/* add action button */}
            {addAction && !addRoute && (
              <button type="button" className="btn btn-secondary add-btn" onClick={addAction}>
                <i className="ri-add-circle-line align-middle me-1"></i>{' '}
                {addString ? addString : t('Add')}
              </button>
            )}
            {/* export csv button */}
            {CSVLink && (
              <button type="button" className="btn btn-secondary" onClick={showExportCSV}>
                <i className="ri-file-download-line align-bottom me-1"></i> {t('Export')}
              </button>
            )}
          </div>
        </div>
      </Row>
    </CardHeader>
  )
}
export default TableHeader
