import { useTranslation } from 'react-i18next'

import { Card, CardBody, CardHeader, Col } from 'reactstrap'
import { LazySelect, MultiSearch } from 'Components/Form'
import { useFormContext } from 'react-hook-form'

const Similar = () => {
  const { t } = useTranslation()
  const {
    register,
    formState: { errors },
    getValues
  } = useFormContext()

  return (
   <div>
     {/* <Card>
      <CardHeader className="">
        <h5 className="card-title mb-0">{t('Taxes & fees')}</h5>
      </CardHeader>
      <CardBody>
            <LazySelect
              isMulti
              label={t('Taxes & fees')}
              placeholder={t('Select taxes & fees')}
              model={register('fees')}
              propTitle="title"
              error={errors.fees}
              path="/fees"
              defaultValue={getValues('fees_data')}
            />
      </CardBody>
    </Card> */}
    </div>
  )
}

export default Similar
