import Flatpickr from 'react-flatpickr'
import { Arabic } from 'flatpickr/dist/l10n/ar'

const DatePicker = ({
  label,
  placeholder,
  model,
  error,
  onChange,
  className,
  defaultValue
}: {
  label: string
  placeholder: string
  model?: any
  error?: any
  onChange?: (value: string) => void
  className?: string
  defaultValue?: Date
}) => {
  // id for input focus
  const randumId = Math.random().toString(36).substring(7)
  // name of input
  const name = model.name
  // change logic
  const changeSelect = (time: Date[]) => {
    const value = new Date(time.toString()).toISOString().split('T')[0]
    model?.onChange({
      target: {
        name,
        value: value
      }
    })
    if (onChange) onChange(value)
  }
  return (
    <div className="mb-3">
      <label htmlFor={randumId} className="form-label">
        {label}
      </label>
      <Flatpickr
        value={defaultValue}
        className={error ? 'form-control is-invalid' : 'form-control' + ' ' + className}
        placeholder={placeholder}
        onChange={changeSelect}
        options={{
          altInput: true,
          altFormat: 'F j, Y',
          mode: 'single',
          dateFormat: 'd.m.y',
          locale: Arabic
        }}
      />
      <div className="invalid-feedback">{error?.message}</div>
    </div>
  )
}

export default DatePicker
