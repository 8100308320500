import Block from './Block/Config'

const config = [
  {
    title: 'Block',
    key: 'Block',
    config: Block
  }
]

export default config
