// react elements
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
// botstrap elements
import { Button, Col, Container, Row } from 'reactstrap'
import { Input, Submit, Select, Checkbox } from 'Components/Form'
import CreateHook from 'helpers/CreateHook'
import PopUp from 'Components/PopUp'

const CreateModal = ({ close, show, onSuccess, region, branchId }: any) => {
  const params = useParams()
  const { t } = useTranslation()

  interface IForm {
    branch_id: number
    shipping_company_id: number
    costs: [
      {
        branch_region_id: number
        shipping_cost: number | null
        delivery_in_types: 'day' | 'hour' | 'minutes' | null
        delivery_in: string
        status: number
      }
    ]
  }

  // prepare hock
  const { register, handleSubmit, loading, create, errors } = CreateHook<IForm>(
    '/branchShippings',
    {
      branch_id: branchId,
      shipping_company_id: parseInt(params.id as string),
      costs: [
        {
          branch_region_id: region.branch_region_id,
          shipping_cost: region.shipping_cost,
          delivery_in_types: region.delivery_in_types ? region.delivery_in_types : 'day',
          delivery_in: region.delivery_in,
          status: region.status
        }
      ]
    }
  )

  const submit = async (data: IForm) => {
    const response = await create(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  return (
    <PopUp title={t('Edit region price')} show={show} close={close}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={12}>
              <Input
                label={t('Shipping cost')}
                placeholder={t('Select shipping cost')}
                type="number"
                model={register('costs.0.shipping_cost', {
                  required: t('Shipping cost is required')
                })}
                error={errors.costs?.[0]?.shipping_cost}
              />
            </Col>
            <Col xs={8}>
              <Input
                label={t('Delevary time')}
                placeholder={t('Select delevary time')}
                type="number"
                model={register('costs.0.delivery_in', {
                  required: t('Delevary time is required')
                })}
                error={errors.costs?.[0]?.delivery_in}
              />
            </Col>
            <Col xs={4}>
              <Select
                label={t('Type')}
                placeholder={t('Type')}
                options={[
                  {
                    label: t('day'),
                    value: 'day'
                  },
                  {
                    label: t('hour'),
                    value: 'hour'
                  },
                  {
                    label: t('minutes'),
                    value: 'minutes'
                  }
                ]}
                value={region.delivery_in_types}
                model={register('costs.0.delivery_in_types', {
                  required: t('Type is required')
                })}
                error={errors.costs?.[0]?.delivery_in_types}
              />
            </Col>
            <Col md={12}>
              <Checkbox label={t('Status')} model={register('costs.0.status')} />
            </Col>
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Apply')} />
        </div>
      </form>
    </PopUp>
  )
}
export default CreateModal
