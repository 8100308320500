import { LinkInputFormula } from 'ThemeManager/Templates/defaultFormula'
import Formula from 'ThemeManager/types/Formula/index'

const formula: Formula = [
  {
    title: 'Content',
    label: 'content',
    type: 'object',
    inputs: [
      {
        title: 'Title',
        label: 'title',
        type: 'translateString'
      },
      {
        title: 'App image',
        label: 'appImage',
        type: 'image'
      },
      {
        title: 'Data',
        label: 'subTitle',
        type: 'translateText'
      },
      {
        title: 'App buttons',
        label: 'appButtons',
        type: 'objectList',
        length: 2,
        inputs: [
          {
            title: 'Button image',
            label: 'appButton',
            type: 'image'
          },
          LinkInputFormula
        ]
      }
    ]
  }
]
export default formula
