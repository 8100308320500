import ImageWithFallback from 'helpers/ImageComponent'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Badge, Card, CardBody, CardHeader } from 'reactstrap'
//Logistics Details

// shipping_company
function ShippingDetails({ data }) {
  const { t } = useTranslation()
  return (
    <Card>
      <CardHeader>
        <div className="d-flex">
          <h5 className="card-title flex-grow-1 mb-0">
            <i className="mdi mdi-truck-fast-outline align-middle me-1 text-muted"></i>
            {t('Shipping Details')}
          </h5>
          <div className="flex-shrink-0">
            <Link to="#" className="badge bg-primary-subtle text-primary fs-11">
              Track Order
            </Link>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className="text-center">
          {/* <i className="ri-truck-line display-5 text-danger"></i> */}
          <ImageWithFallback src={data?.web_logo} alt={data?.title} className="avatar-lg rounded" />
          <h5 className="fs-16 mt-2">{data?.title}</h5>
          <p className="text-muted mb-0">{data?.id && t('ID') + ': ' + data?.id}</p>
          <p className="text-muted mb-0">
            {data?.is_active === 1 ? (
              <Badge color="success">{t('Active')}</Badge>
            ) : (
              <Badge color="danger">{t('Not active')}</Badge>
            )}
          </p>
        </div>
      </CardBody>
    </Card>
  )
}

export default ShippingDetails
