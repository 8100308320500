import { Container } from 'reactstrap'
// page BreadCrumb
import BreadCrumb from 'Components/Common/BreadCrumb'
// translation
import { useTranslation } from 'react-i18next'

import ThemeBuilder from 'ThemeManager/ThemeBuilder'

const FileManager = () => {
  const { t } = useTranslation()
  document.title = 'Customize theme| Sallatk'

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <ThemeBuilder />
      </Container>
    </div>
  )
}

export default FileManager
