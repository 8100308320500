import { useTranslation } from 'react-i18next'
import { Card, CardBody, CardHeader } from 'reactstrap'
import { createContext, useContext } from 'react'
import PaidFeaturesTable from './PaidFeaturesTable'
import DiscountInput from './component/DiscountInput'
import ProductFeaturesMultiImageUpload from './component/ProductFeaturesMultiImageUpload'
import { Search } from 'Components/Form'

const PaidFeaturesContext = createContext(null)
export const usePaidFeaturesContext = () => {
  const context = useContext(PaidFeaturesContext)
  if (!context) {
    throw new Error('usePaidFeaturesContext must be used within a PaidFeaturesProvider')
  }
  return context
}

function Features() {
  return (
    <CardBody className="p-0">
      <PaidFeaturesTable />
    </CardBody>
  )
}

export { Features }

const PaidFeatures = ({ isEdit, type }: { isEdit: boolean; type: 'paid' | 'plus' }) => {
  const { t } = useTranslation()
  const inputs = [
    {
      type: 'custom',
      label: t('Feature Type'),
      name: 'featuresType',
      // noPreview: true,
      renderItem: ({ ctx }) => (
        <Search
          ref={ctx.ref}
          label={t('Feature Type')}
          placeholder={t('Select Feature Type')}
          model={ctx.register('featuresType', {
            required: t('This field is required')
          })}
          error={ctx?.formState?.errors?.featuresType}
          // disabled={isEdit}
          path="features"
          propTitle="name"
          valueId={ctx.getValues('featuresType') || ctx.getValues('feature_id') || ''}
          onChange={(value) => {
            ctx?.resetFeatureOptionId()
            ctx?.setValue('feature_name', value.label)
          }}
          className="col-6"
        />
      )
    },
    {
      type: 'custom',
      label: t('Title'),
      name: 'featureOptionId',
      renderItem: ({ ctx }) => (
        <Search
          onChange={(value) => {
            ctx.setValue('title', value.label)
          }}
          ref={ctx.featuresTypeRef}
          label={t('Title')}
          placeholder={t('Select option')}
          model={ctx.register(`featureOptionId`, {
            required: t('This field is required')
          })}
          error={ctx?.formState?.errors?.featureOptionId}
          disabled={ctx.watch('featuresType') === undefined}
          path={'featureOptions'}
          propTitle={'title'}
          valueId={ctx.getValues('featureOptionId')}
          filter={{
            feature_id: ctx.watch('featuresType')
          }}
          className="col-6"
        />
      )
    },
    {
      type: 'text',
      label: t('Cost'),
      placeholder: t('Cost'),
      name: 'cost',
      disabled: false,
      required: true
    },
    {
      type: 'text',
      label: t('Price'),
      placeholder: t('Price'),
      name: 'price',
      disabled: false,
      required: true
    },

    {
      type: 'custom',
      name: 'discount',
      label: t('Discount'),
      renderItem: () => <DiscountInput />
    },
    {
      type: 'text',
      label: t('Quantity'),
      placeholder: t('Quantity'),
      name: 'quantity',
      disabled: isEdit
    },
    {
      type: 'text',
      label: t('Stock Alert'),
      placeholder: t('Stock Min Alert'),
      name: 'stock_min_alert',
      disabled: false
    },
    //stock_min_alert

    {
      type: 'custom',
      name: 'images',
      label: t('Images'),
      renderItem: () => (
        <div className="mb-3">
          <ProductFeaturesMultiImageUpload />
        </div>
      )
    }
  ]
  return (
    <PaidFeaturesContext.Provider
      value={{
        isEdit,
        paid: true,
        type,
        name: 'featureOptionsPaid',
        inputs: inputs
      }}>
      <Card>
        <CardHeader className="">
          <h5 className="card-title mb-0">{t('Paid Features')}</h5>
        </CardHeader>
        <Features />
      </Card>
    </PaidFeaturesContext.Provider>
  )
}

export default PaidFeatures

const DonationPaidFeatures = ({ isEdit, customInputs }: any) => {
  const { t } = useTranslation()
  const inputs = [
    {
      type: 'custom',
      label: t('Feature Type'),
      name: 'featuresType',
      // noPreview: true,
      renderItem: ({ ctx }) => (
        <Search
          ref={ctx.ref}
          label={t('Feature Type')}
          placeholder={t('Select Feature Type')}
          model={ctx.register('featuresType', {
            required: t('This field is required')
          })}
          error={ctx?.formState?.errors?.featuresType}
          // disabled={isEdit}
          path="features"
          propTitle="name"
          valueId={ctx.getValues('featuresType') || ctx.getValues('feature_id') || ''}
          onChange={(value) => {
            ctx?.resetFeatureOptionId()
            ctx?.setValue('feature_name', value.label)
          }}
          className="col-6"
        />
      )
    },
    {
      type: 'custom',
      label: t('Title'),
      name: 'featureOptionId',
      renderItem: ({ ctx }) => (
        <Search
          ref={ctx.featuresTypeRef}
          label={t('Title')}
          placeholder={t('Select option')}
          model={ctx.register(`featureOptionId`, {
            required: t('This field is required')
          })}
          error={ctx?.formState?.errors?.featureOptionId}
          disabled={ctx.watch('featuresType') === undefined}
          path={'featureOptions'}
          propTitle={'title'}
          valueId={ctx.getValues('featureOptionId')}
          filter={{
            feature_id: ctx.watch('featuresType')
          }}
          className="col-6"
        />
      )
    },
    {
      type: 'text',
      label: t('Price'),
      placeholder: t('Price'),
      name: 'price',
      disabled: false
    },
    {
      type: 'custom',
      name: 'images',
      label: t('Images'),
      renderItem: () => (
        <div className="mb-3">
          <ProductFeaturesMultiImageUpload />
        </div>
      )
    }
  ]
  return (
    <PaidFeaturesContext.Provider
      value={{
        isEdit,
        paid: true,
        type: 'paid',
        name: 'featureOptionsPaid',
        inputs: customInputs || inputs
      }}>
      <Card>
        <CardHeader className="">
          <h5 className="card-title mb-0">{t('Paid Features')}</h5>
        </CardHeader>
        <Features />
      </Card>
    </PaidFeaturesContext.Provider>
  )
}

const CustomFeatures = ({ isEdit }: any) => {
  const { t } = useTranslation()

  const inputs = [
    {
      type: 'custom',
      label: t('Feature Type'),
      name: 'featuresType',
      // noPreview: true,
      renderItem: ({ ctx }) => (
        <Search
          ref={ctx?.ref}
          label={t('Feature Type')}
          placeholder={t('Select Feature Type')}
          model={ctx?.register('featuresType', {
            required: t('This field is required')
          })}
          error={ctx?.formState?.errors?.featuresType}
          disabled={isEdit}
          path="features"
          propTitle="name"
          valueId={ctx?.getValues('featuresType') || ctx?.getValues('feature_id') || ''}
          onChange={(value) => {
            ctx?.resetFeatureOptionId()
            ctx?.setValue('feature_name', value.label)
          }}
          className="col-6"
        />
      )
    },
    {
      type: 'custom',
      label: t('Title'),
      name: 'featureOptionId',
      renderItem: ({ ctx }) => (
        <Search
          onChange={(value) => {
            ctx.setValue('title', value.label)
          }}
          ref={ctx?.featuresTypeRef}
          label={t('Title')}
          placeholder={t('Select option')}
          model={ctx?.register(`featureOptionId`, {
            required: t('This field is required')
          })}
          error={ctx?.formState?.errors?.featureOptionId}
          disabled={ctx?.watch('featuresType') === undefined}
          path={'featureOptions'}
          propTitle={'title'}
          valueId={ctx?.getValues('featureOptionId')}
          filter={{
            feature_id: ctx?.watch('featuresType')
          }}
          className="col-6"
        />
      )
    },
    {
      type: 'custom',
      name: 'images',
      label: t('Images'),
      renderItem: () => (
        <div className="mb-3">
          <ProductFeaturesMultiImageUpload />
        </div>
      )
    }
  ]

  return (
    <PaidFeaturesContext.Provider value={{ isEdit, name: 'custom_features', type: 'free', inputs }}>
      <Card>
        <CardHeader className="">
          <h5 className="card-title mb-0">{t('Features')}</h5>
        </CardHeader>
        <Features />
      </Card>
    </PaidFeaturesContext.Provider>
  )
}

export { CustomFeatures, DonationPaidFeatures }
