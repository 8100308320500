import { UncontrolledTooltip } from 'reactstrap'

function PaymentMethodTableCell({
  title,
  image,
  index
}: {
  title: string
  image: string
  index: number
}) {
  return (
    <>
      <UncontrolledTooltip placement="top" target={'methodImage' + index}>
        <div>{title}</div>
      </UncontrolledTooltip>
      {image ? (
        <img id={'methodImage' + index} height={30} src={image} alt={title} />
      ) : (
        <span id={'methodImage' + index}>{title}</span>
      )}
    </>
  )
}

export default PaymentMethodTableCell
