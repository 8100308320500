import { Slide } from 'react-toastify'
import Default from './GridBlock/Config'
import Slider from './Slider/Config'

const config = [
  {
    title: 'Grid block',
    key: 'GridBlock',
    config: Default
  }
  ,{
    title: 'Slider',
    key: 'Slider',
    config: Slider
  }
]

export default config
