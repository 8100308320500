// react imports
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
// bootstrap elements
import { Col, Container, Row } from 'reactstrap'

// reqired componnent
import BreadCrumb from 'Components/Common/BreadCrumb'
import IndexTable, { FilterItem, HeaderColumn } from 'Components/IndexTable'
// fetch hock logic
import FetchHock from 'helpers/FetchHock'

import axios from 'axios'
import EditLabel from '../../../../../Components/EditLabel'
import linkGenerator from '../../../../../helpers/linkGenerator'
import showSuccess from '../../../../../helpers/SuccessNotfication'
import { Input, Submit } from '../../../../../Components/Form'
import CreateHook from '../../../../../helpers/CreateHook'

type badges = {
  id: number
  title: string
}

const BadgesList = () => {
  const { t } = useTranslation()

  document.title = 'Badges | Sallatk'

  const fetchHock = FetchHock<badges>({ path: '/badges' })
  const headerColumns: HeaderColumn[] = [
    {
      title: 'Title',
      accessor: 'title'
    },
    {
      title: 'Action'
    }
  ]

  const filterItems: FilterItem[] = [
    {
      type: 'search',
      col: 6,
      placeholder: t('Search ...'),
      action: (value) => fetchHock.search(value)
    }
  ]

  type badgeType = {
    id?: number
    title: string
  }

  // prepare hock
  const { register, handleSubmit, errors, create, loading } = CreateHook<badgeType>('/badges', {
    title: ''
  })

  const submit = async (data: badgeType) => {
    const response = await create(data)
    if (response.status) {
      fetchHock.fetch()
    }
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />

        <Row>
          <Col lg={12}>
            <form onSubmit={handleSubmit(submit)}>
              <div className="align-items-center gy-3 mb-3 row">
                <Col md={7}>
                  <Input
                    label={t('Title')}
                    placeholder={t('Enter title')}
                    model={register('title', {
                      required: t('Title is required'),
                      minLength: {
                        value: 3,
                        message: t('Title must be bigger than or equal 3')
                      }
                    })}
                    error={errors.title}
                  />
                </Col>
                <Col md={3}>
                  <div
                    className="d-flex gap-1 flex-wrap"
                    style={{
                      marginTop: 'inherit'
                    }}>
                    <Submit loading={loading} label={t('Create')} />
                  </div>
                </Col>
              </div>
            </form>
            <IndexTable
              title={t('Badges list')}
              headerColumns={headerColumns}
              {...fetchHock}
              filters={filterItems}>
              {fetchHock.items?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className="form-check form-switch-md form-switch form-switch-secondary">
                      <EditLabel label={item.title} path={`/badges/${item.id}`} />
                    </div>
                  </td>

                  <td>
                    <a
                      className="btn btn-sm fs-14 btn-link text-decoration-none px-0 text-danger"
                      onClick={() => fetchHock.openDelete(item.id)}>
                      <i className="ri-delete-bin-line"></i>
                    </a>
                  </td>
                </tr>
              ))}
            </IndexTable>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default BadgesList
