import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

const AccessDenied = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [message, setMessage] = useState<string | null>(null)

  useEffect(() => {
    const messageParam = searchParams.get('message')
    if (messageParam) {
      setMessage(messageParam)
      setSearchParams({})
    } else {
      // Redirect to home if no message
      navigate('/')
    }
  }, [])

  document.title = 'Access denied | Sallatk'
  return (
    <div
      className="page-content "
      style={{
        minHeight: 'calc(100vh - 123px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
      }}>
      {/* <i className="ri-error-warning-line display-5 text-danger"></i>
      <i className="ri-time-line display-5 text-danger"></i> */}
      <i className="ri-alarm-warning-line display-5 text-danger"></i>
      <div className="mt-2 pt-2 fs-15 mx-4 mx-sm-5">
        <h4>{message}</h4>
        <div className="mt-4 text-center">
          <a href="/" className="btn btn-success">
            <i className="ri-home-line me-1"></i>
            {t('Go to Dashboard')}
          </a>
        </div>
      </div>
    </div>
  )
}

export default AccessDenied
