import { ListGroup } from 'reactstrap'

export interface categoryItem {
  id: number
  parent_id: number
  mobile_image: string
  title: string
  description: string
  number: number
  children: categoryItem[]
}

const CategoryTree = ({
  items,
  onUpdate,
  onDelete,
  selectDragItem,
  selectDropItem,
  dropId,
  dragParent,
  addOrder,
  removeOrder,
  onMove
}: {
  items: categoryItem[]
  onUpdate: (id: number) => void
  onDelete: (id: number) => void
  selectDragItem: (item: categoryItem) => void
  selectDropItem: (item: categoryItem) => void
  dropId: number | undefined
  dragParent?: number
  addOrder: (id: number) => void
  removeOrder: () => void
  onMove: () => void
}) => {
  return (
    <ListGroup className="nested-list nested-sortable">
      {items?.map((item, index) => (
        <>
          <li
            className="nested-1 list-group-item nested-1"
            key={index}
            draggable
            onDragCapture={() => selectDragItem(item)}
            onDragEnterCapture={() => selectDropItem(item)}
            onDragEndCapture={() => onMove()}>
            {/* item data */}
            <div className="d-flex align-items-center">
              <i className="ri-drag-move-fill align-bottom handle me-2"></i>
              <div className="avatar-xs flex-shrink-0 me-3">
                <img src={item.mobile_image} alt="" className="avatar-xs img-thumbnail" />
              </div>
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-0">{item.title}</h5>
                <p className="text-muted mb-0">{item.description}</p>
              </div>
              <div>
                <button
                  type="button"
                  onClick={() => onUpdate(item.id)}
                  className="btn btn-sm fs-14 btn-link text-decoration-none px-0 text-success">
                  <i className="ri-edit-2-line align-bottom me-1 text-success"></i>
                </button>

                <button
                  type="button"
                  onClick={() => onDelete(item.id)}
                  className="btn btn-sm fs-14 btn-link text-decoration-none px-0 text-danger">
                  <i className="ri-delete-bin-line align-bottom me-1  text-danger"></i>
                </button>
              </div>
            </div>
            {/* onDragLeave={() => removeOrder()} */}
            {dropId == item.id && (
              <div
                className="border border-primary text-center text-primary w-100 rounded-0 py-2 mt-1 border-dashed"
                draggable
                onDragOver={() => addOrder(1)}>
                +
              </div>
            )}
            {/* chieldren */}
            <CategoryTree
              items={item.children}
              onUpdate={onUpdate}
              onDelete={onDelete}
              selectDragItem={selectDragItem}
              selectDropItem={selectDropItem}
              dropId={dropId}
              dragParent={item.id}
              addOrder={addOrder}
              removeOrder={removeOrder}
              onMove={onMove}
            />
          </li>
          {/* onDragLeave={() => removeOrder()} */}
          {dragParent && dropId == dragParent && (
            <div
              className="border border-primary text-center text-primary w-100 rounded-0 py-2 mt-1 border-dashed"
              draggable
              onDragOver={() => addOrder(item.number + 1)}>
              +
            </div>
          )}
        </>
      ))}
    </ListGroup>
  )
}

export default CategoryTree
