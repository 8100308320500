import { Container, Row } from 'reactstrap'
import { Input, Submit, TextEditor } from 'Components/Form'
import UpdateHock from 'helpers/UpdateHock'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReadOnlyContent from './ReadOnlyContent'

function FilterObject(yourObject: any, keyString: string) {
  const filteredObject = Object.keys(yourObject).reduce((acc: any, key: any) => {
    if (key.startsWith(keyString)) {
      acc[key] = yourObject[key]
    }
    return acc
  }, {})

  return filteredObject
}

function LanguageForm({
  data,
  accessorKey,
  onSuccess,
  readOnly = false
}: {
  data: any
  accessorKey: string
  onSuccess?: any
  readOnly?: boolean
}) {
  const { t } = useTranslation()
  const params = useParams()
  const code = params.code
  const key = params.key
  const { register, handleSubmit, errors, loading, getValues, update } = UpdateHock<any>({
    path: '/translations/' + key + '/edit/' + code,
    initValue: FilterObject(data.formValues, accessorKey),
    allowGet: false
  })
  if (!data) return null
  const submit = async (formData: any) => {
    const response = await update({
      ...formData,
      id: data.formValues.id,
      parent_id: data.formValues.parent_id
    })
    if (response.status) {
      onSuccess && onSuccess()
    }
  }
  const FormInputsMap = {
    text: Input,
    content: TextEditor
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Container fluid>
        <Row>
          {data.formFields.map((field: any, index: number) => {
            const { column, label, type } = field
            //@ts-ignore
            const ComponentType = readOnly ? ReadOnlyContent : FormInputsMap[type] || Input

            const keyString = accessorKey + column
            return (
              <ComponentType
                key={index}
                disabled={readOnly}
                mode={readOnly ? 'view' : 'edit'}
                label={t(label)}
                placeholder={t(`Enter ${column}`)}
                model={register(keyString, {
                  required: t(`${column} is required`),
                  minLength: {
                    value: 3,
                    message: t(`${column} must be bigger than or equal 3`)
                  }
                })}
                value={getValues(keyString)}
                error={errors[keyString]}
              />
            )
          })}
        </Row>
      </Container>
      {readOnly ? null : (
        <div className="hstack gap-2 justify-content-end">
          <Submit loading={loading} label={t('Update')} />
        </div>
      )}
    </form>
  )
}

export default LanguageForm
