import { useTranslation } from 'react-i18next'
import { Alert, Button, Col, Container, Row } from 'reactstrap'
function PaymentFailed() {
  return (
    <div className="w-100 d-flex justify-content-center align-items-center vh-100">
      <FailedComponent />
    </div>
  )
}

export default PaymentFailed

const FailedComponent = () => {
  const { t } = useTranslation()

  return (
    <Container
      fluid
      className="text-center my-5"
      style={{
        maxWidth: '500px'
      }}>
      <Row>
        <Col>
          <Alert color={'danger'}>
            <h4 className="alert-heading">{t('paymentFailed')}</h4>
            <p>{t('paymentFailed')}</p>

            <p className="mb-0">
              {t('contactUs')} <a href="/contact">{t('contactUsLink')}</a>.
            </p>
            <div className="d-flex justify-content-center mt-3">
              <Button color={'danger'} href="/">
                {t('homeButton')}
              </Button>

              <Button color="warning" href="/#" className="ms-2">
                {t('retryButton')}
              </Button>
            </div>
          </Alert>
        </Col>
      </Row>
    </Container>
  )
}
