import { useDefaultCurrency } from 'Components/DefaultCurrency'
import ImageWithFallback from 'helpers/ImageComponent'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Badge, Card, CardBody, CardHeader } from 'reactstrap'

// Order #VL2667

function OrderDetailsTable({ data }) {
  const { t } = useTranslation()

  function withCurrency(num) {
    if (num) {
      return `${data?.currency ? data?.currency : ''} ${num}`
    }
    return '0.00'
  }
  return (
    <Card>
      <CardHeader>
        <div className="d-flex align-items-center">
          <h5 className="card-title flex-grow-1 mb-0">
            {t('Order')} #{data?.id}
          </h5>
          <div className="flex-shrink-0">
            <Link to="/apps-invoices-details" className="btn btn-primary btn-sm">
              <i className="ri-download-2-fill align-middle me-1"></i> Invoice
            </Link>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className="table-responsive table-card">
          <table className="table table-nowrap align-middle mb-0">
            <thead className="table-light text-muted">
              <tr>
                <th scope="col">{t('Image')}</th>
                <th scope="col">{t('Name')}</th>
                <th scope="col">{t('Quantity')}</th>
                <th scope="col">{t('Price')}</th>

                <th scope="col" className="text-end">
                  {t('Discount')}
                </th>
                <th scope="col" className="text-end">
                  {t('Amount')}
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.products?.map((product, key) => (
                <Product product={product} key={key} />
              ))}
              <tr className="border-top border-top-dashed">
                <td colSpan={5}></td>
                <td colSpan={1} className="fw-medium p-0">
                  <table className="table table-borderless mb-0">
                    <tbody>
                      <tr>
                        <td>{t('Sub Total')} :</td>
                        <td className="text-end">{data?.subtotal}</td>
                      </tr>
                      <tr>
                        <td>{t('Discount')} :</td>
                        <td className="text-end">- {data?.total_discount}</td>
                      </tr>
                      <tr>
                        <td>{t('Shipping cost')} :</td>
                        <td className="text-end">{data?.shipping_cost}</td>
                      </tr>
                      <tr>
                        <td>{t('Fees')} :</td>
                        <td className="text-end">{data?.total_fees}</td>
                      </tr>
                      <tr className="border-top border-top-dashed">
                        <th scope="row">{t('Total')} :</th>
                        <th className="text-end">
                          {data?.currency} {data?.total}
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  )
}

export default OrderDetailsTable

const Product = ({ product }: { product: OrderProduct }) => {
  const { t } = useTranslation()
  const defaultCurrency = useDefaultCurrency()

  return (
    <React.Fragment>
      <tr>
        <td>
          <div className="flex-shrink-0 avatar-md bg-light rounded p-1">
            <ImageWithFallback
              src={product.web_main_image}
              alt=""
              className="img-fluid d-block object-fit-cover"
            />
          </div>
        </td>
        <td>
          <h5 className="fs-15">{product?.title}</h5>
        </td>
        <td>{product.count}</td>
        <td>{product.price}</td>

        <td className="fw-medium text-end">
          <Badge color="success">
            {' '}
            {product.discount} {product.discount_type == 'perc' ? '%' : defaultCurrency}
          </Badge>{' '}
          - {product.total_discount}
        </td>

        <td className="fw-medium text-end">{product.count * product.price}</td>
      </tr>
      {product?.order_product_options?.map((option, index) => {
        return (
          <>
            <tr>
              <td></td>
              <td>
                {option?.options?.feature_title} :{' '}
                {option?.options?.color && (
                  <i className="ri-circle-fill me-2" style={{ color: option?.options?.color }}></i>
                )}{' '}
                <span className="text-success"> {option?.options?.title} </span>
              </td>
              <td>{product.count}</td>
              <td>{option?.price}</td>
              <td>
                {option?.discount}
                {option?.discount_type}
              </td>
              <td className="fw-medium text-end">{option?.price * product.count}</td>
            </tr>
          </>
        )
      })}
      {/* <tr>
        {product?.order_product_options.length > 0 && (
          <td style={{ padding: 0 }} colSpan={6}>
            <table
              className="table table-borderless table-sm align-middle mb-0"
              style={{
                width: '90%'
              }}>
              <thead
                style={{
                  backgroundColor: '#f2fafe'
                }}
                className="text-muted">
                <tr>
                  <th scope="col">{t('Title')}</th>
                  <th scope="col">{t('Quantity')}</th>
                  <th scope="col">{t('Price')}</th>
                  <th scope="col">{t('Discount')}</th>
                </tr>
              </thead>
              <tbody>
                {product?.order_product_options?.map((option, index) => {
                  return (
                    <>
                      <tr>
                        <td>
                          {option?.options?.feature_title} {option?.options?.title}{' '}
                          {option?.options?.color && (
                            <i
                              className="ri-circle-fill me-2"
                              style={{ color: option?.options?.color }}></i>
                          )}
                        </td>
                        <td>1</td>
                        <td>{option?.price}</td>
                        <td>
                          {option?.discount}
                          {option?.discount_type}
                        </td>
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </table>
          </td>
        )}
      </tr> */}
    </React.Fragment>
  )
}
