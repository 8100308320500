import { useTranslation } from 'react-i18next'
// bootstrap elements
import { Card, CardBody, Col, CardHeader, Row } from 'reactstrap'
// required components
import { ImageUpload, Input } from 'Components/Form'
import { useFormContext } from 'react-hook-form'
const CourseDetails = ({ openUploading, closeUploading }) => {
  const { t } = useTranslation()

  const {
    register,
    getValues,
    formState: { errors }
  } = useFormContext()

  return (
    <Card>
      <CardHeader className="">
        <h5 className="card-title mb-0">{t('Course details')}</h5>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={12}>
            <ImageUpload
              label={t('Intro')}
              model={register('intro')}
              error={errors.intro}
              onUpload={openUploading}
              onFinishUpload={closeUploading}
              value={getValues('intro_url')}
            />
          </Col>
          <Col md={12}>
            <Input
              label={t('Duration')}
              placeholder={t('Enter duration')}
              model={register('duration')}
              error={errors.duration}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default CourseDetails
