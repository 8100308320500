import { Button, CardBody, FormGroup, Input, Label } from 'reactstrap'
import CreateModal from './component/CreateModal'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

function TitleAndPrice({ item, my, isMyPackage }: { item: Package; my: MyType; isMyPackage }) {
  const { t } = useTranslation()
  const [showCreate, changeShowCreate] = useState<boolean>(false)
  const selectedPlan = item?.plans?.find((item) => item?.duration === my?.duration)
  const [planId, setPlanId] = useState<number>(isMyPackage ? selectedPlan?.id : null)

  return (
    <>
      {showCreate && (
        <CreateModal
          close={() => changeShowCreate(false)}
          show={showCreate}
          data={{ planId }}
          // onSuccess={fetch}
        />
      )}
      <CardBody className="h-100">
        <div>
          <h5 className="mb-1">{item.title}</h5>
          <p className="text-muted">{item.description}</p>
        </div>

        <div className="py-4 d-flex flex-column gap-3 justify-content-center align-items-center">
          {item?.plans?.map((plan, index) => {
            const isChecked = isMyPackage && selectedPlan?.id === plan?.id
            return (
              <FormGroup key={index} check className="d-flex gap-2 align-items-baseline">
                <Input
                  // disabled={isChecked}
                  // defaultChecked={isChecked}
                  id={item.title + plan.id}
                  type="radio"
                  value={plan.id}
                  name={item.title}
                  checked={planId === plan.id}
                  onChange={() => {
                    setPlanId(plan.id)
                  }}
                />
                <Label htmlFor={item.title + plan.id}>
                  <h2>
                    <sup>
                      <small>$</small>
                    </sup>
                    {plan.price}
                    <span className="fs-13 text-muted">
                      /{t('Per')} {plan.duration} {t('Month')}
                    </span>
                  </h2>
                </Label>
              </FormGroup>
            )
          })}
        </div>

        <div className="text-center plan-btn mt-2">
          <Button
            disabled={!planId}
            onClick={() => changeShowCreate(true)}
            className="btn btn-primary w-sm waves-effect waves-light">
            {isMyPackage && selectedPlan?.id === planId
              ? t('Renew')
              : !planId
              ? t('Choose a Plan')
              : t('Submit')}
          </Button>
          {/* <Button
              disabled={(isMyPackage && selectedPlan?.id === planId) || !planId || isPending}
              onClick={handleChoosePlan}
              className="btn btn-primary w-sm waves-effect waves-light">
              {isPending && <Spinner size="sm" className="me-2" />}
  
              {isMyPackage && selectedPlan?.id === planId
                ? t('Renew')
                : !planId
                ? t('Choose a Plan')
                : t('Submit')}
            </Button> */}
        </div>
      </CardBody>
    </>
  )
}
export default TitleAndPrice
