import { useParams } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import Loader from 'Components/Common/Loader'
import FetchHock from 'helpers/FetchHock'

import Error from 'Components/IndexTable/partials/Error'

const StocksDetails = () => {
  document.title = 'Stock Details | Sallatk'
  const { t } = useTranslation()
  const { id } = useParams()

  const {
    result: data,
    loading,
    error
  } = FetchHock<CustomersReviews>({
    path: `/stocks/${id}`
  })
  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center min-vh-100">
        <Loader />
      </div>
    )

  if (error)
    return (
      <div className="page-content">
        <Error />
      </div>
    )

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col xl={9}>
            {/* <OrderDetailsTable data={data} />
            <OrderStatus />
            <TransactionDetails data={data?.transactions || {}} /> */}
          </Col>
          <Col xl={3}>
            {/* <ShippingDetails data={data.shipping_company} />
            <CustomerDetails user={data?.user} />
            <BranchDetails data={data?.branch} /> */}
            {/* Shipping Address */}
            {/* <CardSix />  */}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default StocksDetails
