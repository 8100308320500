import { UseProfile } from 'helpers/UserHooks'

function DefaultLanguage() {
  return <div>DefaultLanguage</div>
}

export const useDefaultLanguage = () => {
  const { userProfile } = UseProfile()
  return userProfile?.settings.default_language?.code
}

export default DefaultLanguage
