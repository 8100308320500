export const iconArray = [
  { label: 'Alarm Warning', value: 'ri-alarm-warning-line' },
  { label: 'Bank Card', value: 'ri-bank-card-line' },
  { label: 'Chat Smile 3', value: 'ri-chat-smile-3-line' },
  { label: 'Cloud', value: 'ri-cloud-fill' },
  { label: 'Creative Commons Zero', value: 'ri-creative-commons-zero-line' },
  { label: 'Folder 3', value: 'ri-folder-3-line' },
  { label: 'Heart 3', value: 'ri-heart-3-fill' },
  { label: 'Map Pin 3', value: 'ri-map-pin-3-fill' },
  { label: 'Shopping Cart 2', value: 'ri-shopping-cart-2-fill' },
  { label: 'Alarm Warning 2', value: 'ri-alarm-warning-2-line' },
  { label: 'Arrow Down S Fill', value: 'ri-arrow-down-s-fill' },
  { label: 'Battery Line', value: 'ri-battery-line' },
  { label: 'Building 3 Line', value: 'ri-building-3-line' },
  { label: 'Chat History Line', value: 'ri-chat-history-line' },
  { label: 'Cloudy Line', value: 'ri-cloudy-line' },
  { label: 'Door Lock Line', value: 'ri-door-lock-line' },
  { label: 'Emotion Line', value: 'ri-emotion-line' },
  { label: 'Flashlight Line', value: 'ri-flashlight-line' },
  { label: 'Game Line', value: 'ri-game-line' },
  { label: 'Heart 3 Line', value: 'ri-heart-3-line' },
  { label: 'Key Line', value: 'ri-key-line' },
  { label: 'Mail Send Line', value: 'ri-mail-send-line' },
  { label: 'Map Pin 5 Line', value: 'ri-map-pin-5-line' },
  { label: 'Movie 2 Line', value: 'ri-movie-2-line' },
  { label: 'Pantone Line', value: 'ri-pantone-line' },
  { label: 'Pencil Ruler Line', value: 'ri-pencil-ruler-line' },
  { label: 'Rest Time Line', value: 'ri-rest-time-line' },
  { label: 'Scales 2 Line', value: 'ri-scales-2-line' },
  { label: 'Shirt Line', value: 'ri-shirt-line' },
  { label: 'Star Line', value: 'ri-star-line' },
  { label: 'Sunny Line', value: 'ri-sunny-line' },
  { label: 'T-Shirt Line', value: 'ri-t-shirt-line' },
  { label: 'User Add Line', value: 'ri-user-add-line' },
  { label: 'Video Chat Line', value: 'ri-video-chat-line' },
  { label: 'Voiceprint Line', value: 'ri-voiceprint-line' },
  { label: 'Wheelchair Line', value: 'ri-wheelchair-line' },
  { label: 'Add Box Line', value: 'ri-add-box-line' },
  { label: 'Bike Line', value: 'ri-bike-line' },
  { label: 'Bubble Chart Line', value: 'ri-bubble-chart-line' },
  { label: 'Building Line', value: 'ri-building-line' },
  { label: 'Checkbox Multiple Line', value: 'ri-checkbox-multiple-line' },
  { label: 'Clapperboard Line', value: 'ri-clapperboard-line' },
  { label: 'Contrast Line', value: 'ri-contrast-line' },
  { label: 'Delete Bin 3 Line', value: 'ri-delete-bin-3-line' },
  { label: 'Eye Line', value: 'ri-eye-line' },
  { label: 'Feather Pen Line', value: 'ri-feather-pen-line' },
  { label: 'Fingerprint 2 Line', value: 'ri-fingerprint-2-line' },
  { label: 'Folder Lock Line', value: 'ri-folder-lock-line' },
  { label: 'Gamepad Line', value: 'ri-gamepad-line' },
  { label: 'Hand Coin Line', value: 'ri-hand-coin-line' },
  { label: 'Headphone Line', value: 'ri-headphone-line' },
  { label: 'Keynote Line', value: 'ri-keynote-line' },
  { label: 'Lock Line', value: 'ri-lock-line' },
  { label: 'Map Pin 2 Line', value: 'ri-map-pin-2-line' }
]
