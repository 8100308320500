import { Button, Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Submit, Radio } from 'Components/Form'
import PopUp from 'Components/PopUp'
import UpdateHock from 'helpers/UpdateHock'

function LogoStyleModal({ show, close }: any) {
  const { t } = useTranslation()

  const { register, handleSubmit, errors, update, loading, watch } = UpdateHock<any>({
    path: '/settings',
    initValue: {
      logoStyle: ''
    },
    allowGet: false
  })

  const submit = async (data: any) => {
    const response = await update({
      data: [{ key: 'logo_style', value: data.logoStyle }]
    })
    if (response.status) {
      close()
    }
  }
  return (
    <PopUp title={t('Change Logo Style')} show={show} close={close}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col xs={6} className="text-center">
              <Radio
                model={register('logoStyle', { required: t('This field is required') })}
                value="standard"
                error={errors.logoStyle}
                label={t('Standard')}
                labelComponent={
                  <img
                    src={'/logoStyle/standardLogo.png'}
                    alt="Standard Logo"
                    className="img-fluid"
                  />
                }
              />
            </Col>
            <Col xs={6} className="text-center">
              <Radio
                model={register('logoStyle', { required: t('This field is required') })}
                value="centered"
                error={errors.logoStyle}
                label={t('Centered')}
                labelComponent={
                  <img
                    src={'/logoStyle/centeredLogo.png'}
                    alt="Centered Logo"
                    className="img-fluid"
                  />
                }
              />
            </Col>
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Create')} />
        </div>
      </form>
    </PopUp>
  )
}

export default LogoStyleModal
