import axios from 'axios'
import { toast } from 'react-toastify'

const LOCALSTORAGE_USER_KEY = 'authUser'

const http = axios.create({
  baseURL: process.env.REACT_APP_API_POS,
  timeout: 20000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': 'en'
  }
})

http.interceptors.request.use(
  (config) => {
    const user = localStorage.getItem(LOCALSTORAGE_USER_KEY)
    if (user) {
      const userData = JSON.parse(user)
      config.headers.Authorization = `Bearer ${userData.accessToken}`
    }

    // get unlimited response items
    config.params = { ...config.params, limit: 999 }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
http.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    toast.error(error?.response?.data?.message)
    return Promise.reject(error)
  }
)

export default http

export const setAuthorizationHeader = (token: string | null) => {
  http.defaults.headers.Authorization = token ? `Bearer ${token}` : null
}
