import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Col, Container, Input, Row } from 'reactstrap'

import BreadCrumb from 'Components/Common/BreadCrumb'
import IndexTable, { FilterItem, HeaderColumn } from 'Components/IndexTable'
import ImageWithFallback from 'helpers/ImageComponent'
import FetchHock from 'helpers/FetchHock'
import axios from 'axios'
import linkGenerator from 'helpers/linkGenerator'
import { useMutation } from '@tanstack/react-query'
import RatingComponent from '../components/RatingComponent'
import clsx from 'clsx'

const publishComment = async ({ id, payload }: { id: number; payload: any }) => {
  const response = await axios.put(linkGenerator(`/comments/${id}`), payload)
  return response.data
}

const CustomersReviewsList = () => {
  const { t } = useTranslation()
  document.title = 'Customers reviews | Sallatk'

  const fetchHook = FetchHock<CustomersReviews, any>({ path: '/comments' })
  const { search } = fetchHook

  const headerColumns: HeaderColumn[] = [
    {
      title: 'Id'
    },
    {
      title: 'Comment',
      accessor: 'comment'
    },
    {
      title: 'User',
      accessor: 'user.name'
    },
    {
      title: 'Product',
      accessor: 'product.name'
    },
    {
      title: 'Published',
      accessor: 'published'
    },
    {
      title: 'Rating',
      accessor: 'rating'
    },
    {
      title: 'Created at',
      accessor: 'created_at'
    },
    {
      title: 'View'
    }
  ]
  const fromOneToFiveArr: number[] = [1, 2, 3, 4, 5]
  const filterItems: FilterItem[] = [
    {
      type: 'asyncSelect',
      col: 2,
      placeholder: t('Product'),
      path: 'products',
      action: (value) => fetchHook.filter('product_id', value)
    },
    {
      type: 'asyncSelect',
      col: 2,
      placeholder: t('Customer'),
      path: 'customers',
      action: (value) => fetchHook.filter('user_id', value)
    },
    {
      type: 'select',
      col: 1,
      placeholder: t('Rating'),
      options: [
        {
          label: 'All',
          value: ''
        },
        ...fromOneToFiveArr.map((item) => ({
          label: item.toString(),
          value: item
        }))
      ],
      action: (value) => fetchHook.filter('rating', value)
    },
    {
      type: 'select',
      col: 1,
      placeholder: t('Status'),
      options: [
        {
          label: 'All',
          value: ''
        },
        {
          label: 'Published',
          value: '1'
        },
        {
          label: 'Unpublished',
          value: '0'
        }
      ],
      action: (value) => fetchHook.filter('published', value)
    },
    {
      type: 'date',
      col: 2,
      placeholder: t('Start date'),
      action: (value) => {
        fetchHook.filter('created_at', value[0], 'from')
      }
    },
    {
      type: 'date',
      col: 2,
      placeholder: t('End date'),
      action: (value) => {
        fetchHook.filter('created_at', value[0], 'to')
      }
    },
    {
      type: 'search',
      col: 2,
      placeholder: t('Search ...'),
      action: (value) => search(value)
    }
  ]

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <Row>
          <Col lg={12}>
            <IndexTable
              title={t('Customers Reviews')}
              headerColumns={headerColumns}
              {...fetchHook}
              filters={filterItems}>
              {fetchHook?.items?.map((item, index: number) => (
                <tr key={index}>
                  <td>
                    <h5 className="fs-14 mb-1">
                      <Link
                        to={`/customers-reviews/${item.id}`}
                        className="text-body text-uppercase">
                        #{item.id}{' '}
                      </Link>
                    </h5>
                  </td>
                  <td>{item?.comment}</td>
                  <td>
                    <div className="flex ">
                      <div className="avatar-sm bg-light rounded">
                        <ImageWithFallback
                          src={item.user?.image}
                          alt={item.user?.name}
                          className="img-fluid d-block"
                        />
                      </div>
                      <div>{item.user?.name || null + item.user?.lastname || null}</div>
                    </div>
                  </td>

                  <td>
                    <div className="flex ">
                      <div className="avatar-sm bg-light rounded overflow-hidden">
                        <ImageWithFallback
                          src={item?.product?.web_main_image}
                          alt={item?.product?.title}
                          className="img-fluid d-block object-fit-contain"
                        />
                      </div>
                      <div>
                        {item?.product?.title}
                        {/* <small className="text-muted d-block">
                          {item?.product?.short_description}
                        </small> */}
                      </div>
                    </div>
                  </td>
                  <td>
                    <PublishToggle
                      id={item?.id}
                      published={item?.published}
                      invalidateData={fetchHook.invalidate}
                    />
                  </td>
                  <td>
                    <RatingComponent value={item.rating} />
                  </td>

                  <td>{item.created_at}</td>
                  <td>
                    <div className="hstack gap-3 flex-wrap">
                      <Link color="link" to={`/customers-reviews/${item?.id}`}>
                        <i className="ri-eye-fill fs-16"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </IndexTable>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CustomersReviewsList

export const PublishToggle = ({ id, published, invalidateData }) => {
  const { mutate, variables, isPending } = useMutation({
    mutationFn: publishComment,
    onSettled: async () => {
      return await invalidateData()
    }
  })

  const update = (id, payload) => {
    mutate({ id, payload })
  }
  const getCheckboxValue = (value, id) => {
    if (isPending && variables.id === id) {
      return variables.payload.published === 1
    }
    return value
  }

  return (
    <div className={clsx('form-check form-switch-md form-switch', isPending && 'opacity-50')}>
      <Input
        className="form-check-input"
        type="checkbox"
        role="switch"
        onChange={() =>
          update(id, {
            published: published === 1 ? 0 : 1
          })
        }
        checked={getCheckboxValue(published === 1, id)}
      />
    </div>
  )
}
