// notfications helper
import { toast } from 'react-toastify'

const showSuccess = (message: string) => {
  toast(message, {
    position: 'top-right',
    hideProgressBar: true,
    closeOnClick: false,
    className: 'bg-success text-white'
  })
}

export default showSuccess
