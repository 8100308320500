// react elements
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
// bootstrap elements
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown
} from 'reactstrap'
// reqired componnent
import ImageWithFallback from 'helpers/ImageComponent'
import BreadCrumb from 'Components/Common/BreadCrumb'
import IndexTable, { FilterItem, HeaderColumn } from 'Components/IndexTable'
// fetch hock logic
import FetchHock from 'helpers/FetchHock'
// import model type
import List from '../models/List'

const Articles = () => {
  const { t } = useTranslation()

  document.title = 'Articles | Sallatk'

  const fetchHock = FetchHock<List>({ path: '/article' })

  const headerColumns: HeaderColumn[] = [
    {
      title: 'Title',
      accessor: 'title'
    },
    {
      title: 'Slug',
      accessor: 'slug'
    },
    {
      title: 'Category',
      accessor: 'category_id.title'
    },
    {
      title: 'Action'
    }
  ]

  const filterItems: FilterItem[] = [
    {
      type: 'search',
      col: 6,
      placeholder: t('Search ...'),
      action: (value) => fetchHock.search(value)
    }
  ]

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />

        <Row>
          <Col lg={12}>
            <IndexTable
              title={t('Articles')}
              addString={t('Add Article')}
              addRoute="/articles/create"
              headerColumns={headerColumns}
              {...fetchHock}
              filters={filterItems}>
              {fetchHock.items?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm bg-light rounded p-1">
                          <ImageWithFallback
                            src={item.mobile_image}
                            alt={item.title}
                            className="img-fluid d-block"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="fs-14 mb-1">{item.title}</h5>
                      </div>
                    </div>
                  </td>
                  <td>{item.slug ? item.slug : ''}</td>
                  <td>{item.category_id?.title ?? ''}</td>
                  <td>
                    <div className="hstack gap-3 flex-wrap">
                      <UncontrolledDropdown>
                        <DropdownToggle href="#" className="btn btn-secondary btn-sm" tag="button">
                          <i className="ri-more-fill" />
                        </DropdownToggle>
                        <DropdownMenu container={'body'} className="dropdown-menu-end">
                          <DropdownItem tag={Link} to={`/articles/${item.id}`}>
                            <i className="ri-edit-2-line me-2"></i>
                            {t('Edit')}
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem onClick={() => fetchHock.openDelete(item.id)}>
                            <i className="ri-delete-bin-line me-2"></i>
                            {t('Delete')}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </td>
                </tr>
              ))}
            </IndexTable>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Articles
