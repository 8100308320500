// react imports
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
// bootstrap elements
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledDropdown
} from 'reactstrap'
import ImageWithFallback from 'helpers/ImageComponent'

// reqired componnent
import BreadCrumb from 'Components/Common/BreadCrumb'
import IndexTable, { FilterItem, HeaderColumn } from 'Components/IndexTable'
// fetch hock logic
import FetchHock from 'helpers/FetchHock'

import CreateModal from '../components/CreateModal'
import UpdateModal from '../components/UpdateModal'

import axios from 'axios'
import { useParams } from 'react-router-dom'

type featuresOptions = {
  id: number
  feature_title: string
  title: string
  type: string
  color?: string
}

const FeaturesOptionsList = () => {
  const { t } = useTranslation()
  const { id } = useParams()

  document.title = 'Features options | Sallatk'

  interface filter {
    feature_id: number | null
  }

  const fetchHock = FetchHock<featuresOptions, filter>({
    path: `/featureOptions`,
    initFilter: {
      feature_id: id
    }
  })

  const headerColumns: HeaderColumn[] = [
    {
      title: 'Title',
      accessor: 'title'
    },
    {
      title: 'Feature title',
      accessor: 'feature_title'
    },
    {
      title: 'Type',
      accessor: 'type'
    },
    {
      title: 'Action'
    }
  ]

  const filterItems: FilterItem[] = [
    {
      type: 'asyncSelect',
      col: 3,
      placeholder: t('Select Feature'),
      path: 'features',
      action: (value) => fetchHock.filter('feature_id', value)
    },
    {
      type: 'search',
      col: 6,
      placeholder: t('Search ...'),
      action: (value) => fetchHock.search(value)
    }
  ]

  // create modal logic
  const [showCreate, changeShowCreate] = useState<boolean>(false)
  const openCreate = () => changeShowCreate(true)
  const closeCreate = () => changeShowCreate(false)

  // update modal logic
  const [itemIdUpdate, changeItemIdUpdate] = useState<number>()
  const [showUpdate, changeShowUpdate] = useState<boolean>(false)
  const openUpdate = () => changeShowUpdate(true)
  const closeUpdate = () => changeShowUpdate(false)

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <Row>
          <Col lg={12}>
            <IndexTable
              title={t('Features options list')}
              addString={t('Add features option')}
              addAction={openCreate}
              headerColumns={headerColumns}
              {...fetchHock}
              filters={filterItems}>
              {fetchHock.items?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className={'d-flex align-items-center'}>
                      {item.type === 'color' && (
                        <button
                          type="button"
                          className="btn avatar-xs me-2 p-0 d-flex align-items-center justify-content-center border rounded-circle fs-22"
                          style={{ color: item.color }}>
                          <i className="ri-checkbox-blank-circle-fill"></i>
                        </button>
                      )}
                      {item.title}
                    </div>
                  </td>
                  <td> {item.feature_title} </td>
                  <td> {t(item.type)} </td>
                  <td>
                    <div className="hstack gap-3 flex-wrap">
                      <UncontrolledDropdown>
                        <DropdownToggle href="#" className="btn btn-secondary btn-sm" tag="button">
                          <i className="ri-more-fill" />
                        </DropdownToggle>
                        <DropdownMenu container={'body'} className="dropdown-menu-end">
                          <DropdownItem
                            onClick={() => {
                              openUpdate()
                              changeItemIdUpdate(item.id)
                            }}>
                            <i className="ri-edit-2-line me-2"></i>
                            {t('Edit')}
                          </DropdownItem>

                          <DropdownItem divider />
                          <DropdownItem onClick={() => fetchHock.openDelete(item.id)}>
                            <i className="ri-delete-bin-line me-2"></i>

                            {t('Delete')}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </td>
                </tr>
              ))}
            </IndexTable>
          </Col>
        </Row>
      </Container>
      {showCreate && (
        <CreateModal
          close={closeCreate}
          show={showCreate}
          onSuccess={fetchHock.fetch}
          featureId={id}
        />
      )}
      {showUpdate && (
        <UpdateModal
          close={closeUpdate}
          show={showUpdate}
          onSuccess={fetchHock.fetch}
          itemId={itemIdUpdate}
        />
      )}
    </div>
  )
}

export default FeaturesOptionsList
