import Block from './Block/Config'
import WithFlashSale from './WithFlashSale/Config'

const config = [
  {
    title: 'Block',
    key: 'Block',
    config: Block
  },
  {
    title: 'With flash sale',
    key: 'WithFlashSale',
    config: WithFlashSale
  }
]

export default config
