import Default from './Default/Config'
const config = [
  {
    title: 'Default',
    key: 'Default',
    config: Default
  }
]

export default config
