// bootstrap elements
import { Badge, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
// reqired componnent
import BreadCrumb from 'Components/Common/BreadCrumb'
// fetch hock logic
import FetchHock from 'helpers/FetchHock'
// translation pakage
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import Loader from 'Components/IndexTable/partials/Loader'
import Error from 'Components/IndexTable/partials/Error'
import Empty from 'Components/IndexTable/partials/Empty'
import Pagination from 'Components/IndexTable/partials/Pagination'
import CreateModal from '../components/CreateModal'
import UpdateModal from '../components/UpdateModal'
import DeleteModal from 'Components/IndexTable/partials/DeleteModal'
import MenuItem from '../components/MenuItem'

const FooterList = () => {
  document.title = 'Footer | Sallatk'

  const { t } = useTranslation()

  // create modal logic
  const [itemIdUpdate, changeItemIdUpdate] = useState<number>()
  const [showUpdate, changeShowUpdate] = useState<boolean>(false)
  const [showCreate, changeShowCreate] = useState<boolean>(false)
  const openCreate = () => changeShowCreate(true)
  const closeCreate = () => changeShowCreate(false)
  const openUpdate = () => changeShowUpdate(true)
  const closeUpdate = () => changeShowUpdate(false)

  const {
    items,
    loading,
    error,
    options,
    totalPages,
    totalItems,
    pagenate,
    openDelete,
    closeDelete,
    deleteItem,
    showedItems,
    deleteId,
    fetch
  } = FetchHock<Footer>({ path: '/menus/group' })

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <CardHeader className="card-header border-0">
          <Row className="align-items-center gy-3 mb-3">
            {/* card title */}
            <div className="col-sm"></div>
            {/* space auto */}
            <div className="col-sm-auto">
              {/* action buttons */}
              <div className="d-flex gap-1 flex-wrap">
                <button type="button" className="btn btn-secondary add-btn" onClick={openCreate}>
                  <i className="ri-add-circle-line align-middle me-1"></i> {t('Add new group')}
                </button>
              </div>
            </div>
          </Row>
        </CardHeader>
        <Row>
          {/*
                            <Col lg={12}>
                            <div className="">
                                <CardBody>
                                    <div className="row gx-5"> */}
          {items?.map((item, index) => (
            <div className="col-lg-6 col-xxl-4">
              <div className="card bg-primary-subtle">
                <div className="card-header bg-primary-subtle">
                  {/* <button type="button" className="btn-close float-end fs-11"
                                                        aria-label="Close"></button> */}
                  <div className="d-flex gap-2 float-end">
                    <button
                      type="button"
                      className="btn btn-soft-success btn-sm white-icon-onhover"
                      onClick={() => {
                        openUpdate()
                        changeItemIdUpdate(item.id)
                      }}>
                      <i className="ri-edit-2-line text-success" />
                    </button>
                    <button
                      type="button"
                      className="btn btn-soft-danger btn-sm white-icon-onhover"
                      onClick={() => openDelete(item.id)}>
                      <i className="ri-delete-bin-line text-danger" />
                    </button>
                  </div>
                  <h6 className="card-title mb-0">
                    {' '}
                    {item?.icon ? (
                      <i className="ri-shopping-cart-fill align-middle me-2"></i>
                    ) : (
                      ''
                    )}{' '}
                    {item.title} &nbsp;
                    <Badge pill color={item.display_title === 1 ? 'success' : 'danger'}>
                      {item.display_title === 1 ? 'displayed' : 'hidden'}
                    </Badge>
                  </h6>
                </div>
                <div className="card-body">
                  <MenuItem groupId={item?.id} menu={item?.menu || []} onSuccess={fetch} />
                </div>
              </div>
            </div>
          ))}
          {/*
                                    </div>
                                </CardBody>
                            </div>
                            </Col> */}
        </Row>
        {/* loader */}
        {loading && <Loader />}
        {!loading && !!error && <Error />}
        {!loading && !error && totalItems == 0 && <Empty />}
        {/* pagination */}
        {totalPages > 1 && (
          <Pagination
            options={options}
            totalPages={totalPages}
            totalItems={totalItems}
            showedItems={showedItems}
            pagenate={pagenate}
          />
        )}
      </Container>
      {showCreate && <CreateModal close={closeCreate} show={openCreate} onSuccess={fetch} />}
      {showUpdate && (
        <UpdateModal
          close={closeUpdate}
          show={openUpdate}
          onSuccess={fetch}
          itemId={itemIdUpdate}
        />
      )}

      {/* delete component */}
      {deleteId && deleteId > 0 && (
        <DeleteModal
          show={deleteId}
          onDeleteClick={deleteItem}
          onCloseClick={() => {
            if (closeDelete) closeDelete()
          }}
        />
      )}
    </div>
  )
}

export default FooterList
