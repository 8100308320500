// react elements
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
// bootstrap elements
import { Card, CardBody, CardHeader, Input, Row } from 'reactstrap'
// fetch hock logic
import FetchHock from 'helpers/FetchHock'
// requierd components
import Loader from 'Components/IndexTable/partials/Loader'
import Error from 'Components/IndexTable/partials/Error'
import Empty from 'Components/IndexTable/partials/Empty'
import Pagination from 'Components/IndexTable/partials/Pagination'
import CreateModal from './CreateModal'
import UpdateModal from './UpdateModal'
import DeleteModal from 'Components/IndexTable/partials/DeleteModal'
import axios from 'axios'
import linkGenerator from 'helpers/linkGenerator'
import showSuccess from 'helpers/SuccessNotfication'
import ImageWithFallback from 'helpers/ImageComponent'

const ShippingCompanies = () => {
  const { t } = useTranslation()

  type shippingCompanies = {
    id: number
    web_logo?: string
    title: string
    is_active: boolean
  }

  const active = async (id: number) => {
    const response = await axios.get(linkGenerator(`/shippingCompanies/offline/active/${id}`))
    if (response.status) showSuccess(t('Shipping company status updated successfully'))
  }

  // create modal logic
  const [itemIdUpdate, changeItemIdUpdate] = useState<number>()
  const [showUpdate, changeShowUpdate] = useState<boolean>(false)
  const [showCreate, changeShowCreate] = useState<boolean>(false)
  const openCreate = () => changeShowCreate(true)
  const closeCreate = () => changeShowCreate(false)
  const openUpdate = () => changeShowUpdate(true)
  const closeUpdate = () => changeShowUpdate(false)

  const {
    items,
    loading,
    error,
    options,
    totalPages,
    totalItems,
    pagenate,
    openDelete,
    closeDelete,
    deleteItem,
    showedItems,
    deleteId,
    fetch
  } = FetchHock<shippingCompanies>({
    path: '/shippingCompanies/offline',
    initFilter: { limit: 1000 }
  })

  return (
    <>
      <Card className="radius-top-0">
        <CardHeader>
          <Row className="align-items-center gy-3">
            {/* card title */}
            <div className="col-sm">
              <h5 className="card-title mb-0">{t('Shipping Companies')}</h5>
            </div>
            {/* space auto */}
            <div className="col-sm-auto">
              {/* action buttons */}
              <div className="d-flex gap-1 flex-wrap">
                <button onClick={() => openCreate()} className="btn btn-secondary add-btn">
                  <i className="ri-add-circle-line align-middle me-1"></i>{' '}
                  {t('Add shipping company')}
                </button>
              </div>
            </div>
          </Row>
        </CardHeader>
        <CardBody>
          <div className="d-flex flex-column">
            {items?.map((item, index) => (
              <Card className="ribbon-box right overflow-hidden" key={index}>
                <CardBody>
                  <div className="d-flex w-100 justify-content-between">
                    <div className="d-flex">
                      <ImageWithFallback src={item.web_logo ?? ''} alt={item.title} height={55} />
                      <div className="ms-2 mt-2">
                        <h5>{item.title}</h5>

                        <span
                          className="text-success me-2"
                          role="button"
                          onClick={() => {
                            openUpdate()
                            changeItemIdUpdate(item.id)
                          }}>
                          <i className="ri-pencil-line align-middle"></i>
                          {t('Edit')}
                        </span>
                        <Link
                          to={`/shipping/${item.id}/cost`}
                          className="text-success me-2"
                          role="button">
                          <i className="ri-money-dollar-circle-line align-middle"></i>
                          {t('Priceing')}
                        </Link>
                        <span
                          className="text-danger me-2"
                          role="button"
                          onClick={() => openDelete(item.id)}>
                          <i className="ri-delete-bin-line align-middle"></i>
                          {t('Delete')}
                        </span>
                      </div>
                    </div>

                    <div className="mt-3 p-0 form-check form-switch-lg form-switch form-switch-secondary">
                      <Input
                        type="checkbox"
                        role="switch"
                        onChange={() => active(item.id)}
                        defaultChecked={item.is_active}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            ))}
          </div>
          {/* loader */}
          {loading && <Loader />}
          {!loading && !!error && <Error />}
          {!loading && !error && totalItems == 0 && <Empty />}
          {/* pagination */}
          {totalPages > 1 && (
            <Pagination
              options={options}
              totalPages={totalPages}
              totalItems={totalItems}
              showedItems={showedItems}
              pagenate={pagenate}
            />
          )}
        </CardBody>
      </Card>
      {showCreate && <CreateModal close={closeCreate} show={openCreate} onSuccess={fetch} />}
      {showUpdate && (
        <UpdateModal
          close={closeUpdate}
          show={openUpdate}
          onSuccess={fetch}
          itemId={itemIdUpdate}
        />
      )}
      {/* delete component */}
      {deleteId && deleteId > 0 && (
        <DeleteModal
          show={deleteId}
          onDeleteClick={deleteItem}
          onCloseClick={() => {
            if (closeDelete) closeDelete()
          }}
        />
      )}
    </>
  )
}

export default ShippingCompanies
