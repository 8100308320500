import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'reactstrap'

import BreadCrumb from 'Components/Common/BreadCrumb'
import IndexTable, { FilterItem, HeaderColumn } from 'Components/IndexTable'
import FetchHock from 'helpers/FetchHock'

import EditLabel from 'Components/EditLabel'

import { Input, Submit } from 'Components/Form'
import CreateHook from 'helpers/CreateHook'
import { Link } from 'react-router-dom'

type labels = {
  id: number
  title: string
}
type labelType = {
  id?: number
  title: string
}

const LabelsList = () => {
  const { t } = useTranslation()

  document.title = 'Labels | Sallatak'

  const fetchHock = FetchHock<labels>({ path: '/labels' })
  const { items, fetch, search, openDelete } = fetchHock
  const headerColumns: HeaderColumn[] = [
    {
      title: 'Title',
      accessor: 'title'
    },
    {
      title: 'View'
    },
    {
      title: 'Action'
    }
  ]

  const filterItems: FilterItem[] = [
    {
      type: 'search',
      col: 6,
      placeholder: t('Search ...'),
      action: (value) => search(value)
    }
  ]
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <Row>
          <Col lg={12}>
            <CreateLabel onSuccess={fetch} />
            <IndexTable
              title={t('Labels list')}
              headerColumns={headerColumns}
              {...fetchHock}
              filters={filterItems}>
              {items?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className="form-check form-switch-md form-switch form-switch-secondary">
                      <EditLabel
                        label={item?.title}
                        path={`/labels/${item.id}`}
                        onSuccess={fetch}
                      />
                    </div>
                  </td>
                  <td>
                    <Link
                      className="btn btn-sm fs-14 btn-link text-decoration-none px-0 text-secoundry"
                      to={`/products/settings/labels/values/list/${item.id}`}>
                      <i className="ri-eye-line"></i>
                    </Link>
                  </td>
                  <td>
                    <a
                      className="btn btn-sm fs-14 btn-link text-decoration-none px-0 text-danger"
                      onClick={() => openDelete(item.id)}>
                      <i className="ri-delete-bin-line"></i>
                    </a>
                  </td>
                </tr>
              ))}
            </IndexTable>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default LabelsList

function CreateLabel({ onSuccess }: any) {
  const { t } = useTranslation()

  const { register, handleSubmit, errors, create, loading, reset } = CreateHook<any>('/labels', {
    title: ''
  })

  const submit = async (data: labelType) => {
    const response = await create(data)
    if (response.status) {
      reset()
      onSuccess()
    }
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="d-flex  align-items-center gy-3 mb-3 row">
        <Col md={7}>
          <Input
            label={t('Title')}
            placeholder={t('Enter title')}
            model={register('title', {
              required: t('Title is required'),
              minLength: {
                value: 3,
                message: t('Title must be bigger than or equal 3')
              }
            })}
            error={errors.title}
          />
        </Col>
        <Col md={3}>
          <div
            className="d-flex gap-1 flex-wrap"
            style={{
              marginTop: 'inherit'
            }}>
            <Submit loading={loading} label={t('Create')} />
          </div>
        </Col>
      </div>
    </form>
  )
}
