import Slider from './Slider/Config'
const config = [
  {
    title: 'Partners Section',
    key: 'Partners',
    config: Slider
  }
]

export default config
