import flagus from '../assets/images/flags/us.svg'
import flagarabic from '../assets/images/flags/kw.svg'

const languages = {
  en: {
    label: 'English',
    flag: flagus
  },
  ar: {
    label: 'العربية',
    flag: flagarabic
  }
}

export default languages
