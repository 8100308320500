import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// bootstrap elements
import { Collapse } from 'reactstrap'

// required components
import classnames from 'classnames'
import { Search } from 'Components/Form'

const Specification = ({
  isOpen,
  toggleItem,
  register,
  getValues
}: {
  isOpen: boolean
  toggleItem: () => void
  register: any
  getValues: any
}) => {
  const { t } = useTranslation()

  const model = register('labels')

  const [specifications, setSpecifications] = useState<(number | null)[]>(getValues('labels'))

  const updateValue = (index: number, value: number) => {
    const oldSpecifications = [...specifications]
    oldSpecifications[index] = value
    setSpecifications(oldSpecifications)
  }

  const addOption = () => {
    const oldSpecifications = [...specifications]
    oldSpecifications.push(null)
    setSpecifications(oldSpecifications)
  }
  const removeOption = (index: number) => {
    const oldSpecifications = [...specifications]
    oldSpecifications.splice(index, 1)
    setSpecifications(oldSpecifications)
  }

  const [lableId, setLabelId] = useState<number[]>([])
  const changeFilterItem = (index: number, value: number) => {
    const oldLableId = [...lableId]
    oldLableId[index] = value
    setLabelId(oldLableId)
  }

  useEffect(() => {
    const name = model?.name
    model.onChange({
      target: {
        name,
        value: specifications
      }
    })
  }, [specifications])

  useEffect(() => {
    const labelData = getValues('labels_data') ?? []

    const newLabelId: number[] = []
    labelData?.map((value: any, index: number) => (newLabelId[index] = parseInt(value?.label?.id)))
    setLabelId(newLabelId)
  }, [])

  return (
    <>
      <h2 className="accordion-header" id="headingOne">
        <button
          className={classnames('accordion-button', { collapsed: !isOpen })}
          type="button"
          onClick={toggleItem}
          style={{ cursor: 'pointer' }}>
          {t('Specification')}
        </button>
      </h2>
      <Collapse isOpen={isOpen} className="accordion-collapse" id="collapseOne">
        <div className="accordion-body  p-0">
          <table className="table table-hover table-striped align-middle table-nowrap mb-0">
            <thead>
              <tr>
                <th scope="col">{t('Label')}</th>
                <th scope="col">{t('Value')}</th>
                <th scope="col">{t('Options')}</th>
              </tr>
            </thead>
            <tbody>
              {specifications?.map((specification, index) => (
                <tr key={index}>
                  <td>
                    <Search
                      placeholder={t('Select label')}
                      propTitle="title"
                      path="labels"
                      value={getValues(`labels_data.${index}.label`)}
                      onChange={(value) => changeFilterItem(index, parseInt(value.value))}
                    />
                  </td>
                  <td>
                    <Search
                      placeholder={t('Select value')}
                      path={`labelValues`}
                      propTitle="title"
                      filter={{
                        label_id: lableId[index]
                      }}
                      value={getValues(`labels_data.${index}.values`)}
                      onChange={(value) => {
                        updateValue(index, parseInt(value.value))
                      }}
                    />
                  </td>
                  <td>
                    <button
                      className="btn btn-outline-danger ms-2"
                      type="button"
                      disabled={specifications.length <= 1}
                      onClick={() => removeOption(index)}>
                      <i className="ri-close-line align-middle"></i>
                    </button>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan={3}>
                  <button
                    className="btn btn-secondary w-100"
                    type="button"
                    onClick={addOption}>
                    <i className="ri-add-line align-middle"></i> {t('Add option')}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Collapse>
    </>
  )
}

export default Specification
