import { Button, Container } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import BreadCrumb from 'Components/Common/BreadCrumb'
import { useNavigate } from 'react-router'
import ButtonCards from 'pages/BasicSettings/component/ButtonCards'

function AdvancedSettings() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <ButtonCards
          data={[
            { name: 'Articles', url: '/articles/list', icon: 'ri ri-file-list-line' },
            {
              name: 'Articles Categories',
              url: '/articlesCategories/list',
              icon: 'ri ri-folder-open-line'
            },
            { name: 'Static Pages', url: '/staticPages/list', icon: 'ri ri-pages-line' },
            {
              name: 'Stocks Management',
              url: '/stocks/list',
              icon: 'ri ri-database-2-line'
            },
            {
              name: 'Translations',
              url: '/translation',
              icon: 'las la-language'
            },

            {
              name: 'Extra Fees',
              url: '/products/settings/extraFees/list',
              icon: 'las la-coins'
            },
            {
              name: 'Partners',
              icon: 'las la-handshake',
              url: '/partners/list'
            },
            {
              name: 'Menu Custom Links',
              icon: 'ri-links-line',
              url: '/MenuCustomLinks/list'
            },
            {
              name: 'Moderators',
              url: '/moderators/list',
              icon: 'las la-users'
            },
            {
              name: 'Roles',
              url: '/roles/list',
              icon: 'las la-users-cog'
            }
          ]}
        />
      </Container>
    </div>
  )
}

export default AdvancedSettings
