import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Col, Row, Accordion, AccordionItem } from 'reactstrap'
import UpdateHock from 'helpers/UpdateHock'

import MainDetails from './components/MainDetails'
import MainPrice from './components/MainPrice'
import Gallery from './components/Gallery'
import Specification from './components/Specification'
import OrderForm from './components/OrderForm'
import ProductSidebar from '../Components/ProductSidebar'
import ProductsLayout from './components/ProductsLayout'

const Default = () => {
  document.title = 'Update product | Sallatk'
  const { id } = useParams()

  const uploadMethods = UpdateHock<any>({
    path: '/ready_product',
    editId: +id
  })
  const { closeUploading, openUploading } = uploadMethods
  const [open, setOpen] = useState<string>('')
  const selectLabel = (label: string) => setOpen(label)

  return (
    <ProductsLayout uploadMethods={uploadMethods}>
      <Row>
        <Col lg={8}>
          <MainDetails />
          <MainPrice />
          <Gallery openUploading={openUploading} closeUploading={closeUploading} />

          <Accordion
            toggle={() => {}}
            className="lefticon-accordion custom-accordionwithicon accordion-border-box"
            open="test">
            <AccordionItem>
              <Specification
                isOpen={open == 'spicifcation'}
                toggleItem={() => selectLabel('spicifcation')}
              />
            </AccordionItem>
            <AccordionItem>
              <OrderForm isOpen={open == 'orderForm'} toggleItem={() => selectLabel('orderForm')} />
            </AccordionItem>
          </Accordion>
        </Col>

        <Col lg={4}>
          <ProductSidebar isEdit />
        </Col>
      </Row>
    </ProductsLayout>
  )
}

export default Default
