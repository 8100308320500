import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row, Card, CardBody, CardHeader } from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import { Search, Submit, Switch, Input } from 'Components/Form'
import CreateHook from 'helpers/CreateHook'
import Areas from './components/areas'
import { Controller, FormProvider } from 'react-hook-form'
import GoogleMapComponent from './components/GoogleMapComponent'
import { BranchType } from '../types'

const CreateBranch = () => {
  document.title = 'Create branch | Sallatk'

  const { t } = useTranslation()
  const history = useNavigate()

  const {
    register,
    handleSubmit,
    errors,
    create,
    loading,
    formMethods: { setError },
    control,
    formMethods,
    getValues
  } = CreateHook<BranchType>('/branches', {
    name: '',
    address: '',
    city_id: null,
    max_order_day: null,
    allow_pickup: 0,
    minimum_cart: null,
    available: 0,
    main: false,
    region_id: [],
    lat: null,
    lng: null,
    latLng: null
  })

  const submit = async (data: BranchType) => {
    const latLng = data.latLng
    const payload = latLng
      ? {
          ...data,
          lat: latLng?.lat,
          lng: latLng?.lng
        }
      : data
    const response = await create(payload)
    if (response.status) history(-1)
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(submit)}>
            <Card>
              <CardHeader>
                <h5 className="mb-0">{t('Branch details')}</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <Input
                      label={t('Branch Name')}
                      placeholder={t('Enter branch name')}
                      model={register('name', {
                        required: t('Branch name is required'),
                        minLength: {
                          value: 3,
                          message: t('Branch name must be bigger than or equal 3')
                        }
                      })}
                      error={errors.name}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      label={t('Address')}
                      placeholder={t('Enter address')}
                      model={register('address', {
                        required: t('Address is required'),
                        minLength: {
                          value: 3,
                          message: t('Address must be bigger than or equal 3')
                        }
                      })}
                      error={errors.address}
                    />
                  </Col>
                  <Col md={6}>
                    <Search
                      label={t('City')}
                      placeholder={t('Enter city')}
                      model={register('city_id', {
                        required: t('City is required')
                      })}
                      error={errors.city_id}
                      propTitle="title"
                      path="cities"
                    />
                  </Col>
                  <Col xs={6}>
                    <Input
                      label={t('Max order')}
                      type="number"
                      placeholder={t('Enter max order')}
                      model={register('max_order_day', {
                        required: t('Max order is required')
                      })}
                      error={errors.max_order_day}
                      atEnd={t('Per day')}
                    />
                  </Col>
                  <Col xs={6}>
                    <Input
                      label={t('Minimum cart')}
                      type="number"
                      placeholder={t('Enter minimum cart cost')}
                      model={register('minimum_cart', {
                        required: t('Minimum cart is required')
                      })}
                      error={errors.minimum_cart}
                    />
                  </Col>
                  <Col md={6}></Col>
                  <Col md={4}>
                    <Switch
                      label={t('Allow pickup')}
                      model={register('allow_pickup')}
                      error={errors.allow_pickup}
                      defaultChecked={getValues('allow_pickup') === 1}
                    />
                  </Col>
                  <Col md={4}>
                    <Switch
                      label={t('Active')}
                      model={register('available')}
                      error={errors.available}
                      defaultChecked={getValues('available') === 1}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h5 className="mb-0">{t('Branch location')}</h5>
              </CardHeader>
              <CardBody>
                <Controller
                  control={control}
                  rules={{
                    required: t('Location is required')
                  }}
                  name="latLng"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <GoogleMapComponent onChange={onChange} value={value} error={error} />
                  )}
                />
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h5 className="mb-0">{t('Branch areas')}</h5>
              </CardHeader>
              <CardBody>
                <Areas model={register('region_id')} />
              </CardBody>
            </Card>
            <div className="hstack gap-2 justify-content-start mb-2">
              <Submit loading={loading} label={t('Create')} />
            </div>
          </form>
        </FormProvider>
      </Container>
    </div>
  )
}

export default CreateBranch
