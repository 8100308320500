import { useState } from 'react'
// bootstrap elements
import { Row, Col } from 'reactstrap'
// debendant components
import CategoryTree, { categoryItem } from './CategoryTree'

const DragZone = ({
  items,
  openUpdate,
  openDelete,
  orderItem
}: {
  items: categoryItem[]
  openUpdate: (id: number) => void
  openDelete: (id: number) => void
  orderItem: (id: number, parentId: number, order: number) => void
}) => {
  // drag elements
  const [dragedItem, setDragedItem] = useState<categoryItem | null>(null)
  const [dropedItem, setDropedItem] = useState<categoryItem | null>(null)
  const [order, setOrder] = useState<number | null>(null)

  const selectDragItem = (item: categoryItem) => setDragedItem(item)
  const selectDropItem = (item: categoryItem) => {
    if (dragedItem) setDropedItem(item)
  }
  const addOrder = (id: number) => setOrder(id)
  const removeOrder = () => setOrder(null)

  let lock: boolean = false

  const onMove = async () => {
    // send to backend
    console.log(lock)
    if (dragedItem && dropedItem && order && !lock) {
      lock = true
      await orderItem(dragedItem.id, dropedItem.id, order)
      lock = false
    }
    // empty data
    setDragedItem(null)
    setDropedItem(null)
    setOrder(null)
  }

  return (
    <Row>
      {items?.map((item, key) => (
        <Col xl={4} lg={4} md={4} sm={6} xs={12} key={key}>
          <CategoryTree
            items={[item]}
            onUpdate={openUpdate}
            onDelete={openDelete}
            selectDragItem={selectDragItem}
            selectDropItem={selectDropItem}
            dropId={dropedItem?.id}
            addOrder={addOrder}
            removeOrder={removeOrder}
            onMove={onMove}
          />
        </Col>
      ))}
    </Row>
  )
}

export default DragZone
