import { Button, ListGroup, ListGroupItem } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import PopUp from 'Components/PopUp'
// fetch hock logic
import FetchHock from 'helpers/FetchHock'

interface input {
  id: number
  name: string
  placeholder: string
  type: 'text' | 'number' | 'textarea' | 'radio' | 'checkbox' | 'date' | 'time' | 'color'
  required: 1 | 0
  sort: number
  options?: { name: string }[]
}

const ShowFormModal = ({ close, show, formId }: any) => {
  console.log(formId)
  const { t } = useTranslation()

  const fetchHock = FetchHock<input>({
    path: `/form/${formId}/inputs`,
    sortBy: 'form_inputs.sort',
    sortDirection: 'asc'
  })

  return (
    <PopUp title={t('Show Form')} show={show} close={close} loading={fetchHock.loading}>
      <ListGroup flush>
        {fetchHock.items?.map((input: input, index: number) => (
          <ListGroupItem key={index}>
            <label className="form-label"> {input.name}</label>
            {(() => {
              switch (input.type) {
                case 'text':
                  return <input className="form-control" placeholder={input.placeholder} readOnly />
                case 'number':
                  return (
                    <input
                      type="number"
                      className="form-control"
                      placeholder={input.placeholder}
                      readOnly
                    />
                  )
                case 'textarea':
                  return (
                    <textarea
                      className="form-control"
                      placeholder={input.placeholder}
                      readOnly></textarea>
                  )
                case 'date':
                  return (
                    <input
                      type="date"
                      className="form-control"
                      placeholder={input.placeholder}
                      readOnly
                    />
                  )
                case 'time':
                  return (
                    <input
                      type="time"
                      className="form-control"
                      placeholder={input.placeholder}
                      readOnly
                    />
                  )
                case 'color':
                  return (
                    <input
                      type="color"
                      className="form-control"
                      placeholder={input.placeholder}
                      readOnly
                    />
                  )
                case 'checkbox':
                  return (
                    <>
                      {input.options?.map((option: any, index: number) => (
                        <div className="form-check mb-2" key={index}>
                          <input className="form-check-input" type="checkbox" readOnly />
                          <label className="form-check-label">{option.name}</label>
                        </div>
                      ))}
                    </>
                  )
                case 'radio':
                  return (
                    <>
                      {input.options?.map((option: any, index: number) => (
                        <div className="form-check mb-2" key={index}>
                          <input className="form-check-input" type="radio" name="inputs" readOnly />
                          <label className="form-check-label">{option.name}</label>
                        </div>
                      ))}
                    </>
                  )
              }
            })()}
          </ListGroupItem>
        ))}
      </ListGroup>

      <div className="hstack gap-2 justify-content-end">
        <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
          <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
        </Button>
      </div>
    </PopUp>
  )
}
export default ShowFormModal
