import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const ChatOnWhatsappLink = ({ phoneNumber }: { phoneNumber: string }) => {
  const { t } = useTranslation()
  return (
    <Link to={`https://wa.me/${phoneNumber}`} target="_blank">
      <div
        className="d-flex align-items-center gap-1 px-1"
        style={{
          color: 'white',
          backgroundColor: '#25D366',
          borderRadius: '0.2rem'
        }}>
        <i className="ri-whatsapp-line align-middle fs-16"></i>
        <p className="m-0 fs-14"> {t('Chat on Whatsapp')}</p>
      </div>
    </Link>
  )
}
export default ChatOnWhatsappLink
