import clsx from 'clsx'
import { useState } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap'
import CODPaymentMethod from './CODPaymentMethod'
import PaymentMethodTabBody from './PaymentMethodTabBody'
import { Company } from 'pages/PaymentMethod/types'

function PaymentMethodTabs({ tabsData, referesh }: { tabsData: Company[]; referesh: any }) {
  const [activeTab, setActiveTab] = useState(tabsData[0]?.id)
  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <Row>
      <Col md={12}>
        <Card>
          <CardBody>
            {tabsData?.map((item, index) => {
              if (item && item.type == 'cod') {
                return <CODPaymentMethod key={index} company={item} onSuccess={referesh} />
              }
            })}
          </CardBody>
        </Card>
      </Col>
      <Col md={12}>
        <Card>
          <CardHeader>
            <div>
              <Nav
                className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                role="tablist">
                {tabsData.map((item, index) => {
                  if (item && item.type != 'cod') {
                    return (
                      <NavItem key={index}>
                        <NavLink
                          href="#"
                          className={clsx({ active: activeTab === item.id })}
                          onClick={() => {
                            toggleTab(item.id)
                          }}>
                          {item.name}
                        </NavLink>
                      </NavItem>
                    )
                  }
                })}
              </Nav>
            </div>
          </CardHeader>
          <CardBody>
            <TabContent activeTab={activeTab}>
              {tabsData?.map((item, index) => {
                if (item.type == 'cod') {
                  return null
                }
                if (item.id == activeTab) {
                  return (
                    <TabPane key={index} tabId={item.id}>
                      <PaymentMethodTabBody companyData={item} />
                    </TabPane>
                  )
                }
              })}
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default PaymentMethodTabs
