import { Fragment, useEffect, useState } from 'react'
import { useFetchOrder } from 'services/pos/orders'
import logo from '../../../assets/images/sallatk.svg'

type Props = {}

const formatPrice = (price: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'KWD' // kuwaiti din
  }).format(price)
}
export const POSPrint = (props: Props) => {
  const urlParams = new URLSearchParams(window.location.search)
  const orderId = urlParams.get('orderId')
  const [name, setName] = useState<string>('')

  const { data: orderData } = useFetchOrder(orderId)

  useEffect(() => {
    const authUSer: any = localStorage.getItem('authUser')
    if (!authUSer) return
    const obj: any = JSON.parse(authUSer)
    setName(obj.name + (obj.lastName ?? ''))
  }, [])

  window.onafterprint = () => {
    window.close()
  }
  
  useEffect(() => {
    if (!orderData) return

    window.print()
  }, [orderData])

  if (!orderData) return null

  return (
    <div className="page-content">
      <div className="logo text-center">
        <img src={logo} alt="" height="40" />
      </div>
      <div className="mb-5 mt-2 row justify-content-evenly">
        <div className="col-12">
          <p className="mb-1 ">
            Cashier:
            <span className="fw-bold ms-2">{name}</span>
          </p>
        </div>
        <div className="col-12">
          <p className="mb-1 ">
            Invoice Number
            <span className="fw-bold ms-2">
              {orderData?.transactions[0]?.invoice_id || orderData?.transactions[0]?.id}
            </span>
          </p>
        </div>
        <div className="col-12">
          <p className="mb-1 ">
            Order Number
            <span className="fw-bold ms-2">{orderData?.id}</span>
          </p>
        </div>
        <div className="col-12">
          <p className="mb-1 ">
            Order Date
            <span className="fw-bold ms-2">{orderData?.created_at}</span>
          </p>
        </div>
        <div className="col-12">
          <p className="mb-1 ">
            Payment Method
            <span className="fw-bold ms-2">
              {orderData?.transactions[0]?.payment_method?.title}
              {orderData?.transactions[0]?.reference &&
                ` (${orderData?.transactions[0]?.reference})`}
            </span>
          </p>
        </div>
      </div>

      <p className="my-5 text-center  ">Here's what you ordered</p>

      <table className="table w-100 text-left align-middle" cellPadding="10">
        <thead>
          <tr className="border-spacing-y-4 border-bottom-2 border-y-gray-350 ">
            <th className="">Product</th>
            <th className="text-end ">Quantity</th>
            <th className="text-end ">Amount</th>
          </tr>
          {orderData?.products.map((product, i) => (
            <Fragment key={product.id}>
              <tr className={i !== 0 ? '' : ''}>
                <td>
                  <p className="mb-1  ">{product.title}</p>

                  {product?.order_product_options?.map((option, index) => (
                    <p key={index} className={`mb-0 small ps-3 ${index === 0 && 'mt-2'}`}>
                      {option.options.feature_title}: <b>{option.options.title}</b>
                    </p>
                  ))}
                </td>
                <td className="text-end align-middle ">{product.count}</td>
                <td className="text-end  ">
                  <p className="mb-1  ">{formatPrice(product.price * product.count)}</p>

                  {product?.order_product_options?.map((option, index) => (
                    <p key={index} className={`mb-0 small ps-3 ${index === 0 && 'mt-2'}`}>
                      {option.price
                        ? formatPrice(product.count * Number(option.price) - (option.discount || 0))
                        : ''}
                    </p>
                  ))}
                </td>
              </tr>
            </Fragment>
          ))}

          <tr className={''}>
            <td colSpan={2} className="text-end">
              Subtotal
            </td>
            <td className="text-end  ">{formatPrice(orderData.subtotal)}</td>
          </tr>
          <tr className={''}>
            <td colSpan={2} className="text-end">
              Shipping
            </td>
            <td className="text-end  ">{formatPrice(orderData.shipping_cost)}</td>
          </tr>
          <tr className={''}>
            <td colSpan={2} className="text-end">
              Taxes
            </td>
            <td className="text-end  ">{formatPrice(orderData.total_fees)}</td>
          </tr>
          <tr className={''}>
            <td colSpan={2} className="text-end">
              Discount
            </td>
            <td className="text-end  ">{formatPrice(orderData.total_discount)}</td>
          </tr>
          <tr className={'border-top-2 border-t-gray-350/50'}>
            <td colSpan={2} className="text-end">
              Total Amount
            </td>
            <td className="text-end  ">{formatPrice(orderData.total)}</td>
          </tr>
        </thead>
      </table>
      <p className="mt-5 text-center  text-muted">
        We appreciate your business and hope you enjoy your purchase.
      </p>
      <p className="text-center text-muted ">Thank you!</p>
    </div>
  )
}
