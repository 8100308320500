import { Button, Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Input, Submit, Switch } from 'Components/Form'
import UpdateHock from 'helpers/UpdateHock'
import PopUp from 'Components/PopUp'

type UpdateFooter = Omit<Footer, 'id' | 'sort' | 'icon'>

const UpdateModal = ({ show, close, onSuccess, itemId }: any) => {
  const { t } = useTranslation()

  // prepare hock
  const { register, handleSubmit, errors, loading, update, getValues, getLoading } =
    UpdateHock<UpdateFooter>({
      path: '/menus/group',
      initValue: {
        title: '',
        display_title: 0
      },
      editId: itemId
    })

  const submit = async (data: UpdateFooter) => {
    console.log(data)
    const response = await update(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  return (
    <PopUp title={t('Update Partner')} show={show} close={close} loading={getLoading}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={12}>
              <Input
                label={t('Title')}
                placeholder={t('Enter title')}
                model={register('title', {
                  required: t('Title is required')
                })}
                error={errors.title}
              />
            </Col>
            <Col md={12}>
              <Switch
                label={t('Display title')}
                model={register('display_title')}
                error={errors.display_title}
                defaultChecked={getValues('display_title') === 1}
              />
            </Col>
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Update')} />
        </div>
      </form>
    </PopUp>
  )
}
export default UpdateModal
