import { useState } from 'react'
import { useTranslation } from 'react-i18next'
// bootstrap elements
import { Collapse, Button } from 'reactstrap'

// required components
import { LazySelect, Search } from 'Components/Form'

import ShowFormModal from './ShowFormModal'

import classnames from 'classnames'
import { useFormContext } from 'react-hook-form'

const Specification = ({ isOpen, toggleItem }: { isOpen: boolean; toggleItem: () => void }) => {
  const { t } = useTranslation()
  const {
    register,
    formState: { errors },
    getValues
  } = useFormContext()

  // show form
  const [selectedForm, changeSelectedForm] = useState<number | null>(getValues('form_id'))
  const [showForm, changeShowForm] = useState<boolean>(false)
  const openForm = () => changeShowForm(true)
  const closeForm = () => changeShowForm(false)

  return (
    <>
      <h2 className="accordion-header" id="headingTwo">
        <button
          className={classnames('accordion-button', { collapsed: !isOpen })}
          type="button"
          onClick={toggleItem}
          style={{ cursor: 'pointer' }}>
          {t('Order form')}
        </button>
      </h2>
      <Collapse isOpen={isOpen} className="accordion-collapse">
        <div className="accordion-body">
          <div className="d-flex align-items-start justify-content-between w-100">
            <LazySelect
              className="w-100 me-2"
              placeholder={t('Select form')}
              model={register('form_id')}
              error={errors.categories}
              propTitle="name"
              onChange={(value) => {
                changeSelectedForm(parseInt(value.value))
              }}
              defaultValue={getValues('form')}
              path="/forms"
            />
            <Button
              className="btn btn-secoundry"
              disabled={!selectedForm}
              style={{ width: '160px' }}
              onClick={openForm}>
              <i className="mdi mdi-eye-check"></i>
              {t('View Form')}
            </Button>
          </div>
          {showForm && <ShowFormModal close={closeForm} show={showForm} formId={selectedForm} />}
        </div>
      </Collapse>
    </>
  )
}

export default Specification
