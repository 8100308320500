// react elements
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
// botstrap elements
import { Card, CardBody, Col, Collapse, Row } from 'reactstrap'
import { useThemeBuilder } from '..'

const Sidebar = ({ item }: any) => {
  const { t } = useTranslation()

  const [colsban, setColsban] = useState<boolean>(true)
  const toggleColspan = () => setColsban(!colsban)

  const { handelDrag } = useThemeBuilder()

  return (
    <>
      <div
        className="d-flex justify-content-between mb-1"
        style={{ cursor: 'pointer' }}
        onClick={toggleColspan}>
        <h6>{t(item.title)}</h6>
        <i className="ri-arrow-down-s-line fs-18"></i>
      </div>
      <Collapse isOpen={colsban}>
        <Row>
          {item.sections.map((el: any, index: number) => (
            <Col md={6} style={{ cursor: 'pointer' }} key={index}>
              <Card
                className="mb-2"
                draggable
                onDragStart={(e) => {
                  handelDrag(e, {
                    type: item.key,
                    data: {
                      type: el.key,
                      data: el.config.default
                    }
                  })
                }}>
                <CardBody className="text-center p-2">
                  <img src={el.config.placeholder} style={{ width: '100%' }} />
                  <br />
                  {t(el.title)}
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Collapse>
      <hr />
    </>
  )
}

export default Sidebar
