type Props = {
  className?: string
  children?: any
}

export const Fieldset = ({ className, children, ...rest }: Props) => {
  return (
    <fieldset
      style={{ border: '1px solid var(--vz-input-border-custom)' }}
      className={`p-3 pt-2 mb-2 ${className}`}
      {...rest}>
      {children}
    </fieldset>
  )
}
