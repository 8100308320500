// react imports
import { useState } from 'react'
import { Link } from 'react-router-dom'
// botstrap elemnts
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown
} from 'reactstrap'

// translation
import { useTranslation } from 'react-i18next'
// debependat components
import IndexTable, { FilterItem, HeaderColumn } from 'Components/IndexTable'
import FetchHock from 'helpers/FetchHock'
// create model popup
import CreateModal from '../components/CreateModal'

const TicketsData = ({ refreshReport }: { refreshReport: () => void }) => {
  const { t } = useTranslation()

  interface tickets {
    id: number
    number?: string
    subject: string
    status: string
    priority: string
    category?: {
      id: number
      name: string
    }
    created_at: string
  }

  interface filter {
    status: string
    priority: string
    category_id: number | null
  }

  const {
    items,
    loading,
    error,
    options,
    totalPages,
    totalItems,
    showedItems,
    pagenate,
    sort,
    openDelete,
    closeDelete,
    deleteItem,
    deleteId,
    search,
    filter,
    fetch
  } = FetchHock<tickets, filter>({
    path: '/tickets',
    initFilter: {
      status: '',
      priority: '',
      category_id: ''
    }
  })

  const headerColumns: HeaderColumn[] = [
    {
      title: 'Id',
      accessor: 'id'
    },
    {
      title: 'Subject',
      accessor: 'subject'
    },
    {
      title: 'Category',
      accessor: 'category.name'
    },
    {
      title: 'Created at',
      accessor: 'created_at'
    },
    {
      title: 'Status',
      accessor: 'status'
    },
    {
      title: 'Priority',
      accessor: 'priority'
    },
    {
      title: 'Action'
    }
  ]

  const filterItems: FilterItem[] = [
   
    {
      type: 'asyncSelect',
      col: 2,
      placeholder: t('Category'),
      path: 'ticket_category',
      action: (value) => filter('category_id', value)
    },
    {
      type: 'date',
      col: 2,
      placeholder: t('Date'),
      action: (value) => console.log(value)
    },
    {
      type: 'select',
      col: 2,
      placeholder: t('Status'),
      options: [
        { label: t('Opened'), value: 'opened' },
        { label: t('Closed'), value: 'closed' }
      ],
      action: (value) => filter('status', value)
    },
    {
      type: 'select',
      col: 2,
      placeholder: t('Priority'),
      options: [
        { label: t('Low'), value: 'Low' },
        { label: t('Medium'), value: 'Medium' },
        { label: t('High'), value: 'High' }
      ],
      action: (value) => filter('priority', value)
    },
    {
      type: 'search',
      col: 4,
      placeholder: t('Search ...'),
      action: (value) => search(value)
    },
  ]

  // create modal logic
  const [showCreate, changeShowCreate] = useState<boolean>(false)
  const openCreate = () => changeShowCreate(true)
  const closeCreate = () => changeShowCreate(false)

  const customDeleteItem = async () => {
    await deleteItem()
    refreshReport()
  }

  return (
    <Row>
      <Col lg={12}>
        <IndexTable
          title={t('Tickets')}
          addString={t('Add new ticket')}
          addAction={openCreate}
          headerColumns={headerColumns}
          loading={loading}
          error={error}
          options={options}
          totalPages={totalPages}
          totalItems={totalItems}
          showedItems={showedItems}
          pagenate={pagenate}
          deleteId={deleteId}
          closeDelete={closeDelete}
          deleteItem={customDeleteItem}
          sort={sort}
          filters={filterItems}>
          {items?.map((item, index) => (
            <tr key={index}>
              <td>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    <h5 className="fs-14 mb-1">
                      <Link to={`/tickets/${item.id}`} className="text-body text-uppercase">
                        #{item.number}{' '}
                      </Link>
                    </h5>
                  </div>
                </div>
              </td>
              <td>
                <p className="text-muted mb-1">{item.subject}</p>
              </td>
              <td>
                <p className="text-muted mb-1">{item.category?.name}</p>
              </td>
              <td>
                <p className="text-muted mb-1">
                  {(() => {
                    return new Date(item.created_at).toLocaleString()
                  })()}
                </p>
              </td>
              <td>
                {(() => {
                  switch (item.status) {
                    case 'closed':
                      return (
                        <span className="badge bg-danger text-uppercase">{t(item.status)}</span>
                      )
                    case 'processing':
                      return (
                        <span className="badge bg-warning text-uppercase">{t(item.status)}</span>
                      )
                    case 'opened':
                      return (
                        <span className="badge bg-success text-uppercase">{t(item.status)}</span>
                      )
                    default:
                      return (
                        <span className="badge bg-success text-uppercase">{t(item.status)}</span>
                      )
                  }
                })()}
              </td>
              <td>
                {(() => {
                  switch (item.priority) {
                    case 'High':
                      return (
                        <span className="badge bg-danger-subtle text-danger text-uppercase">
                          {t(item.priority)}
                        </span>
                      )
                    case 'Medium':
                      return (
                        <span className="badge bg-warning-subtle text-warning text-uppercase">
                          {t(item.priority)}
                        </span>
                      )
                    case 'Low':
                      return (
                        <span className="badge bg-success-subtle text-success text-uppercase">
                          {t(item.priority)}
                        </span>
                      )
                    default:
                      return (
                        <span className="badge bg-success-subtle text-uppercase">
                          {t(item.priority)}
                        </span>
                      )
                  }
                })()}
              </td>
              <td>
                <div className="hstack gap-3 flex-wrap">
                  <UncontrolledDropdown>
                    <DropdownToggle href="#" className="btn btn-secondary btn-sm" tag="button">
                      <i className="ri-more-fill" />
                    </DropdownToggle>
                    <DropdownMenu container={'body'} className="dropdown-menu-end">
                      <DropdownItem tag={Link} to={`/tickets/${item.id}`}>
                        <i className="ri-eye-line me-2"></i>
                        {t('View')}
                      </DropdownItem>

                      <DropdownItem divider />
                      <DropdownItem onClick={() => openDelete(item.id)}>
                        <i className="ri-delete-bin-line me-2"></i>

                        {t('Delete')}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </td>
            </tr>
          ))}
        </IndexTable>
        {showCreate && (
          <CreateModal
            close={closeCreate}
            show={showCreate}
            onSuccess={() => {
              refreshReport()
              fetch()
            }}
          />
        )}
      </Col>
    </Row>
  )
}

export default TicketsData
