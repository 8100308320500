// react elements
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
// botstrap elements
import { Card, CardBody, CardFooter, CardHeader, Spinner } from 'reactstrap'
// required components
import ColspanItem from './ColspanItem'
import config from 'ThemeManager/Templates/Config'
import { useThemeBuilder } from '..'
import { useOnClickOutside } from 'hooks/useOnClickOutside'

const Sidebar = ({}: any) => {
  const { t } = useTranslation()

  const [showSide, setShowSide] = useState(false)
  const toggleSide = () => setShowSide(!showSide)

  const { saveTheme, loading } = useThemeBuilder()

  const ref = useRef(null)
  const handleClickOutside = () => {
    setShowSide(false)
  }
  useOnClickOutside(ref, handleClickOutside)
  return (
    <div
      ref={ref}
      className={
        showSide
          ? 'component-sidebar d-flex align-items-center show'
          : 'component-sidebar d-flex align-items-center'
      }>
      <div className="colspaner pointer" onClick={toggleSide}>
        {!showSide && <i className="ri-arrow-left-s-line fs-18"></i>}
        {showSide && <i className="ri-arrow-right-s-line fs-18"></i>}
      </div>
      <Card>
        <CardHeader>
          <h5 className="card-title mb-0">{t('Elements')}</h5>
        </CardHeader>
        <CardBody>
          {config.map((item: any, index: number) => (
            <ColspanItem item={item} key={index} />
          ))}
        </CardBody>
        <CardFooter>
          {loading ? (
            <button type="submit" className={'btn btn-secondary'}>
              <Spinner color="light" size="sm" />
            </button>
          ) : (
            <button type="submit" className={'btn btn-secondary'} onClick={() => saveTheme()}>
              {t('Save')}
            </button>
          )}
        </CardFooter>
      </Card>
    </div>
  )
}

export default Sidebar
