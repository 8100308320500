import { useState } from 'react'
import { useTranslation } from 'react-i18next'
// bootstrap elements
import {
  Card,
  CardBody,
  Col,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Label
} from 'reactstrap'
// required components
import { TextEditor, Textarea, Input } from 'Components/Form'
import classnames from 'classnames'
import { useFormContext } from 'react-hook-form'

const MainDetails = () => {
  const { t } = useTranslation()
  const {
    register,
    formState: { errors },
    setValue,
    getValues
  } = useFormContext()

  // Custom Tabs Bordered
  const [customNav, setcustomNav] = useState<string>('1')
  const customNavtoggle = (tab: string) => setcustomNav(tab)

  const handleTitleChange = (title: string): string => title.replace(/[^\p{L}0-9 _-]/gu, '_')

  return (
    <Card>
      <CardHeader className="">
        <h5 className="card-title mb-0">{t('Main details')}</h5>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={6}>
            <Input
              label={t('Product Title')}
              placeholder={t('Enter product title')}
              model={register('title', {
                required: t('Title is required'),
                minLength: {
                  value: 3,
                  message: t('Title must be bigger than or equal 3')
                }
              })}
              error={errors.title}
              onChange={(value) => setValue('slug', handleTitleChange(value))}
            />
          </Col>
          <Col md={6}>
            <Input
              label={t('Slug')}
              placeholder={t('Enter slug')}
              model={register('slug', {
                required: t('Slug is required'),
                minLength: {
                  value: 3,
                  message: t('Slug must be bigger than or equal 3')
                }
              })}
              error={errors.slug}
            />
          </Col>
          <Col md={12}>
            <Nav className="nav-tabs mb-0 border-0">
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({ active: customNav === '1' })}
                  onClick={() => customNavtoggle('1')}>
                  <i className="mdi mdi-newspaper-variant-outline"></i>
                  {t('Short Description')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({ active: customNav === '2' })}
                  onClick={() => customNavtoggle('2')}>
                  <i className="mdi mdi-newspaper-variant-multiple-outline"></i>
                  {t('Full Description')}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={customNav} className="text-muted">
              <TabPane tabId="1">
                <Textarea
                  placeholder={t('Enter short description')}
                  model={register('short_description', {
                    required: t('Short description is required'),
                    minLength: {
                      value: 3,
                      message: t('short description must be bigger than or equal 3')
                    }
                  })}
                  error={errors.short_description}
                />
              </TabPane>
              <TabPane tabId="2">
                <TextEditor
                  placeholder={t('Enter description')}
                  model={register('description')}
                  error={errors.description}
                  value={getValues('description')}
                />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default MainDetails
