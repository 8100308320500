// react elements
import { useEffect, useState } from 'react'
// bootstrap elements
import { Col, Row } from 'reactstrap'
// required components
import City from './city'
import ICities from './ICity'
import axios from 'axios'
import linkGenerator from 'helpers/linkGenerator'

const UiAccordions = ({ model }: { model: any }) => {
  // name of input
  const name = model.name
  // cities hock
  const [cities, setCities] = useState<ICities[]>([])
  // get data from server
  useEffect(() => {
    axios
      .get(linkGenerator('/cities/regions'))
      .then((response) => setCities(response.data.result.data))
  }, [])

  const [regions, setRegions] = useState<number[]>([])

  // toggle regions logic
  const toggleRegons = (Regons: number[]) => {
    const check = checkRegons(Regons)
    const regonsImge = [...regions]
    if (check) {
      Regons.map((regon) => {
        const index = regonsImge.indexOf(regon)
        if (index >= 0) regonsImge.splice(index, 1)
      })
    } else {
      Regons.map((regon) => {
        const index = regonsImge.indexOf(regon)
        if (index < 0) regonsImge.push(regon)
      })
    }
    setRegions(regonsImge)
  }

  const checkRegons = (regons: number[]): boolean => {
    let check = true
    if (!regons.every((r) => regions.includes(r))) check = false
    return check
  }
  // remove regon logic
  const toggleRegon = (regon: number) => {
    const regonsImge = [...regions]
    const index = regonsImge.indexOf(regon)
    if (index >= 0) regonsImge.splice(index, 1)
    else regonsImge.push(regon)
    setRegions(regonsImge)
  }
  // check regon
  const checkRegon = (regon: number): boolean => (regions.indexOf(regon) >= 0 ? true : false)
  // on change update regions
  useEffect(() => {
    model.onChange({
      target: {
        name,
        value: regions
      }
    })
  }, [regions])

  return (
    <Row>
      {cities.map((city, index) => (
        <Col md={4} className="mb-4" key={index}>
          <City
            city={city}
            toggleRegon={toggleRegon}
            checkRegon={checkRegon}
            toggleRegons={toggleRegons}
            checkRegons={checkRegons}
          />
        </Col>
      ))}
    </Row>
  )
}

export default UiAccordions
