import CreateHook from 'helpers/CreateHook'
import { UseProfile } from 'helpers/UserHooks'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, Search, Submit } from './Form'
import PopUp from './PopUp'

import { editUserData } from 'helpers/Auth'
import { Alert, Button } from 'reactstrap'

function CompleteSetting() {
  const { t } = useTranslation()
  const { userProfile } = UseProfile()
  const [openCompleteSetting, setOpenCompleteSetting] = useState(false)
  useEffect(() => {
    if (userProfile?.default_setting === false) {
      setOpenCompleteSetting(true)
    }
  }, [userProfile])

  const { register, handleSubmit, errors, create, watch, setValue, loading } = CreateHook<any>(
    '/set-settings',
    {}
  )

  const cityRef = useRef<any>()

  const resetCity = () => {
    setValue('city_id', '')
    if (cityRef?.current) {
      cityRef?.current?.setValue(null)
    }
  }

  const submit = async (data: any) => {
    const response = await create(data)
    if (response.status) {
      editUserData({
        default_setting: true
      })
      window.location.reload()
    }
  }
  if (userProfile?.default_setting === true) return null
  return (
    <>
      <Alert color="danger" className="mb-0 border-0 rounded-0 text-center fs-15 fw-medium">
        Please{' '}
        <Button
          color="link"
          className="px-0 fw-bold text-decoration-underline"
          onClick={() => setOpenCompleteSetting(true)}>
          Click here
        </Button>{' '}
        to Complete your profile settings to get access to all features
      </Alert>
      <PopUp
        show={openCompleteSetting}
        close={() => setOpenCompleteSetting(false)}
        title="Complete your profile">
        <div className="d-flex flex-column gap-3">
          <form onSubmit={handleSubmit(submit)}>
            <Search
              label={t('Default Language')}
              placeholder={t('Enter language')}
              model={register('default_language_id', {
                required: t('default language is required')
              })}
              error={errors.default_language_id}
              path="all-langs"
              propTitle="title"
            />
            <Search
              label={t('Default Currency')}
              placeholder={t('Enter currency')}
              model={register('default_currency_id', {
                required: t('default currency is required')
              })}
              error={errors.default_currency_id}
              path="all-currencies"
              propTitle="name"
            />
            <Search
              label={t('Country')}
              placeholder={t('Enter country')}
              model={register('country_id', { required: t('country is required') })}
              error={errors.country_id}
              path="all-countries"
              propTitle="title"
              onChange={() => resetCity()}
            />
            <Search
              ref={cityRef}
              label={t('City')}
              placeholder={t('Enter city')}
              model={register('city_id', { required: t('city is required') })}
              error={errors.city_id}
              path="all-cities"
              propTitle="title"
              filter={{ country_id: watch('country_id') }}
              disabled={!watch('country_id')}
              limit={100}
            />
            {/* <Input
              label={t('Domain')}
              placeholder={t('Enter domain')}
              model={register('domain', {
                required: t('Domain is required'),
                //   pattern: {
                //     value: /^(?!:\/\/)([a-zA-Z0-9-_]{1,63}\.)+[a-zA-Z]{2,6}$/,
                //     message: t('Domain must be valid')
                //   },
                validate: (value) => isValidDomain(value) || t('Domain must be valid')
              })}
              error={errors.domain}
            /> */}

            <Input
              label={t('Subdomain')}
              placeholder={t('Enter subdomain')}
              model={register('subdomain', {
                required: t('Subdomain is required')
              })}
              error={errors.subdomain}
              atEnd=".sallatk.com"
            />
            <Submit label={t('Submit')} loading={loading} />
          </form>
        </div>
      </PopUp>
    </>
  )
}

export default CompleteSetting
