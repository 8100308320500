export default {
  sectionHeading: {
    ar: 'الكل',
    en: 'All'
  },
  categorySlug: 'Category',
  filter: null,
  limit: 10,
  hideProductDescription: false,
  showRating: false
}
