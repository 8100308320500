import { Navigate } from 'react-router-dom'

import ProductsRoute from './products'

//login
import Login from '../pages/Authentication/Login'
import Logout from '../pages/Authentication/Logout'
import Basic404 from '../pages/AuthenticationInner/Errors/404'
import Error500 from '../pages/AuthenticationInner/Errors/500'

//Dashboard
import Dashboard from '../pages/Dashboard'

// banners
import BaseComponentList from '../pages/BaseComponent/list/index'

// editable column
import Editable from '../pages/Editable/list/index'

// banners
import BannersList from '../pages/Banners/list/index'

// moderators
import ModeratorsList from '../pages/Moderators/list/index'

// brands
import BrandsList from '../pages/Brands/list/index'

// brands
import Sliders from '../pages/Sliders/list/index'

// tickest
import TicketsDetails from '../pages/Tickets/details/index'
import TicketList from '../pages/Tickets/list/index'

// articles
import AddArticle from '../pages/Articles/Articles/create'
import Articles from '../pages/Articles/Articles/list'
import UpdateArticle from '../pages/Articles/Articles/update'

import ArticlesCategory from '../pages/Articles/ArticlesCategories/list'

// areas

// Shipping Companies
import Shipping from 'pages/Shipping/list'
import ShippingCompanyCost from 'pages/Shipping/ShippingCompanyCost'
// branches
import BranchAreas from 'pages/Branches/areas'
import CreateBranche from 'pages/Branches/create'
import Branches from 'pages/Branches/list'
import UpdateBranche from 'pages/Branches/update'
import WorkingHours from 'pages/Branches/workingHours'
import StaticPages from '../pages/StaticPages/list'
import UpdateStaticPages from '../pages/StaticPages/update'

// domain
import DomainList from 'pages/Domain/list'

import ActivitiesDetails from 'pages/Activities/details'
import ActivitiesList from 'pages/Activities/list'
import BranchContactsList from 'pages/Branches/contacts'
import CurrencyList from 'pages/currencies/list'
import CustomersList from 'pages/Customers/list'
import CustomerReviewDetails from 'pages/CustomersReviews/details'
import FooterList from 'pages/Footer/list'
import GalleryList from 'pages/Gallery/list'
import LanguagesList from 'pages/Languages/list'
import MenuCustomLinksList from 'pages/MenuCustomLinks/list'
import MenusHeader from 'pages/MenusHeader/list'
import NewsletterSubscribers from 'pages/NewsletterSubscribers/list'
import OrdersList from 'pages/Orders/list'
import PartnersList from 'pages/Partners/list'
import PaymentMethodList from 'pages/PaymentMethod/list'
import { POS } from 'pages/POS'
import { POSPrint } from 'pages/POS/components/POSPrint'
import Pricing from 'pages/Pricing'
import PaymentFailed from 'pages/Pricing/PaymentFailed'
import PaymentSuccess from 'pages/Pricing/PaymentSuccess'
import AddRole from 'pages/Roles/create'
import RolesList from 'pages/Roles/list'
import UpdateRole from 'pages/Roles/update'
import GeneralSettings from 'pages/settings/edit'
import StocksDetails from 'pages/Stocks/details'
import StocksList from 'pages/Stocks/list'
import CustomizeTheme from 'pages/Themes/customize'
import Themes from 'pages/Themes/list'
import TransactionDetails from 'pages/Transactions/details'
import Transactions from 'pages/Transactions/list'
import TranslationsEdit from 'pages/Translation/edit'
import Translations from 'pages/Translation/list'
import CustomersReviewsList from '../pages/CustomersReviews/list'
import CreateOrder from '../pages/Orders/create'
import OrderDetails from '../pages/Orders/details'

import AccessDenied from 'pages/403'
import AdvancedSettings from 'pages/AdvancedSettings/list'
import ApiIntegrationsList from 'pages/ApiIntegrations/list'
import UserProfile from 'pages/Authentication/user-profile'
import BasicSettings from 'pages/BasicSettings/list'
import HomepageSEO from 'pages/HomepageSEO/list'
import SearchResults from 'pages/SearchResults'
// import MenusFooter from 'pages/MenusFooter/list'

const authProtectedRoutes = [
  { path: '/', component: <Dashboard /> },

  ...ProductsRoute,

  //User Profile
  { path: '/profile', component: <UserProfile /> },

  // banners
  { path: '/basecomponent/list', component: <BaseComponentList /> },
  { path: '/editable', component: <Editable /> },

  // domain
  { path: '/domain', component: <DomainList /> },

  // banners
  { path: '/banners/list', component: <BannersList /> },

  // banners
  { path: '/moderators/list', component: <ModeratorsList /> },

  { path: '/roles/list', component: <RolesList /> },
  { path: '/roles/create', component: <AddRole /> },
  { path: '/roles/:id', component: <UpdateRole /> },

  // brands
  { path: '/brands/list', component: <BrandsList /> },

  // sliders
  { path: '/sliders/list', component: <Sliders /> },

  // tickets
  { path: '/tickets/list', component: <TicketList /> },
  { path: '/tickets/:id', component: <TicketsDetails /> },

  // partners
  { path: '/partners/list', component: <PartnersList /> },

  { path: '/MenuCustomLinks/list', component: <MenuCustomLinksList /> },

  // menus header
  { path: '/menus/header', component: <MenusHeader /> },

  { path: '/403', component: <AccessDenied /> },

  { path: '/basic-settings', component: <BasicSettings /> },
  { path: '/advanced-settings', component: <AdvancedSettings /> },

  // footer
  // { path: '/menus/footer', component: <MenusFooter /> },

  // articles categories
  { path: '/articlesCategories/list', component: <ArticlesCategory /> },
  // articles categories
  { path: '/articles/list', component: <Articles /> },
  { path: '/articles/create', component: <AddArticle /> },
  { path: '/articles/:id', component: <UpdateArticle /> },

  // newsletters Subscribers
  { path: '/news-letter-subscribers/list', component: <NewsletterSubscribers /> },

  // static pages
  { path: '/staticPages/list', component: <StaticPages /> },
  { path: '/staticPages/:id', component: <UpdateStaticPages /> },

  // Shipping Companies
  { path: '/shipping', component: <Shipping /> },
  { path: '/shipping/:id/cost', component: <ShippingCompanyCost /> },

  // branches
  { path: '/branches', component: <Branches /> },
  { path: '/branches/create', component: <CreateBranche /> },
  { path: '/branches/:id', component: <UpdateBranche /> },
  { path: '/branches/:id/workingHours', component: <WorkingHours /> },
  { path: '/branches/:id/areas', component: <BranchAreas /> },
  { path: '/branches/:id/contacts', component: <BranchContactsList /> },

  //activities
  { path: '/activities/list', component: <ActivitiesList /> },
  { path: '/activities/:id', component: <ActivitiesDetails /> },

  // Settings
  { path: '/settings', component: <GeneralSettings /> },

  { path: '/customers-reviews/list', component: <CustomersReviewsList /> },
  { path: '/customers-reviews/:id', component: <CustomerReviewDetails /> },
  // transactions
  { path: '/transactions/list', component: <Transactions /> },
  { path: '/transactions/:id', component: <TransactionDetails /> },

  // Stocks
  { path: '/stocks/list', component: <StocksList /> },
  { path: '/stocks/:id', component: <StocksDetails /> },

  { path: '/pricing', component: <Pricing /> },

  { path: '/homepage-seo', component: <HomepageSEO /> },

  { path: '/payment-success', component: <PaymentSuccess /> },
  { path: '/payment-failed', component: <PaymentFailed /> },

  // orders
  { path: '/orders/list', component: <OrdersList /> },
  { path: '/orders/:id', component: <OrderDetails /> },
  { path: '/orders/create', component: <CreateOrder /> },

  // footer
  { path: '/footer', component: <FooterList /> },

  { path: '/languages', component: <LanguagesList /> },

  // gallery
  { path: '/gallery', component: <GalleryList /> },

  { path: '/currencies', component: <CurrencyList /> },

  { path: '/payment-methods', component: <PaymentMethodList /> },

  { path: '/search', component: <SearchResults /> },

  { path: '/api-integrations', component: <ApiIntegrationsList /> },

  // Transactions
  { path: '/translation', component: <Translations /> },
  { path: '/translation/:key/edit/:code', component: <TranslationsEdit /> },

  { path: '/customers/list', component: <CustomersList /> },

  // themes
  { path: '/themes', component: <Themes /> },
  { path: '/themes/customize/:id', component: <CustomizeTheme /> },

  // POS
  { path: '/pos', component: <POS /> },
  { path: '/pos-print', component: <POSPrint /> },

  { path: '*', component: <Navigate to="/404" /> }
]

const publicRoutes = [
  // Authentication Page
  { path: '/logout', component: <Logout /> },
  { path: '/login', component: <Login /> },

  //AuthenticationInner pages
  { path: '/404', component: <Basic404 /> },
  { path: '/500', component: <Error500 /> }
]

export { authProtectedRoutes, publicRoutes }
