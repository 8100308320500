import { useParams } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import Loader from 'Components/Common/Loader'
import FetchHock from 'helpers/FetchHock'

import OrderDetailsTable from '../Components/OrderDetailsTable'
import OrderStatus from '../Components/OrderStatus'
import CustomerDetails from '../Components/CustomerDetails'
import ShippingDetails from '../Components/ShippingDetails'
import BranchDetails from '../Components/BranchDetails'
import TransactionDetails from '../Components/TransactionDetails'
import CardSeven from '../Components/TransactionDetails'
import Error from 'Components/IndexTable/partials/Error'
import BreadCrumb from 'Components/Common/BreadCrumb'

const OrderDetails = () => {
  document.title = 'Order Details | Sallatk'
  const { t } = useTranslation()
  const { id } = useParams()

  const {
    result: data,
    loading,
    error
  } = FetchHock<CustomersReviews>({
    path: `/orders/${id}`
  })
  console.log(data)
  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center min-vh-100">
        <Loader />
      </div>
    )
  if (error)
    return (
      <div className="page-content">
        <Error />
      </div>
    )

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <Row>
          <Col xl={9}>
            <OrderDetailsTable data={data} />
            <OrderStatus />
            <TransactionDetails data={data?.transactions || {}} />
          </Col>
          <Col xl={3}>
            <ShippingDetails data={data.shipping_company} />
            <CustomerDetails user={data?.user} />
            <BranchDetails data={data?.branch} />
            {/* Shipping Address */}
            {/* <CardSix />  */}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default OrderDetails
