import UpdateHock from 'helpers/UpdateHock'

function useUpdateDomain() {
  return UpdateHock<{ domain: string; tld?: string; type?: string }>({
    path: '/settings/domain',
    initValue: {
      domain: '',
      tld: 'com',
      type: 'register'
    },
    allowGet: false
  })
}

export default useUpdateDomain
