import AsyncReactSelect from 'react-select/async'
import linkGenerator from 'helpers/linkGenerator'
import axios from 'axios'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

const Search = forwardRef(
  (
    {
      label,
      placeholder,
      model,
      error,
      value,
      path,
      propTitle = 'name',
      isMulti = false,
      className,
      onChange,
      filter = {},
      disabled = false,
      valueId,
      limit = 15
    }: {
      label?: string
      placeholder?: string | null
      model?: any
      error?: any
      value?: any
      path: string
      propTitle?: string
      isMulti?: boolean
      className?: string
      filter?: any
      onChange?: (value: { label: string; value: string }) => void
      disabled?: boolean
      valueId?: string
      limit?: number
    },
    ref
  ) => {
    const { t } = useTranslation()
    // id for input focus
    const randomId = Math.random().toString(36).substring(7)

    // State to store the selected value option
    const [selectedValue, setSelectedValue] = useState<{ label: string; value: string } | null>(
      model?.value
    )

    // Fetch selected value option if value is provided
    useEffect(() => {
      if (value) {
        if (value?.label && value?.value) {
          setSelectedValue(value)
        }
        if (propTitle in value && value?.id) {
          setSelectedValue({ label: value[propTitle], value: value?.id })
        }
      }
    }, [value])

    // options logic
    const loadOptions = (
      inputValue: string,
      callback: (options: { label: string; value: string }[]) => void
    ) => {
      axios
        .get(linkGenerator('/' + path), {
          params: {
            term: inputValue || '',
            page: 1,
            limit: limit,
            sort_direction: 'desc',
            sort_by: 'id',
            filter: filter
          }
        })
        .then((response) => {
          const items = response.data.result?.data || response.data.result

          const options = items?.map((item: any) => {
            return { label: item[propTitle], value: item.id }
          })

          // Set selected value if valueId is provided
          if (valueId) {
            const selectedOption = options.find((option: any) => option.value === valueId)

            if (selectedOption) {
              changeSelect(selectedOption)
            }
          }
          callback(options)
        })
        .catch(() => {
          callback([])
        })
    }

    // change logic
    const changeSelect = (select: any) => {
      setSelectedValue(select)
      if (model) {
        // name of input
        const name = model?.name
        model?.onChange({
          target: {
            name,
            value: select.value ?? select
          }
        })
      }
      if (onChange) {
        onChange(select)
      }
    }

    // refresh on filter
    const [changeOptions, setChangeOptions] = useState(0)
    const [oldFilter, setOldFilter] = useState({})
    useEffect(() => {
      if (JSON.stringify(filter) !== JSON.stringify(oldFilter)) {
        setOldFilter({ ...filter })
        setChangeOptions(changeOptions + 1)
        // setSelectedValue(null)
      }
    }, [filter])

    useImperativeHandle(ref, () => ({
      setValue: (value) => setSelectedValue(value)
    }))

    return (
      <div className={clsx('mb-3', className)}>
        {label && (
          <label htmlFor={randomId} className="form-label">
            {label}
          </label>
        )}
        <AsyncReactSelect
          id={randomId}
          isDisabled={disabled}
          key={changeOptions}
          isMulti={isMulti}
          loadOptions={loadOptions}
          placeholder={placeholder ?? `${t('Enter')} ${label}`}
          className={error ? 'form-control is-invalid p-0' : 'form-control p-0'}
          value={selectedValue}
          onChange={changeSelect}
          defaultOptions
        />
        <div className="invalid-feedback">{error?.message}</div>
      </div>
    )
  }
)

export default Search
