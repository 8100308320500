import { useContext } from 'react'
import { Button } from 'reactstrap'
import { useCreateOrderMutation } from 'services/pos/orders'
import { POSContext } from '../POSContext'

type Props = {}

export const POSSubmitOrder = (props: Props) => {
  const { paymentMethod, invoiceId } = useContext(POSContext)
  const { mutateAsync: createOrder, isPending } = useCreateOrderMutation()

  const submitOrder = async () => {
    const response = await createOrder({
      method_id: paymentMethod?.id,
      ...(invoiceId && {
        invoice_id: invoiceId
      })
    })
    if (response?.id) {
      const receiptTab = window.open(`/pos-print?orderId=${response.id}`)
      console.log('receiptTab: ', receiptTab)
      
      receiptTab?.focus()
    }
    console.log('response: ', response)
  }

  return (
    <div>
      <Button className="" block disabled={isPending} onClick={submitOrder}>
        Submit Order
      </Button>
    </div>
  )
}
