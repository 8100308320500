import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'

import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/pagination'

import { Autoplay, Mousewheel } from 'swiper/modules'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import FetchHock from 'helpers/FetchHock'
import ViewDataWithLoading from 'Components/ViewDataWithLoading'
import Rating from './Rating'

const RecentActivity = (props: any) => {
  return (
    <React.Fragment>
      <div
        className={
          props.rightColumn
            ? 'col-auto layout-rightside-col d-block '
            : 'col-auto layout-rightside-col d-none'
        }
        id="layout-rightside-coll">
        <div className="overlay" onClick={props?.hideRightColumn && props.hideRightColumn}></div>
        <div className="layout-rightside">
          <Card className="h-100 rounded-0">
            <CardBody className="p-0">
              <RecentReviews />
              <RecentActivitySlider />
              <TopSellingCategories />
              <Rating />
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default RecentActivity

const TopSellingCategories = () => {
  const { t } = useTranslation()

  // Top Categories orders {category , total orders}
  const { items: topSellCategories, loading: categoriesLoading } = FetchHock<any>({
    path: '/dashboard/top-sell-categories'
  })
  return (
    <div className="p-3">
      <h6 className="text-muted mb-3 text-uppercase fw-semibold">{t('Top Selling Categories')}</h6>

      <ol className="ps-3 text-muted">
        {(topSellCategories || []).map((item, key) => (
          <li className="py-1" key={key}>
            <Link to="#" className="text-muted">
              {item.title} <span className="float-end">({item.total})</span>
            </Link>
          </li>
        ))}
      </ol>
      <div className="mt-3 text-center">
        <Link to="#" className="text-muted text-decoration-underline">
          {t('View all Categories')}
        </Link>
      </div>
    </div>
  )
}

const RecentActivitySlider = () => {
  const { t } = useTranslation()

  // Recent Activity {activity logs}
  const { items: data, loading } = FetchHock<any>({ path: '/activity' })

  return (
    <div className="p-3">
      <h6 className="text-muted mb-3 text-uppercase fw-semibold">{t('Recent Activity')}</h6>
      <Swiper
        direction={'vertical'}
        slidesPerView={2}
        spaceBetween={10}
        mousewheel={true}
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false
        }}
        modules={[Autoplay, Mousewheel]}
        className="mySwiper vertical-swiper"
        style={{ height: '246px' }}>
        {data.map((item, index) => (
          <SwiperSlide key={index}>
            <Card className="border border-dashed shadow-none">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-shrink-0 avatar-sm">
                    <div className="avatar-title bg-light rounded">
                      {item?.causer?.image ? (
                        <img src={item?.causer?.image} alt="" height="30" />
                      ) : (
                        <i className="mdi mdi-information text-secondary fs-20"></i>
                      )}
                    </div>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <div>
                      <p className=" mb-1 fst-italic text-truncate-two-lines">{item?.name}</p>
                      <p className=" mb-1 fst-italic text-truncate-two-lines">
                        {item?.causer ? (
                          <>
                            {t('by')} {item?.causer?.name ? item?.causer?.name : ' '}{' '}
                            {item?.causer?.lastname ? item?.causer?.lastname : ' '}
                          </>
                        ) : null}
                      </p>
                      <p className=" mb-1 fst-italic text-truncate-two-lines">{item?.created_at}</p>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

type RecentReview = {
  id: number
  comment: string
  published: number
  user: {
    id: number
    name: string
  }
  created_at: string
}
const RecentReviews = () => {
  const { t } = useTranslation()

  // recent reviews last week
  const {
    items: data,
    loading,
    error
  } = FetchHock<any>({
    path: '/dashboard/recent-reviews'
  })

  return (
    <div className="p-3">
      <h6 className="text-muted mb-3 text-uppercase fw-semibold">{t('Recent Reviews')}</h6>
      <ViewDataWithLoading items={data} loading={loading} error={error}>
        <Swiper
          direction={'vertical'}
          slidesPerView={2}
          spaceBetween={10}
          mousewheel={true}
          loop={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false
          }}
          modules={[Autoplay, Mousewheel]}
          className="mySwiper vertical-swiper"
          style={{ height: '246px' }}>
          {(data || [])?.map((item: RecentReview, index) => {
            return (
              <SwiperSlide key={index}>
                <Card className="border border-dashed shadow-none">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-shrink-0 avatar-sm">
                        <div className="avatar-title bg-light rounded-circle">
                          <i
                            className={clsx(
                              ' fs-20',
                              item?.published
                                ? 'mdi mdi-check-bold text-success'
                                : 'mdi mdi-exclamation-thick text-warning'
                            )}></i>
                        </div>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <div>
                          <p className="text-muted mb-1 fst-italic text-truncate-two-lines">
                            " {item?.comment} "
                          </p>
                        </div>

                        <div className="text-end mb-0 text-muted">
                          - {t('by')}{' '}
                          <cite title="Source Title">{item?.user?.name || t('Unknown')}</cite>
                        </div>
                        {item?.created_at ? (
                          <div className="text-end mb-0 text-muted">
                            - {t('at')} <cite title="Source Title">{item?.created_at}</cite>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </ViewDataWithLoading>
    </div>
  )
}
