// react imports
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useParams } from 'react-router'
// bootstrap elements
import { Card, CardBody, CardHeader, ListGroup, ListGroupItem, Row, Col } from 'reactstrap'

import CreateModal from './CreateModal'
import Loader from 'Components/IndexTable/partials/Loader'
import DeleteModal from 'Components/IndexTable/partials/DeleteModal'
import FetchHock from 'helpers/FetchHock'

interface workHour {
  id: number
  branch_id: number
  status: number
  day: string
  from_time: string
  to_time: string
}

const WorkHours = () => {
  const params = useParams()
  const { t } = useTranslation()

  const fetchHock = FetchHock<workHour>({
    path: '/branch/workingHours',
    initFilter: { branch_id: params.id }
  })

  // create modal logic
  const [showCreate, changeShowCreate] = useState<boolean>(false)
  const openCreate = () => changeShowCreate(true)
  const closeCreate = () => changeShowCreate(false)

  return (
    <Card className="mt-2">
      <CardHeader>
        <div className="d-flex justify-content-between">
          {/* card title */}
          <h5 className="card-title mb-0">{t('Work hours')}</h5>
          {/* add action button */}
          <button type="button" className="btn btn-primary add-btn" onClick={openCreate}>
            <i className="ri-add-circle-line align-middle me-1"></i> {t('Add')}
          </button>
        </div>
      </CardHeader>
      <CardBody>
        {fetchHock.loading && <Loader />}
        {!fetchHock.loading && (
          <Row>
            <Col md={12}>
              <ListGroup>
                {/* Saturday */}
                <ListGroupItem className="border-0">{t('Saturday')}</ListGroupItem>
                {fetchHock.items
                  .filter((el) => el.day == 'saturday')
                  .map((item, index) => (
                    <ListGroupItem color="primary" className="border-0" key={index}>
                      <div className="d-flex justify-content-between">
                        <span className="text-primary">
                          {item.from_time} - {item.to_time}
                        </span>
                        <a
                          className="lh-1 align-middle link-danger"
                          onClick={() => fetchHock.openDelete(item.id)}>
                          <i className="ri-delete-bin-line"></i>
                        </a>
                      </div>
                    </ListGroupItem>
                  ))}
                {!fetchHock.items.filter((el) => el.day == 'saturday').length && (
                  <ListGroupItem color="dark" className="border-0 text-center">
                    {t('No slots available')}
                  </ListGroupItem>
                )}
                {/* Sunday */}
                <ListGroupItem className="border-0">{t('Sunday')}</ListGroupItem>
                {fetchHock.items
                  .filter((el) => el.day == 'sunday')
                  .map((item, index) => (
                    <ListGroupItem color="primary" className="border-0" key={index}>
                      <div className="d-flex justify-content-between">
                        <span className="text-primary">
                          {item.from_time} - {item.to_time}
                        </span>
                        <a
                          className="lh-1 align-middle link-danger"
                          onClick={() => fetchHock.openDelete(item.id)}>
                          <i className="ri-delete-bin-line"></i>
                        </a>
                      </div>
                    </ListGroupItem>
                  ))}
                {!fetchHock.items.filter((el) => el.day == 'sunday').length && (
                  <ListGroupItem color="dark" className="border-0 text-center">
                    {t('No slots available')}
                  </ListGroupItem>
                )}
                {/* Monday */}
                <ListGroupItem className="border-0">{t('Monday')}</ListGroupItem>
                {fetchHock.items
                  .filter((el) => el.day == 'monday')
                  .map((item, index) => (
                    <ListGroupItem color="primary" className="border-0" key={index}>
                      <div className="d-flex justify-content-between">
                        <span className="text-primary">
                          {item.from_time} - {item.to_time}
                        </span>
                        <a
                          className="lh-1 align-middle link-danger"
                          onClick={() => fetchHock.openDelete(item.id)}>
                          <i className="ri-delete-bin-line"></i>
                        </a>
                      </div>
                    </ListGroupItem>
                  ))}
                {!fetchHock.items.filter((el) => el.day == 'monday').length && (
                  <ListGroupItem color="dark" className="border-0 text-center">
                    {t('No slots available')}
                  </ListGroupItem>
                )}
                {/* Tuesday */}
                <ListGroupItem className="border-0">{t('Tuesday')}</ListGroupItem>
                {fetchHock.items
                  .filter((el) => el.day == 'tuesday')
                  .map((item, index) => (
                    <ListGroupItem color="primary" className="border-0" key={index}>
                      <div className="d-flex justify-content-between">
                        <span className="text-primary">
                          {item.from_time} - {item.to_time}
                        </span>
                        <a
                          className="lh-1 align-middle link-danger"
                          onClick={() => fetchHock.openDelete(item.id)}>
                          <i className="ri-delete-bin-line"></i>
                        </a>
                      </div>
                    </ListGroupItem>
                  ))}
                {!fetchHock.items.filter((el) => el.day == 'tuesday').length && (
                  <ListGroupItem color="dark" className="border-0 text-center">
                    {t('No slots available')}
                  </ListGroupItem>
                )}
                {/* Thursday */}
                <ListGroupItem className="border-0">{t('Thursday')}</ListGroupItem>
                {fetchHock.items
                  .filter((el) => el.day == 'thursday')
                  .map((item, index) => (
                    <ListGroupItem color="primary" className="border-0" key={index}>
                      <div className="d-flex justify-content-between">
                        <span className="text-primary">
                          {item.from_time} - {item.to_time}
                        </span>
                        <a
                          className="lh-1 align-middle link-danger"
                          onClick={() => fetchHock.openDelete(item.id)}>
                          <i className="ri-delete-bin-line"></i>
                        </a>
                      </div>
                    </ListGroupItem>
                  ))}
                {!fetchHock.items.filter((el) => el.day == 'thursday').length && (
                  <ListGroupItem color="dark" className="border-0 text-center">
                    {t('No slots available')}
                  </ListGroupItem>
                )}
                {/* Friday */}
                <ListGroupItem className="border-0">{t('Friday')}</ListGroupItem>
                {fetchHock.items
                  .filter((el) => el.day == 'friday')
                  .map((item, index) => (
                    <ListGroupItem color="primary" className="border-0" key={index}>
                      <div className="d-flex justify-content-between">
                        <span className="text-primary">
                          {item.from_time} - {item.to_time}
                        </span>
                        <a
                          className="lh-1 align-middle link-danger"
                          onClick={() => fetchHock.openDelete(item.id)}>
                          <i className="ri-delete-bin-line"></i>
                        </a>
                      </div>
                    </ListGroupItem>
                  ))}
                {!fetchHock.items.filter((el) => el.day == 'friday').length && (
                  <ListGroupItem color="dark" className="border-0 text-center">
                    {t('No slots available')}
                  </ListGroupItem>
                )}
              </ListGroup>
            </Col>
          </Row>
        )}
      </CardBody>
      {showCreate && (
        <CreateModal close={closeCreate} show={showCreate} onSuccess={fetchHock.fetch} />
      )}
      {/* delete component */}
      {fetchHock.deleteId && fetchHock.deleteId > 0 && (
        <DeleteModal
          show={fetchHock.deleteId}
          onDeleteClick={fetchHock.deleteItem}
          onCloseClick={() => fetchHock.closeDelete()}
        />
      )}
    </Card>
  )
}

export default WorkHours
