import LanguageBlock from 'Components/Common/LanguageBlock'
import withRouter from 'Components/Common/withRouter'
import { Checkbox, Input, PasswordInput, Submit } from 'Components/Form'
import logoLight from 'assets/images/sallatk.svg'
import clsx from 'clsx'
import { login } from 'helpers/Auth'
import PhoneField from 'pages/Customers/components/PhoneField'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap'
import ParticlesAuth from '../AuthenticationInner/ParticlesAuth'

interface formType {
  email: string
  password: string
  remember: boolean
}

const Login = () => {
  document.title = 'SignIn | Sallatk'

  const { t } = useTranslation()

  const [activeTab, setActiveTab] = useState('email')

  const toggleTab = (tab: 'email' | 'phone') => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="70" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4 p-2">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">{t('Welcome Back')} !</h5>
                      <p className="text-muted">
                        {t('Sign in to continue to')} {t('Sallatk')}.
                      </p>
                    </div>
                    <Nav tabs pills fill>
                      <NavItem>
                        <NavLink
                          className={clsx(
                            activeTab === 'email' && 'active bg-secondary text-black',
                            'cursor-pointer'
                          )}
                          onClick={() => toggleTab('email')}>
                          {/* <i className="las la-upload me-2"></i> */}
                          {t('Email')}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={clsx(
                            activeTab === 'phone' && 'active bg-secondary text-black',
                            'cursor-pointer'
                          )}
                          onClick={() => toggleTab('phone')}>
                          {/* <i className="las la-images me-2"></i> */}
                          {t('Phone')}
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab} className="pt-3">
                      <TabPane tabId={'email'}>
                        <EmailForm />
                      </TabPane>
                      <TabPane tabId={'phone'}>
                        <PhoneForm />
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
                <LanguageBlock />
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  )
}

export default withRouter(Login)

type LoginPhoneFormType = {
  dialing_code?: string
  phone?: string
  password?: string
  remember?: boolean
}

function PhoneForm() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues
  } = useForm<LoginPhoneFormType>({})

  const submit = async (data: LoginPhoneFormType) => {
    setLoading(true)
    const response = await login(data)
    setLoading(false)

    if (response.status) {
      if (response?.data?.is_package_expired) {
        // use protected route instead
        navigate('/pricing')
      } else {
        navigate('/')
      }
    }
  }

  return (
    <div className="p-2 mt-4">
      <form onSubmit={handleSubmit(submit)}>
        <PhoneField register={register} errors={errors} getValues={getValues}>
          <div className="d-flex w-100 gap-2 ">
            <div>
              <PhoneField.Code />
            </div>
            <div className="flex-grow-1">
              <PhoneField.Number />
            </div>
          </div>
        </PhoneField>
        <PasswordInput
          label={t('Password')}
          placeholder={t('Enter Password')}
          model={register('password', {
            required: t('Password is required')
          })}
          error={errors.password}
        />
        <Checkbox label={t('Remember me')} model={register('remember')} />

        <Submit loading={loading} label={t('Sign In')} className="w-100" />
      </form>
    </div>
  )
}

function EmailForm() {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [loading, setLoading] = useState<boolean>(false)
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<any>({})

  const submit = async (data: formType) => {
    setLoading(true)
    const response = await login(data)
    setLoading(false)

    if (response.status) {
      if (response?.data?.is_package_expired) {
        // use protected route instead
        navigate('/pricing')
      } else {
        navigate('/')
      }
    }
  }

  return (
    <div className="p-2 mt-4">
      <form onSubmit={handleSubmit(submit)}>
        <Input
          label={t('Email')}
          type="email"
          placeholder={t('Enter email')}
          model={register('email', {
            required: t('email is required')
          })}
          error={errors.email}
        />

        <PasswordInput
          label={t('Password')}
          placeholder={t('Enter Password')}
          model={register('password', {
            required: t('Password is required')
          })}
          error={errors.password}
        />

        <Checkbox label={t('Remember me')} model={register('remember')} />

        <div className="mt-4">
          <Submit loading={loading} label={t('Sign In')} className="w-100" />
        </div>
      </form>
    </div>
  )
}
