import { components } from 'react-select'
import { Select } from 'Components/Form'
import { useTranslation } from 'react-i18next'
import { iconArray } from '../staticData'

const { Option } = components
const IconOption = (props: any) => (
  <Option {...props}>
    <div className="d-flex align-items-center gap-2">
      <i className={props.data.value} />
      {props.data.label}
    </div>
  </Option>
)

function SelectWithIconInOptions({ register, errors }: any) {
  const { t } = useTranslation()
  return (
    <Select
      label={t('Icon')}
      model={register('icon')}
      error={errors.icon}
      options={iconArray}
      components={{ Option: IconOption }}
    />
  )
}

export default SelectWithIconInOptions
