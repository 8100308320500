// react elements
import { useTranslation } from 'react-i18next'
// botstrab elements
import { Card, CardBody, CardHeader } from 'reactstrap'
// required components
import { Input, Search, Select } from 'Components/Form'
import { useFieldArray } from 'react-hook-form'

const Features = ({
  register,
  errors,
  control,
  getValues
}: {
  register: any
  errors: any
  control: any
  getValues: any
}) => {
  const { t } = useTranslation()

  // options logic
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'featureOption'
  })
  const addOption = () =>
    append({
      featureOptionId: null,
      discount: null,
      discount_type: null,
      cost: null,
      price: null
    })
  const removeOption = (index: number) => remove(index)

  return (
    <Card>
      <CardHeader>
        <h5 className="card-title mb-0">{t('Instruments')}</h5>
      </CardHeader>
      <CardBody className="p-0">
        <table className="table table-hover table-striped align-middle table-nowrap mb-0">
          <thead>
            <tr>
              <th scope="col">{t('Title')}</th>
              <th scope="col">{t('Price')}</th>
              <th scope="col">{t('Delete')}</th>
            </tr>
          </thead>
          <tbody>
            {fields.map((field, index) => (
              <tr key={index}>
                <td>
                  <Search
                    placeholder={t('Select instrument')}
                    model={register(`featureOption.${index}.featureOptionId`)}
                    path="featureOptions"
                    value={getValues(`featureOption.${index}.featureOption`)}
                    propTitle="title"
                  />
                </td>
                <td>
                  <Input
                    type="number"
                    className="form-control"
                    placeholder={t('Price')}
                    model={register(`featureOption.${index}.price`)}
                  />
                </td>
                <td>
                  <button
                    className="btn btn-outline-danger ms-2"
                    type="button"
                    disabled={fields.length <= 1}
                    onClick={() => removeOption(index)}
                  >
                    <i className="ri-close-line align-middle"></i>
                  </button>
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan={10000}>
                <button className="btn btn-secondary w-100" type="button" onClick={addOption}>
                  <i className="ri-add-line align-middle"></i> {t('Add instrument')}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </CardBody>
    </Card>
  )
}

export default Features
