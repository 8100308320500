import axios from 'axios'
import linkGenrator from './linkGenerator'

interface fetchResponse<dataType> {
  status: boolean
  data?: dataType
  errors: string[]
  message: string
}

const fetch = async <dataType>(
  endpoint: string,
  data: any = {},
  method: string = 'post'
): Promise<fetchResponse<dataType>> => {
  try {
    let response
    if (method === 'get') response = await axios.get(linkGenrator(endpoint), { params: data })
    else if (method === 'delete') response = await axios.delete(linkGenrator(endpoint))
    else if (method === 'put') response = await axios.put(linkGenrator(endpoint), data)
    else response = await axios.post(linkGenrator(endpoint), data)

    if (response.status === 200 && response.data.status === 'success') {
      return {
        status: true,
        data: response.data.result,
        message: response.data.message,
        errors: []
      }
    } else {
      return {
        status: false,
        data: undefined,
        message: '',
        errors: ['Error in Login']
      }
    }
  } catch (err: any) {
    const errors: string[] = []
    const errorsObject = err?.response?.data?.errors
    // handel error and push to errors
    if (err.code === 'ERR_NETWORK') errors.push('Network error you have error in your network')
    else if (err.code === 'ERR_BAD_REQUEST' && errorsObject)
      for (const key in errorsObject) {
        errors.push(errorsObject[key][0])
      }
    const errorMessage = err?.response?.data?.message
    if (errorMessage && !errors.includes(errorMessage)) {
      errors.push(errorMessage)
    }
    return {
      status: false,
      data: undefined,
      message: '',
      errors: errors
    }
  }
}

export default fetch
