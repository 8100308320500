// react hocks
import { useState } from 'react'

const Input = ({
  label,
  placeholder,
  model,
  error,
  onChange
}: {
  label: string
  placeholder: string
  model?: any
  error?: any
  onChange?: (value: string) => void
}) => {
  const randumId = Math.random().toString(36).substring(7)
  const [passwordShow, setPasswordShow] = useState<boolean>(false)

  return (
    <div className="mb-3">
      <label htmlFor={randumId} className="form-label">
        {label}
      </label>
      <div
        className={
          error
            ? 'position-relative auth-pass-inputgroup is-invalid'
            : 'position-relative auth-pass-inputgroup'
        }
      >
        <input
          type={passwordShow ? 'text' : 'password'}
          className={error ? 'form-control is-invalid' : 'form-control'}
          id={randumId}
          placeholder={placeholder}
          {...model}
          onChange={(event) => {
            if (onChange) onChange(event.target.value)
          }}
        />
        <button
          className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
          type="button"
          id="password-addon"
          onClick={() => setPasswordShow(!passwordShow)}
        >
          <i className="ri-eye-fill align-middle"></i>
        </button>
      </div>
      <div className="invalid-feedback">{error?.message}</div>
    </div>
  )
}

export default Input
