import showSuccess from 'helpers/SuccessNotfication'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import DomainButton from './DomainButton'

function RegisterButton({ isAvailable = true, loading, submit }) {
  const { t } = useTranslation()
  const { reset, getValues, watch } = useFormContext()

  const handleRegisterSubmit = async () => {
    const data = getValues()
    const domain = data.domain
    const tld = data.tld
    const response = await submit({
      domain: domain + '.' + tld,
      type: 'register'
    })
    if (response.status) {
      showSuccess(t('Domain registered successfully'))
      reset()
    }
  }

  return (
    <DomainButton
      onClick={handleRegisterSubmit}
      loading={loading}
      loadingLabel={t('Registering')}
      label={t('Register')}
      disabled={loading || !isAvailable || !watch('domain') || !watch('tld')}
      color="primary"
      type="submit"
    />
  )
}

export default RegisterButton
