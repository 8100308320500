import { useTranslation } from 'react-i18next'
import { Row } from 'reactstrap'
import { Input, Search } from 'Components/Form'
import { useFormContext } from 'react-hook-form'
import { usePaidFeaturesContext } from '.'
import { useRef } from 'react'

function PaidOptionsInputs() {
  const { t } = useTranslation()

  const formContext = useFormContext()

  const {
    register,
    setValue,
    formState: { errors }
  } = formContext

  const featuresTypeRef = useRef<any>()

  const paidFeaturesContext = usePaidFeaturesContext()
  const { inputs, type } = paidFeaturesContext

  const resetFeatureOptionId = () => {
    setValue('featureOptionId', '')
    if (featuresTypeRef?.current) {
      featuresTypeRef?.current?.setValue(null)
    }
  }
  return (
    <Row>
      <input
        type="hidden"
        {...register('type', {
          value: type
        })}
      />
      {inputs.map((input, index) => {
        switch (input.type) {
          case 'search':
            return (
              <Search
                ref={input.ref}
                key={index}
                label={input.label}
                placeholder={input.placeholder}
                model={register(`${input.name}`, {
                  required: t('This field is required')
                })}
                error={errors[`${input.name}`]}
                disabled={input.disabled}
                path={input.path}
                propTitle={input.propTitle}
                valueId={input.valueId}
                onChange={input.onChange}
                filter={input.filter}
                className="col-6"
              />
            )
          case 'text':
            return (
              <Input
                key={index}
                label={input?.label}
                type="number"
                className="form-control"
                placeholder={input.placeholder}
                disabled={input.disabled}
                model={register(input.name, {
                  required: input?.required
                })}
                error={errors[input.name]}
                wrapperClassName="col-3"
              />
            )

          case 'custom':
            return input?.renderItem({
              ctx: {
                ...formContext,
                ...paidFeaturesContext,
                featuresTypeRef,
                resetFeatureOptionId
              },
              key: index
            })

          default:
            break
        }
      })}
    </Row>
  )
}

export default PaidOptionsInputs
