import { useEffect, useState } from 'react'
import { Spinner } from 'reactstrap'

import axios from 'axios'
import linkGenerator from 'helpers/linkGenerator'

const FileUpload = ({
  label,
  placeholder,
  model,
  error,
  value,
  onUpload,
  onFinishUpload,
  anyFiles,
  uploadUrl
}: {
  label: string
  placeholder?: string
  model?: any
  error?: any
  value?: any
  onUpload?: () => void
  onFinishUpload?: () => void
  anyFiles?: boolean
  uploadUrl?: string
}) => {
  const randumId = Math.random().toString(36).substring(7)

  const [fileName, setFileName] = useState<string>(value || '')
  const [loading, setLoading] = useState<boolean>(false)
  const [serverError, setServerError] = useState<boolean>(null)
  useEffect(() => {
    if (!fileName && value) {
      setFileName(value)
    }
  }, [value])

  const uploadFile = async (event: any) => {
    setLoading(true)
    onUpload && onUpload()
    const file = event.target.files[0]

    const formData = new FormData()
    formData.append('file', file)

    event.target.value = null

    try {
      const url = uploadUrl || `/fileService${anyFiles ? '/file' : ''}`
      const response = await axios.post(linkGenerator(url), formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      const name = model.name
      const value = response?.data?.result?.file || response.data.result
      model.onChange({
        target: {
          name,
          value
        }
      })
      setFileName(value)
    } catch (error) {
      showError(error.response.data.message)
    }

    setLoading(false)
    onFinishUpload && onFinishUpload()
  }

  const removeFile = () => {
    setFileName('')

    const name = model.name
    model.onChange({
      target: {
        name,
        value: ''
      }
    })
  }

  const showError = (err) => {
    setServerError(err)
    setTimeout(() => setServerError(null), 3000)
  }

  return (
    <div className="mb-3">
      <label htmlFor={randumId} className="form-label">
        {label}
      </label>
      <div className="input-group">
        <span className="input-group-text" id="inputGroup-sizing-default">
          {label}
        </span>
        <input
          type="text"
          placeholder={placeholder}
          className="form-control"
          value={fileName}
          onChange={(event) => event.preventDefault()}
        />
        <input
          className="abslute-mack"
          type="file"
          id={randumId}
          onChange={(event) => uploadFile(event)}
        />
        {loading && <Spinner className="upload-icon" color="secondary" size="sm" />}
        {!loading && fileName && (
          <i onClick={() => removeFile()} className="ri-close-line remove-icon"></i>
        )}
      </div>
      {serverError && <div className="text-danger">{serverError}</div>}
      <div className="text-danger">{error?.message}</div>
    </div>
  )
}

export default FileUpload
