import Slider from './Slider/Config'
const config = [
  {
    title: 'Slider',
    key: 'Slider',
    config: Slider
  }
]

export default config
