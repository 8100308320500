import FetchHock from 'helpers/FetchHock'
import useStaticData from './useStaticData'
import { useState } from 'react'

const useMenuFormOptions = () => {
  const { moduleMap } = useStaticData()
  const [modulePath, setModulePath] = useState<string>('/')
  const {
    items: moduleIdOptions,
    loading: moduleIdOptionsLoading,
    isFetching: moduleIdOptionsIsFetching,
    error,
    options,
    totalPages,
    totalItems,
    showedItems,
    pagenate,
    sort,
    search
  } = FetchHock<{ [key: string]: string }>({
    path: modulePath,
    enabled: modulePath !== '/'
  })
  const { items: parentOptions, loading: parentOptionsLoading } = FetchHock<{
    icon: string
    id: number
    text: string
    title: string
    url: string
  }>({
    path: '/menus/header?filter[code]=dynamic&filter[parent_id]=-1'
  })

  const fetchModuleIds = (value: string) => {
    setModulePath(`/${moduleMap[value]}`)
  }

  return {
    moduleIdOptions,
    moduleIdOptionsLoading: moduleIdOptionsLoading || moduleIdOptionsIsFetching,
    error,
    options,
    totalPages,
    totalItems,
    showedItems,
    pagenate,
    sort,
    search,
    fetchModuleIds,
    parentOptions,
    parentOptionsLoading
  }
}

export default useMenuFormOptions
