import { Button, Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Input, Submit, ImageUpload, Switch, Textarea } from 'Components/Form'
import UpdateHock from 'helpers/UpdateHock'
import PopUp from 'Components/PopUp'

type UpdatePartner = Omit<Partner, 'id'>

const UpdateModal = ({ show, close, onSuccess, itemId }: any) => {
  const { t } = useTranslation()

  // init value
  const initForm: UpdatePartner = {
    title: '',
    published: null,
    image: null,
    link: ''
  }

  // prepare hock
  const {
    register,
    handleSubmit,
    errors,
    loading,
    closeUploading,
    openUploading,
    update,
    getValues,
    getLoading
  } = UpdateHock<UpdatePartner>({
    path: '/success/partners',
    initValue: initForm,
    editId: itemId
  })

  const submit = async (data: UpdatePartner) => {
    console.log(data)
    const response = await update(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  return (
    <PopUp title={t('Update Partner')} show={show} close={close} loading={getLoading}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={12}>
              <Input
                label={t('Title')}
                placeholder={t('Enter title')}
                model={register('title', {
                  required: t('Title is required'),
                  minLength: {
                    value: 3,
                    message: t('Title must be bigger than or equal 3')
                  }
                })}
                error={errors.title}
              />
            </Col>
            <Col md={12}>
              <Input
                label={t('Link')}
                placeholder={t('Enter link')}
                model={register('link', {
                  required: t('Link is required'),
                  minLength: {
                    value: 3,
                    message: t('Link must be bigger than or equal 3')
                  }
                })}
                error={errors.link}
              />
            </Col>

            <Col md={12}>
              <ImageUpload
                label={t('Image')}
                placeholder={t('Select file')}
                model={register('image', {
                  required: t('Image is required')
                })}
                error={errors.image}
                onUpload={openUploading}
                onFinishUpload={closeUploading}
                value={getValues('image')}
              />
            </Col>
            <Col md={12}>
              <Switch
                label={t('Published')}
                model={register('published')}
                error={errors.published}
                defaultChecked={getValues('published') === 1}
              />
            </Col>
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Update')} />
        </div>
      </form>
    </PopUp>
  )
}
export default UpdateModal
