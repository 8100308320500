// react imports
import { useState } from 'react'
// botstrap elemnts
import { Container, Row } from 'reactstrap'
// page BreadCrumb
import BreadCrumb from 'Components/Common/BreadCrumb'
// page elements
import Widgets from './Widgets'
import TicketsData from './TicketsData'
// translation
import { useTranslation } from 'react-i18next'

const TicketList = () => {
  const { t } = useTranslation()

  document.title = 'Tickets | Sallatk'
  // refresh wedget logic
  const [refresh, setRefresh] = useState<boolean>(false)
  const refreshReport = () => setRefresh(!refresh)

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <Row>
          <Widgets refresh={refresh} />
        </Row>
        <TicketsData refreshReport={refreshReport} />
      </Container>
    </div>
  )
}

export default TicketList
