// react imports
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
// bootstrap elements
import { Card, CardBody } from 'reactstrap'
// fetch hock logic
import ShowHock from 'helpers/ShowHock'

const BranchData = () => {
  const params = useParams()
  const { t } = useTranslation()

  interface branch {
    id: number
    name: string
    address: string
    city_id: number
    city_name: string
    max_order_day: number
    allow_pickup: boolean
    minimum_cart: number
    available: boolean
    main: boolean
  }

  const { item, loading } = ShowHock<branch>('/branches/' + params.id)

  return (
    <Card>
      <CardBody>
        <div>
          <div className="d-flex mb-4 align-items-center">
            <div className="avatar-sm me-1">
              <div className="avatar-title bg-light text-primary fs-17 rounded">
                <i className="las la-code-branch"></i>
              </div>
            </div>
            <div className="flex-grow-1 ms-2">
              <h5 className="card-title mb-1">
                {item?.name}
                {!!item?.main && (
                  <span className="badge bg-success align-middle fs-10 ms-2">{t('Main')}</span>
                )}
                {!item?.main && (
                  <span className="badge bg-info align-middle fs-10 ms-2">{t('Sup')}</span>
                )}
              </h5>
              <p className="text-muted mb-0">{item?.address}</p>
            </div>
          </div>
          <h6 className="mb-1">
            {t('Max orders')} {item?.max_order_day}
          </h6>
          <p className="card-text text-muted">
            {t('Allow pickup')} :
            {!!item?.allow_pickup && (
              <span className="badge bg-success text-uppercase ms-2">{t('Allowed')}</span>
            )}
            {!item?.allow_pickup && (
              <span className="badge bg-danger text-uppercase ms-2">{t('Not Allowed')}</span>
            )}
          </p>
        </div>
      </CardBody>
    </Card>
  )
}

export default BranchData
