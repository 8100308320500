import React, { ReactNode } from 'react'
import { Spinner } from 'reactstrap'
import Error from './IndexTable/partials/Error'
import Empty from './IndexTable/partials/Empty'

function ViewDataWithLoading({
  items,
  loading,
  error,
  style = { height: '300px' },
  children
}: {
  items: any[]
  loading: boolean
  error: boolean
  style?: React.CSSProperties
  children: ReactNode
}) {
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 " style={style}>
        <Spinner color="primary" />
      </div>
    )
  }
  if (error) {
    return <Error />
  }

  if (items && items?.length === 0) {
    return <Empty description={' '} />
  }

  if (items) {
    return <>{children}</>
  }
}

export default ViewDataWithLoading
