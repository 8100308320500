import { Input } from 'Components/Form'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  InputGroup,
  UncontrolledButtonDropdown
} from 'reactstrap'

function DomainInput({ setIsAvailable = (data) => {} }) {
  const { t } = useTranslation()
  const {
    register,
    control,
    formState: { errors },
    setValue
  } = useFormContext()
  return (
    <>
      <InputGroup className="w-100 d-flex">
        <Input
          onChange={(e) => setIsAvailable(false)}
          placeholder={t('Enter domain name')}
          wrapperClassName="flex-grow-1"
          model={register('domain', { required: 'Domain is required' })}
        />

        <Controller
          control={control}
          name="tld"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <UncontrolledButtonDropdown>
              <DropdownToggle className="mb-3" caret>
                .{value}
              </DropdownToggle>
              <DropdownMenu>
                {[
                  { name: '.com', value: 'com' },
                  { name: '.net', value: 'net' }
                ].map((item) => (
                  <DropdownItem
                    key={item.value}
                    onClick={() => {
                      onChange(item.value)
                      setIsAvailable?.(false)
                    }}>
                    {item.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          )}
        />
      </InputGroup>
      {errors.domain && <p className="text-danger">{errors.domain.message.toString()}</p>}
    </>
  )
}

export default DomainInput
