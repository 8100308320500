import { useEffect, useState } from 'react'
import { CardBody, Col, Container, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import BreadCrumb from 'Components/Common/BreadCrumb'
import FetchHock from 'helpers/FetchHock'
import { useTranslation } from 'react-i18next'
import CreateModal from '../components/CreateModal'
import UpdateModal from '../components/UpdateModal'

import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import clsx from 'clsx'
import Spinners from 'Components/Common/Spinner'
import GalleryGrid from 'Components/GalleryGrid'
import linkGenerator from 'helpers/linkGenerator'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

const GalleryList = () => {
  useEffect(() => {
    document.title = 'Gallery | Sallatk'
  }, [])

  const { t } = useTranslation()

  // create modal logic
  const [itemIdUpdate, changeItemIdUpdate] = useState<number>()
  const [showUpdate, changeShowUpdate] = useState<boolean>(false)
  const [showCreate, changeShowCreate] = useState<boolean>(false)

  const openCreate = () => changeShowCreate(true)
  const closeCreate = () => changeShowCreate(false)
  const openUpdate = () => changeShowUpdate(true)
  const closeUpdate = () => changeShowUpdate(false)

  const [filtered, setFiltered] = useState(null)

  const { result, loading, fetch, error } = FetchHock<Gallery>({
    path: '/storeGallery'
  })

  const { mutate: deleteImage } = useMutation({
    mutationFn: async (filename) =>
      await axios.post(linkGenerator('/storeGallery/delete'), { filename }),
    onSuccess() {
      fetch()
    }
  })
  const data = result?.data || []
  const tags = result?.tags || []

  const [displayCategory, setCategory] = useState<string>(null)
  const [index, setIndex] = useState<number>(-1)

  useEffect(() => {
    if (displayCategory) handleChooseTag(displayCategory)
  }, [data])

  const slideGallery = data.map((item) => ({ src: item?.url }))

  const handleChooseTag = (tag: string) => {
    const filteredTags = tag ? data?.filter((item) => item?.tag === tag) : null
    setCategory(tag)
    setFiltered(filteredTags)
    // setVisibleItems(10)
  }

  if (loading) {
    return <Spinners />
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb />

          <CardBody>
            <Row>
              <Col lg={12}>
                <div className="text-center">
                  <ul className="list-inline categories-filter animation-nav" id="filter">
                    <li className="list-inline-item">
                      <Link
                        to="#"
                        onClick={() => handleChooseTag(null)}
                        className={clsx('categories', displayCategory === null ? 'active' : '')}
                        data-filter="*">
                        {t('All')}
                      </Link>
                    </li>
                    {tags.map((tag, index) => (
                      <li key={index} className="list-inline-item">
                        <Link
                          to="#"
                          onClick={() => handleChooseTag(tag)}
                          className={clsx('categories', displayCategory === tag ? 'active' : '')}
                          data-filter="*">
                          {tag}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>

                {!loading && !error && (
                  <GalleryGrid
                    data={filtered || data}
                    onClickImage={(img) => setIndex(img.key)}
                    onDelete={deleteImage}
                  />
                )}
              </Col>
            </Row>
          </CardBody>
        </Container>
        {showCreate && <CreateModal close={closeCreate} show={openCreate} onSuccess={fetch} />}
        {showUpdate && (
          <UpdateModal
            close={closeUpdate}
            show={openUpdate}
            onSuccess={fetch}
            itemId={itemIdUpdate}
          />
        )}
      </div>
      {index >= 0 && (
        <Lightbox
          index={index}
          slides={slideGallery}
          open={index >= 0}
          close={() => setIndex(-1)}
        />
      )}
    </>
  )
}

export default GalleryList
