import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'
import IndexTable, { HeaderColumn } from 'Components/IndexTable'

import Filter from './Filter'
import ProductActionButtons from '../ActionButtons'
import AddProductsDropdownButton from '../AddProductsDropdownButton'
import { useProductContext } from '../../Context'
import ImageWithFallback from 'helpers/ImageComponent'

const ItemsCardsView = () => {
  const { t } = useTranslation()
  const { products } = useProductContext()
  const { closeDelete, deleteItem, deleteId, openDelete } = products

  const headerColumns: HeaderColumn[] = [
    {
      title: 'Product',
      accessor: 'title'
    },
    {
      title: 'Stock',
      accessor: 'stock'
    },
    {
      title: 'Price',
      accessor: 'price'
    },
    {
      title: 'Orders',
      accessor: 'orders_count'
    },
    {
      title: 'Rating',
      accessor: 'ratings'
    },
    {
      title: 'Published',
      accessor: 'published'
    },
    {
      title: 'Action'
    }
  ]

  return (
    <Row>
      <Col xl={3} lg={4}>
        <Filter />
      </Col>

      <Col xl={9} lg={8}>
        <Row className="mb-2 d-flex justify-content-end ">
          <AddProductsDropdownButton />
        </Row>
        <IndexTable
          deleteId={deleteId}
          closeDelete={closeDelete}
          deleteItem={deleteItem}
          title={t('Products')}
          headerColumns={headerColumns}
          {...products}>
          {products?.items?.map((item: any, index: number) => (
            <tr key={index}>
              <td data-column-id="product">
                <span>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 me-3">
                      <div className="avatar-sm bg-light rounded p-1">
                        <ImageWithFallback
                          src={item.web_main_image}
                          alt=""
                          className="img-fluid d-block"
                        />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="fs-14 mb-1">
                        {/* <a href={item.slug} className="text-body"> */}
                        {item.title}
                        {/* </a> */}
                      </h5>
                      <p className="text-muted mb-0">
                        {' '}
                        {t('Type')} : <span className="fw-medium">{item.product_type?.title}</span>
                      </p>
                    </div>
                  </div>
                </span>
              </td>
              <td data-column-id="stock">
                {(() => {
                  if (
                    item.product_type?.code === 'product_features' ||
                    item.product_type?.code === 'product_plus_features' ||
                    item.product_type?.code === 'donation'
                  ) {
                    return t('Contain options')
                  } else if (item.product_type?.code === 'course_product') {
                    return t('Unlimited')
                  } else {
                    return item.stock
                  }
                })()}
              </td>
              <td data-column-id="price">
                {' '}
                {item.raw_price} {item.currency}
              </td>
              <td data-column-id="orders" className="text-center">
                {item.ordered}
              </td>
              <td data-column-id="rating">
                <span className="badge  bg-light text-body fs-12 fw-medium d-flex gap-1">
                  <span>
                    {[...new Array(5)].map((star, index) => (
                      <i
                        key={index}
                        className={
                          index < item.ratings
                            ? 'ri-star-fill text-warning'
                            : 'ri-star-line text-warning'
                        }></i>
                    ))}
                  </span>

                  {item?.ratings ? Math.round(item?.ratings * 10) / 10 : 0}
                </span>
              </td>
              <td data-column-id="published" className="text-center">
                {item.published == 0 ? (
                  <i className="text-danger ri-close-circle-line fs-17 align-middle"></i>
                ) : (
                  <i className="text-success ri-checkbox-circle-line fs-17 align-middle"></i>
                )}
                {/* {item.published==0?t('Unpublished'):t('Published')} */}
              </td>

              <td>
                <div className="hstack gap-3 flex-wrap">
                  <ProductActionButtons
                    product={item}
                    openDelete={openDelete}
                    onSuccess={products?.fetch}
                  />
                </div>
              </td>
            </tr>
          ))}
        </IndexTable>
      </Col>
    </Row>
  )
}

export default ItemsCardsView
