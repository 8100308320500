// react elements
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// bootstrap elements
import { Card, CardBody, Col, Container, CardHeader, Row } from 'reactstrap'
// reqired componnent
import BreadCrumb from 'Components/Common/BreadCrumb'
import { ImageUpload, Search, Submit, Switch, TextEditor, Textarea, Input } from 'Components/Form'
import UpdateHock from 'helpers/UpdateHock'
import Loader from 'Components/IndexTable/partials/Loader'
// import model type
import Update from '../models/Update'

const UpdateStaticPages = (): any => {
  document.title = 'Update Static Pages | Sallatk'

  const { t } = useTranslation()
  const { id } = useParams()
  const history = useNavigate()

  // Convert 'id' to a number if needed
  const numericId: number = id as unknown as number

  // prepare hock
  const {
    register,
    handleSubmit,
    errors,
    loading,
    closeUploading,
    openUploading,
    getValues,
    update,
    getLoading
  } = UpdateHock<Update>({
    path: '/staticPages',
    initValue: {
      id: null,
      title: '',
      slug: '',
      img_url: '',
      web_image: '',
      published: 0,
      content: '',
      meta_routes_id: 0,
      meta: {
        title: '',
        keywords: '',
        description: ''
      }
    },
    editId: numericId
  })

  const submit = async (data: Update) => {
    const response = await update(data)
    if (response.status) history('/staticPages/list')
  }

  return (
    <div className="page-content">
      {getLoading && <Loader />}
      {!getLoading && (
        <Container fluid>
          <BreadCrumb />
          <form onSubmit={handleSubmit(submit)}>
            <Row>
              <Col lg={8}>
                <Card>
                  <CardBody>
                    <div className="mt-3 mb-3">
                      <Input
                        label={t('Title')}
                        placeholder={t('Enter title')}
                        model={register('title', {
                          required: t('Title is required'),
                          minLength: {
                            value: 3,
                            message: t('Title must be bigger than or equal 3')
                          }
                        })}
                        error={errors.title}
                      />
                      <Input
                        label={t('Slug')}
                        placeholder={t('Enter slug')}
                        model={register('slug')}
                        disabled
                      />
                    </div>
                    <div>
                      <TextEditor
                        label={t('Content')}
                        placeholder={t('Enter content')}
                        model={register('content', {
                          required: t('Content is required'),
                          minLength: {
                            value: 3,
                            message: t('Content must be bigger than or equal 3')
                          }
                        })}
                        value={getValues('content')}
                        error={errors.content}
                      />
                    </div>
                  </CardBody>
                </Card>
                <div className="hstack gap-2 justify-content-start">
                  <Submit loading={loading} label={t('Update')} />
                </div>
              </Col>

              <Col lg={4}>
                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">{t('Info')}</h5>
                  </CardHeader>
                  <CardBody>
                    <Switch
                      defaultChecked={false}
                      label={t('Published')}
                      model={register('published')}
                    />
                    <hr />
                    <ImageUpload
                      label={t('Image')}
                      model={register('img_url', {
                        required: t('Image is required')
                      })}
                      error={errors.img_url}
                      onUpload={openUploading}
                      onFinishUpload={closeUploading}
                      value={getValues('web_image')}
                    />
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">{t('SEO data')}</h5>
                  </CardHeader>
                  <CardBody>
                    <Input
                      label={t('Title')}
                      placeholder={t('Enter title')}
                      model={register('meta.title')}
                    />
                    <hr />
                    <Input
                      label={t('Keywords')}
                      placeholder={t('Enter keywords')}
                      model={register('meta.keywords')}
                    />
                    <hr />
                    <Textarea
                      label={t('Short description')}
                      placeholder={t('Enter short description')}
                      model={register('meta.description')}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </form>
        </Container>
      )}
    </div>
  )
}

export default UpdateStaticPages
