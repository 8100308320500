import { useState } from 'react'
import { DefaultValues, FieldValues, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import linkGenerator from './linkGenerator'
import showError from './ErorrNotfication'
import successNotification from 'helpers/SuccessNotfication'
import ErorrNotfication from 'helpers/ErorrNotfication'

type Response = {
  status: boolean
  response?: any
}

const CreateHook = <FormType extends FieldValues>(path: string, initValue: FormType) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const formMethods = useForm<FormType>({ defaultValues: initValue as DefaultValues<FormType> })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    reset,
    watch
  } = formMethods

  const [uploading, setUploading] = useState<boolean>(false)

  const mutation = useMutation<Response, Error, FormType>({
    mutationFn: async (item: FormType) => {
      if (uploading) {
        throw new Error(t('Please wait until uploading finish'))
      }

      const response = await axios.post(linkGenerator(path), item)
      return { status: true, response: response.data }
    },
    onSuccess: () => {
      successNotification(t('Item created successfully'))
      queryClient.invalidateQueries({ queryKey: [path] }) // Invalidate and refetch queries related to this path
    },
    onError: (error: any) => {
      if (error.code === 'ERR_NETWORK') {
        ErorrNotfication(t('Network error you have error in your network'))
      }

      if (
        error.response?.status === 422 ||
        error.response?.status === 400 ||
        error.response?.status === 403
      ) {
        const errorObject = error?.response?.data?.data ?? error?.response?.data?.errors
        if (errorObject !== undefined) {
          for (const key in errorObject) {
            const errorArr = errorObject[key] || []
            if (errorArr?.length > 0) {
              showError(errorArr[0])
            }
          }
        } else {
          showError(error?.response?.data?.message)
        }
      }
    }
  })

  const create = async (item: FormType): Promise<Response> => {
    try {
      const result = await mutation?.mutateAsync(item)
      return result
    } catch (error) {
      return { status: false }
    }
  }

  const openUploading = () => setUploading(true)
  const closeUploading = () => setUploading(false)

  return {
    loading: mutation.isPending,
    register,
    handleSubmit,
    errors,
    create,
    openUploading,
    closeUploading,
    setValue,
    getValues,
    control,
    reset,
    watch,
    formMethods
  }
}

export default CreateHook
