import { LinkInputFormula } from 'ThemeManager/Templates/defaultFormula'
import Formula from 'ThemeManager/types/Formula/index'

const formula: Formula = [
  {
    title: 'Section heading',
    label: 'sectionHeading',
    type: 'translateString'
  },
  LinkInputFormula,
  // {
  //   title: 'Class',
  //   label: 'className',
  //   type: 'string'
  // },
  {
    title: 'Limit',
    label: 'limit',
    type: 'number'
  },

  {
    title: 'Filter',
    label: 'filter',
    type: 'select',
    options: [
      {
        label: 'most selling',
        value: 'most_selling'
      },
      {
        label: 'new arrivals',
        value: 'new_arrivals'
      },
      {
        label: 'week trades',
        value: 'week_trades'
      },
      {
        label: 'most discount',
        value: 'most_discount'
      }
    ]
  },
  {
    title: 'Hide product description',
    label: 'hideProductDescription',
    type: 'checkBoox'
  },
  {
    title: 'Show rating',
    label: 'showRating',
    type: 'checkBoox'
  }
]
export default formula
