import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PaidOptionsInputs from './PaidOptionsInputs'

function PaidOptionsForm({ syncValueToForm, defaultValues }: any) {
  const { t } = useTranslation()
  const formMethods = useForm({
    values: defaultValues || {}
  })

  const { handleSubmit } = formMethods
  const handle = (values) => {
    syncValueToForm(values)
  }
  return (
    <FormProvider {...formMethods}>
      <form>
        <PaidOptionsInputs />
        <button type="submit" className="btn btn-primary" onClick={handleSubmit(handle)}>
          <i className="ri-add-line align-middle"></i> {t('Add option')}
        </button>
      </form>
    </FormProvider>
  )
}

export default PaidOptionsForm
