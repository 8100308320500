import Reviews from './Reviews/Config'

const config = [
  {
    title: 'Reviews',
    key: 'Reviews',
    config: Reviews
  }
]

export default config
