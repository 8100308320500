import { useTranslation } from 'react-i18next'

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Col, Container, Row, Form, Accordion, AccordionItem } from 'reactstrap'

import BreadCrumb from 'Components/Common/BreadCrumb'
import { Submit } from 'Components/Form'
import CreateHook from 'helpers/CreateHook'

import MainDetails from './components/MainDetails'
import Gallery from './components/Gallery'

import OrderForm from './components/OrderForm'
import Instruments from './components/Instruments'
import ProductSidebar from '../Components/ProductSidebar'
import { FormProvider } from 'react-hook-form'
import { DonationPaidFeatures } from '../Components/PaidFeatures'
import ProductsLayout from './components/ProductsLayout'

const Donation = () => {
  document.title = 'Create Product | Salltak'
  const { t } = useTranslation()
  const navigate = useNavigate()

  type readyProduct = {
    title: string
    slug: string
    short_description: string
    description: string
    brand_id: number | null
    published: number
    badge_id: number | null
    categories: number[]
    main_image: string
    images: string[]
    form_id: number | null
    similar_products: number[]
    meta: {
      title: ''
      keywords: ''
      description: ''
    }
    featureOptionsPaid:
      | {
          featureOptionId: number | null
          price: number | null
        }[]
      | null
  }

  // prepare hock
  const createMethods = CreateHook<readyProduct>('/donation_product', {
    title: '',
    slug: '',
    short_description: '',
    description: '',
    brand_id: null,
    published: 1,
    badge_id: null,
    categories: [],
    main_image: '',
    images: [],
    form_id: null,
    similar_products: [],
    meta: {
      title: '',
      keywords: '',
      description: ''
    },
    featureOptionsPaid: null
  })

  const [opend, setOpen] = useState<string>('')
  const selectLable = (label: string) => setOpen(label)

  const { openUploading, closeUploading } = createMethods
  return (
    <ProductsLayout createMethods={createMethods}>
      <Row>
        <Col lg={8}>
          <MainDetails />

          {/* <Instruments /> */}
          <DonationPaidFeatures isEdit={false} />

          <Gallery openUploading={openUploading} closeUploading={closeUploading} />

          <Accordion
            toggle={() => {}}
            className="lefticon-accordion custom-accordionwithicon accordion-border-box"
            open="test">
            <AccordionItem>
              <OrderForm
                isOpen={opend == 'orderForm'}
                toggleItem={() => selectLable('orderForm')}
              />
            </AccordionItem>
          </Accordion>
        </Col>

        <Col lg={4}>
          {/* <Details register={register} errors={errors} />
              <Similar register={register} errors={errors} />
              <Seo register={register} errors={errors} /> */}
          <ProductSidebar
            components={{
              Shipping: false,
              Stock: false,
              Info: false,
            }}
          />
        </Col>
      </Row>
    </ProductsLayout>
  )
}

export default Donation
