import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'reactstrap'

import BreadCrumb from 'Components/Common/BreadCrumb'
import IndexTable, { FilterItem, HeaderColumn } from 'Components/IndexTable'
// fetch hock logic
import FetchHock from 'helpers/FetchHock'
import EditLabel from '../../../../../Components/EditLabel'
import { Input, Submit } from '../../../../../Components/Form'
import CreateHook from '../../../../../helpers/CreateHook'
import Search from '../../../../../Components/Form/Search'
import { useParams } from 'react-router-dom'

type featuresOptions = {
  id: number
  label_title: string
  title: string
}

const LabelValuesList = () => {
  const { t } = useTranslation()
  const { id } = useParams()

  document.title = 'Label values | Sallatk'

  // interface filter {
  //   label_id: number | null
  // }

  const fetchHock = FetchHock<featuresOptions>({ path: `/labelValues?filter[label_id]=${id}` })

  const headerColumns: HeaderColumn[] = [
    {
      title: 'Title',
      accessor: 'title'
    },
    {
      title: 'Label title',
      accessor: 'feature_title'
    },
    {
      title: 'Action'
    }
  ]

  const filterItems: FilterItem[] = [
    {
      type: 'search',
      col: 6,
      placeholder: t('Search ...'),
      action: (value) => fetchHock.search(value)
    }
    // {
    //   type: 'asyncSelect',
    //   col: 3,
    //   placeholder: t('Select Label'),
    //   path: 'labels',
    //   action: (value) => fetchHock.filter('label_id', value)
    // }
  ]

  type labelValueType = {
    id?: number
    title: string
    label_id: number | null | string | undefined
  }

  // prepare hock
  const { register, handleSubmit, errors, create, loading } = CreateHook<labelValueType>(
    '/labelValues',
    {
      title: '',
      label_id: id
    }
  )

  const submit = async (data: labelValueType) => {
    const response = await create(data)
    if (response.status) {
      fetchHock.fetch()
    }
  }
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <Row>
          <Col lg={12}>
            <form onSubmit={handleSubmit(submit)}>
              <div className="align-items-center gy-3 mb-3 row">
                <Col md={5}>
                  <Input
                    label={t('Title')}
                    placeholder={t('Enter title')}
                    model={register('title', {
                      required: t('Title is required'),
                      minLength: {
                        value: 3,
                        message: t('Title must be bigger than or equal 3')
                      }
                    })}
                    error={errors.title}
                  />
                </Col>
                {/*<Col md={4}>*/}
                {/* <Search*/}
                {/*    label={t('Label')}*/}
                {/*    placeholder={t('Search for label')}*/}
                {/*    model={register('label_id', {*/}
                {/*      required: t('Label is required')*/}
                {/*    })}*/}
                {/*    error={errors.label_id}*/}
                {/*    path="labels"*/}
                {/*    propTitle="title"*/}
                {/*  />*/}
                {/*</Col>*/}
                <Col md={3}>
                  <div
                    className="d-flex gap-1 flex-wrap"
                    style={{
                      marginTop: 'inherit'
                    }}>
                    <Submit loading={loading} label={t('Create')} />
                  </div>
                </Col>
              </div>
            </form>

            <IndexTable
              title={t('Label values list')}
              headerColumns={headerColumns}
              {...fetchHock}
              filters={filterItems}>
              {fetchHock.items?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className="form-check form-switch-md form-switch form-switch-secondary">
                      <EditLabel
                        label={item.title}
                        path={`/labelValues/${item.id}`}
                        onSuccess={fetchHock.fetch}
                      />
                    </div>
                  </td>
                  <td> {item.label_title} </td>
                  <td>
                    <a
                      className="btn btn-sm fs-14 btn-link text-decoration-none px-0 text-danger"
                      onClick={() => fetchHock.openDelete(item.id)}>
                      <i className="ri-delete-bin-line"></i>
                    </a>
                  </td>
                </tr>
              ))}
            </IndexTable>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default LabelValuesList
