import { Button, Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Input, Submit, Select } from 'Components/Form'
import UpdateHock from 'helpers/UpdateHock'
import PopUp from 'Components/PopUp'

export const MethodOptions = [
  {
    label: 'Mobile',
    value: 'mobile'
  },
  {
    label: 'Phone',
    value: 'phone'
  },
  {
    label: 'Whatsapp',
    value: 'whatsapp'
  },
  {
    label: 'Snapchat',
    value: 'snapchat'
  },
  {
    label: 'Facebook',
    value: 'facebook'
  }
]

const UpdateModal = ({ show, close, onSuccess, itemId }: any) => {
  const { t } = useTranslation()

  // init value
  const initForm: BranchContact = {
    id: null,
    value: '',
    method: '',
    branch_id: null,
    branch_name: ''
  }

  // prepare hock
  const { register, handleSubmit, errors, loading, update, getValues, getLoading } =
    UpdateHock<BranchContact>({
      path: `/branch/contact`,
      initValue: initForm,
      editId: itemId
    })

  const submit = async (data: BranchContact) => {
    const response = await update(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  return (
    <PopUp title={`${t('Update')} ${t('Contact')}`} show={show} close={close} loading={getLoading}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            {/* <label>
              {getValues('branch_name')} {t('Branch')}
            </label> */}
            <Col md={12}>
              <Select
                label={t('Contact Method')}
                model={register('method', {
                  required: `${t('Contact Method')} ${t('is required')}`
                })}
                error={errors?.method}
                options={MethodOptions}
                value={getValues('method')}
              />
            </Col>
            <Col md={12}>
              <Input
                label={t('Value')}
                placeholder={`${t('Enter')} ${t('Value')}`}
                model={register('value', {
                  required: `${t('Value')} ${t('is required')}`
                })}
                error={errors.value}
              />
            </Col>
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Update')} />
        </div>
      </form>
    </PopUp>
  )
}
export default UpdateModal
