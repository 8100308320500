import { Container } from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import FetchHock from 'helpers/FetchHock'
import { useTranslation } from 'react-i18next'
import ViewDataWithLoading from 'Components/ViewDataWithLoading'
import { Company } from '../types'
import PaymentMethodTabs from './components/PaymentMethodTabs'

const PaymentMethodList = () => {
  document.title = 'Payment methods | Sallatk'

  const { t } = useTranslation()

  const { items, loading, error, invalidate } = FetchHock<Company>({ path: '/company' })

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <ViewDataWithLoading items={items} loading={loading} error={error}>
          <PaymentMethodTabs tabsData={items} referesh={invalidate} />
        </ViewDataWithLoading>
      </Container>
    </div>
  )
}

export default PaymentMethodList
