import { useMutation, useQuery } from '@tanstack/react-query'
import { POSContext } from 'pages/POS/POSContext'
import { useContext } from 'react'
import { toast } from 'react-toastify'
import http from 'services/axios'
import { usePOSGetCart } from './cart'

type OrderRequest = {
  method_id: number
  comment?: string
  invoice_id?: string
}

const createOrder = async (orderData: OrderRequest) => {
  try {
    const res = await http.post(`/orders`, {
      ...orderData,
      is_completed: 0 // for restaurant
    })

    return res.data.result
  } catch (error) {
    console.error('Error posting order:', error)
  }
}

export const useCreateOrderMutation = () => {
  const { setPaymentMethod, setInvoiceId } = useContext(POSContext)
  const { refetch: refetchCart } = usePOSGetCart()

  return useMutation({
    mutationFn: (orderData: OrderRequest) => createOrder(orderData),
    onSuccess(data, variables, context) {
      console.log(data)
      if (!data) return

      toast.success('Order created successfully')
      refetchCart()
      setPaymentMethod(null)
      setInvoiceId(null)
    },
    onError(error, variables, context) {
      console.error('Error adding product to cart:', error)
    }
  })
}

const getOrder = async (orderId: string) => {
  try {
    const res = await http.get(`/orders/${orderId}`)

    return res.data.result
  } catch (error) {
    console.error('Error fetching order:', error)
  }
}

export const useFetchOrder = (orderId: string) => {
  return useQuery({
    queryKey: ['order', orderId],
    queryFn: () => getOrder(orderId)
  })
}
