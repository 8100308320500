import BreadCrumb from 'Components/Common/BreadCrumb'
import BranchCard from '../components/branchCard'
import IndexTable from 'Components/IndexTable'
import FetchHock from 'helpers/FetchHock'
import { useParams } from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import CreateModal from './components/CreateModal'
import UpdateModal from './components/UpdateModal'
import IndexTableActionsDropdown from 'Components/IndexTableActionsDropdown'
const headerColumns = [
  {
    title: 'Id',
    accessor: 'id'
  },
  {
    title: 'Value',
    accessor: 'value'
  },
  {
    title: 'Contact Method',
    accessor: 'method'
  },
  {
    title: 'Branch Name',
    accessor: 'branch_name'
  },
  {
    title: 'View'
  }
]

function BranchContactsList() {
  const { t } = useTranslation()
  const { id } = useParams()

  const [itemIdUpdate, changeItemIdUpdate] = useState<number>()
  const [showUpdate, changeShowUpdate] = useState<boolean>(false)
  const [showCreate, changeShowCreate] = useState<boolean>(false)

  const openCreate = () => changeShowCreate(true)
  const closeCreate = () => changeShowCreate(false)
  const openUpdate = () => changeShowUpdate(true)
  const closeUpdate = () => changeShowUpdate(false)

  document.title = 'Branch Contacts | Sallatk'

  const fetchHook = FetchHock<BranchContact>({
    path: `/branch/contact`,
    initFilter: { branch_id: id }
  })

  const { items, openDelete, fetch } = fetchHook

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <Row>
          <Col md={3}>
            <BranchCard />
          </Col>
          <Col lg={9}>
            <IndexTable
              title={t('Contacts')}
              headerColumns={headerColumns}
              addString={t('Add') + ' ' + t('Contact')}
              addAction={openCreate}
              {...fetchHook}
              // filters={filterItems}
            >
              {items?.map((item, index: number) => (
                <tr key={index}>
                  <td>
                    <h5 className="fs-14 mb-1">#{item.id} </h5>
                  </td>
                  <td>{item?.value}</td>
                  <td>{item?.method}</td>
                  <td>{item?.branch_name}</td>
                  {/* <td>{item?.branch_id}</td> */}
                  <td>
                    <IndexTableActionsDropdown
                      actions={[
                        {
                          type: 'edit',
                          onClick: () => {
                            openUpdate()
                            changeItemIdUpdate(item.id)
                          }
                        },
                        { type: 'divider' },
                        { type: 'delete', onClick: () => openDelete(item.id) }
                      ]}
                    />
                  </td>
                </tr>
              ))}
            </IndexTable>
          </Col>
        </Row>
      </Container>
      {showCreate && <CreateModal close={closeCreate} show={openCreate} onSuccess={fetch} />}
      {showUpdate && (
        <UpdateModal
          close={closeUpdate}
          show={openUpdate}
          onSuccess={fetch}
          itemId={itemIdUpdate}
        />
      )}
    </div>
  )
}

export default BranchContactsList
