import { useEffect, useState } from 'react'
import { getUserData } from 'helpers/Auth'

const UseProfile = () => {
  // get from seesion
  const userProfileSession = getUserData()
  const tokenSeesion = userProfileSession ? userProfileSession.accessToken : null
  // create statement
  const [userProfile, setUserProfile] = useState(userProfileSession ? userProfileSession : null)
  const [token, setUserToken] = useState(tokenSeesion)
  // watch changes
  useEffect(() => {
    const userProfileSession = getUserData()
    const tokenSeesion = userProfileSession ? userProfileSession.accessToken : null

    setUserProfile(userProfileSession ? userProfileSession : null)
    setUserToken(tokenSeesion)
  }, [])

  return { userProfile, token }
}

export { UseProfile }
