import { useTranslation } from 'react-i18next'

import { Card, CardBody, CardHeader } from 'reactstrap'
import { Switch, MultiSearch, LazySelect } from 'Components/Form'
import { useFormContext } from 'react-hook-form'

const Details = () => {
  const { t } = useTranslation()
  const {
    register,
    formState: { errors },
    getValues
  } = useFormContext()

  return (
    <Card>
      <CardHeader className="">
        <h5 className="card-title mb-0">{t('Product details')}</h5>
      </CardHeader>
      <CardBody>
        <Switch
          defaultChecked={getValues && getValues('published') === 1}
          label={t('Published')}
          model={register('published')}
        />
        <LazySelect
          isMulti
          label={t('Categories')}
          placeholder={t('Enter categories')}
          model={register('categories', { required: true })}
          propTitle="title"
          error={errors.categories}
          path="/category"
          defaultValue={getValues('categories_data')}
        />
        <LazySelect
          label={t('Brand')}
          placeholder={t('Enter brand')}
          model={register('brand_id')}
          error={errors.brand_id}
          path="/brands"
          propTitle="name"
          defaultValue={getValues('brand')}
        />
        <LazySelect
          label={t('Badge')}
          placeholder={t('Enter badge')}
          model={register('badge_id')}
          propTitle="title"
          error={errors.badge_id}
          path="/badges"
          defaultValue={getValues('badge')}
        />
        <LazySelect
          isMulti
          label={t('Tags')}
          placeholder={t('Enter tags')}
          model={register('tags')}
          propTitle="title"
          error={errors.tags}
          path="/product_tags"
          defaultValue={getValues('tags_data')}
        />
         <LazySelect
          isMulti
          label={t('Similar products')}
          placeholder={t('Enter similar products')}
          model={register('similar_products')}
          propTitle="title"
          error={errors.similar_products}
          path="/products"
          defaultValue={getValues && getValues('similar_products_data')}
        />
      </CardBody>
    </Card>
  )
}

export default Details
