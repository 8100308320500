import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Badge, Card, CardBody, CardHeader } from 'reactstrap'
import ImageWithFallback from '../../../helpers/ImageComponent'
import ChatOnWhatsappLink from 'Components/ChatOnWhatsappLink'

// Customer Details
function CustomerDetails({ user }) {
  const { t } = useTranslation()
  return (
    <Card>
      <CardHeader>
        <div className="d-flex">
          <h5 className="card-title flex-grow-1 mb-0">{t('Customer Details')}</h5>
          <div className="flex-shrink-0">
            <Link to="#" className="link-secondary">
              View Profile
            </Link>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <ul className="list-unstyled mb-0 vstack gap-3">
          <li>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <ImageWithFallback
                  src={user?.web_image}
                  alt={user?.name}
                  className="avatar-sm rounded"
                />
              </div>
              <div className="flex-grow-1 ms-3">
                <h6 className="fs-14 mb-1">{`${user?.name || ''} ${user?.lastName || ''}`}</h6>
                <p className="text-muted mb-0">
                  {user?.is_active === 1 ? (
                    <Badge color="success">{t('Active')}</Badge>
                  ) : (
                    <Badge color="danger">{t('Not active')}</Badge>
                  )}
                </p>
              </div>
            </div>
          </li>
          <li>
            <i className="ri-mail-line me-2 align-middle text-muted fs-16"></i>
            {user?.email}
          </li>
          <li>
            <i className="ri-phone-line me-2 align-middle text-muted fs-16"></i>
            {user?.dialing_code}
            {user?.phone}
          </li>
          <li className="d-flex ">
            <ChatOnWhatsappLink phoneNumber={`${user?.dialing_code}${user?.phone}`} />
          </li>
        </ul>
      </CardBody>
    </Card>
  )
}

export default CustomerDetails
