import { useContext } from 'react'
import { Category } from 'types'
import { POSContext } from '../POSContext'

type Props = {
  category: Category
}

export const POSSingleCategory = ({ category }: Props) => {
  const { selectedCategory, setSelectedCategory } = useContext(POSContext)

  const selectCategory = () => {
    // toggle selected category
    if (selectedCategory?.id === category.id) {
      setSelectedCategory(null)
      return
    }
    // in case of back button click
    if (!category?.id && selectedCategory?.parent) {
      setSelectedCategory(selectedCategory?.parent?.parent)
      return
    }
    setSelectedCategory(category)
  }

  const isSelected = selectedCategory?.id === category.id

  return (
    <div
      onClick={selectCategory}
      className={`cursor-pointer d-flex flex-column gap-3 align-items-center justify-content-center px-3 py-2 rounded border border-2 ${
        isSelected ? 'border-primary' : ''
      }`}
      style={{ minWidth: '120px', maxWidth: '120px' }}>
      {category.icon ? (
        category.icon
      ) : (
        <img
          src={category.mobile_image || category.web_image}
          className=""
          style={{ width: '50px', aspectRatio: '1/1' }}
        />
      )}
      {category.title && (
        <p className="text-center fw-bold text-capitalize mb-0 text-ellipsis">{category.title}</p>
      )}
    </div>
  )
}
