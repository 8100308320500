import { useState } from 'react'

import {
  CardBody,
  Row,
  Col,
  Card,
  Container,
  Form,
  Input,
  Label,
  Table,
  FormFeedback,
  Button
} from 'reactstrap'

import { Link, useNavigate } from 'react-router-dom'

import BreadCrumb from 'Components/Common/BreadCrumb'
import { Search, Textarea } from 'Components/Form'
import { useTranslation } from 'react-i18next'
import CreateHook from 'helpers/CreateHook'
import { useFieldArray } from 'react-hook-form'
import { useDefaultCurrency } from 'Components/DefaultCurrency'

const CreateOrderView = () => {
  const history = useNavigate()
  const defaultCurrency = useDefaultCurrency()

  const { t } = useTranslation()

  const [count, setCount] = useState(0)
  const [rate, setRate] = useState(0)
  const [tax, setTax] = useState(0)
  const [dis, setDis] = useState(0)
  const [charge, setCharge] = useState(0)

  const { register, handleSubmit, errors, create, loading, setValue, getValues, control, watch } =
    CreateHook<any>('/orders', {
      comment: '',
      method_id: null,
      customer_id: null,
      address_id: null,
      branch_id: null,
      shipping_company_id: null,
      products: [{ product_id: null, quantity: 1 }]
    })

  const submit = async (data: any) => {
    const response = await create(data)
    if (response.status) history('/orders/list')
  }

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'products'
  })

  // Function to calculate the total quantity
  const getTotalQuantity = () => {
    return fields.reduce((total, field, index) => {
      const quantity = getValues(`products.${index}.quantity`) || 0
      console.log(total + quantity)
      return total + quantity
    }, 0)
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <Row className="justify-content-center">
          <Col xxl={9}>
            <Card>
              <Form onSubmit={handleSubmit(submit)} className="needs-validation" id="invoice_form">
                <CardBody className="p-4 border-top border-top-dashed">
                  <Row>
                    <Col sm={6}>
                      <div>
                        <Label for="billingName" className="text-muted text-uppercase fw-semibold">
                          {t('Branch')}
                        </Label>
                      </div>

                      <Search
                        // label={t('Branch')}
                        placeholder={t('Select branch')}
                        model={register('branch_id', {
                          required: t('Branch is required')
                        })}
                        error={errors.branch_id}
                        path="branches"
                        propTitle="name"
                      />
                    </Col>
                    <Col sm={6} className="ms-auto">
                      <div>
                        <Label for="shippingName" className="text-muted text-uppercase fw-semibold">
                          {t('Customer')}
                        </Label>
                      </div>
                      <Search
                        // label={t('Customer')}
                        placeholder={`${t('Select')} ${t('Customer')}`}
                        model={register('customer_id', {
                          required: `${t('Customer')} ${t('is required')}`
                        })}
                        error={errors.customer_id}
                        path="customers"
                        propTitle="name"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <div>
                        <Label for="billingName" className="text-muted text-uppercase fw-semibold">
                          {t('Address')}
                        </Label>
                      </div>

                      <Search
                        // label={t('Address')}
                        placeholder={`${t('Select')} ${t('Address')}`}
                        model={register('address_id', {
                          // required: t('Address is required')
                        })}
                        error={errors.address_id}
                        path="addresses"
                        propTitle="address"
                      />
                    </Col>
                    <Col sm={6} className="ms-auto">
                      <div>
                        <Label for="shippingName" className="text-muted text-uppercase fw-semibold">
                          {t('Shipping Company')}
                        </Label>
                      </div>
                      <Search
                        // label={t('Shipping Company')}
                        placeholder={t('Select shipping company')}
                        model={register('shipping_company_id', {
                          required: t('Shipping company is required')
                        })}
                        error={errors.shipping_company_id}
                        path="shippingCompanies/offline"
                        propTitle="title"
                      />
                    </Col>
                  </Row>
                </CardBody>
                <CardBody className="p-4">
                  <div className="table-responsive">
                    <Table className="invoice-table table-borderless table-nowrap mb-0">
                      <thead className="align-middle">
                        <tr className="table-active">
                          <th scope="col" style={{ width: '50px' }}>
                            #
                          </th>
                          <th scope="col">Product Details</th>
                          {/* <th scope="col" style={{ width: '120px' }}>
                            <div className="d-flex currency-select input-light align-items-center">
                              Rate
                              <Select
                                defaultValue={isCurrency}
                                onChange={(e: any) => {
                                  handleisCurrency(e)
                                }}
                                options={allcurrency}
                                id="choices-payment-currency"
                                className="form-selectborder-0 bg-light"
                              />
                            </div>
                          </th> */}
                          <th scope="col" style={{ width: '120px' }}>
                            Quantity
                          </th>
                          {/* <th scope="col" className="text-end" style={{ width: '150px' }}>
                            Amount
                          </th> */}
                          <th scope="col" className="text-end" style={{ width: '105px' }}></th>
                        </tr>
                      </thead>
                      <tbody id="newlink">
                        {fields.map((field, index) => {
                          return (
                            <tr key={index} id="1" className="product">
                              <th scope="row" className="product-id">
                                {index + 1}
                              </th>
                              <td className="text-start">
                                <div className="mb-2">
                                  <Search
                                    // label={t('Product')}
                                    placeholder={t('Select product')}
                                    model={register(`products.${index}.product_id`, {
                                      required: t('Product is required')
                                    })}
                                    error={errors.products?.[index]?.product_id}
                                    path="products"
                                    propTitle="title"
                                  />
                                </div>
                              </td>
                              {/* <td>
                                <Input
                                  type="number"
                                  className="form-control product-price bg-light border-0"
                                  placeholder="0.00"
                                  id="productRate-1"
                                  step="0.01"
                                  onChange={(e: any) => {
                                    setRate(e.target.value)
                                  }}
                                />
                                <div className="invalid-feedback">Please enter a rate</div>
                              </td> */}
                              <td>
                                <div className="input-step">
                                  <button
                                    type="button"
                                    className="minus"
                                    onClick={() => {
                                      const newValue = getValues(`products.${index}.quantity`) - 1
                                      console.log(newValue)
                                      setValue(`products.${index}.quantity`, newValue)
                                    }}>
                                    –
                                  </button>
                                  <Input
                                    type="number"
                                    className="product-quantity"
                                    id="product-qty-1"
                                    {...register(`products.${index}.quantity`, {
                                      required: t('Quantity is required'),
                                      min: 1,
                                      valueAsNumber: true
                                    })}
                                    value={watch(`products.${index}.quantity`)}
                                    onChange={(e: any) => {
                                      setValue(`products.${index}.quantity`, Number(e.target.value))
                                    }}
                                  />
                                  <button
                                    type="button"
                                    className="plus"
                                    onClick={() => {
                                      const newValue = getValues(`products.${index}.quantity`) + 1
                                      setValue(`products.${index}.quantity`, newValue)
                                    }}>
                                    +
                                  </button>
                                </div>
                              </td>
                              {/* <td className="text-end">
                                <div>
                                  <Input
                                    type="text"
                                    className="form-control bg-light border-0 product-line-price"
                                    id="productPrice-1"
                                    placeholder="$0.00"
                                    value={currency + rate * count}
                                    readOnly
                                  />
                                </div>
                              </td> */}
                              <td>
                                <Button color="danger" onClick={() => remove(index)}>
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                      <tbody>
                        <tr id="newForm" style={{ display: 'none' }}>
                          <td className="d-none" colSpan={5}>
                            <p>Add New Form</p>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={5}>
                            <Button
                              onClick={() =>
                                append({
                                  product_id: null,
                                  quantity: 1
                                })
                              }
                              className="btn btn-secondary fw-medium"
                              id="add-item">
                              <i className="ri-add- fill me-1 align-bottom"></i> Add Item
                            </Button>
                          </td>
                        </tr>
                        <tr className="border-top border-top-dashed mt-2">
                          <td colSpan={2}></td>
                          <td colSpan={3} className="p-0">
                            <Table className="table-borderless table-sm table-nowrap align-middle mb-0">
                              <tbody>
                                <tr>
                                  <th scope="row">Sub Total</th>
                                  <td style={{ width: '150px' }}>
                                    <Input
                                      type="text"
                                      className="form-control bg-light border-0"
                                      id="cart-subtotal"
                                      placeholder="$0.00"
                                      readOnly
                                      // value={currency + rate * count}
                                      value={getTotalQuantity()}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Estimated Tax (12.5%)</th>
                                  <td>
                                    <Input
                                      type="text"
                                      className="form-control bg-light border-0"
                                      id="cart-tax"
                                      placeholder="$0.00"
                                      readOnly
                                      value={defaultCurrency + tax}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    Discount <small className="text-muted"></small>
                                  </th>
                                  <td>
                                    <Input
                                      type="text"
                                      className="form-control bg-light border-0"
                                      id="cart-discount"
                                      placeholder="$0.00"
                                      readOnly
                                      value={defaultCurrency + dis}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Shipping Charge</th>
                                  <td>
                                    <Input
                                      type="text"
                                      className="form-control bg-light border-0"
                                      id="cart-shipping"
                                      placeholder="$0.00"
                                      readOnly
                                      value={defaultCurrency + charge}
                                    />
                                  </td>
                                </tr>
                                <tr className="border-top border-top-dashed">
                                  <th scope="row">Total Amount</th>
                                  <td>
                                    <Input
                                      type="text"
                                      className="form-control bg-light border-0"
                                      id="cart-total"
                                      placeholder="$0.00"
                                      readOnly
                                      value={
                                        defaultCurrency +
                                        Math.round(rate * count + tax + charge - dis)
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <Row className="mt-3">
                    <Col lg={4}>
                      <div className="mb-2">
                        <Label
                          for="choices-payment-type"
                          className="form-label text-muted text-uppercase fw-semibold">
                          Payment Details
                        </Label>
                        <Search
                          // label={t('Payment Method')}
                          placeholder={t('Select payment method')}
                          model={register('method_id', {
                            required: t('Payment method is required')
                          })}
                          error={errors.method_id}
                          path="dashboard/payment-methods"
                          propTitle="title"
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="mt-4">
                    <Label
                      for="exampleFormControlTextarea1"
                      className="form-label text-muted text-uppercase fw-semibold">
                      {t('Comment')}
                    </Label>
                    <Textarea
                      // label={t('Comment')}
                      placeholder={t('Enter comment')}
                      model={register('comment')}
                      error={errors.comment}
                      className="form-control alert alert-info"
                      // rows={2}
                    />
                  </div>
                  <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                    <button type="submit" className="btn btn-success">
                      <i className="ri-printer-line align-bottom me-1"></i> Save
                    </button>
                    <Link to="#" className="btn btn-primary">
                      <i className="ri-download-2-line align-bottom me-1"></i> Download Invoice
                    </Link>
                    <Link to="#" className="btn btn-danger">
                      <i className="ri-send-plane-fill align-bottom me-1"></i> Send Invoice
                    </Link>
                  </div>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CreateOrderView
