import { Button, Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Input, Submit, Radio } from 'Components/Form'
import CreateHook from 'helpers/CreateHook'
import PopUp from 'Components/PopUp'
import { useForm } from 'react-hook-form'
import FetchHock from 'helpers/FetchHock'
import Empty from 'Components/IndexTable/partials/Empty'
import { useEffect } from 'react'
import Loader from 'Components/Common/Loader'

type FormElement = {
  key: string
  type: 'text' | 'radio'
  label: string
  options?: string[]
}

const CreateModal = ({ close, show, onSuccess, data }: any) => {
  const { t } = useTranslation()

  // Fetch form fields
  const {
    result: formFields,
    loading: fieldsLoading,
    invalidate: fetchFields
  } = FetchHock({
    path: `/payment_settings/${data.companyId}/form`
  })

  // Fetch form values
  const {
    result: formValues,
    loading: valuesLoading,
    invalidate: fetchValues
  } = FetchHock({
    path: `/payment_settings/${data.companyId}/edit`,
    enabled: formFields.length > 0
  })

  // Prepare the form
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm()

  // Set form values when they are loaded
  useEffect(() => {
    if (formValues?.length > 0) {
      formFields.forEach((field: FormElement) => {
        const formValue = formValues?.find((value) => value.key === field.key)
        setValue(field.key, formValue?.value)
      })
    }
  }, [formValues, formFields])

  // Create hook for submitting the form
  const { create, loading: submitLoading } = CreateHook(`/payment_settings`, null)

  const submit = async (formData) => {
    const payload = {
      company_id: data.companyId,
      form: Object.entries(formData).map(([key, value]) => {
        return {
          key,
          value
        }
      })
    }
    const response = await create(payload)
    if (response.status) {
      close()
      onSuccess()
      fetchFields()
      fetchValues()
    }
  }

  // Render form elements dynamically
  const renderFormElement = (field: FormElement) => {
    switch (field.type) {
      case 'text':
        return (
          <Input
            label={field.label}
            type="text"
            placeholder={t('Enter ') + field.label}
            model={register(field.key, {
              required: true
            })}
            error={errors[field.key]}
          />
        )
      case 'radio':
        return (
          <>
            <label className="form-label">{field.label}</label>
            {field.options.map((option, index) => (
              <Radio
                key={index}
                label={option}
                model={register(field.key, {
                  required: true
                })}
                value={option}
                error={errors[field.key]}
              />
            ))}
          </>
        )
      default:
        return null
    }
  }

  return (
    <PopUp title={t('Settings')} show={show} close={close} loading={fieldsLoading}>
      {(() => {
        if (formFields.length === 0) {
          return <Empty description={' '} />
        }
        if (valuesLoading) {
          return (
            <div
              style={{
                height: '300px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <Loader />
            </div>
          )
        }
        return (
          <form onSubmit={handleSubmit(submit)}>
            <Container fluid>
              <Row>
                {formFields.map((field, idx) => (
                  <Col key={idx} md={12}>
                    {renderFormElement(field)}
                  </Col>
                ))}
              </Row>
            </Container>

            <div className="hstack gap-2 justify-content-end">
              <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
                <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
              </Button>
              <Submit loading={submitLoading} label={t('Save')} />
            </div>
          </form>
        )
      })()}
    </PopUp>
  )
}
export default CreateModal
