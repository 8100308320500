import clsx from 'clsx'
import { Spinner } from 'reactstrap'

const Switch = ({
  label,
  model,
  error,
  defaultChecked,
  onChange,
  checked,
  loading,
  horizontal
}: {
  label: string
  model?: any
  error?: any
  defaultChecked?: boolean
  checked?: any
  onChange?: (value: any) => void
  loading?: boolean
  horizontal?: boolean
}) => {
  const randumId = Math.random().toString(36).substring(7)
  // name of input
  const name = model?.name

  return (
    <div
      className={clsx(
        'form-check form-switch-md form-switch form-switch-secondary mb-4',
        horizontal && 'row-md d-flex flex-md-row-reverse gap-2 gap-md-0 p-0'
      )}>
      {loading ? (
        <span
          className="me-2 form-check-input"
          style={{
            background: 'none',
            border: 'none',
            width: 0
          }}>
          <Spinner size={'sm'} color="primary" />
        </span>
      ) : (
        <div className={clsx(horizontal && 'col-md-9')}>
          <input
            className={clsx(
              'form-check-input',
              error && 'is-invalid',
              horizontal && ' m-0 start-0'
            )}
            type="checkbox"
            role="switch"
            id={randumId}
            checked={checked}
            onChange={(event) => {
              const value = event.target.checked ? 1 : 0
              model?.onChange({
                target: {
                  name,
                  value: value
                }
              })
              if (onChange) onChange(value)
            }}
            defaultChecked={defaultChecked ?? false}
          />
        </div>
      )}
      <div className={clsx(horizontal && 'col-md-3')}>
        <label className="form-check-label" htmlFor={randumId}>
          {label}
        </label>
      </div>
    </div>
  )
}

export default Switch
