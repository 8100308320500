import { Button, Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Input, Submit, ImageUpload, Switch, Textarea } from 'Components/Form'
import UpdateHock from 'helpers/UpdateHock'
import PopUp from 'Components/PopUp'

type UpdateCustomLink = Omit<MenuCustomLinks, 'id'>

const UpdateModal = ({ show, close, onSuccess, itemId }: any) => {
  const { t } = useTranslation()

  // init value
  const initForm: UpdateCustomLink = {
    title: '',
    text: '',
    url: '',
    icon: ''
  }

  // prepare hock
  const { register, handleSubmit, errors, loading, update, getLoading } =
    UpdateHock<UpdateCustomLink>({
      path: '/menuCustomLinks',
      initValue: initForm,
      editId: itemId
    })

  const submit = async (data: UpdateCustomLink) => {
    console.log(data)
    const response = await update(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  return (
    <PopUp title={t('Update custom link')} show={show} close={close} loading={getLoading}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={12}>
              <Input
                label={t('Title')}
                placeholder={t('Enter title')}
                model={register('title', {
                  required: t('Title is required')
                })}
                error={errors.title}
              />
            </Col>
            <Col md={12}>
              <Input
                label={t('Text')}
                placeholder={t('Enter Text')}
                model={register('text', {
                  required: t('Text is required'),
                  minLength: {
                    value: 3,
                    message: t('Text must be bigger than or equal 3')
                  }
                })}
                error={errors.text}
              />
            </Col>
            <Col md={12}>
              <Input
                label={t('url')}
                placeholder={t('Enter url')}
                model={register('url', {
                  required: t('url is required'),
                  minLength: {
                    value: 3,
                    message: t('url must be bigger than or equal 3')
                  }
                })}
                error={errors.text}
              />
            </Col>
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Update')} />
        </div>
      </form>
    </PopUp>
  )
}
export default UpdateModal
