import { createSlice } from '@reduxjs/toolkit'

interface userData {
  id: number
  name: string
  last_name: string
  email: string
  phone: string
  package_remaining: string
  gender: string
  status: number
  is_store_owner: boolean
  online_at: string
  accessToken: string
}

export const initialState: userData | null = null

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserAction: (state: any, action: any): void => {
      state.user = action.payload
    },
    removeUseAction: (state: any): void => {
      state.user = null
    }
  }
})

export const { setUserAction, removeUseAction } = userSlice.actions
export default userSlice.reducer
