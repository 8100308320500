import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { Switch } from 'Components/Form'
import linkGenerator from 'helpers/linkGenerator'

import { useTranslation } from 'react-i18next'

function UpdatePaymentSwitch({ item, onSuccess, type, checkedParam }) {
  const { t } = useTranslation()

  const { mutate, isPending, variables } = useMutation({
    mutationFn: async ({ id, status }: { id: number; status: number }) =>
      await axios.put(
        linkGenerator(
          type == 'offline' ? `/offline-payment/${item.id}/status` : `/payments/${item.id}`
        ),
        {}
      ),
    onSettled: async () => {
      await onSuccess()
    }
  })

  return (
    <Switch
      checked={
        isPending && variables?.id == item?.id ? variables?.status == 1 : item?.[checkedParam]
      }
      label={item.type == 'cod' ? t('Allow COD Payment') : t('Active')}
      onChange={() =>
        mutate({
          id: item.id,
          status: item?.[checkedParam] ? 0 : 1
        })
      }
    />
  )
}

export default UpdatePaymentSwitch
