import { useTranslation } from 'react-i18next'

import { Card, CardBody, CardHeader } from 'reactstrap'
import { LazySelect } from 'Components/Form'
import { useFormContext } from 'react-hook-form'
import { ProductsPreview } from '../../create/components/Package'
import { useState } from 'react'

const Details = () => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState([])
  const {
    register,
    formState: { errors },
    getValues,
    watch
  } = useFormContext()

  return (
    <Card>
      <CardHeader className="">
        <h5 className="card-title mb-0">{t('Package products')}</h5>
      </CardHeader>
      <CardBody>
        <LazySelect
          isMulti
          label={t('Package products')}
          placeholder={t('Enter package products')}
          model={register('packageProductIds')}
          propTitle="title"
          error={errors.packageProductIds}
          path="/products"
          defaultValue={getValues('packageProducts')}
          onChange={(value) => setSelected(value)}
        />
        <ProductsPreview
          selectedProductIds={watch('packageProductIds')}
          selectedProductData={[...selected, ...(getValues('packageProducts') || [])]}
        />
      </CardBody>
    </Card>
  )
}

export default Details
