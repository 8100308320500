import { Button, Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Input, Submit, Select } from 'Components/Form'
import UpdateHock from 'helpers/UpdateHock'
import PopUp from 'Components/PopUp'

const UpdateModal = ({ close, show, onSuccess, itemId }: any) => {
  const { t } = useTranslation()

  type feesType = {
    id: number | null
    title: string
    type: string
    code: string
    fees: number
  }

  const { register, handleSubmit, errors, loading, getLoading, getValues, update } =
    UpdateHock<feesType>({
      path: '/fees',
      initValue: {
        id: null,
        title: '',
        type: '',
        code: '',
        fees: 0
      },
      editId: itemId
    })

  const submit = async (data: feesType) => {
    const response = await update(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  return (
    <PopUp title={t('Edit Extra Fees')} show={show} close={close} loading={getLoading}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={12}>
              <Input
                label={t('Title')}
                placeholder={t('Enter title')}
                model={register('title', {
                  required: t('Title is required'),
                  minLength: {
                    value: 3,
                    message: t('Title must be bigger than or equal 3')
                  }
                })}
                error={errors.title}
              />
            </Col>
            <Col md={8}>
              <Input
                label={t('Fees')}
                type="number"
                model={register('fees', {
                  required: t('Fees is required')
                })}
                error={errors.fees}
                placeholder={t('Enter fees')}
              />
            </Col>
            <Col md={4}>
              <Select
                label={t('Type')}
                placeholder={t('Type')}
                model={register('type', {
                  required: t('type is required')
                })}
                error={errors.type}
                options={[
                  { value: 'perc', label: t('%') },
                  { value: 'net', label: t('KWD') }
                ]}
                value={getValues('type')}
              />
            </Col>
            <Col md={12}>
              <Select
                label={t('Code')}
                placeholder={t('Code')}
                model={register('code', {
                  required: t('Code is required')
                })}
                error={errors.code}
                options={[
                  { value: 'service', label: t('Service') },
                  { value: 'tax', label: t('Tax') }
                ]}
                value={getValues('code')}
              />
            </Col>
          </Row>
        </Container>
        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Update')} />
        </div>
      </form>
    </PopUp>
  )
}
export default UpdateModal
