import DeleteModal from 'Components/Common/DeleteModal'
import FetchHock from 'helpers/FetchHock'
import UpdateItemModal from './UpdateItemModal'
import { useState } from 'react'
import AddItemModal from './AddItemModal'

function MenuItem({
  menu,
  onSuccess,
  groupId
}: {
  menu: { id: number; title: string; published: 1 | 0 }[]
  onSuccess: () => void
  groupId: number
}) {
  const [itemDataUpdate, changeItemDataUpdate] = useState<{ itemId: number; parentId: number }>()
  const [showUpdate, changeShowUpdate] = useState<boolean>(false)
  const [addItemId, changeAddItemId] = useState<number>()
  const [showAddItem, changeShowAddItem] = useState<boolean>(false)

  const openUpdate = () => changeShowUpdate(true)
  const closeUpdate = () => changeShowUpdate(false)
  const openAddItem = () => changeShowAddItem(true)
  const closeAddItem = () => changeShowAddItem(false)

  const { openDelete, closeDelete, deleteItem, deleteId } = FetchHock<Footer>({
    path: '/menus/footer'
  })
  return (
    <>
      <ul className="list-group mb-3">
        {(menu &&
          menu?.length > 0 &&
          menu?.map((item, index) => (
            <li key={index} className="list-group-item d-flex justify-content-between lh-sm">
              <div className="d-flex gap-2 align-items-baseline">
                {item?.published === 1 && (
                  <span
                    className="bg-success rounded-pill"
                    style={{
                      width: '10px',
                      height: '10px'
                    }}></span>
                )}{' '}
                <h6 className="my-0">{item?.title || '⚠️'}</h6>
              </div>
              <div className="d-flex gap-2">
                <span
                  className="lh-1 align-middle link-success"
                  onClick={() => {
                    changeItemDataUpdate({
                      itemId: item.id,
                      parentId: groupId
                    })
                    openUpdate()
                  }}>
                  <i className="ri-edit-2-line"></i>
                </span>
                <span
                  className="lh-1 align-middle link-danger"
                  onClick={() => {
                    openDelete(item.id)
                  }}>
                  <i className="ri-delete-bin-line"></i>
                </span>
              </div>
            </li>
          ))) || <li className="list-group-item">No items</li>}
        <li className="list-group-item d-flex justify-content-between lh-sm">
          <button
            type="button"
            className="btn btn-soft-primary btn-sm white-icon-onhover w-100"
            onClick={() => {
              openAddItem()
              changeAddItemId(groupId)
            }}>
            <i className="ri-add-line text-primary" />
          </button>
        </li>
      </ul>
      {deleteId && deleteId > 0 && (
        <DeleteModal
          show={deleteId}
          onDeleteClick={() => {
            deleteItem()
            onSuccess()
          }}
          onCloseClick={() => {
            if (closeDelete) closeDelete()
          }}
        />
      )}
      {showUpdate && (
        <UpdateItemModal
          close={closeUpdate}
          show={openUpdate}
          onSuccess={onSuccess}
          data={itemDataUpdate}
        />
      )}
      {showAddItem && (
        <AddItemModal
          close={closeAddItem}
          show={openAddItem}
          onSuccess={onSuccess}
          parentId={addItemId}
        />
      )}
    </>
  )
}

export default MenuItem
