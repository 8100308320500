// react elements
import { useTranslation } from 'react-i18next'
// botstrab elements
import { Card, CardBody, CardHeader } from 'reactstrap'
// required components
import { Input, Search, Select } from 'Components/Form'
import { useFieldArray } from 'react-hook-form'
import { useDefaultCurrency } from 'Components/DefaultCurrency'

const Features = ({
  register,
  errors,
  control,
  getValues
}: {
  register: any
  errors: any
  control: any
  getValues: any
}) => {
  const { t } = useTranslation()
  const defaultCurrency = useDefaultCurrency()

  // options logic
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'featureOption'
  })
  const addOption = () =>
    append({
      featureOptionId: null,
      discount: null,
      discount_type: null,
      cost: null,
      price: null
    })
  const removeOption = (index: number) => remove(index)

  return (
    <Card>
      <CardHeader>
        <h5 className="card-title mb-0">{t('Features')}</h5>
      </CardHeader>
      <CardBody className="p-0">
        <table className="table table-hover table-striped align-middle table-nowrap mb-0">
          <thead>
            <tr>
              <th scope="col">{t('Title')}</th>
              <th scope="col">{t('Quantity')}</th>
              <th scope="col">{t('Price')}</th>
              <th scope="col">{t('Cost')}</th>
              <th scope="col">{t('Discount')}</th>
              <th scope="col">{t('Delete')}</th>
            </tr>
          </thead>
          <tbody>
            {fields.map((field, index) => (
              <tr key={index}>
                <td>
                  <Search
                    placeholder={t('Select option')}
                    model={register(`featureOption.${index}.featureOptionId`)}
                    path="featureOptions"
                    value={getValues(`featureOption.${index}.featureOption`)}
                    propTitle="title"
                  />
                </td>
                <td style={{ width: '100px' }}>
                  <Input
                    type="number"
                    className="form-control"
                    placeholder={t('Quantity')}
                    disabled={true}
                    model={register(`featureOption.${index}.quantity`)}
                  />
                </td>
                <td style={{ width: '100px' }}>
                  <Input
                    type="number"
                    className="form-control"
                    placeholder={t('Price')}
                    model={register(`featureOption.${index}.price`)}
                  />
                </td>
                <td style={{ width: '100px' }}>
                  <Input
                    type="number"
                    className="form-control"
                    placeholder={t('Cost')}
                    model={register(`featureOption.${index}.cost`)}
                  />
                </td>

                <td>
                  <div className="d-flex" style={{ width: '160px' }}>
                    <div style={{ width: '80px' }}>
                      <Select
                        placeholder={t('Type')}
                        className="rounded-start-1"
                        options={[
                          {
                            label: '%',
                            value: 'perc'
                          },
                          {
                            label: defaultCurrency,
                            value: 'net'
                          }
                        ]}
                        model={register(`featureOption.${index}.discount_type`)}
                        error={errors[`featureOption.${index}.discount_type`]}
                        value={getValues(`featureOption.${index}.discount_type`)}
                      />
                    </div>
                    <div style={{ width: '80px' }}>
                      <Input
                        placeholder={t('Enter discount')}
                        className="rounded-end-1"
                        type="number"
                        model={register(`featureOption.${index}.discount`)}
                        error={errors[`featureOption.${index}.discount`]}
                      />
                    </div>
                  </div>
                </td>
                {/* <td>
                  <div className="d-flex align-items-center">
                    <Button className="btn btn-secoundry">
                      <i className="mdi mdi-image"></i>
                    </Button>
                  </div>
                </td> */}
                <td>
                  <button
                    className="btn btn-outline-danger ms-2"
                    type="button"
                    disabled={fields.length <= 1}
                    onClick={() => removeOption(index)}>
                    <i className="ri-close-line align-middle"></i>
                  </button>
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan={10000}>
                <button className="btn btn-secondary w-100" type="button" onClick={addOption}>
                  <i className="ri-add-line align-middle"></i> {t('Add option')}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </CardBody>
    </Card>
  )
}

export default Features
