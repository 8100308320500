import { Button, Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Input, Submit } from 'Components/Form'
import PopUp from 'Components/PopUp'
import CreateHook from 'helpers/CreateHook'

const UpdatePasswordModal = ({ show, close, onSuccess, itemId }: any) => {
  const { t } = useTranslation()

  const { register, handleSubmit, errors, loading, create } = CreateHook<any>(
    `/customers/${itemId}/password/update`,
    {}
  )

  const submit = async (data) => {
    const response = await create(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  return (
    <PopUp title={`${t('Update')} ${t('Customer')} ${t('Password')}`} show={show} close={close}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={12}>
              <Input
                type="password"
                label={t('Password')}
                placeholder={`${t('Enter')} ${t('Password')}`}
                model={register('password')}
                error={errors.password}
              />
            </Col>
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Update')} />
        </div>
      </form>
    </PopUp>
  )
}
export default UpdatePasswordModal
