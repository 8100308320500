import { useState } from 'react'
import { FormProvider } from 'react-hook-form'
import useUpdateDomain from '../Hooks/useUpdateDomain'
import CheckAvailabilityButton from './CheckAvailabilityButton'
import DomainInput from './DomainInput'
import RegisterButton from './RegisterButton'

function RegisterMyDomain() {
  const [isAvailable, setIsAvailable] = useState(false)

  const { update, loading, formMethods: methods } = useUpdateDomain()

  return (
    <FormProvider {...methods}>
      <form>
        <br />
        <DomainInput setIsAvailable={setIsAvailable} />
        <div className="d-flex gap-2 justify-content-center">
          <CheckAvailabilityButton setIsAvailable={setIsAvailable} />
          <RegisterButton isAvailable={isAvailable} loading={loading} submit={update} />
        </div>
      </form>
    </FormProvider>
  )
}

export default RegisterMyDomain
