// required components
import { useThemeBuilder } from '..'
import SectionBuilder from './SectionBuilder'
import config from 'ThemeManager/Templates/Config'

const Previewer = () => {
  const { sections, handelDrop, showDragPositions, handelDragOver } = useThemeBuilder()

  // Helper function to render drag position indicator
  const renderDragPosition = (dropIndex: number) => (
    <div
      className="border border-primary text-center text-primary w-100 rounded-0 py-2 mt-1 border-dashed my-2"
      onDrop={(e) => handelDrop(e, dropIndex)}
      onDragOver={(e) => handelDragOver(e)}>
      +
    </div>
  )

  return (
    <div className="theme-builder-container p-3">
      <div className="theme-builder-background">
        {showDragPositions && renderDragPosition(0)}
        {sections?.map((section: any, index: number) => {
          const sectionConfig = findSectionConfig(config, section)

          if (!sectionConfig) return null
          return (
            <span key={index}>
              <SectionBuilder
                index={index}
                value={section.data.data}
                config={sectionConfig?.config}
              />
              {showDragPositions && renderDragPosition(index + 1)}
            </span>
          )
        })}
      </div>
    </div>
  )
}

export default Previewer

const findSectionConfig = (config, section) => {
  const matchedSection = config.find((el) => el.key === section.type)

  if (!matchedSection || !matchedSection.sections) return undefined

  return matchedSection.sections.find((el) => el.key === section.data.type)
}
