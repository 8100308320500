import { useTranslation } from 'react-i18next'

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Col, Container, Row, Form, Accordion, AccordionItem } from 'reactstrap'

import BreadCrumb from 'Components/Common/BreadCrumb'
import { Submit } from 'Components/Form'
import CreateHook from 'helpers/CreateHook'

import MainDetails from './components/MainDetails'
import MainPrice from './components/MainPrice'
import Gallery from './components/Gallery'
import Specification from './components/Specification'
import OrderForm from './components/OrderForm'
import Features from './components/Features'
import ProductSidebar from '../Components/ProductSidebar'

const ProductWithAddedFeatures = () => {
  document.title = 'Create Product | Salltak'
  const { t } = useTranslation()
  const navigate = useNavigate()

  type readyProduct = {
    title: string
    slug: string
    short_description: string
    description: string
    sku: string
    MPN: string
    GTIN: string
    points: number | null
    brand_id: number | null
    hidden: number | null
    cost: number | null
    price: number | null
    weight_unit: string
    weight: number | null
    quantity: number | null
    badge_id: number | null
    categories: number[]
    fees: number[]
    main_image: string
    images: string[]
    labels: number[]
    form_id: number | null
    discount: number | null
    discount_type: string
    is_free_shipping: number | null
    cod_allowed: number | null
    stock_min_alert: number | null
    shipping_allowed: number | null
    ready_in: number | null
    ready_in_type: string
    similar_products: number[]
    meta: {
      title: ''
      keywords: ''
      description: ''
    }
    featureOption: {
      discount: string
      discount_type: string
      cost: string
      price: string
      quantity: string
    }[]
  }

  // prepare hock
  const {
    register,
    handleSubmit,
    errors,
    create,
    loading,
    closeUploading,
    openUploading,
    setValue,
    control,
    getValues
  } = CreateHook<readyProduct>('/ready_product_plus_features', {
    title: '',
    slug: '',
    short_description: '',
    description: '',
    sku: '',
    MPN: '',
    GTIN: '',
    points: null,
    brand_id: null,
    hidden: null,
    cost: null,
    price: null,
    weight_unit: '',
    weight: null,
    quantity: null,
    badge_id: null,
    categories: [],
    fees: [],
    main_image: '',
    images: [],
    labels: [],
    form_id: null,
    discount: null,
    discount_type: '',
    is_free_shipping: null,
    cod_allowed: null,
    stock_min_alert: null,
    shipping_allowed: null,
    ready_in: null,
    ready_in_type: '',
    similar_products: [],
    meta: {
      title: '',
      keywords: '',
      description: ''
    },
    featureOption: []
  })

  const [opend, setOpen] = useState<string>('')
  const selectLable = (label: string) => setOpen(label)

  const submit = async (data: readyProduct) => {
    const response = await create(data)
    if (response.status) navigate('/products/list')
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Form onSubmit={handleSubmit(submit)}>
          <Row>
            <Col lg={8}>
              <MainDetails
                register={register}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
              />
              <MainPrice register={register} errors={errors} getValues={getValues} />

              <Features
                register={register}
                errors={errors}
                control={control}
                getValues={getValues}
              />

              <Gallery
                register={register}
                errors={errors}
                openUploading={openUploading}
                closeUploading={closeUploading}
                getValues={getValues}
              />

              <Accordion
                toggle={() => {}}
                className="lefticon-accordion custom-accordionwithicon accordion-border-box"
                open="test">
                <AccordionItem>
                  <Specification
                    isOpen={opend == 'spicifcation'}
                    toggleItem={() => selectLable('spicifcation')}
                    register={register}
                    getValues={getValues}
                  />
                </AccordionItem>
                <AccordionItem>
                  <OrderForm
                    isOpen={opend == 'orderForm'}
                    toggleItem={() => selectLable('orderForm')}
                    register={register}
                    errors={errors}
                    getValues={getValues}
                  />
                </AccordionItem>
              </Accordion>
              <div className="text-end mb-3 m-2">
                <Submit loading={loading} label={t('Save')} />
              </div>
            </Col>

            <Col lg={4}>
              <ProductSidebar />
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  )
}

export default ProductWithAddedFeatures
