import { Button, Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Input, Submit } from 'Components/Form'
import CreateHook from 'helpers/CreateHook'
import PopUp from 'Components/PopUp'

const CreateModal = ({ close, show, onSuccess }: any) => {
  const { t } = useTranslation()

  type moderatortype = {
    id?: number
    name: string
  }

  // prepare hock
  const { register, handleSubmit, errors, create, loading } = CreateHook<moderatortype>('/forms', {
    name: ''
  })

  const submit = async (data: moderatortype) => {
    const response = await create(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  return (
    <PopUp title={t('Create New order form')} show={show} close={close}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={12}>
              <Input
                label={t('Form name')}
                placeholder={t('Enter form name')}
                model={register('name', {
                  required: t('Form name is required'),
                  minLength: {
                    value: 3,
                    message: t('Form name must be bigger than or equal 3')
                  }
                })}
                error={errors.name}
              />
            </Col>
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Create')} />
        </div>
      </form>
    </PopUp>
  )
}
export default CreateModal
