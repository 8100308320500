import CreateHook from 'helpers/CreateHook'
import { useState } from 'react'
import { Accordion, AccordionItem, Col, Row } from 'reactstrap'

import Gallery from './components/Gallery'
import MainDetails from './components/MainDetails'
import MainPrice from './components/MainPrice'

import Assets from '../Components/Assets'
import ProductSidebar from '../Components/ProductSidebar'
import { DigitalProduct } from '../types'
import OrderForm from './components/OrderForm'
import ProductsLayout from './components/ProductsLayout'
import Specification from './components/Specification'

const Digital = () => {
  document.title = 'Create product | Sallatk'

  // prepare hock
  const createMethods = CreateHook<DigitalProduct>('/digital_product', {
    title: '',
    slug: '',
    short_description: '',
    description: '',
    sku: '',
    MPN: '',
    GTIN: '',
    points: null,
    brand_id: null,
    published: 0,
    cost: null,
    price: null,
    weight_unit: '',
    weight: null,
    badge_id: null,
    categories: [],
    fees: [],
    main_image: '',
    images: [],
    labels: [],
    form_id: null,
    discount: null,
    discount_type: '',
    stock_min_alert: null,
    similar_products: [],
    meta: {
      title: '',
      keywords: '',
      description: ''
    },
    assets: [
      {
        title: '',
        file: ''
      }
    ]
  })

  const [opend, setOpen] = useState<string>('')
  const selectLable = (label: string) => setOpen(label)

  const { openUploading, closeUploading } = createMethods
  return (
    <ProductsLayout createMethods={createMethods}>
      <Row>
        <Col lg={8}>
          <MainDetails />
          <MainPrice />
          <Assets openUploading={openUploading} closeUploading={closeUploading} />
          <Gallery openUploading={openUploading} closeUploading={closeUploading} />
          <Accordion
            toggle={() => {}}
            className="lefticon-accordion custom-accordionwithicon accordion-border-box"
            open="test">
            <AccordionItem>
              <Specification
                isOpen={opend == 'spicifcation'}
                toggleItem={() => selectLable('spicifcation')}
              />
            </AccordionItem>
            <AccordionItem>
              <OrderForm
                isOpen={opend == 'orderForm'}
                toggleItem={() => selectLable('orderForm')}
              />
            </AccordionItem>
          </Accordion>
        </Col>

        <Col lg={4}>
          <ProductSidebar components={{ Shipping: false, Stock: false }} />
        </Col>
      </Row>
    </ProductsLayout>
  )
}

export default Digital
