import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Scrollbar, A11y, Thumbs } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { useState } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import { useTranslation } from 'react-i18next'
import FormPreview from './components/FormPreview'
import TestIndex from './TestIndex'

const isEmptyObject = (obj: any) => {
  return Object.keys(obj).length === 0
}

export const ProductDetails = ({ data }: { data: any }) => {
  console.log(data)
  const { t } = useTranslation()
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null)
  const [activeTab, setActiveTab] = useState('1')

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  if (isEmptyObject(data)) return null

  return <TestIndex data={data} />

  // return (
  //   <div className="page-content">
  //     <div className="container-fluid">
  //       <div className="row">
  //         <div className="col-lg-12">
  //           <div className="card">
  //             <div className="card-body">
  //               <div className="gx-lg-5 row">
  //                 <div className="mx-auto col-md-8 col-xl-4">
  //                   <div className="product-img-slider sticky-side-div">
  //                     <Swiper
  //                       wrapperClass="d-flex align-items-center"
  //                       loop
  //                       style={{ transform: 'translate3d(0px, 0px, 0px)' }}
  //                       modules={[Navigation, Pagination, Scrollbar, A11y, Thumbs]}
  //                       spaceBetween={50}
  //                       slidesPerView={1}
  //                       thumbs={{ swiper: thumbsSwiper }}
  //                       navigation
  //                       // pagination={{ clickable: true }}
  //                       // scrollbar={{ draggable: true }}
  //                       onSwiper={(swiper) => console.log(swiper)}
  //                       onSlideChange={() => console.log('slide change')}>
  //                       <SwiperSlide style={{ width: 482, border: '2px solid #25a0e2' }}>
  //                         <img
  //                           src={data?.web_main_image}
  //                           alt=""
  //                           className="img-fluid d-block w-100"
  //                         />
  //                       </SwiperSlide>
  //                       {data?.web_images &&
  //                         data?.web_images?.map((el: any, i: number) => {
  //                           return (
  //                             <SwiperSlide key={i} style={{ width: 482 }}>
  //                               <img src={el} alt="" className="img-fluid d-block w-100" />
  //                             </SwiperSlide>
  //                           )
  //                         })}
  //                     </Swiper>

  //                     <div className="product-nav-slider mt-2">
  //                       <Swiper
  //                         wrapperClass="d-flex align-items-center"
  //                         slidesPerView={4}
  //                         spaceBetween={10}
  //                         modules={[Thumbs]}
  //                         watchSlidesProgress
  //                         onSwiper={setThumbsSwiper}>
  //                         <SwiperSlide
  //                           style={{ width: 117, marginRight: 10, border: '1px solid #25a0e2' }}>
  //                           <img
  //                             src={data?.web_main_image}
  //                             alt=""
  //                             className="img-fluid d-block rounded"
  //                           />
  //                         </SwiperSlide>
  //                         {data?.web_images &&
  //                           data?.web_images?.map((el: any, i: number) => {
  //                             return (
  //                               <SwiperSlide key={i} style={{ width: 117, marginRight: 10 }}>
  //                                 <img src={el} alt="" className="img-fluid d-block rounded" />
  //                               </SwiperSlide>
  //                             )
  //                           })}
  //                       </Swiper>
  //                     </div>
  //                   </div>
  //                 </div>

  //                 <div className="col-xl-8">
  //                   <div className="mt-xl-0 mt-5">
  //                     <div className="d-flex">
  //                       <div className="flex-grow-1">
  //                         <h4>{data?.title}</h4>
  //                         <div className="hstack gap-3 flex-wrap">
  //                           <div>
  //                             <a
  //                               className="text-primary d-block"
  //                               href="/velzon/react-ts/minimal/apps-ecommerce-product-details">
  //                               Tommy Hilfiger
  //                             </a>
  //                           </div>
  //                           <div className="vr" />
  //                           <div className="text-muted">
  //                             brand :{' '}
  //                             <span className="text-dark fw-medium">{data?.brand?.name}</span>
  //                           </div>
  //                           <div className="vr" />
  //                           <div className="text-muted">
  //                             Type :{' '}
  //                             <span className="text-dark fw-medium">
  //                               {data?.product_type?.title}
  //                             </span>
  //                           </div>
  //                           <div className="vr" />
  //                           <div className="text-muted">
  //                             Published : <span className="text-dark fw-medium">26 Mar, 2021</span>
  //                           </div>
  //                         </div>
  //                       </div>
  //                       <div className="flex-shrink-0">
  //                         <div>
  //                           <a
  //                             href="apps-ecommerce-add-product"
  //                             id="TooltipTop"
  //                             className="btn btn-light">
  //                             <i className="ri-pencil-fill align-bottom" />
  //                           </a>
  //                         </div>
  //                       </div>
  //                     </div>
  //                     <div className="d-flex flex-wrap gap-2 align-items-center mt-3">
  //                       <div className="text-muted fs-16">
  //                         <span className="mdi mdi-star text-warning" />
  //                         <span className="mdi mdi-star text-warning" />
  //                         <span className="mdi mdi-star text-warning" />
  //                         <span className="mdi mdi-star text-warning" />
  //                         <span className="mdi mdi-star text-warning" />
  //                       </div>
  //                       <div className="text-muted">( 5.50k Customer Review )</div>
  //                     </div>
  //                     <div className="mt-4 row">
  //                       <div className="col-sm-6 col-lg-3">
  //                         <div className="p-2 border border-dashed rounded">
  //                           <div className="d-flex align-items-center">
  //                             <div className="avatar-sm me-2">
  //                               <div className="avatar-title rounded bg-transparent text-primary fs-24">
  //                                 <i className="ri-money-dollar-circle-fill" />
  //                               </div>
  //                             </div>
  //                             <div className="flex-grow-1">
  //                               <p className="text-muted mb-1">Price :</p>
  //                               <h5 className="mb-0">$120.40</h5>
  //                             </div>
  //                           </div>
  //                         </div>
  //                       </div>
  //                       <div className="col-sm-6 col-lg-3">
  //                         <div className="p-2 border border-dashed rounded">
  //                           <div className="d-flex align-items-center">
  //                             <div className="avatar-sm me-2">
  //                               <div className="avatar-title rounded bg-transparent text-primary fs-24">
  //                                 <i className="ri-file-copy-2-fill" />
  //                               </div>
  //                             </div>
  //                             <div className="flex-grow-1">
  //                               <p className="text-muted mb-1">No. of Orders :</p>
  //                               <h5 className="mb-0">2,234</h5>
  //                             </div>
  //                           </div>
  //                         </div>
  //                       </div>
  //                       <div className="col-sm-6 col-lg-3">
  //                         <div className="p-2 border border-dashed rounded">
  //                           <div className="d-flex align-items-center">
  //                             <div className="avatar-sm me-2">
  //                               <div className="avatar-title rounded bg-transparent text-primary fs-24">
  //                                 <i className="ri-stack-fill" />
  //                               </div>
  //                             </div>
  //                             <div className="flex-grow-1">
  //                               <p className="text-muted mb-1">Available Stocks :</p>
  //                               <h5 className="mb-0">1,230</h5>
  //                             </div>
  //                           </div>
  //                         </div>
  //                       </div>
  //                       <div className="col-sm-6 col-lg-3">
  //                         <div className="p-2 border border-dashed rounded">
  //                           <div className="d-flex align-items-center">
  //                             <div className="avatar-sm me-2">
  //                               <div className="avatar-title rounded bg-transparent text-primary fs-24">
  //                                 <i className="ri-inbox-archive-fill" />
  //                               </div>
  //                             </div>
  //                             <div className="flex-grow-1">
  //                               <p className="text-muted mb-1">Total Revenue :</p>
  //                               <h5 className="mb-0">$60,645</h5>
  //                             </div>
  //                           </div>
  //                         </div>
  //                       </div>
  //                     </div>
  //                     <div className="row">
  //                       <div className="col-xl-6">
  //                         <div className=" mt-4">
  //                           <h5 className="fs-14">Sizes :</h5>
  //                           <div className="d-flex flex-wrap gap-2">
  //                             <input
  //                               name="productsize-radio"
  //                               type="radio"
  //                               className="btn-check form-check-input"
  //                             />
  //                             <label
  //                               id="TooltipSSize"
  //                               className="btn btn-soft-primary avatar-xs rounded-circle p-0 d-flex justify-content-center align-items-center form-label">
  //                               S
  //                             </label>
  //                             <input
  //                               name="productsize-radio"
  //                               type="radio"
  //                               className="btn-check form-check-input"
  //                             />
  //                             <label
  //                               id="TooltipMSize"
  //                               className="btn btn-soft-primary avatar-xs rounded-circle p-0 d-flex justify-content-center align-items-center form-label">
  //                               M
  //                             </label>
  //                             <input
  //                               name="productsize-radio"
  //                               type="radio"
  //                               className="btn-check form-check-input"
  //                             />
  //                             <label
  //                               id="TooltipLSize"
  //                               className="btn btn-soft-primary avatar-xs rounded-circle p-0 d-flex justify-content-center align-items-center form-label">
  //                               L
  //                             </label>
  //                             <input
  //                               name="productsize-radio"
  //                               type="radio"
  //                               className="btn-check form-check-input"
  //                             />
  //                             <label
  //                               id="TooltipXlSize"
  //                               className="btn btn-soft-primary avatar-xs rounded-circle p-0 d-flex justify-content-center align-items-center form-label">
  //                               XL
  //                             </label>
  //                           </div>
  //                         </div>
  //                       </div>
  //                       <div className="col-xl-6">
  //                         <div className=" mt-4">
  //                           <h5 className="fs-14">Colors :</h5>
  //                           <div className="d-flex flex-wrap gap-2">
  //                             <div
  //                               data-bs-toggle="tooltip"
  //                               data-bs-trigger="hover"
  //                               data-bs-placement="top"
  //                               title="Out of Stock">
  //                               <button
  //                                 type="button"
  //                                 className="btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-primary"
  //                                 disabled>
  //                                 <i className="ri-checkbox-blank-circle-fill" />
  //                               </button>
  //                             </div>
  //                             <div
  //                               data-bs-toggle="tooltip"
  //                               data-bs-trigger="hover"
  //                               data-bs-placement="top"
  //                               title="03 Items Available">
  //                               <button
  //                                 type="button"
  //                                 className="btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-secondary">
  //                                 <i className="ri-checkbox-blank-circle-fill" />
  //                               </button>
  //                             </div>
  //                             <div
  //                               data-bs-toggle="tooltip"
  //                               data-bs-trigger="hover"
  //                               data-bs-placement="top"
  //                               title="03 Items Available">
  //                               <button
  //                                 type="button"
  //                                 className="btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-success">
  //                                 <i className="ri-checkbox-blank-circle-fill" />
  //                               </button>
  //                             </div>
  //                             <div
  //                               data-bs-toggle="tooltip"
  //                               data-bs-trigger="hover"
  //                               data-bs-placement="top"
  //                               title="02 Items Available">
  //                               <button
  //                                 type="button"
  //                                 className="btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-primary">
  //                                 <i className="ri-checkbox-blank-circle-fill" />
  //                               </button>
  //                             </div>
  //                             <div
  //                               data-bs-toggle="tooltip"
  //                               data-bs-trigger="hover"
  //                               data-bs-placement="top"
  //                               title="01 Items Available">
  //                               <button
  //                                 type="button"
  //                                 className="btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-warning">
  //                                 <i className="ri-checkbox-blank-circle-fill" />
  //                               </button>
  //                             </div>
  //                             <div
  //                               data-bs-toggle="tooltip"
  //                               data-bs-trigger="hover"
  //                               data-bs-placement="top"
  //                               title="04 Items Available">
  //                               <button
  //                                 type="button"
  //                                 className="btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-danger">
  //                                 <i className="ri-checkbox-blank-circle-fill" />
  //                               </button>
  //                             </div>
  //                             <div
  //                               data-bs-toggle="tooltip"
  //                               data-bs-trigger="hover"
  //                               data-bs-placement="top"
  //                               title="03 Items Available">
  //                               <button
  //                                 type="button"
  //                                 className="btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-light">
  //                                 <i className="ri-checkbox-blank-circle-fill" />
  //                               </button>
  //                             </div>
  //                             <div
  //                               data-bs-toggle="tooltip"
  //                               data-bs-trigger="hover"
  //                               data-bs-placement="top"
  //                               title="04 Items Available">
  //                               <button
  //                                 type="button"
  //                                 className="btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-dark">
  //                                 <i className="ri-checkbox-blank-circle-fill" />
  //                               </button>
  //                             </div>
  //                           </div>
  //                         </div>
  //                       </div>
  //                     </div>
  //                     <div className="mt-4 text-muted">
  //                       <h5 className="fs-14">Description :</h5>
  //                       <p>{data?.description}</p>
  //                     </div>
  //                     <div className="row">
  //                       <div className="col-sm-6">
  //                         <div className="mt-3">
  //                           <h5 className="fs-14">Features :</h5>
  //                           <ul className="list-unstyled">
  //                             {data?.featureOptionsPaid?.map((feature: any, i: number) => (
  //                               <li className="py-1">
  //                                 <i className="mdi mdi-circle-medium me-1 text-muted align-middle" />{' '}
  //                                 {feature?.filter_key}
  //                               </li>
  //                             ))}
  //                           </ul>
  //                         </div>
  //                       </div>
  //                       <div className="col-sm-6">
  //                         <div className="mt-3">
  //                           <h5 className="fs-14">Services :</h5>
  //                           <ul className="list-unstyled product-desc-list">
  //                             <li className="py-1">10 Days Replacement</li>
  //                             <li className="py-1">Cash on Delivery available</li>
  //                           </ul>
  //                         </div>
  //                       </div>
  //                     </div>
  //                     <div className="product-content mt-5">
  //                       <h5 className="fs-14 mb-3">Product Description :</h5>
  //                       <Nav className="nav-tabs-custom">
  //                         <NavItem>
  //                           <NavLink style={{ cursor: 'pointer' }} onClick={() => toggleTab('1')}>
  //                             Specification
  //                           </NavLink>
  //                         </NavItem>
  //                         <NavItem>
  //                           <NavLink style={{ cursor: 'pointer' }} onClick={() => toggleTab('2')}>
  //                             Details
  //                           </NavLink>
  //                         </NavItem>
  //                       </Nav>
  //                       <TabContent activeTab={activeTab} className="border border-top-0 p-4">
  //                         <TabPane tabId="1">
  //                           <div className="table-responsive">
  //                             <table className="table mb-0">
  //                               <tbody>
  //                                 <tr>
  //                                   <th scope="row" style={{ width: 200 }}>
  //                                     Category
  //                                   </th>
  //                                   <td>T-Shirt</td>
  //                                 </tr>
  //                                 <tr>
  //                                   <th scope="row">Brand</th>
  //                                   <td>Tommy Hilfiger</td>
  //                                 </tr>
  //                                 <tr>
  //                                   <th scope="row">Color</th>
  //                                   <td>Blue</td>
  //                                 </tr>
  //                                 <tr>
  //                                   <th scope="row">Material</th>
  //                                   <td>Cotton</td>
  //                                 </tr>
  //                                 <tr>
  //                                   <th scope="row">Weight</th>
  //                                   <td>140 Gram</td>
  //                                 </tr>
  //                               </tbody>
  //                             </table>
  //                           </div>
  //                         </TabPane>
  //                         <TabPane tabId="2">
  //                           <div>
  //                             <h5 className="font-size-16 mb-3">
  //                               Tommy Hilfiger Sweatshirt for Men (Pink)
  //                             </h5>
  //                             <p>
  //                               Tommy Hilfiger men striped pink sweatshirt. Crafted with cotton.
  //                               Material composition is 100% organic cotton. This is one of the
  //                               world’s leading designer lifestyle brands and is internationally
  //                               recognized for celebrating the essence of classic American cool
  //                               style, featuring preppy with a twist designs.
  //                             </p>
  //                             <div>
  //                               <p className="mb-2">
  //                                 <i className="mdi mdi-circle-medium me-1 text-muted align-middle" />{' '}
  //                                 Machine Wash
  //                               </p>
  //                               <p className="mb-2">
  //                                 <i className="mdi mdi-circle-medium me-1 text-muted align-middle" />{' '}
  //                                 Fit Type: Regular
  //                               </p>
  //                               <p className="mb-2">
  //                                 <i className="mdi mdi-circle-medium me-1 text-muted align-middle" />{' '}
  //                                 100% Cotton
  //                               </p>
  //                               <p className="mb-0">
  //                                 <i className="mdi mdi-circle-medium me-1 text-muted align-middle" />{' '}
  //                                 Long sleeve
  //                               </p>
  //                             </div>
  //                           </div>
  //                         </TabPane>
  //                       </TabContent>
  //                     </div>
  //                     <div className="mt-5">
  //                       <div>
  //                         <h5 className="fs-14 mb-3">product form preview</h5>
  //                         <FormPreview form={data.form} />
  //                       </div>
  //                     </div>

  //                     <div className="mt-5">
  //                       <div>
  //                         <h5 className="fs-14 mb-3">Ratings &amp; Reviews</h5>
  //                       </div>
  //                       <div className="gy-4 gx-0 row">
  //                         <div className="col-lg-4">
  //                           <div>
  //                             <div className="pb-3">
  //                               <div className="bg-light px-3 py-2 rounded-2 mb-2">
  //                                 <div className="d-flex align-items-center">
  //                                   <div className="flex-grow-1">
  //                                     <div className="fs-16 align-middle text-warning">
  //                                       <i className="ri-star-fill" />{' '}
  //                                       <i className="ri-star-fill" />{' '}
  //                                       <i className="ri-star-fill" />{' '}
  //                                       <i className="ri-star-fill" />{' '}
  //                                       <i className="ri-star-half-fill" />
  //                                     </div>
  //                                   </div>
  //                                   <div className="flex-shrink-0">
  //                                     <h6 className="mb-0">4.5 out of 5</h6>
  //                                   </div>
  //                                 </div>
  //                               </div>
  //                               <div className="text-center">
  //                                 <div className="text-muted">
  //                                   Total <span className="fw-medium">5.50k</span> reviews
  //                                 </div>
  //                               </div>
  //                             </div>
  //                             <div className="mt-3">
  //                               <div className="align-items-center g-2 row">
  //                                 <div className="col-auto">
  //                                   <div className="p-2">
  //                                     <h6 className="mb-0">5 star</h6>
  //                                   </div>
  //                                 </div>
  //                                 <div className="col">
  //                                   <div className="p-2">
  //                                     <div className="progress animated-progess progress-sm">
  //                                       <div
  //                                         className="progress-bar bg-primary"
  //                                         role="progressbar"
  //                                         style={{ width: '50.16%' }}
  //                                       />
  //                                     </div>
  //                                   </div>
  //                                 </div>
  //                                 <div className="col-auto">
  //                                   <div className="p-2">
  //                                     <h6 className="mb-0 text-muted">2758</h6>
  //                                   </div>
  //                                 </div>
  //                               </div>
  //                               <div className="align-items-center g-2 row">
  //                                 <div className="col-auto">
  //                                   <div className="p-2">
  //                                     <h6 className="mb-0">4 star</h6>
  //                                   </div>
  //                                 </div>
  //                                 <div className="col">
  //                                   <div className="p-2">
  //                                     <div className="progress animated-progess progress-sm">
  //                                       <div
  //                                         className="progress-bar bg-success"
  //                                         role="progressbar"
  //                                         style={{ width: '19.32%' }}
  //                                       />
  //                                     </div>
  //                                   </div>
  //                                 </div>
  //                                 <div className="col-auto">
  //                                   <div className="p-2">
  //                                     <h6 className="mb-0 text-muted">1063</h6>
  //                                   </div>
  //                                 </div>
  //                               </div>
  //                               <div className="align-items-center g-2 row">
  //                                 <div className="col-auto">
  //                                   <div className="p-2">
  //                                     <h6 className="mb-0">3 star</h6>
  //                                   </div>
  //                                 </div>
  //                                 <div className="col">
  //                                   <div className="p-2">
  //                                     <div className="progress animated-progess progress-sm">
  //                                       <div
  //                                         className="progress-bar "
  //                                         role="progressbar"
  //                                         style={{ width: '18.12%' }}
  //                                       />
  //                                     </div>
  //                                   </div>
  //                                 </div>
  //                                 <div className="col-auto">
  //                                   <div className="p-2">
  //                                     <h6 className="mb-0 text-muted">997</h6>
  //                                   </div>
  //                                 </div>
  //                               </div>
  //                               <div className="align-items-center g-2 row">
  //                                 <div className="col-auto">
  //                                   <div className="p-2">
  //                                     <h6 className="mb-0">2 star</h6>
  //                                   </div>
  //                                 </div>
  //                                 <div className="col">
  //                                   <div className="p-2">
  //                                     <div className="progress animated-progess progress-sm">
  //                                       <div
  //                                         className="progress-bar bg-warning"
  //                                         role="progressbar"
  //                                         style={{ width: '7.42%' }}
  //                                       />
  //                                     </div>
  //                                   </div>
  //                                 </div>
  //                                 <div className="col-auto">
  //                                   <div className="p-2">
  //                                     <h6 className="mb-0 text-muted">408</h6>
  //                                   </div>
  //                                 </div>
  //                               </div>
  //                               <div className="align-items-center g-2 row">
  //                                 <div className="col-auto">
  //                                   <div className="p-2">
  //                                     <h6 className="mb-0">1 star</h6>
  //                                   </div>
  //                                 </div>
  //                                 <div className="col">
  //                                   <div className="p-2">
  //                                     <div className="progress animated-progess progress-sm">
  //                                       <div
  //                                         className="progress-bar bg-danger"
  //                                         role="progressbar"
  //                                         style={{ width: '4.98%' }}
  //                                       />
  //                                     </div>
  //                                   </div>
  //                                 </div>
  //                                 <div className="col-auto">
  //                                   <div className="p-2">
  //                                     <h6 className="mb-0 text-muted">274</h6>
  //                                   </div>
  //                                 </div>
  //                               </div>
  //                             </div>
  //                           </div>
  //                         </div>
  //                         <div className="col-lg-8">
  //                           <div className="ps-lg-4">
  //                             <div className="d-flex flex-wrap align-items-start gap-3">
  //                               <h5 className="fs-14">Reviews: </h5>
  //                             </div>
  //                             <div
  //                               data-simplebar="init"
  //                               className="me-lg-n3 pe-lg-4 simplebar-scrollable-y"
  //                               style={{ maxHeight: 225 }}>
  //                               <div
  //                                 className="simplebar-wrapper"
  //                                 style={{ margin: '0px -24px 0px 0px' }}>
  //                                 <div className="simplebar-height-auto-observer-wrapper">
  //                                   <div className="simplebar-height-auto-observer" />
  //                                 </div>
  //                                 <div className="simplebar-mask">
  //                                   <div
  //                                     className="simplebar-offset"
  //                                     style={{ right: 0, bottom: 0 }}>
  //                                     <div
  //                                       className="simplebar-content-wrapper"
  //                                       tabIndex={0}
  //                                       role="region"
  //                                       aria-label="scrollable content"
  //                                       style={{
  //                                         height: 'auto',
  //                                         overflow: 'hidden scroll'
  //                                       }}>
  //                                       <div
  //                                         className="simplebar-content"
  //                                         style={{ padding: '0px 24px 0px 0px' }}>
  //                                         <ul className="list-unstyled mb-0">
  //                                           <li className="py-2">
  //                                             <div className="border border-dashed rounded p-3">
  //                                               <div className="d-flex align-items-start mb-3">
  //                                                 <div className="hstack gap-3">
  //                                                   <div className="badge rounded-pill bg-primary mb-0">
  //                                                     <i className="mdi mdi-star" /> 4.2
  //                                                   </div>
  //                                                   <div className="vr" />
  //                                                   <div className="flex-grow-1">
  //                                                     <p className="text-muted mb-0">
  //                                                       Superb sweatshirt. I loved it. It is for
  //                                                       winter.
  //                                                     </p>
  //                                                   </div>
  //                                                 </div>
  //                                               </div>
  //                                               <div className="d-flex flex-grow-1 gap-2 mb-3">
  //                                                 <a
  //                                                   className="d-block"
  //                                                   href="/velzon/react-ts/minimal/apps-ecommerce-product-details">
  //                                                   <img
  //                                                     src="/velzon/react-ts/minimal/static/media/img-12.a55aa6ce7f369f59ab6f.jpg"
  //                                                     alt=""
  //                                                     className="avatar-sm rounded object-fit-cover"
  //                                                   />
  //                                                 </a>
  //                                                 <a
  //                                                   className="d-block"
  //                                                   href="/velzon/react-ts/minimal/apps-ecommerce-product-details">
  //                                                   <img
  //                                                     src="/velzon/react-ts/minimal/static/media/img-11.c7dbbec4252d538c779d.jpg"
  //                                                     alt=""
  //                                                     className="avatar-sm rounded object-fit-cover"
  //                                                   />
  //                                                 </a>
  //                                                 <a
  //                                                   className="d-block"
  //                                                   href="/velzon/react-ts/minimal/apps-ecommerce-product-details">
  //                                                   <img
  //                                                     src="/velzon/react-ts/minimal/static/media/img-10.35db1022349370768826.jpg"
  //                                                     alt=""
  //                                                     className="avatar-sm rounded object-fit-cover"
  //                                                   />
  //                                                 </a>
  //                                               </div>
  //                                               <div className="d-flex align-items-end">
  //                                                 <div className="flex-grow-1">
  //                                                   <h5 className="fs-14 mb-0">Henry</h5>
  //                                                 </div>
  //                                                 <div className="flex-shrink-0">
  //                                                   <p className="text-muted fs-13 mb-0">
  //                                                     12 Jul, 21
  //                                                   </p>
  //                                                 </div>
  //                                               </div>
  //                                             </div>
  //                                           </li>
  //                                           <li className="py-2">
  //                                             <div className="border border-dashed rounded p-3">
  //                                               <div className="d-flex align-items-start mb-3">
  //                                                 <div className="hstack gap-3">
  //                                                   <div className="badge rounded-pill bg-primary mb-0">
  //                                                     <i className="mdi mdi-star" /> 4.0
  //                                                   </div>
  //                                                   <div className="vr" />
  //                                                   <div className="flex-grow-1">
  //                                                     <p className="text-muted mb-0">
  //                                                       Great at this price, Product quality and
  //                                                       look is awesome.
  //                                                     </p>
  //                                                   </div>
  //                                                 </div>
  //                                               </div>
  //                                               <div className="d-flex align-items-end">
  //                                                 <div className="flex-grow-1">
  //                                                   <h5 className="fs-14 mb-0">Nancy</h5>
  //                                                 </div>
  //                                                 <div className="flex-shrink-0">
  //                                                   <p className="text-muted fs-13 mb-0">
  //                                                     06 Jul, 21
  //                                                   </p>
  //                                                 </div>
  //                                               </div>
  //                                             </div>
  //                                           </li>
  //                                           <li className="py-2">
  //                                             <div className="border border-dashed rounded p-3">
  //                                               <div className="d-flex align-items-start mb-3">
  //                                                 <div className="hstack gap-3">
  //                                                   <div className="badge rounded-pill bg-primary mb-0">
  //                                                     <i className="mdi mdi-star" /> 4.2
  //                                                   </div>
  //                                                   <div className="vr" />
  //                                                   <div className="flex-grow-1">
  //                                                     <p className="text-muted mb-0">
  //                                                       Good product. I am so happy.
  //                                                     </p>
  //                                                   </div>
  //                                                 </div>
  //                                               </div>
  //                                               <div className="d-flex align-items-end">
  //                                                 <div className="flex-grow-1">
  //                                                   <h5 className="fs-14 mb-0">Joseph</h5>
  //                                                 </div>
  //                                                 <div className="flex-shrink-0">
  //                                                   <p className="text-muted fs-13 mb-0">
  //                                                     06 Jul, 21
  //                                                   </p>
  //                                                 </div>
  //                                               </div>
  //                                             </div>
  //                                           </li>
  //                                           <li className="py-2">
  //                                             <div className="border border-dashed rounded p-3">
  //                                               <div className="d-flex align-items-start mb-3">
  //                                                 <div className="hstack gap-3">
  //                                                   <div className="badge rounded-pill bg-primary mb-0">
  //                                                     <i className="mdi mdi-star" /> 4.1
  //                                                   </div>
  //                                                   <div className="vr" />
  //                                                   <div className="flex-grow-1">
  //                                                     <p className="text-muted mb-0">
  //                                                       Nice Product, Good Quality.
  //                                                     </p>
  //                                                   </div>
  //                                                 </div>
  //                                               </div>
  //                                               <div className="d-flex align-items-end">
  //                                                 <div className="flex-grow-1">
  //                                                   <h5 className="fs-14 mb-0">Jimmy</h5>
  //                                                 </div>
  //                                                 <div className="flex-shrink-0">
  //                                                   <p className="text-muted fs-13 mb-0">
  //                                                     24 Jun, 21
  //                                                   </p>
  //                                                 </div>
  //                                               </div>
  //                                             </div>
  //                                           </li>
  //                                         </ul>
  //                                       </div>
  //                                     </div>
  //                                   </div>
  //                                 </div>
  //                                 <div
  //                                   className="simplebar-placeholder"
  //                                   style={{ width: 506, height: 488 }}
  //                                 />
  //                               </div>
  //                               <div
  //                                 className="simplebar-track simplebar-horizontal"
  //                                 style={{ visibility: 'hidden' }}>
  //                                 <div
  //                                   className="simplebar-scrollbar"
  //                                   style={{ width: 0, display: 'none' }}
  //                                 />
  //                               </div>
  //                               <div
  //                                 className="simplebar-track simplebar-vertical"
  //                                 style={{ visibility: 'visible' }}>
  //                                 <div
  //                                   className="simplebar-scrollbar"
  //                                   style={{
  //                                     height: 103,
  //                                     transform: 'translate3d(0px, 0px, 0px)',
  //                                     display: 'block'
  //                                   }}
  //                                 />
  //                               </div>
  //                             </div>
  //                           </div>
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // )
}

export default ProductDetails
