import classnames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import IHaveDomain from './components/IHaveDomain'
import RegisterMyDomain from './components/RegisterMyDomain'

const DomainList = () => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState('1')

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => toggleTab('1')}>
              {t('Register My Domain')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => toggleTab('2')}>
              {t('I Have a Domain')}
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <RegisterMyDomain />
          </TabPane>

          <TabPane tabId="2">
            <IHaveDomain />
          </TabPane>
        </TabContent>
      </Container>
    </div>
  )
}

export default DomainList
