import { useContext } from 'react'
import { Card, CardBody } from 'reactstrap'
import { usePOSCategoriesTree } from 'services/pos/categories'
import { Category } from 'types'
import { POSContext } from '../POSContext'
import { POSSingleCategory } from './POSSingleCategory'

type Props = {}

const BackToParent: Category = {
  id: null,
  title: '',
  mobile_image: '',
  web_image: '',
  children: [],
  icon: <i className="bx bx-arrow-back" style={{ fontSize: '2.4rem' }} />
}

function attachParent(nodes: Category[], parent = null) {
  for (const node of nodes) {
    node.parent = parent
    if (node.children && node.children.length > 0) {
      attachParent(node.children, node)
    }
  }

  return nodes
}

export const POSCategories = (props: Props) => {
  const { selectedCategory } = useContext(POSContext)

  const { data } = usePOSCategoriesTree({ limit: 999, 'filter[parent_id]': -1, get_products: 1 })
  const categories = attachParent(data?.data || [])

  const categoriesList = () => {
    if (selectedCategory?.children?.length) {
      return selectedCategory.children
    }

    if (!selectedCategory?.children?.length && selectedCategory?.parent) {
      return selectedCategory?.parent?.children
    }

    return categories
  }

  return (
    <Card className="mb-3">
      <CardBody className="d-flex gap-3 flex-no-wrap overflow-auto">
        {selectedCategory?.parent?.children?.length || selectedCategory?.children?.length ? (
          <POSSingleCategory category={BackToParent} />
        ) : null}

        {categoriesList()?.map((category: Category, index: number) => (
          <POSSingleCategory key={category.id} category={category} />
        ))}
      </CardBody>
    </Card>
  )
}
