import { Button, Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Input, Submit, Select, ImageUpload, Search } from 'Components/Form'
import UpdateHock from 'helpers/UpdateHock'
import PopUp from 'Components/PopUp'

const UpdateModal = ({ close, show, onSuccess, itemId }: any) => {
  const { t } = useTranslation()

  type featureOptionType = {
    id: number | null
    title: string
    color?: string
    type: string
  }

  // prepare hock
  const { register, handleSubmit, errors, loading, getLoading, update, getValues } =
    UpdateHock<featureOptionType>({
      path: '/featureOptions',
      initValue: {
        id: null,
        title: '',
        color: '',
        type: ''
      },
      editId: itemId
    })

  const submit = async (data: featureOptionType) => {
    const response = await update(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  return (
    <PopUp title={t('Update feature option')} show={show} close={close} loading={getLoading}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={12}>
              <Input
                label={t('Title')}
                placeholder={t('Title ')}
                model={register('title', {
                  required: t('Title is required'),
                  minLength: {
                    value: 3,
                    message: t('Title must be bigger than or equal 3')
                  }
                })}
                error={errors.title}
              />
            </Col>

            {getValues('type') === 'color' && (
              <Col md={12}>
                <Input
                  type={'color'}
                  label={t('Color')}
                  placeholder={t('Enter Color')}
                  model={register('color')}
                  error={errors.color}
                  className={'form-control form-control-color w-100 form-control'}
                />
              </Col>
            )}
          </Row>
        </Container>
        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Update')} />
        </div>
      </form>
    </PopUp>
  )
}
export default UpdateModal
