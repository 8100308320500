import Flatpickr from 'react-flatpickr'

const TimePicker = ({
  label,
  placeholder,
  model,
  error,
  onChange,
  className
}: {
  label: string
  placeholder: string
  model?: any
  error?: any
  onChange?: (value: string) => void
  className?: string
}) => {
  // id for input focus
  const randumId = Math.random().toString(36).substring(7)
  // name of input
  const name = model.name
  // change logic
  const changeSelect = (time: Date[]) => {
    const value =
      (time[0].getHours() > 9 ? time[0].getHours() : '0' + time[0].getHours()) +
      ':' +
      (time[0].getMinutes() > 9 ? time[0].getMinutes() : '0' + time[0].getMinutes())

    model.onChange({
      target: {
        name,
        value: value
      }
    })
    if (onChange) onChange(value)
  }
  return (
    <div className="mb-3">
      <label htmlFor={randumId} className="form-label">
        {label}
      </label>
      <Flatpickr
        className={error ? 'form-control is-invalid' : 'form-control' + ' ' + className}
        placeholder={placeholder}
        onChange={changeSelect}
        options={{
          enableTime: true,
          noCalendar: true,
          dateFormat: 'H:i',
          time_24hr: true
        }}
      />
      <div className="invalid-feedback">{error?.message}</div>
    </div>
  )
}

export default TimePicker
