import { useTranslation } from 'react-i18next'
import { Button, Col, Container, Row } from 'reactstrap'
import { Submit } from 'Components/Form'
import PopUp from 'Components/PopUp'
import { useMutation, useQuery } from '@tanstack/react-query'
import linkGenerator from 'helpers/linkGenerator'
import axios from 'axios'
import { useForm } from 'react-hook-form'

const CreateModal = ({ close, show, onSuccess, data }: any) => {
  const { t } = useTranslation()

  const { data: paymentMethods, isLoading } = useQuery({
    queryKey: ['payment_methods'],
    queryFn: async () => {
      const response = await axios.get(linkGenerator('/payment/methods', 'front'))
      return response.data.result.data
    }
  })

  const { register, handleSubmit } = useForm()

  const { mutate, isPending } = useMutation({
    mutationFn: (paymentMethodId) => {
      return axios.post(linkGenerator('/package/renew'), {
        plan_id: data.planId,
        payment_method_id: paymentMethodId,
        redirect_url_success: window.location.origin.toString() + '/payment-success',
        redirect_url_failed: window.location.origin.toString() + '/payment-failed'
      })
    }
  })

  const submit = async (data) => {
    mutate(data.payment_method_id, {
      onSuccess(data) {
        const invoiceUrl = data?.data?.result?.invoice_url || null
        if (invoiceUrl) window.location.href = invoiceUrl
      }
    })
  }

  return (
    <PopUp title={t('Choose Payment Method')} show={show} close={close} loading={isLoading}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row className="row-gap-3 mb-3">
            {paymentMethods?.map((item) => {
              return (
                <Col key={item?.id} xs="6" className="mb-20  d-flex align-items-center gap-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    {...register('payment_method_id', {
                      required: true,
                      valueAsNumber: true
                    })}
                    id={item?.id}
                    value={item?.id}
                  />

                  <label
                    className="form-check-label d-flex align-items-center gap-3"
                    htmlFor={item?.id}>
                    <img src={item?.image} alt={item?.title} width={50} />
                    {item.title}
                  </label>
                </Col>
              )
            })}
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={isPending} label={t('Submit')} />
        </div>
      </form>
    </PopUp>
  )
}
export default CreateModal
