// react imports
import { useEffect, useState } from 'react'
// botstrap elemnts
import { Card, CardBody, Container, Row, Spinner } from 'reactstrap'
// page elements
import fetch from 'helpers/server'
import { translations } from '../models/details'
import CardComponent from '../components/CardComponent'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import FetchHock from 'helpers/FetchHock'
import Error from 'Components/IndexTable/partials/Error'
import BreadCrumb from 'Components/Common/BreadCrumb'

const Translations = () => {
  document.title = 'Translations | Sallatk'
  const { t } = useTranslation()

  const {
    items,
    loading,
    error,
    options,
    totalPages,
    totalItems,
    showedItems,
    pagenate,
    sort,
    search,
    fetch
  } = FetchHock<translations>({ path: '/translations' })

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <CardBody>
          <Row>
            <TranslationsList items={items} loading={loading} error={error} />
          </Row>
        </CardBody>
      </Container>
    </div>
  )
}

export default Translations

function TranslationsList({
  items,
  loading,
  error
}: {
  items: translations[]
  loading: boolean
  error: boolean
}) {
  // if still loading
  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}>
        <Spinner color="primary" />
      </div>
    )
  }

  if (error) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 100px)'
        }}>
        <Error />
      </div>
    )
  }
  if (items) {
    return (
      <>
        <div className="col-12 col">
          <div className="row-cols-xxl-5 row-cols-lg-3 row-cols-1 row">
            {items?.map((item, index) => (
              <div key={index} className="col">
                <Card
                  className="card-body "
                  style={{
                    padding: '20px ',
                    backgroundColor: 'var(--vz-topbar-user-bg)'
                  }}>
                  <h4>{item.label}</h4>

                  {item.langs.map((lang, index) => (
                    <Link
                      to={`/translation/${item.key}/edit/${lang.code}`}
                      key={index}
                      className="col">
                      <CardComponent lang={lang} />
                    </Link>
                  ))}
                </Card>
              </div>
            ))}
          </div>
        </div>
      </>
    )
  }
}
