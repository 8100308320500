import ImageWithFallback from 'helpers/ImageComponent'
import React, { useState } from 'react'
// import { Document, Page } from 'react-pdf'

interface DynamicContentProps {
  content: {
    type: string
    stream: string
    name?: string
  }
}

const DynamicContent: React.FC<DynamicContentProps> = ({ content }) => {
  const [numPages, setNumPages] = useState<number | null>(null)
  const [pageNumber, setPageNumber] = useState(1)

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
  }

  const renderContent = () => {
    if (!content) return
    switch (content.type) {
      case 'image/gif':
      case 'image/jpeg':
      case 'image/png':
        return (
          <i
            className="ri-file-image-line align-bottom display-5"
            style={{
              color: '#4DB6AC'
            }}></i>
        )
        return (
          <ImageWithFallback
            src={content.stream}
            alt={content.name || 'Image'}
            className="avatar-md object-fit-cover rounded"
          />
        )

      case 'audio/ogg':
      case 'audio/wav':
      case 'audio/mpeg':
        return <i className="ri-file-music-fill align-bottom display-5"></i>
        return (
          <audio controls className="w-full">
            <source src={content.stream} type={content.type} />
            Your browser does not support the audio element.
          </audio>
        )

      case 'text/plain':
        return <i className="ri-file-text-line"></i>
        return <iframe src={content.stream} className="w-full h-96 border" />

      case 'video/mp4':
      case 'video/ogg':
      case 'video/webm':
        return (
          <i
            className="ri-file-video-line align-bottom display-5"
            style={{
              color: '#448AFF'
            }}></i>
        )
        return (
          <video
            controls
            style={{
              width: '100%'
            }}>
            <source src={content.stream} type={content.type} />
            Your browser does not support the video tag.
          </video>
        )

      case 'application/zip':
      case 'application/x-rar-compressed':
        return (
          <div className="p-4 border rounded">
            <p>Compressed file: {content.name}</p>
            <a href={content.stream} download className="text-blue-500 hover:underline">
              Download
            </a>
          </div>
        )

      case 'application/pdf':
        return (
          <i
            className="ri-file-pdf-2-line align-bottom display-5"
            style={{
              color: '#FF5722'
            }}></i>
        )
        return (
          <div>
            {/* <Document file={content.url} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} />
            </Document> */}
            <p>
              Page {pageNumber} of {numPages}
            </p>
            {numPages && numPages > 1 && (
              <div>
                <button onClick={() => setPageNumber(Math.max(1, pageNumber - 1))}>Previous</button>
                <button onClick={() => setPageNumber(Math.min(numPages, pageNumber + 1))}>
                  Next
                </button>
              </div>
            )}
          </div>
        )

      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return <i className="ri-file-excel-2-line"></i>
        return (
          <div className="p-4 border rounded">
            <p>Excel file: {content.name}</p>
            <a href={content.stream} download className="text-blue-500 hover:underline">
              Download
            </a>
          </div>
        )

      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return (
          <div className="p-4 border rounded">
            <p>PowerPoint file: {content.name}</p>
            <a href={content.stream} download className="text-blue-500 hover:underline">
              Download
            </a>
          </div>
        )

      default:
        return <p>Unsupported content type: {content.type}</p>
    }
  }

  return <div className="w-full">{renderContent()}</div>
}

export default DynamicContent
