import { Col, Container, Row } from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import IndexTable, { HeaderColumn } from '../../../Components/IndexTable'

import FetchHock from 'helpers/FetchHock'

type banner = {
  id: number
  web_image: string
}

const EcommerceOrders = () => {
  document.title = 'Orders | Sallatk'

  const { items, loading } = FetchHock<banner>({ path: '/api/banners' })

  const tabs = [
    {
      title: 'All Orders',
      icon: 'ri-store-2-fill',
      action: () => console.log(1)
    },
    {
      title: 'Delivered',
      icon: 'ri-checkbox-circle-line',
      action: () => console.log(2)
    },
    {
      title: 'Pickups',
      icon: 'ri-truck-line',
      action: () => console.log(3)
    },
    {
      title: 'Returns',
      icon: 'ri-arrow-left-right-fill',
      action: () => console.log(4)
    },
    {
      title: 'Cancelled',
      icon: 'ri-close-circle-line',
      action: () => console.log(5)
    }
  ]

  const filters: any = [1]

  const headerColumns: HeaderColumn[] = [
    {
      title: 'Order Id',
      accessor: 'orderId'
    },
    {
      title: 'Customer',
      accessor: 'customer'
    },
    {
      title: 'Product',
      accessor: 'product'
    },
    {
      title: 'Order Date',
      accessor: 'orderDate'
    },
    {
      title: 'Amount',
      accessor: 'amount'
    },
    {
      title: 'Payment Method',
      accessor: 'payment'
    },
    {
      title: 'Delivery Status',
      accessor: 'status'
    },

    {
      title: 'Action'
    }
  ]

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <Row>
          <Col lg={12}>
            <IndexTable
              title={'customer'}
              CSVLink="hhtps://google.com"
              addRoute="/baseComponent/create"
              tabs={tabs}
              filters={filters}
              headerColumns={headerColumns}
              loading={loading}
              error={false}
              options={undefined}
              totalPages={0}
              totalItems={0}
              pagenate={function (page: number): void {
                throw new Error('Function not implemented.')
              }}
              addString={''}>
              {items?.map((item) => (
                <tr>
                  <td>{item.id}</td>
                </tr>
              ))}
            </IndexTable>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default EcommerceOrders
