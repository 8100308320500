import React, { useEffect, useState } from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { get, map } from 'lodash'

//i18n
import i18n from '../../i18n'
import languages from '../../common/languages'

const LanguageDropdown = () => {
// Declare a new state variable, which we'll call "menu"
const [selectedLang, setSelectedLang] = useState('')

useEffect(() => {
const currentLanguage: any = localStorage.getItem('I18N_LANGUAGE')
setSelectedLang(currentLanguage)
}, [])

useEffect(() => {
document.documentElement.dir = i18n.dir(i18n.language)
}, [i18n, i18n.language])

const changeLanguageAction = (lang: any) => {
//set language as i18n
i18n.changeLanguage(lang)
localStorage.setItem('I18N_LANGUAGE', lang)
setSelectedLang(lang)
//reload page
// window.location.reload()
}

const [isLanguageDropdown, setIsLanguageDropdown] = useState(false)
const toggleLanguageDropdown = () => {
setIsLanguageDropdown(!isLanguageDropdown)
}
return (
<React.Fragment>
    <Dropdown isOpen={isLanguageDropdown} toggle={toggleLanguageDropdown}
        className="ms-1 topbar-head-dropdown header-item">
        <DropdownToggle className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" tag="button">
            <img src={get(languages, `${selectedLang}.flag`)} alt="Header Language" height="20" className="rounded" />
        </DropdownToggle>
        <DropdownMenu className="notify-item language py-2">
            {map(Object.keys(languages), (key) =>{
             if( key !== selectedLang )
              return (
            
<DropdownItem key={key} onClick={()=> changeLanguageAction(key)}
    className={`notify-item ${selectedLang === key ? 'active' : 'none'}`}>
    <img src={get(languages, `${key}.flag`)} alt="Skote" className="me-2 rounded" height="18" />
    <span className="align-middle">{get(languages, `${key}.label`)}</span>
</DropdownItem>

)} )}
        </DropdownMenu>
    </Dropdown>
</React.Fragment>
)
}

export default LanguageDropdown
