import Nouislider from 'nouislider-react'
import 'nouislider/dist/nouislider.css'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useProductContext } from '../../Context'
import { withErrorBoundary } from 'react-error-boundary'
import { useDebouncedCallback } from 'use-debounce'
import { InputGroup, InputGroupText } from 'reactstrap'

function Price() {
  const { filters, products } = useProductContext()

  const selectedMinPrice = products?.options?.filter?.minimum || null
  const selectedMaxPrice = products?.options?.filter?.maximum || null

  const prices = filters?.prices?.items || {}
  const range = {
    min: prices?.min || 0,
    max: prices?.max || prices?.min + 1 || 10
  }

  const { t } = useTranslation()
  const [minCost, setMinCost] = useState(range?.min)
  const [maxCost, setMaxCost] = useState(range?.max)

  const debounced = useDebouncedCallback((key, value) => {
    products.filter(key, value)
  }, 1000)

  const onUpdatePrice = (value: number, key: string) => {
    if (key === 'minimum') {
      setMinCost(value)
    } else {
      setMaxCost(value)
    }

    debounced(key, value)
  }

  useEffect(() => {
    if (selectedMinPrice) {
      setMinCost(selectedMinPrice)
    }
    if (selectedMaxPrice) {
      setMaxCost(selectedMaxPrice)
    }
  }, [])

  return (
    <div className="card-body border-bottom">
      <p className="text-muted text-uppercase fs-12 fw-medium mb-4">{t('Price')}</p>
      <br />
      <Nouislider
        range={range}
        start={[minCost, maxCost]}
        step={1}
        connect
        // tooltips
        format={{
          to: (value) => Math.round(Number(value)),
          from: (value) => Math.round(Number(value))
        }}
        onChange={(values, handle) =>
          onUpdatePrice(Math.round(values[handle]), handle === 0 ? 'minimum' : 'maximum')
        }
        data-slider-color="primary"
        id="product-price-range"
      />

      <div className="formCost d-flex gap-2 align-items-center mt-3">
        <InputGroup>
          <InputGroupText className="p-1">$</InputGroupText>
          <input
            className="form-control form-control-sm"
            type="text"
            value={minCost}
            onChange={(e: any) => onUpdatePrice(e.target.value, 'minimum')}
            id="minCost"
          />
        </InputGroup>
        <span className="fw-semibold text-muted">{t('to')}</span>
        <InputGroup>
          <InputGroupText className="p-1">$</InputGroupText>
          <input
            className="form-control form-control-sm"
            type="text"
            value={maxCost}
            onChange={(e: any) => onUpdatePrice(e.target.value, 'maximum')}
            id="maxCost"
          />
        </InputGroup>
      </div>
    </div>
  )
}

export default withErrorBoundary(Price, { fallback: <div>error in price</div> })
