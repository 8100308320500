import axios from 'axios'
import linkGenerator from './linkGenerator'
import { useEffect, useState } from 'react'

const ShowHock = <itemType>(path: string, customLink: boolean | undefined = false) => {
  // defien states
  const [item, setItem] = useState<itemType | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  // fetch items method
  const fetch = async (): Promise<void> => {
    // open loader
    setLoading(true)
    // remove error
    setError(false)
    // empty  items
    setItem(null)

    try {
      // get data
      const response = await axios.get(customLink? path : linkGenerator(path))
      // asign items
      setItem(response.data.result ?? null)
      // stop loading
      setLoading(false)
    } catch (error) {
      // open error
      setError(true)
      // stop loading
      setLoading(false)
    }
  }

  useEffect(() => {
    fetch()
  }, [])

  return {
    item,
    loading,
    error,
    fetch
  }
}

export default ShowHock
