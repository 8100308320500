import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Card, CardBody, CardHeader, Col, Collapse, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import FetchHock from 'helpers/FetchHock'
import ChangeStatusModal from './ChangeStatusModal'
import clsx from 'clsx'
import ViewDataWithLoading from 'Components/ViewDataWithLoading'

function OrderStatus() {
  const { t } = useTranslation()
  const { id } = useParams()
  const [openModal, setOpenModal] = useState(false)

  const {
    result: tracks,
    fetch,
    error,
    loading
  } = FetchHock<any>({
    path: `/orders/${id}/tracks`
  })

  const openChangeStatusModal = () => {
    setOpenModal(true)
  }
  return (
    <>
      {openModal && (
        <ChangeStatusModal close={() => setOpenModal(false)} show={openModal} onSuccess={fetch} />
      )}
      <Card>
        <CardHeader>
          <div className="d-sm-flex align-items-center">
            <h5 className="card-title flex-grow-1 mb-0">{t('Order Status')}</h5>
            <div className="flex-shrink-0 mt-2 mt-sm-0">
              <button
                onClick={openChangeStatusModal}
                className="btn btn-primary btn-sm mt-2 mt-sm-0">
                <i className="ri-settings-4-fill align-middle me-1"></i>
                {t('Change Status')}
              </button>{' '}
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <ViewDataWithLoading items={tracks} loading={loading} error={error}>
            <div className="profile-timeline">
              <div className="accordion accordion-flush" id="accordionFlushExample">
                {tracks?.map((track, index) => (
                  <TrackItemComponent key={index} ItemData={track} />
                ))}
              </div>
            </div>
          </ViewDataWithLoading>
        </CardBody>
      </Card>
    </>
  )
}

export default OrderStatus

const TrackItemComponent = ({ ItemData }) => {
  const { t } = useTranslation()
  const [col, setCol] = useState(true)

  const title = ItemData?.status?.title
  const statusCode = ItemData?.status?.code
  const createdAt = ItemData?.created_at
  const comment = ItemData?.comment

  const statusStyle = statusStyles[statusCode] || {
    icon: 'mdi mdi-check-circle',
    bgColor: 'bg-light'
  }

  function toggle() {
    setCol(!col)
  }

  return (
    <div className="accordion-item border-0" onClick={toggle}>
      <div className="accordion-header" id="headingOne">
        <Link
          to="#"
          className={clsx('accordion-button', 'p-2', 'shadow-none', {
            collapsed: !col
          })}>
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0 avatar-xs">
              <div className={`avatar-title ${statusStyle.bgColor} rounded-circle`}>
                <i className={statusStyle.icon}></i>
              </div>
            </div>
            <div className="flex-grow-1 ms-3">
              <h6 className="fs-15 mb-0 fw-semibold">{title}</h6>
            </div>
          </div>
        </Link>
      </div>
      <Collapse id="collapseOne" className="accordion-collapse" isOpen={col}>
        <div className="accordion-body ms-2 ps-5 pt-0">
          <Row>
            <Col xl={6}>
              <h6 className="mb-1">
                {comment || <span className="text-muted">{t('No Comments')}</span>}
              </h6>
              <p className="text-muted">{createdAt}</p>
            
              <UserData data={ItemData?.user} />
            </Col>
          </Row>
        </div>
      </Collapse>
    </div>
  )
}

function UserData({ data }) {
  return (
    <div className="d-flex align-items-center">
      <div className="flex-shrink-0 avatar-xs me-3">
        <img
          src={data?.image || 'https://picsum.photos/200'}
          alt=""
          className="img-fluid rounded-circle"
        />
      </div>
      <div className="flex-grow-1">
        <h6 className="fs-14 mb-1">{`${data?.name || ''} ${data?.lastname || ''}`}</h6>
        <p className=" mb-0">{`${data?.dialing_code} ${data?.phone}`}</p>
        <p className="text-muted mb-0">{data?.email}</p>
      </div>
    </div>
  )
}

const statusStyles = {
  shipping: {
    icon: 'mdi mdi-truck-delivery',
    bgColor: 'bg-info'
  },
  completed: {
    icon: 'mdi mdi-check',
    bgColor: 'bg-success'
  },
  return: {
    icon: 'mdi mdi-arrow-left',
    bgColor: 'bg-warning'
  },
  shipped: {
    icon: 'mdi mdi-rocket',
    bgColor: 'bg-primary'
  },
  processing: {
    icon: 'mdi mdi-cog',
    bgColor: 'bg-secondary'
  },
  review: {
    icon: 'mdi mdi-magnify',
    bgColor: 'bg-dark'
  },
  canceled: {
    icon: 'mdi mdi-close',
    bgColor: 'bg-danger'
  },
  refused: {
    icon: 'mdi mdi-thumb-down',
    bgColor: 'bg-danger'
  },
  wait: {
    icon: 'mdi mdi-clock',
    bgColor: 'bg-warning'
  }
}
