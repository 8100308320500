import React, { useEffect, useState } from 'react'
import { get, map } from 'lodash'

//i18n
import i18n from '../../i18n'
import languages from '../../common/languages'

const LanguageBlock = () => {
  // Declare a new state variable, which we'll call "menu"
  const [selectedLang, setSelectedLang] = useState('')

  useEffect(() => {
    const currentLanguage: any = localStorage.getItem('I18N_LANGUAGE')
    setSelectedLang(currentLanguage)
  }, [])

  useEffect(() => {
    document.documentElement.dir = i18n.dir(i18n.language)
  }, [i18n, i18n.language])

  const changeLanguageAction = (lang: any) => {
    //set language as i18n
    i18n.changeLanguage(lang)
    localStorage.setItem('I18N_LANGUAGE', lang)
    setSelectedLang(lang)
  }

  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center">
              {map(Object.keys(languages), (key) => (
                <button
                  key={key}
                  type="button"
                  className={`btn  rounded-pill ms-2 ${selectedLang === key ? 'active' : ''}`}
                  onClick={() => changeLanguageAction(key)}
                >
                  <img
                    src={get(languages, `${key}.flag`)}
                    alt={get(languages, `${key}.label`)}
                    className="me-2 rounded"
                    height="18"
                  />
                  <span className="align-middle"> {get(languages, `${key}.label`)} </span>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default LanguageBlock
