import { Button, Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Input, Submit, Select } from 'Components/Form'
import CreateHook from 'helpers/CreateHook'
import PopUp from 'Components/PopUp'
import Radio from '../../../../../Components/Form/Radio'

const CreateModal = ({ close, show, onSuccess }: any) => {
  const { t } = useTranslation()

  type feestype = {
    id?: number
    title: string
    type: string
    code: string
    fees: number
  }

  // prepare hock
  const { control, register, handleSubmit, errors, create, loading } = CreateHook<feestype>(
    '/fees',
    {
      title: '',
      type: '',
      code: '',
      fees: 0
    }
  )

  const submit = async (data: feestype) => {
    const response = await create(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  return (
    <PopUp title={t('Create New Extra Fees')} show={show} close={close}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={12}>
              <Input
                label={t('Title')}
                placeholder={t('Enter title')}
                model={register('title', {
                  required: t('Title is required'),
                  minLength: {
                    value: 3,
                    message: t('Title must be bigger than or equal 3')
                  }
                })}
                error={errors.title}
              />
            </Col>
            <Col md={8}>
              <Input
                label={t('Fees')}
                type="number"
                model={register('fees', {
                  required: t('Fees is required'),
                  valueAsNumber: true,
                  min: {
                    value: 1,
                    message: t('Fees must be bigger than 0')
                  }
                })}
                error={errors.fees}
                placeholder={t('Enter fees')}
              />
            </Col>
            <Col md={4}>
              <Select
                label={t('Type')}
                placeholder={t('Type')}
                model={register('type', {
                  required: t('type is required')
                })}
                error={errors.type}
                options={[
                  { value: 'perc', label: t('%') },
                  { value: 'net', label: t('KWD') }
                ]}
              />
            </Col>
            <Col md={12}>
              <div>
                <Radio
                  label={t('service')}
                  model={register('code', {
                    required: t('Code is required')
                  })}
                  value="service"
                  error={errors?.code}
                  noErrorMessage
                />
                <Radio
                  label={t('tax')}
                  model={register('code', {
                    required: t('Code is required')
                  })}
                  value="tax"
                  error={errors?.code}
                  noErrorMessage
                />
                {errors?.code && <small className="text-danger">{errors?.code?.message}</small>}
              </div>
            </Col>
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Create')} />
        </div>
      </form>
    </PopUp>
  )
}
export default CreateModal
