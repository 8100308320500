import ChatOnWhatsappLink from 'Components/ChatOnWhatsappLink'
import ImageWithFallback from 'helpers/ImageComponent'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardHeader } from 'reactstrap'

interface UserType {
  id: number // الرقم التعريفي
  name: string // الاسم
  lastname: string // اسم العائلة
  email: string // البريد الإلكتروني
  dialing_code: string // رمز الاتصال
  phone: string // رقم الهاتف
  image: string | null // الصورة
}

function User({ data }: { data: { user: UserType } }) {
  const { t } = useTranslation()
  const userData = data?.user || null
  if (!userData) return null
  return (
    <Card>
      <CardHeader>
        <div className="d-flex">
          <h5 className="card-title flex-grow-1 mb-0">
            <i className="ri-user-line me-1 text-muted"></i> {t('Customer Details')}
          </h5>
        </div>
      </CardHeader>
      <CardBody>
        <ul className="list-unstyled mb-0 vstack gap-3">
          <li className="d-flex">
            <div>
              <ImageWithFallback
                className="avatar-md rounded"
                src={userData?.image}
                alt={userData?.name}
              />
            </div>
          </li>
          <li className="d-flex">
            <i className="ri-user-line me-2 align-middle text-muted fs-16"></i>
            <div>{userData?.name + ' ' + userData?.lastname}</div>
          </li>
          <li className="d-flex">
            <i className="ri-mail-line me-2 align-middle text-muted fs-16"></i>
            <div>{userData?.email}</div>
          </li>
          <li className="d-flex align-items-center">
            <i className="ri-phone-line me-2 align-middle text-muted fs-16"></i>
            <div>
              {userData?.dialing_code}
              {userData?.phone}
            </div>
          </li>
          <li className="d-flex align-items-center">
            <ChatOnWhatsappLink phoneNumber={`${userData?.dialing_code}${userData?.phone}`} />
          </li>
        </ul>
      </CardBody>
    </Card>
  )
}

export default User
