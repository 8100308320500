import { Button, Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Submit, Textarea, Search } from 'Components/Form'
import CreateHook from 'helpers/CreateHook'
import PopUp from 'Components/PopUp'
import { useParams } from 'react-router-dom'

const ChangeStatusModal = ({ close, show, onSuccess }: any) => {
  const { t } = useTranslation()
  const { id } = useParams()

  type ChangeStatus = {
    status_id: number | null
    comment: string
  }

  // prepare hock
  const { register, handleSubmit, errors, create, loading } = CreateHook<ChangeStatus>(
    `/orders/${id}/changeStatus`,
    {
      status_id: null,
      comment: ''
    }
  )

  const submit = async (data: ChangeStatus) => {
    const response = await create(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  return (
    <PopUp title={t('')} show={!!show} close={close}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={12}>
              <Search
                label={t('Status')}
                model={register('status_id', {
                  required: t('Role is required')
                })}
                error={errors.status_id}
                path="order-status"
                propTitle="title"
              />
            </Col>
            <Col md={12}>
              <Textarea label={t('Comment')} model={register('comment')} error={errors.comment} />
            </Col>
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Create')} />
        </div>
      </form>
    </PopUp>
  )
}
export default ChangeStatusModal
