import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import { CSVLink } from 'react-csv'
import { useState } from 'react'
import axios from 'axios'
import linkGenerator from 'helpers/linkGenerator'
import { useTranslation } from 'react-i18next'

const ExportCSVModal = ({ show, onCloseClick, data }: any) => {
  const { t } = useTranslation()
  const [CSVData, setCSVData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const handleDownloadCSV = async () => {
    setLoading(true)
    try {
      const response = await axios.get(linkGenerator(data))
      setCSVData(response.data)
    } catch (error) {
      console.log(error)
      setError(true)
      console.error('Error fetching csv data:', error)
    } finally {
      setLoading(false)
    }
  }
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader toggle={onCloseClick}></ModalHeader>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          {loading ? (
            <Spinner color="success" />
          ) : CSVData ? (
            <i className="ri-checkbox-circle-fill display-5 text-success"></i>
          ) : (
            <i className="ri-file-text-line display-5 text-success"></i>
          )}

          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>{CSVData ? t('Exported') : t('Are you sure ?')}</h4>
            <p className="text-muted mx-4 mb-0">
              {CSVData
                ? t('CSV file has been Exported successfully you can download it')
                : t('Are you sure you want to export CSV file?')}
            </p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button
            type="button"
            className="btn w-sm btn-light"
            data-bs-dismiss="modal"
            onClick={onCloseClick}>
            {t('Close')}
          </button>

          {CSVData ? (
            <CSVLink
              data={CSVData}
              type="button"
              onClick={onCloseClick}
              className="btn w-sm btn-success "
              id="delete-record"
              filename="data.csv">
              {t('Download')}
            </CSVLink>
          ) : (
            <button
              type="button"
              disabled={loading || error}
              className="btn w-sm btn-success "
              data-bs-dismiss="modal"
              onClick={handleDownloadCSV}>
              {loading ? t('Exporting') : t('Export')}
              {loading && <Spinner size="sm" color="light" className="ms-2" />}
            </button>
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ExportCSVModal
