import ViewDataWithLoading from 'Components/ViewDataWithLoading'
import FetchHock from 'helpers/FetchHock'
import ImageWithFallback from 'helpers/ImageComponent'
import CreateModal from 'pages/PaymentMethod/Components/CreateModal'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col, Row } from 'reactstrap'
import UpdatePaymentSwitch from './UpdatePaymentSwitch'

function OnlinePaymentMethodTabBody({ company }) {
  const type = company.type
  const isCOD = type == 'cod'
  const { t } = useTranslation()

  const [showCreate, changeShowCreate] = useState<boolean>(false)
  const openCreate = () => changeShowCreate(true)
  const closeCreate = () => changeShowCreate(false)

  const { items, loading, error, fetch } = FetchHock<{
    active: boolean
    company_id: number
    id: number
    image_url: string
    title: string
  }>({
    path: '/payments',
    initFilter: {
      company_id: company.id
    }
  })

  return (
    <>
      <Row className="mt-4">
        <div className="mb-2 d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-secondary add-btn "
            onClick={() => {
              openCreate()
            }}>
            <i className="ri-settings-2-line align-middle me-1"></i> {t('Settings')}
          </button>
        </div>

        <ViewDataWithLoading items={items} loading={loading} error={error}>
          {items?.map((item, key: number) => {
            return (
              <React.Fragment key={key}>
                <Col xl={isCOD ? 12 : 3} lg={isCOD ? 12 : 6}>
                  <Card className="ribbon-box right overflow-hidden">
                    <CardBody className="text-center p-4">
                      <ImageWithFallback src={item?.image_url} alt="" height={45} />
                      <h5 className="mb-1 mt-4">
                        <Link to="#" className="link-primary">
                          {item?.title}
                        </Link>
                      </h5>
                      <div className="mt-4 d-flex justify-content-center ">
                        <UpdatePaymentSwitch
                          checkedParam={'active'}
                          item={item}
                          onSuccess={fetch}
                          type={type}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </React.Fragment>
            )
          })}
        </ViewDataWithLoading>
      </Row>
      {showCreate && (
        <CreateModal
          data={{ companyId: company.id }}
          close={closeCreate}
          show={openCreate}
          onSuccess={fetch}
        />
      )}
    </>
  )
}
export default OnlinePaymentMethodTabBody
