// react imports
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// bootstrap elements
import { Col, Container, Row, Card, CardBody, CardHeader, Spinner } from 'reactstrap'
// reqired componnent
import { Submit } from 'Components/Form'
import BreadCrumb from 'Components/Common/BreadCrumb'
import BranchCard from '../components/branchCard'
import Areas from './components/areas'
import UpdateHock from 'helpers/UpdateHock'

const EcommerceOrders = () => {
  const { t } = useTranslation()

  document.title = 'Branches areas| Sallatk'
  const history = useNavigate()
  const params = useParams()

  type branchstype = {
    region_id: number[]
  }

  // prepare hock
  const { register, handleSubmit, getValues, update, loading, getLoading } =
    UpdateHock<branchstype>({
      path: '/branch/regions',
      initValue: { region_id: [] },
      editId: parseInt(params.id as string)
    })

  const submit = async (data: branchstype) => {
    const response = await update(data)
    if (response.status) history(-1)
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        {/* branch details */}
        <Row>
          <Col md={3}>
            <BranchCard />
          </Col>
          <Col md={9}>
            <form onSubmit={handleSubmit(submit)}>
              <Card>
                <CardHeader>
                  <h5 className="mb-0">{t('Branch areas')}</h5>
                </CardHeader>
                <CardBody>
                  {!!getLoading && (
                    <div className="d-flex justify-content-center mx-2 mt-2">
                      <Spinner color="primary" />
                    </div>
                  )}
                  {!getLoading && (
                    <Areas model={register('region_id')} value={getValues('region_id')} />
                  )}
                </CardBody>
              </Card>
              <div className="hstack gap-2 justify-content-start mb-2">
                <Submit loading={loading} label={t('Update')} />
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default EcommerceOrders
