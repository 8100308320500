import { useState } from 'react'
import { useTranslation } from 'react-i18next'
// bootstrap elements
import { Collapse, Button } from 'reactstrap'

// required components
import { Search } from 'Components/Form'

import ShowFormModal from './ShowFormModal'

import classnames from 'classnames'

const Specification = ({
  isOpen,
  toggleItem,
  register,
  errors,
  getValues
}: {
  isOpen: boolean
  toggleItem: () => void
  register: any
  errors: any
  getValues: any
}) => {
  const { t } = useTranslation()

  // show form
  const [selectedForm, changeSelectedForm] = useState<number | null>(getValues('form_id'))
  const [showForm, changeShowForm] = useState<boolean>(false)
  const openForm = () => changeShowForm(true)
  const closeForm = () => changeShowForm(false)

  return (
    <>
      <h2 className="accordion-header" id="headingTwo">
        <button
          className={classnames('accordion-button', { collapsed: !isOpen })}
          type="button"
          onClick={toggleItem}
          style={{ cursor: 'pointer' }}
        >
          {t('Order form')}
        </button>
      </h2>
      <Collapse isOpen={isOpen} className="accordion-collapse">
        <div className="accordion-body">
          <div className="d-flex align-items-start justify-content-between w-100">
            <Search
              className="w-100 me-2"
              placeholder={t('Select form')}
              model={register('form_id')}
              error={errors.categories}
              onChange={(value) => {
                changeSelectedForm(parseInt(value.value))
              }}
              value={getValues('form')}
              path="forms"
            />
            <Button
              className="btn btn-secoundry"
              disabled={!selectedForm}
              style={{ width: '160px' }}
              onClick={openForm}
            >
              <i className="mdi mdi-eye-check"></i>
              {t('View Form')}
            </Button>
          </div>
          {showForm && <ShowFormModal close={closeForm} show={showForm} formId={selectedForm} />}
        </div>
      </Collapse>
    </>
  )
}

export default Specification
