import { combineReducers } from 'redux'

// Front
import LayoutReducer from './layouts/reducer'
import UserReducer from './user/reducer'
import productsReducer from './products/reducer'

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  User: UserReducer,
  products: productsReducer
})

export default rootReducer
