import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

const Textarea = ({
  label,
  placeholder,
  model,
  error,
  className,
  horizontal
}: {
  label?: string
  placeholder?: string
  model?: any
  error?: any
  className?: string
  horizontal?: boolean
}) => {
  const { t } = useTranslation()
  const randumId = Math.random().toString(36).substring(7)
  return (
    <div className={clsx('mb-3', { row: horizontal })}>
      {!!label && (
        <label htmlFor={randumId} className={clsx('form-label', { 'col-md-3': horizontal })}>
          {label}
        </label>
      )}
      <div className={clsx({ 'col-md-9': horizontal })}>
        <textarea
          className={`${error ? 'form-control is-invalid' : 'form-control'} ${className || ''}`}
          id={randumId}
          placeholder={placeholder ?? `${t('Enter')} ${label}`}
          rows={3}
          {...model}></textarea>
        <div className="invalid-feedback">{error?.message}</div>
      </div>
    </div>
  )
}

export default Textarea
