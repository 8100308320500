import { Card, CardBody, CardHeader } from 'reactstrap'
import CardItem from './CardItem'
import { useTranslation } from 'react-i18next'

function Package({ data }: any) {
  const { t } = useTranslation()
  const packageData = data?.data?.plan || null

  if (!packageData) return null
  return (
    <Card>
      <CardHeader>
        <div className="d-flex">
          <h5 className="card-title flex-grow-1 mb-0">
            <i className="ri-archive-2-line align-bottom me-1 text-muted"></i>{' '}
            {t('Package Details')}
          </h5>
        </div>
      </CardHeader>
      <CardBody>
        {/* <CardItem title="ID" value={packageData?.id} /> */}
        <CardItem title={t('Title')} value={packageData?.title} />
        <CardItem title={t('Description')} value={packageData?.description} />
        <CardItem title={t('Price')} value={packageData?.price + ' ' + t('KWD')} />
        <CardItem title={t('Duration')} value={packageData?.duration + ' ' + t('Months')} />
      </CardBody>
    </Card>
  )
}

export default Package
