// react imports
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Link } from 'react-router-dom'
// bootstrap elements
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown
} from 'reactstrap'

// reqired componnent
import BreadCrumb from 'Components/Common/BreadCrumb'
import IndexTable, { FilterItem, HeaderColumn } from 'Components/IndexTable'
// fetch hock logic
import FetchHock from 'helpers/FetchHock'

import CreateModal from '../components/CreateModal'
import UpdateModal from '../components/UpdateModal'

type form = {
  id: number
  name: string
}

const EcommerceOrders = () => {
  const { t } = useTranslation()

  document.title = 'Order forms | Sallatk'

  const fetchHock = FetchHock<form>({ path: '/forms' })

  const headerColumns: HeaderColumn[] = [
    {
      title: 'Name',
      accessor: 'name'
    },
    {
      title: 'Action'
    }
  ]

  const filterItems: FilterItem[] = [
    {
      type: 'search',
      col: 12,
      placeholder: t('Search ...'),
      action: (value) => fetchHock.search(value)
    }
  ]

  // create modal logic
  const [showCreate, changeShowCreate] = useState<boolean>(false)
  const openCreate = () => changeShowCreate(true)
  const closeCreate = () => changeShowCreate(false)

  // update modal logic
  const [itemIdUpdate, changeItemIdUpdate] = useState<number>()
  const [showUpdate, changeShowUpdate] = useState<boolean>(false)

  const openUpdate = () => changeShowUpdate(true)
  const closeUpdate = () => changeShowUpdate(false)

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />

        <Row>
          <Col lg={12}>
            <IndexTable
              title={t('Order forms')}
              addString={t('Add Form')}
              addAction={openCreate}
              headerColumns={headerColumns}
              {...fetchHock}
              filters={filterItems}>
              {fetchHock.items?.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>
                    <div className="hstack gap-3 flex-wrap">
                      <UncontrolledDropdown>
                        <DropdownToggle href="#" className="btn btn-secondary btn-sm" tag="button">
                          <i className="ri-more-fill" />
                        </DropdownToggle>
                        <DropdownMenu container={'body'} className="dropdown-menu-end">
                          <DropdownItem
                            tag={Link}
                            to={`/products/settings/forms/preview/${item.id}`}>
                            <i className="ri-eye-line me-2"></i>
                            {t('Preview')}
                          </DropdownItem>

                          <DropdownItem
                            onClick={() => {
                              openUpdate()
                              changeItemIdUpdate(item.id)
                            }}>
                            <i className="ri-edit-2-line me-2"></i>
                            {t('Edit')}
                          </DropdownItem>

                          <DropdownItem divider />
                          <DropdownItem onClick={() => fetchHock.openDelete(item.id)}>
                            <i className="ri-delete-bin-line me-2"></i>
                            {t('Delete')}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </td>
                </tr>
              ))}
            </IndexTable>
          </Col>
        </Row>
      </Container>
      {showCreate && (
        <CreateModal close={closeCreate} show={showCreate} onSuccess={fetchHock.fetch} />
      )}
      {showUpdate && (
        <UpdateModal
          close={closeUpdate}
          show={showUpdate}
          onSuccess={fetchHock.fetch}
          itemId={itemIdUpdate}
        />
      )}
    </div>
  )
}

export default EcommerceOrders
