import clsx from 'clsx'
import ReactSelect from 'react-select'

const Select = ({
  label,
  placeholder,
  options,
  model,
  error,
  onChange,
  value,
  className,
  components,
  disabled,
  wrapperClassName,
  labelClassName,
  horizontal
}: {
  label?: string
  placeholder?: string
  options?: { label: string; value: string }[]
  model?: any
  error?: any
  onChange?: (value: string) => void
  value?: any
  disabled?: boolean
  className?: string
  components?: any
  wrapperClassName?: string
  labelClassName?: string
  horizontal?: boolean
}) => {
  // id for input focus
  const randumId = Math.random().toString(36).substring(7)
  // name of input
  const name = model?.name

  function handleChange(select: any) {
    model &&
      model?.onChange({
        target: {
          name,
          value: select.value
        }
      })
    onChange && onChange(select.value)
  }
  return (
    <div className={clsx('mb-3', wrapperClassName, { row: horizontal })}>
      {!!label && (
        <label
          htmlFor={randumId}
          className={clsx('form-label', labelClassName, { 'col-md-3': horizontal })}>
          {label}
        </label>
      )}
      <div className={clsx({ 'col-md-9': horizontal })}>
        <ReactSelect
          placeholder={placeholder}
          className={clsx(
            'form-control react-select-container p-0',
            error ? 'is-invalid' : '',
            className
          )}
          classNamePrefix="react-select"
          defaultValue={options?.find((el) => el.value == value) ?? null}
          onChange={handleChange}
          options={options}
          components={components ? components : null}
          isDisabled={disabled}
        />
        <div className="invalid-feedback">{error?.message}</div>
      </div>
    </div>
  )
}

export default Select
