import Formula from 'ThemeManager/types/Formula/index'

const formula: Formula = [
  {
    title: 'Section heading',
    label: 'sectionHeading',
    type: 'translateString'
  },
  // {
  //   title: 'Class',
  //   label: 'className',
  //   type: 'string'
  // },
  {
    title: 'Show name',
    label: 'showName',
    type: 'checkBoox'
  }
]
export default formula
