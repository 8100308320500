// bootstrap elements
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown
} from 'reactstrap'
// react link
import { Link } from 'react-router-dom'
// reqired componnent
import BreadCrumb from 'Components/Common/BreadCrumb'
// fetch hock logic
import FetchHock from 'helpers/FetchHock'
// translation pakage
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import Loader from 'Components/IndexTable/partials/Loader'
import Error from 'Components/IndexTable/partials/Error'
import Empty from 'Components/IndexTable/partials/Empty'
import Pagination from 'Components/IndexTable/partials/Pagination'
import CreateModal from '../components/CreateModal'
import UpdateModal from '../components/UpdateModal'
import DeleteModal from 'Components/IndexTable/partials/DeleteModal'
import IndexTable from 'Components/IndexTable'

const MenuCustomLinksList = () => {
  document.title = 'Menu Custom Links | Sallatk'

  const { t } = useTranslation()

  // create modal logic
  const [itemIdUpdate, changeItemIdUpdate] = useState<number>()
  const [showUpdate, changeShowUpdate] = useState<boolean>(false)
  const [showCreate, changeShowCreate] = useState<boolean>(false)
  const [index, setIndex] = useState<any>(-1)

  const openCreate = () => changeShowCreate(true)
  const closeCreate = () => changeShowCreate(false)
  const openUpdate = () => changeShowUpdate(true)
  const closeUpdate = () => changeShowUpdate(false)

  const {
    items,
    loading,
    error,
    options,
    totalPages,
    totalItems,
    pagenate,
    openDelete,
    closeDelete,
    deleteItem,
    showedItems,
    deleteId,
    fetch
  } = FetchHock<MenuCustomLinks>({ path: '/menuCustomLinks' })

  const headerColumns: any[] = [
    {
      title: 'Id',
      accessor: 'id'
    },
    {
      title: 'Title',
      accessor: 'title'
    },
    {
      title: 'Text',
      accessor: 'text'
    },
    {
      title: 'Icon'
    },
    {
      title: 'Action'
    }
  ]

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />

        <Row>
          <Col lg={12}>
            <IndexTable
              title={t('Menu Custom Links')}
              addString={t('Add new Custom Link')}
              addAction={openCreate}
              headerColumns={headerColumns}
              loading={loading}
              error={error}
              options={options}
              totalPages={totalPages}
              totalItems={totalItems}
              showedItems={showedItems}
              pagenate={pagenate}
              deleteId={deleteId}
              closeDelete={closeDelete}
              deleteItem={deleteItem}
              // sort={sort}
              // filters={filterItems}
            >
              {items?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <h5 className="fs-14 mb-1">
                          <Link to={`/tickets/${item.id}`} className="text-body text-uppercase">
                            #{item.id}{' '}
                          </Link>
                        </h5>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p className="text-muted mb-1">{item.title}</p>
                  </td>
                  <td>
                    <p className="text-muted mb-1">{item.text}</p>
                  </td>
                  <td>
                    <i className={item.icon} />
                  </td>
                  <td>
                    <div className="hstack gap-3 flex-wrap">
                      <UncontrolledDropdown>
                        <DropdownToggle href="#" className="btn btn-secondary btn-sm" tag="button">
                          <i className="ri-more-fill" />
                        </DropdownToggle>
                        <DropdownMenu container={'body'} className="dropdown-menu-end">
                          {/* <DropdownItem tag={Link} to={`/tickets/${item.id}`}>
                            <i className="ri-eye-line me-2"></i>
                            {t('View')}
                          </DropdownItem> */}
                          <DropdownItem
                            tag={Link}
                            onClick={() => {
                              openUpdate()
                              changeItemIdUpdate(item.id)
                            }}>
                            <i className="ri-edit-2-line me-2"></i>
                            {t('Edit')}
                          </DropdownItem>

                          <DropdownItem divider />
                          <DropdownItem onClick={() => openDelete(item.id)}>
                            <i className="ri-delete-bin-line me-2"></i>

                            {t('Delete')}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </td>
                </tr>
              ))}
            </IndexTable>
          </Col>
        </Row>
      </Container>
      {showCreate && <CreateModal close={closeCreate} show={openCreate} onSuccess={fetch} />}
      {showUpdate && (
        <UpdateModal
          close={closeUpdate}
          show={openUpdate}
          onSuccess={fetch}
          itemId={itemIdUpdate}
        />
      )}
    </div>
  )
}

export default MenuCustomLinksList
