import { Button, Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Checkbox, Input, Select, Submit } from 'Components/Form'
import UpdateHock from 'helpers/UpdateHock'
import PopUp from 'Components/PopUp'
import { useEffect, useState } from 'react'
import { useFieldArray } from 'react-hook-form'

const UpdateModal = ({ close, show, onSuccess, itemId }: any) => {
  const { t } = useTranslation()
  const params = useParams()

  type InputType = {
    id?: number
    name: string
    placeholder: string
    type: 'text' | 'number' | 'textarea' | 'radio' | 'checkbox' | 'date' | 'time' | 'color'
    required: 0 | 1
    options: {
      name: string
    }[]
  }

  // prepare hock
  const { register, handleSubmit, errors, loading, getLoading, update, getValues, control } =
    UpdateHock<InputType>({
      path: `/form/${params.id}/inputs`,
      initValue: {
        name: '',
        placeholder: '',
        type: 'text',
        required: 0,
        options: [{ name: '' }]
      },
      editId: itemId
    })

  // options logic
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'options'
  })
  const addOption = () => append({ name: '' })
  const removeOption = (index: number) => remove(index)
  const [inputType, setInputType] = useState<string>()

  useEffect(() => setInputType(getValues('type')), [getValues('type')])

  const submit = async (data: InputType) => {
    const response = await update(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  return (
    <PopUp title={t('Update input')} show={show} close={close} loading={getLoading}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={12}>
              <Input
                label={t('Input name')}
                placeholder={t('Enter input name')}
                model={register('name', {
                  required: t('Input name is required'),
                  minLength: {
                    value: 3,
                    message: t('Input name must be bigger than or equal 3')
                  }
                })}
                error={errors.name}
              />
            </Col>
            <Col md={12}>
              <Input
                label={t('Placeholder')}
                placeholder={t('Enter Placeholder')}
                model={register('placeholder', {
                  required: t('Placeholder is required'),
                  minLength: {
                    value: 3,
                    message: t('Placeholder must be bigger than or equal 3')
                  }
                })}
                error={errors.placeholder}
              />
            </Col>
            <Col md={12}>
              <Select
                label={t('Type')}
                placeholder={t('Enter Type')}
                value={getValues('type')}
                options={[
                  {
                    label: t('Text'),
                    value: 'text'
                  },
                  {
                    label: t('Number'),
                    value: 'number'
                  },
                  {
                    label: t('Textarea'),
                    value: 'textarea'
                  },
                  {
                    label: t('Radio'),
                    value: 'radio'
                  },
                  {
                    label: t('Checkbox'),
                    value: 'checkbox'
                  },
                  {
                    label: t('Date'),
                    value: 'date'
                  },
                  {
                    label: t('Time'),
                    value: 'time'
                  },
                  {
                    label: t('Color'),
                    value: 'color'
                  }
                ]}
                onChange={(value) => setInputType(value)}
                model={register('type', {
                  required: t('Type is required')
                })}
                error={errors.type}
              />
            </Col>
            <Col md={12}>
              <Checkbox
                label={t('Required')}
                model={register('required')}
                error={errors.required}
              />
            </Col>
            {(inputType == 'checkbox' || inputType == 'radio') && (
              <Col md={12}>
                <div className="mb-3">
                  <label className="form-label">{t('Options')}</label>

                  {fields.map((field, index) => (
                    <div className={'d-flex mb-2'}>
                      <input
                        key={field.id}
                        className={'form-control'}
                        placeholder={t('Enter option')}
                        {...register(`options.${index}.name`, {
                          required: t('option is required')
                        })}
                      />
                      <button
                        className="btn btn-outline-danger ms-2"
                        type="button"
                        disabled={fields.length <= 1}
                        onClick={() => removeOption(index)}>
                        <i className="ri-close-line align-middle"></i>
                      </button>
                    </div>
                  ))}

                  <div>
                    <button className="btn btn-secondary w-100" type="button" onClick={addOption}>
                      <i className="ri-add-line align-middle"></i> {t('Add option')}
                    </button>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Container>
        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Update')} />
        </div>
      </form>
    </PopUp>
  )
}
export default UpdateModal
