import { Badge } from 'reactstrap'
import DynamicContent from './DynamicContent'
import SimpleBar from 'simplebar-react'

function DetailContent({ sidebarData, sidebarClose }) {
  return (
    <div className="file-manager-detail-content p-3 py-0 border">
      <SimpleBar className="mx-n3 pt-3 px-3 file-detail-content-scroll">
        <div id="file-overview" className="h-100">
          <div className="d-flex h-100 flex-column">
            <div className="d-flex align-items-center pb-3 border-bottom border-bottom-dashed mb-3 gap-2">
              <h5 className="flex-grow-1 fw-semibold mb-0">File Preview</h5>
              <div>
                <button
                  type="button"
                  className="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-overview"
                  onClick={() => sidebarClose('file-detail-show')}>
                  <i className="ri-close-fill align-bottom"></i>
                </button>
              </div>
            </div>

            <div className="pb-3 border-bottom border-bottom-dashed mb-3">
              <div className="file-details-box bg-light p-3 text-center rounded-3 border border-light mb-3">
                <div className="display-4 file-icon">
                  <DynamicContent content={sidebarData} />
                </div>
              </div>

              <h5 className="fs-16 mb-1 file-name">{sidebarData?.title}</h5>
            </div>
            <div>
              <h5 className="fs-12 text-uppercase text-muted mb-3">File Description :</h5>

              <div className="table-responsive">
                <table className="table table-borderless table-nowrap table-sm">
                  <tbody>
                    <tr>
                      <th scope="row" style={{ width: '35%' }}>
                        title :
                      </th>
                      <td className="file-name">{sidebarData?.title}</td>
                    </tr>
                    <tr>
                      <th scope="row" style={{ width: '35%' }}>
                        description :
                      </th>
                      <td className="file-name">{sidebarData?.description}</td>
                    </tr>
                    <tr>
                      <th scope="row">type :</th>
                      <td className="file-type">{sidebarData?.type}</td>
                    </tr>
                    <tr>
                      <th scope="row">duration :</th>
                      <td className="file-size">{sidebarData?.duration}</td>
                    </tr>
                    <tr>
                      <th scope="row">active :</th>
                      <td className="create-date">
                        {sidebarData?.active === 1 ? (
                          <Badge color="success">Active</Badge>
                        ) : (
                          <Badge color="danger">Inactive</Badge>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">free :</th>
                      <td className="create-date">
                        {sidebarData?.free === 1 ? (
                          <Badge color="success">Yes</Badge>
                        ) : (
                          <Badge color="danger">No</Badge>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="mt-auto border-top border-top-dashed py-3">
              <div className="hstack gap-2">
                <button
                  onClick={() => window.open(sidebarData?.stream, '_blank')}
                  type="button"
                  className="btn btn-soft-primary w-100">
                  <i className="ri-arrow-right-up-line align-bottom me-1"></i> open
                </button>
                {/* <button
                    type="button"
                    className="btn btn-soft-danger w-100 remove-file-overview"
                    // onClick={() => onClickFileDelete(sidebarData)}
                  >
                    <i className="ri-close-fill align-bottom me-1"></i> Delete
                  </button> */}
              </div>
            </div>
          </div>
        </div>
      </SimpleBar>
    </div>
  )
}

export default DetailContent
