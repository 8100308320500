// react imports
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
// bootstrap elements
import { Card, CardBody } from 'reactstrap'
// fetch hock logic
import ShowHock from 'helpers/ShowHock'
import ImageWithFallback from 'helpers/ImageComponent'

const ShippingCompanyCard = () => {
  const params = useParams()
  const { t } = useTranslation()

  interface shippingCompany {
    id: number
    web_logo?: string
    title: string
    is_active: boolean
  }

  const { item, loading } = ShowHock<shippingCompany>('/shippingCompanies/offline/' + params.id)

  return (
    <Card>
      <CardBody>
        <div>
          <div className="d-flex align-items-center">
            <ImageWithFallback src={item?.web_logo ?? ''} alt={item?.title} height={55} />
            <div className="flex-grow-1 ms-2">
              <h5 className="card-title mb-1">{item?.title}</h5>
              {!!item?.is_active && (
                <span className="badge bg-success align-middle fs-10 ">{t('Active')}</span>
              )}
              {!item?.is_active && (
                <span className="badge bg-info align-middle fs-10 ">{t('Not active')}</span>
              )}
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default ShippingCompanyCard
