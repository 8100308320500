import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { Switch } from 'Components/Form'
import linkGenerator from 'helpers/linkGenerator'

import { useTranslation } from 'react-i18next'

function CODPaymentMethod({ company, onSuccess }) {
  const { t } = useTranslation()
  const CODData = company?.COD

  const { mutate, isPending, variables } = useMutation({
    mutationFn: async () => await axios.put(linkGenerator(`/payments/${CODData?.method_id}`), {}),
    onSettled: async () => {
      await onSuccess()
    }
  })

  return (
    <div className="mt-4 d-flex">
      <Switch
        loading={isPending}
        checked={CODData?.active}
        label={t('Allow COD Payment')}
        onChange={() => mutate()}
      />
    </div>
  )
}

export default CODPaymentMethod
