import React from 'react'
// translation pakage
import { useTranslation } from 'react-i18next'

const Empty = ({ title = null, description = null }) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <div className="mt-3 mb-3 text-center">
        <i className="ri-inbox-line display-5 text-danger"></i>
        <div className="mt-2 pt-2 fs-15 mx-4 mx-sm-5">
          <h4>{title ? title : t('There are no data')}</h4>
          <p className="text-muted mx-4 mb-0">
            {description ? description : t('Please Try add items and try again')}
          </p>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Empty
