import { useTranslation } from 'react-i18next'
import { UncontrolledCollapse } from 'reactstrap'
import { useProductsMultiFilters } from './Brand'

function Rating() {
  const { t } = useTranslation()

  const { list: ratings, selected, filterByAction } = useProductsMultiFilters('ratings')
  console.log(ratings)

  return (
    <div className="accordion-item">
      <h2 className="accordion-header">
        <button
          className="accordion-button bg-transparent shadow-none collapsed"
          type="button"
          id="flush-headingRating">
          <span className="text-muted text-uppercase fs-12 fw-medium">{t('Rating')}</span>
        </button>
      </h2>
      <UncontrolledCollapse toggler="#flush-headingRating">
        <div
          id="flush-collapseRating"
          className="accordion-collapse collapse show"
          aria-labelledby="flush-headingRating">
          <div className="accordion-body text-body">
            <div className="d-flex flex-column gap-2">
              {Array.isArray(ratings) && ratings.length > 0 ? (
                ratings
                  .sort((a, b) => b.id - a.id)
                  .map((rat, index) => (
                    <div className="form-check" key={index}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={'rating' + rat.rating}
                        checked={selected.includes(rat.rating)}
                        onClick={() => filterByAction(rat)}
                      />
                      <label className="form-check-label" htmlFor={'rating' + rat?.rating}>
                        <span className="text-muted">
                          {[...Array(rat.rating)].map((_, index) => (
                            <i key={index} className="mdi mdi-star text-warning"></i>
                          ))}
                          {[...Array(5 - rat.rating)].map((_, index) => (
                            <i key={index} className="mdi mdi-star"></i>
                          ))}
                        </span>
                        {'  '}
                        {rat.rating}
                      </label>
                    </div>
                  ))
              ) : (
                <p className="text-muted">No ratings</p>
              )}
            </div>
          </div>
        </div>
      </UncontrolledCollapse>
    </div>
  )
}

export default Rating
