import { useEffect, useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { setAuthorization } from 'helpers/Auth'
import { Search, Submit, Input } from 'Components/Form'

import { UseProfile } from 'helpers/UserHooks'
import PopUp from 'Components/PopUp'
import { useTranslation } from 'react-i18next'
import CreateHook from 'helpers/CreateHook'

const AuthProtected = (props: any) => {
  const { userProfile, token } = UseProfile()
  const { t } = useTranslation()
  const navigate = useNavigate()
  if (token) setAuthorization(token)
  useEffect(() => {
    if (token) setAuthorization(token)
  }, [token, userProfile])

  if (!userProfile && !token) return <Navigate to={{ pathname: '/login' }} />
  else return <>{props.children}</>
}

export default AuthProtected
