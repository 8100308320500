import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, Collapse, Card, CardBody, CardHeader, Button } from 'reactstrap'
import SimpleBar from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'
import clsx from 'clsx'
import { Input } from 'Components/Form'
import FetchHock from 'helpers/FetchHock'
import Loader from 'Components/Common/Loader'

const PermissionsCard = ({ value = [], onChange }) => {
  const { t } = useTranslation()
  const {
    items: permissions,
    search,
    isFetching,
    loading
  } = FetchHock<any>({
    path: '/permissions',
    limit: 10000
  })
  const [selectedPermissions, setSelectedPermissions] = useState(new Set(value))
  const [expandedItems, setExpandedItems] = useState({})
  const addItem = (item) => {
    setSelectedPermissions((prev) => new Set(prev).add(item))
  }
  const addItems = (items) => {
    setSelectedPermissions((prev) => {
      const next = new Set(prev)
      items.forEach((item) => next.add(item))
      return next
    })
  }

  const removeItem = (item) => {
    setSelectedPermissions((prev) => {
      const next = new Set(prev)
      next.delete(item)
      return next
    })
  }
  const removeItems = (items) => {
    setSelectedPermissions((prev) => {
      const next = new Set(prev)
      items.forEach((item) => next.delete(item))
      return next
    })
  }

  const handleMainCheckboxChange = (checked, subPermissions) => {
    const subPermissionIds = subPermissions.map((sp) => sp.id)
    if (checked) addItems(subPermissionIds)
    else removeItems(subPermissionIds)
  }
  const calculateIfAllSubPermissionsSelected = (subPermissions) => {
    const subPermissionIds = subPermissions.map((sp) => sp.id)
    return subPermissionIds.some((id) => selectedPermissions.has(id))
  }

  const handleToggle = (idx) => {
    setExpandedItems((prev) => ({ ...prev, [idx]: !prev[idx] }))
  }

  const collapseAll = () => {
    const collapsed = {}
    permissions.forEach((_, idx) => {
      collapsed[idx] = false
    })
    setExpandedItems(collapsed)
  }

  const expandAll = () => {
    const expanded = {}
    permissions.forEach((_, idx) => {
      expanded[idx] = true
    })
    setExpandedItems(expanded)
  }

  useEffect(() => {
    onChange(Array.from(selectedPermissions))
  }, [selectedPermissions])

  return (
    <Card>
      <CardHeader>
        <h5 className="card-title mb-0">{t('Permissions List ')}</h5>
      </CardHeader>
      <CardBody>
        <Row className={clsx(isFetching && 'opacity-75')}>
          <Row className="">
            <Col lg={12}>
              <Input placeholder={t('Search')} onChange={(value) => search(value)} />
              <div className="float-end mb-2">
                <Button
                  type="button"
                  color="link"
                  onClick={(e) => {
                    e.preventDefault()
                    collapseAll()
                  }}>
                  {t('Collapse All ')}
                </Button>
                <span className="text-primary">|</span>
                <Button
                  type="button"
                  color="link"
                  onClick={(e) => {
                    e.preventDefault()
                    expandAll()
                  }}>
                  {t('Expand All')}
                </Button>
              </div>
            </Col>
          </Row>

          {loading ? (
            <Loader />
          ) : (
            permissions?.map((permission, idx) => (
              <Col key={idx} md={4}>
                <div className="shadow-lg p-0 mb-3 d-inline-block position-relative w-100">
                  <div className="bg-secondary rounded-top">
                    <div className="d-flex align-items-center p-3">
                      <h6
                        onClick={() => handleToggle(idx)}
                        className="m-0 fs-16 flex-grow-1 fw-semibold text-dark cursor-pointer">
                        {permission.name}
                      </h6>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={calculateIfAllSubPermissionsSelected(permission.permissions)}
                        onChange={(e) =>
                          handleMainCheckboxChange(e.target.checked, permission.permissions)
                        }
                      />
                    </div>
                  </div>
                  <Collapse isOpen={expandedItems[idx]}>
                    <SimpleBar className=" p-3">
                      <div className="d-flex flex-column gap-3">
                        {permission?.permissions?.map((subPermission, index) => (
                          <div key={index} className={clsx('form-check')}>
                            <input
                              id={subPermission.id}
                              className="form-check-input"
                              type="checkbox"
                              key={subPermission.id}
                              value={subPermission.id}
                              checked={selectedPermissions?.has(subPermission.id)}
                              onChange={(e) => {
                                if (e.target.checked) addItem(subPermission.id)
                                else removeItem(subPermission.id)
                              }}
                            />
                            <label htmlFor={subPermission.id} className="form-check-label">
                              {subPermission.title}
                            </label>
                          </div>
                        ))}
                      </div>
                    </SimpleBar>
                  </Collapse>
                </div>
              </Col>
            ))
          )}
        </Row>
      </CardBody>
    </Card>
  )
}

export default PermissionsCard
