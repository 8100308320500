import { useState } from 'react'
import { Card, CardBody, Container } from 'reactstrap'
import { Category, Product } from 'types'
import { POSCategories } from './components/POSCategories'
import { POSCheckout } from './components/POSCheckout'
import { POSPaymentMethod } from './components/POSPaymentMethod'
import { POSPopup } from './components/POSPopup'
import { POSProducts } from './components/POSProducts'
import { POSSearch } from './components/POSSearch'
import { POSSubmitOrder } from './components/POSSubmitOrder'
import { POSContext } from './POSContext'

export const POS = () => {
  const [searchInput, setSearchInput] = useState<string>('')
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null)
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null)
  const [selectedProducts, setSelectedProducts] = useState<Product[] | null>([])
  const [popupIsOpen, setPopupIsOpen] = useState<boolean>(false)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [invoiceId, setInvoiceId] = useState<string | null>(null)

  // useEffect(() => {
  //   requestFullscreen()

  //   setTimeout(() => {
  //     hideSidebar()
  //   }, 500)
  // }, [])

  const addProductToCart = (product: Product) => {
    setSelectedProducts([...selectedProducts, product])
  }
  const removeProductFromCart = (product: Product) => {
    setSelectedProducts(selectedProducts.filter((p) => p.id !== product.id))
  }

  return (
    <POSContext.Provider
      value={{
        searchInput,
        setSearchInput,
        selectedCategory,
        setSelectedCategory,
        selectedProduct,
        setSelectedProduct,
        selectedProducts,
        setSelectedProducts,
        addProductToCart,
        removeProductFromCart,
        popupIsOpen,
        setPopupIsOpen,
        paymentMethod,
        setPaymentMethod,
        invoiceId,
        setInvoiceId
      }}>
      <div
        className="page-content"
        style={{
          backgroundColor: 'var(--vz-light)',
          height: '100vh'
        }}>
        {popupIsOpen && <POSPopup />}
        <Container fluid className="h-100">
          <div className="row h-100">
            <div className="col-md-7 d-flex flex-column">
              <POSSearch />
              <POSCategories />
              <POSProducts />
            </div>
            <div className="col d-flex flex-column">
              <Card className="flex-grow-1 mb-0" style={{ height: '1px' }}>
                <CardBody className="overflow-auto d-flex flex-column">
                  <POSCheckout />
                  <div className="mt-auto d-flex gap-3 mt-3 flex-column">
                    <POSPaymentMethod />
                    <POSSubmitOrder />
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </Container>
      </div>
    </POSContext.Provider>
  )
}
