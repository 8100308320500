import { Input, Submit } from 'Components/Form'
import Empty from 'Components/IndexTable/partials/Empty'
import PopUp from 'Components/PopUp'
import UpdateHock from 'helpers/UpdateHock'
import { removeEmptyValues, transformDataBeforeSubmit } from 'pages/settings/helper/helper'
import { useTranslation } from 'react-i18next'
import { Button, Col, Container, Row } from 'reactstrap'
import { SettingsGroup, SettingsItem } from '../list'

const CreateModal = ({
  close,
  show,
  onSuccess,
  data
}: {
  close: any
  show: any
  onSuccess: any
  data: SettingsGroup
}) => {
  const { t } = useTranslation()

  // Create hook for submitting the form
  const {
    update,
    loading: submitLoading,
    register,
    errors,
    handleSubmit
  } = UpdateHock({
    path: `/settings-cstm`,
    allowGet: false
  })

  const submit = async (data) => {
    const dataWithoutNulls = removeEmptyValues(data)
    if (!dataWithoutNulls) return
    const dataWithoutNullsTransformed = transformDataBeforeSubmit(dataWithoutNulls)

    const response = await update(dataWithoutNullsTransformed)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  // Render form elements dynamically
  const renderFormElement = (field: SettingsItem) => {
    switch (field.type) {
      case 'text':
      case 'number':
        return (
          <Input
            label={t(field.key)}
            type={field.type}
            placeholder={t('Enter ') + t(field.key)}
            model={register(field.key, {
              required: true,
              value: field?.value
            })}
            error={errors[field.key]}
          />
        )

      default:
        return <p>{field?.key}</p>
    }
  }

  const formFields = data.items || []

  return (
    <PopUp title={t('Settings')} show={show} close={close}>
      {(() => {
        if (formFields.length === 0) {
          return <Empty description={' '} />
        }

        return (
          <form onSubmit={handleSubmit(submit)}>
            <Container fluid>
              <Row>
                {formFields.map((field, idx) => (
                  <Col key={idx} md={12}>
                    {renderFormElement(field)}
                  </Col>
                ))}
              </Row>
            </Container>

            <div className="hstack gap-2 justify-content-end">
              <Button
                type="button"
                className="btn btn-link btn-secondary fw-medium"
                outline
                onClick={close}>
                <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
              </Button>
              <Submit loading={submitLoading} label={t('Save')} />
            </div>
          </form>
        )
      })()}
    </PopUp>
  )
}
export default CreateModal
