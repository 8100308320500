import { Input } from 'Components/Form'

import UpdateHock from 'helpers/UpdateHock'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Col, Form, Row } from 'reactstrap'

function ChangePasswordForm() {
  const { t } = useTranslation()
  const { register, update, handleSubmit, errors, reset } = UpdateHock<any>({
    path: '/profile/update-password',
    allowGet: false
  })

  const submit = async (data: any) => {
    const response = await update(data)
    if (response.status) {
      reset()
    }
  }

  return (
    <Form onSubmit={handleSubmit(submit)}>
      <Row className="g-2">
        <Col lg={6}>
          <Input
            type="password"
            label={t('Old Password')}
            model={register('old_password', {
              required: t('Old Password is required')
            })}
            placeholder="Enter Old Password"
            error={errors.old_password}
          />
        </Col>

        <Col lg={6}>
          <Input
            type="password"
            label={t('New Password')}
            placeholder="Enter New Password"
            model={register('password', {
              required: t('New Password is required')
            })}
            error={errors.password}
          />
        </Col>

        <Col lg={12}>
          <div className="text-end">
            <button type="submit" className="btn btn-primary">
              Change Password
            </button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default ChangePasswordForm
