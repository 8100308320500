import Loader from 'Components/Common/Loader'
import { Input, Select } from 'Components/Form'
import FetchHock from 'helpers/FetchHock'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { FieldErrors, UseFormGetValues, UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface PhoneContextType {
  regExp?: string
  setRegExp?: (regExp: string) => void
  register: UseFormRegister<any>
  errors: FieldErrors
  getValues?: UseFormGetValues<any>
}

const PhoneContext = createContext<PhoneContextType | null>(null)

interface PhoneFieldProps {
  children: ReactNode
  register: UseFormRegister<any>
  errors: FieldErrors
  getValues?: UseFormGetValues<any>
}

function PhoneField({ children, register, errors, getValues }: PhoneFieldProps) {
  const [regExp, setRegExp] = useState<string>('')

  return (
    <PhoneContext.Provider value={{ register, errors, getValues, regExp, setRegExp }}>
      {children}
    </PhoneContext.Provider>
  )
}

export default PhoneField

PhoneField.Code = () => {
  const { t } = useTranslation()
  const context = useContext(PhoneContext)

  if (!context?.register) return <p className="text-danger">Can't view component please refresh</p>

  const { register, errors, setRegExp, getValues } = context

  const { items, loading } = FetchHock<{
    country: string
    dialing_code: string
    id: number
    reg_exp: string
  }>({
    path: '/dialling-codes',
    limit: 100
  })

  useEffect(() => {
    const dialingCode = getValues('dialing_code')
    if (dialingCode && items?.length) {
      const item = items.find(({ dialing_code }) => dialing_code === dialingCode)
      if (item) setRegExp?.(item.reg_exp)
    }
  }, [items, getValues])

  if (loading) {
    return <Loader />
  }

  return (
    <Select
      label={t('Dialing Code')}
      placeholder={`${t('Select')} ${t('Code')}`}
      model={register('dialing_code', {
        required: `${t('Dialing Code')} ${t('is required')}`
      })}
      options={items.map(({ dialing_code }) => ({
        value: dialing_code,
        label: dialing_code
      }))}
      error={errors.dialing_code}
      onChange={(e) => {
        const item = items.find(({ dialing_code }) => dialing_code === e)
        if (item) setRegExp?.(item.reg_exp)
      }}
      value={getValues?.('dialing_code')}
    />
  )
}

PhoneField.Number = () => {
  const { t } = useTranslation()
  const context = useContext(PhoneContext)

  if (!context?.register) return null

  const { register, errors, regExp } = context

  return (
    <Input
      type="number"
      label={t('Phone')}
      placeholder={`${t('Enter')} ${t('Phone')}`}
      model={register('phone', {
        required: `${t('Phone')} ${t('is required')}`,
        pattern: regExp
          ? {
              value: new RegExp(regExp),
              message: t('Invalid phone number')
            }
          : undefined
      })}
      error={errors.phone}
    />
  )
}
