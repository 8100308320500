import { Button, Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Input, Submit, Select } from 'Components/Form'
import CreateHook from 'helpers/CreateHook'
import PopUp from 'Components/PopUp'
import { useParams } from 'react-router-dom'
import { MethodOptions } from './UpdateModal'

type CreateBranchContact = Omit<BranchContact, 'id' | 'branch_name'>

const CreateModal = ({ close, show, onSuccess }: any) => {
  const { t } = useTranslation()
  const { id } = useParams()

  // prepare hock
  const { register, handleSubmit, errors, create, loading, closeUploading, openUploading } =
    CreateHook<CreateBranchContact>('/branch/contact', {
      branch_id: Number(id),
      method: '',
      value: ''
    })

  const submit = async (data: CreateBranchContact) => {
    const response = await create(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  return (
    <PopUp title={`${t('Create')} ${t('Contact')}`} show={show} close={close}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={12}>
              <Select
                label={t('Contact Method')}
                model={register('method', {
                  required: `${t('Contact Method')} ${t('is required')}`
                })}
                error={errors?.method}
                options={MethodOptions}
              />
            </Col>
            <Col md={12}>
              <Input
                label={t('Value')}
                placeholder={t('Enter') + ' ' + t('Value')}
                model={register('value', {
                  required: t('Value is required')
                })}
                error={errors.value}
              />
            </Col>
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Create')} />
        </div>
      </form>
    </PopUp>
  )
}
export default CreateModal
