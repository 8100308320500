import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col, Row } from 'reactstrap'

function ButtonCards({ data }) {
  const { t } = useTranslation()

  return (
    <>
      <Row className="mt-4">
        {data?.map((i, key: number) => {
          return (
            <Col key={key} xl={3} lg={4} md={6} sm={6} className="col">
              <Card className="ribbon-box right overflow-hidden">
                <CardBody className="text-center p-4">
                  <i className={`${i.icon} fs-1 btn-secondary`}></i>
                  <h5 className="mb-1 mt-4">
                    <Link to={i?.url} className="link-primary">
                      {t(i.name)}
                    </Link>
                  </h5>
                </CardBody>
              </Card>
            </Col>
          )
        })}
      </Row>
    </>
  )
}

export default ButtonCards
