import { useContext } from 'react'
import { useAddToCart } from 'services/pos/cart'
import { Product } from 'types'
import { POSContext } from '../POSContext'
import ImageWithFallback from 'helpers/ImageComponent'

type Props = {
  product: Product
}

export const POSSingleProduct = ({ product }: Props) => {
  const { setSelectedProduct, setPopupIsOpen } = useContext(POSContext)
  const { mutateAsync: addToCart } = useAddToCart()

  const selectProduct = () => {
    const shouldOpenPopup = product.has_features || product.has_form
    if (!shouldOpenPopup) {
      addToCart({
        product_id: product.id,
        quantity: 1
      })
      return
    }
    setSelectedProduct(product)
    setPopupIsOpen(true)
  }

  return (
    <div
      onClick={selectProduct}
      className={`cursor-pointer d-flex flex-column gap-3 align-items-center justify-content-center px-3 py-2 rounded border border-2 h-fit`}
      style={{ minWidth: '120px', maxWidth: '120px', height: '110px' }}>
      <ImageWithFallback
        src={product.web_main_image}
        className=""
        style={{ width: '50px', aspectRatio: '1/1' }}
      />
      <p className="text-center text-capitalize mb-0 text-ellipsis">{product.title}</p>
    </div>
  )
}
