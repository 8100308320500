import placeholder from './placeholder.png'
import Config from 'ThemeManager/types/Config'
import formula from './Formula'
import DefaultValue from './DefaultValue'

const config: Config = {
  title: 'Support section',
  placeholder: placeholder,
  form: formula,
  default: DefaultValue
}
export default config
