
type Props = {
  className?: string
  children?: any
}

export const Legend = ({ className, children, ...rest }: Props) => {
  return (
    <legend className={`fs-6 fw-medium ${className}`} {...rest}>
      {children}
    </legend>
  )
}
