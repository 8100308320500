import { Badge, Card, CardBody, UncontrolledCollapse } from 'reactstrap'
import Brand, { useProductsMultiFilters } from './Brand'
import Categories from './Categories'
import Features from './Features'
import Price from './Price'
import Rating from './Rating'
import { useProductContext } from '../../Context'
// import { Input } from 'Components/Form'
import { Input } from 'reactstrap'
import { useTranslation } from 'react-i18next'
type BrandType = {
  id: number
  web_image: string
  mobile_image: string
  published: number
  name: string
  description: string
  total: number
  filter_key: string
}

type Category = {
  id: number
  title: string
  parent_id: number | null
  total: number
  filter_key: string
}

type BadgeType = {
  id: number
  title: string
  total: number | null
}

type ProductType = {
  id: number
  title: string
  description: string
  code: string
  filter_key: string
  total: number | null
}

type Rating = {
  rating: number | null
  id: number | null
  total: number
  filter_key: string
}

type Tag = {
  id: number
  title: string
  total: number
  filter_key: string
}

type PriceRange = {
  min: number
  max: number
}

type Feature = {
  id: number
  feature_id: number
  feature_title: string
  type: string
  title: string
  color: string | null
  total: number
  filter_key: string
}

type FiltersData = {
  brands: BrandType[]
  categories: Category[]
  badges: BadgeType[]
  product_types: ProductType[]
  ratings: Rating[]
  tags: Tag[]
  prices: PriceRange
  features: Feature[]
}
type FilterItem =
  | BrandType
  | Category
  | BadgeType
  | ProductType
  | Rating
  | Tag
  | PriceRange
  | Feature

const Filters = ({ filterKey }) => {
  const { list, info, selected, filterByAction } = useProductsMultiFilters(filterKey)
const t = useTranslation().t
  const hasChecked = selected?.length > 0
  return (
    <div className="accordion accordion-flush">
      <Card className="mb-0">
        <CardBody className="mb-0">
          <p
            id={filterKey + 'toggler'}
            className=" text-uppercase fs-14 fw-medium mb-0 cursor-pointer">
            {t(info?.label)}
          </p>
          <UncontrolledCollapse defaultOpen={hasChecked} toggler={'#' + filterKey + 'toggler'}>
            <ul className="list-unstyled mb-0 filter-list mt-3">
              <FilterItem
                list={list}
                filterKey={filterKey}
                selected={selected}
                filterByAction={filterByAction}
              />
            </ul>
          </UncontrolledCollapse>
        </CardBody>
      </Card>
    </div>
  )
}

function FilterItem({ list, filterKey, selected, filterByAction }) {
  return (
    <>
      <ul className="list-unstyled mb-0 filter-list mt-3">
        {Array.isArray(list) &&
          list?.map((filter: any, index) => {
            if (filter?.items) {
              return (
                <li key={index}>
                  {filter?.title}
                  <FilterItem
                    list={filter.items}
                    filterKey={filterKey}
                    selected={selected}
                    filterByAction={filterByAction}
                  />
                </li>
              )
            }
            return (
              <li key={index}>
                <div className="form-check d-flex mb-2" key={index}>
                  <div className="flex-grow-1">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={filter?.filter_key + filter?.id}
                      checked={selected?.includes(filter.id)}
                      onChange={() => filterByAction(filter)}
                    />
                    <label
                      className="form-check-label font-weight-normal !important"
                      htmlFor={filter?.filter_key + filter?.id}>
                      {filterKey === 'ratings' && (
                        <span className="text-muted">
                          {[...Array(filter.rating)].map((_, index) => (
                            <i key={index} className="mdi mdi-star text-warning"></i>
                          ))}
                          {[...Array(5 - filter.rating)].map((_, index) => (
                            <i key={index} className="mdi mdi-star"></i>
                          ))}
                        </span>
                      )}
                      {filter?.title || filter?.name}
                    </label>
                  </div>
                  <div className="flex-shrink-0 ms-2">
                    <span className="badge bg-light text-muted">{filter?.total}</span>
                  </div>
                </div>
              </li>
            )
          })}
      </ul>
    </>
  )
}
function FilterComponents() {
  const { filters, products } = useProductContext() as {
    filters: FiltersData
    products: any
  }

  return (
    <div className="d-flex flex-column gap-2">
      <CardBody>
        <Input
          type="text"
          className="form-control"
          placeholder="Search"
          onChange={(e) => products.search(e.target.value)}
          defaultValue={products?.options.term}
        />
      </CardBody>

      <Price />
      {Object.keys(filters).map((key, index) => {
        if (key === 'prices') return null
        return <Filters key={index} filterKey={key} />
      })}
    </div>
  )
  return (
    <div className="accordion accordion-flush">
      <div className="card-body border-bottom">
        <Categories />
      </div>
      <Price />
      <Brand />
      <Features />
      {/* <div className="accordion-item">
        <h2 className="accordion-header">
          <button
            className="accordion-button bg-transparent shadow-none collapsed"
            type="button"
            id="flush-headingDiscount">
            <span className="text-muted text-uppercase fs-12 fw-medium">{t('Discount')}</span>
          </button>
        </h2>
        <UncontrolledCollapse toggler="#flush-headingDiscount">
          <div id="flush-collapseDiscount" className="accordion-collapse collapse show">
            <div className="accordion-body text-body pt-1">
              <div className="d-flex flex-column gap-2">
                {discounts.map((discount, index) => (
                  <div className="form-check" key={index}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={'discount' + discount.id}
                    />
                    <label className="form-check-label" htmlFor={'discount' + discount.id}>
                      {discount.title}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </UncontrolledCollapse>
      </div> */}
      <Rating />
      <Badge data={filters?.badges} />
    </div>
  )
}

export default FilterComponents
