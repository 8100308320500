import { Col, Container, Row } from 'reactstrap'
import Widget from './Widgets'
import RecentActivity from './RecentActivity'
import Section from './Section'
import RecentOrders from './RecentOrders'
import BestSellingProducts from './BestSellingProducts'
import StoreVisits from './StoreVisits'
import Revenue from './Revenue'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const DashboardEcommerce = () => {
  const { t } = useTranslation()
  document.title = 'Dashboard | Sallatk'

  const [rightColumn, setRightColumn] = useState(true)
  const toggleRightColumn = () => {
    setRightColumn(!rightColumn)
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col>
            <div className="h-100">
              <Row>
                <Section rightClickBtn={toggleRightColumn} />
              </Row>
              <Row>
                <Widget />
              </Row>
              <Row>
                <Col xl={8}>
                  <Revenue />
                </Col>
                <Col xl={4}>
                  <StoreVisits />
                </Col>
              </Row>
              <Row>
                <Col xl={6}>
                  <BestSellingProducts />
                </Col>
                <Col xl={6}>
                  <RecentOrders />
                </Col>
              </Row>
            </div>
          </Col>
          <RecentActivity rightColumn={rightColumn} hideRightColumn={toggleRightColumn} />
        </Row>
      </Container>
    </div>
  )
}

export default DashboardEcommerce
