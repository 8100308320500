// react imports
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import classnames from 'classnames'
// bootstrap elements
import { Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
// reqired componnent
import BreadCrumb from 'Components/Common/BreadCrumb'
import ShippingCompanies from './components/shippingCompanies'

const Shipping = () => {
  const { t } = useTranslation()

  document.title = t('Shipping Companies') + ' | Sallatk'


  // Custom Tabs Bordered
  const [customNav, setcustomNav] = useState<string>('1')
  const customNavtoggle = (tab: string) => setcustomNav(tab)

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <Nav className="nav-tabs mb-0 border-0">
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={classnames({ active: customNav === '1' })}
              onClick={() => customNavtoggle('1')}>
              <i className="las la-shipping-fast me-2"></i>
              {t('Shipping Companies')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={classnames({ active: customNav === '2' })}
              onClick={() => customNavtoggle('2')}>
              <i className="las la-map me-2"></i>
              {t('Shipping gates')}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={customNav} className="text-muted">
          <TabPane tabId="1">
            <ShippingCompanies />
          </TabPane>

          <TabPane tabId="2">
            <div className="d-flex">
              <div className="flex-shrink-0">
                <i className="ri-checkbox-circle-fill text-success"></i>
              </div>
              <div className="flex-grow-1 ms-2">
                In some designs, you might adjust your tracking to create a certain artistic effect.
                It can also help you fix fonts that are poorly spaced to begin with.
              </div>
            </div>
            <div className="d-flex mt-2">
              <div className="flex-shrink-0">
                <i className="ri-checkbox-circle-fill text-success"></i>
              </div>
              <div className="flex-grow-1 ms-2">
                A wonderful serenity has taken possession of my entire soul, like these sweet
                mornings of spring which I enjoy with my whole heart.
              </div>
            </div>
          </TabPane>
        </TabContent>
      </Container>
    </div>
  )
}

export default Shipping
