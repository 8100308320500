// react includes
import { useState } from 'react'
// botstrap elemnts
import { Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
// translation pakage
import { useTranslation } from 'react-i18next'

type tab = {
  title: string
  icon: string
  action?: () => void
}

const SliderNav = ({ tabs }: { tabs: tab[] }) => {
  // translation function
  const { t } = useTranslation()
  // taps state
  const [activeTab, setActiveTab] = useState<number>(0)
  // toggle tap options
  const toggleTab = (tab: any, index: number) => {
    if (activeTab !== index) {
      if (tab.action) tab.action()
      setActiveTab(index)
    }
  }

  return (
    <Nav className="nav-tabs nav-tabs-custom nav-primary mb-3" role="tablist">
      {tabs.map((tab, index) => (
        <NavItem key={index}>
          <NavLink
            className={classnames({ active: activeTab === index })}
            onClick={() => toggleTab(tab, index)}
            href="#">
            <i className={tab.icon + ' me-1 align-bottom'}></i>
            {t(tab.title)}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  )
}

export default SliderNav
