import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Col, Row, Accordion, AccordionItem } from 'reactstrap'

import MainDetails from './components/MainDetails'
import MainPrice from './components/MainPrice'
import Gallery from './components/Gallery'
import Specification from './components/Specification'
import OrderForm from './components/OrderForm'
import ProductSidebar from '../Components/ProductSidebar'
import PaidFeatures from '../Components/PaidFeatures'
import UpdateHock from 'helpers/UpdateHock'
import ProductsLayout from './components/ProductsLayout'

const WithPaidFeatures = () => {
  document.title = 'Create Product | Salltak'

  const { id } = useParams()

  type readyProduct = {
    title: string
    slug: string
    short_description: string
    description: string
    sku: string
    MPN: string
    GTIN: string
    points: number | null
    brand_id: number | null
    hidden: number | null
    cost: number | null
    price: number | null
    weight_unit: string
    weight: number | null
    quantity: number | null
    badge_id: number | null
    categories: number[]
    fees: number[]
    main_image: string
    images: string[]
    labels: number[]
    form_id: number | null
    discount: number | null
    discount_type: string
    is_free_shipping: number | null
    cod_allowed: number | null
    stock_min_alert: number | null
    shipping_allowed: number | null
    ready_in: number | null
    ready_in_type: string
    similar_products: number[]
    meta: {
      title: ''
      keywords: ''
      description: ''
    }
    featureOption: {
      discount: string
      discount_type: string
      cost: string
      price: string
      quantity: string
    }[]
  }

  // prepare hock
  const uploadMethods = UpdateHock<readyProduct>({
    path: '/ready_product_plus_features',
    initValue: {
      title: '',
      slug: '',
      short_description: '',
      description: '',
      sku: '',
      MPN: '',
      GTIN: '',
      points: null,
      brand_id: null,
      hidden: null,
      cost: null,
      price: null,
      weight_unit: '',
      weight: null,
      quantity: null,
      badge_id: null,
      categories: [],
      fees: [],
      main_image: '',
      images: [],
      labels: [],
      form_id: null,
      discount: null,
      discount_type: '',
      is_free_shipping: null,
      cod_allowed: null,
      stock_min_alert: null,
      shipping_allowed: null,
      ready_in: null,
      ready_in_type: '',
      similar_products: [],
      meta: {
        title: '',
        keywords: '',
        description: ''
      },
      featureOption: []
    },
    editId: Number(id)
  })

  const [opend, setOpen] = useState<string>('')
  const selectLable = (label: string) => setOpen(label)

  const { closeUploading, openUploading } = uploadMethods

  return (
    <ProductsLayout uploadMethods={uploadMethods}>
      <Row>
        <Col lg={8}>
          <MainDetails />
          <MainPrice />
          <PaidFeatures isEdit type="paid" />
          {/* <Features /> */}

          <Gallery openUploading={openUploading} closeUploading={closeUploading} />

          <Accordion
            toggle={() => {}}
            className="lefticon-accordion custom-accordionwithicon accordion-border-box"
            open="test">
            <AccordionItem>
              <Specification
                isOpen={opend == 'spicifcation'}
                toggleItem={() => selectLable('spicifcation')}
              />
            </AccordionItem>
            <AccordionItem>
              <OrderForm
                isOpen={opend == 'orderForm'}
                toggleItem={() => selectLable('orderForm')}
              />
            </AccordionItem>
          </Accordion>
        </Col>

        <Col lg={4}>
          <ProductSidebar isEdit />
        </Col>
      </Row>
    </ProductsLayout>
  )
}

export default WithPaidFeatures
