import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
// bootstrap elements
import { Accordion, AccordionItem, Col, Container, Form, Row } from 'reactstrap'
// reqired componnent
import { Submit } from 'Components/Form'
import Loader from 'Components/IndexTable/partials/Loader'
import UpdateHock from 'helpers/UpdateHock'

import Gallery from './components/Gallery'
import MainDetails from './components/MainDetails'
import MainPrice from './components/MainPrice'
import OrderForm from './components/OrderForm'

const Digital = () => {
  document.title = 'Update product | Sallatk'

  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()

  // Convert 'id' to a number if needed
  const numericId: number = id as unknown as number

  type readyProduct = {
    title: string
    slug: string
    short_description: string
    description: string
    sku: string
    MPN: string
    GTIN: string
    points: number | null
    brand_id: number | null
    published: number
    cost: number | null
    price: number | null
    weight_unit: string
    weight: number | null
    badge_id: number | null
    categories: number[]
    fees: number[]
    main_image: string
    images: string[]
    labels: number[]
    form_id: number | null
    discount: number | null
    discount_type: string
    stock_min_alert: number | null
    similar_products: number[]
    meta: {
      title: ''
      keywords: ''
      description: ''
    }
  }

  // prepare hock
  const {
    register,
    handleSubmit,
    errors,
    update,
    loading,
    closeUploading,
    openUploading,
    setValue,
    getValues,
    getLoading
  } = UpdateHock<readyProduct>({
    path: '/card_product',
    initValue: {
      title: '',
      slug: '',
      short_description: '',
      description: '',
      sku: '',
      MPN: '',
      GTIN: '',
      points: null,
      brand_id: null,
      published: 1,
      cost: null,
      price: null,
      weight_unit: '',
      weight: null,
      badge_id: null,
      categories: [],
      fees: [],
      main_image: '',
      images: [],
      labels: [],
      form_id: null,
      discount: null,
      discount_type: '',
      stock_min_alert: null,
      similar_products: [],
      meta: {
        title: '',
        keywords: '',
        description: ''
      }
    },
    editId: numericId
  })

  const [opend, setOpen] = useState<string>('')
  const selectLable = (label: string) => setOpen(label)

  const submit = async (data: readyProduct) => {
    const response = await update(data)
    if (response.status) navigate('/products/list')
  }

  return (
    <div className="page-content">
      <Container fluid>
        {getLoading && <Loader />}
        {!getLoading && (
          <Row>
            <Col lg={8}>
              <Form onSubmit={handleSubmit(submit)}>
                <MainDetails
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                />
                <MainPrice register={register} errors={errors} getValues={getValues} />
                <Gallery
                  register={register}
                  errors={errors}
                  openUploading={openUploading}
                  closeUploading={closeUploading}
                  getValues={getValues}
                />

                <Accordion
                  toggle={() => {}}
                  className="lefticon-accordion custom-accordionwithicon accordion-border-box"
                  open="test">
                  {/* <AccordionItem>
                    <Specification
                      isOpen={opend == 'spicifcation'}
                      toggleItem={() => selectLable('spicifcation')}
                      register={register}
                      errors={errors}
                      control={control}
                    />
                  </AccordionItem> */}
                  <AccordionItem>
                    <OrderForm
                      isOpen={opend == 'orderForm'}
                      toggleItem={() => selectLable('orderForm')}
                      register={register}
                      errors={errors}
                      getValues={getValues}
                    />
                  </AccordionItem>
                </Accordion>
                <div className="text-end mb-3 m-2">
                  <Submit loading={loading} label={t('Save')} />
                </div>
              </Form>
            </Col>

            <Col lg={4}>
              {/* <Details register={register} errors={errors} getValues={getValues} />
              <Similar register={register} errors={errors} getValues={getValues} />
              <Info register={register} errors={errors} />
              <Seo register={register} errors={errors} /> */}

              {/* <ProductSidebar /> */}
            </Col>
          </Row>
        )}
      </Container>
    </div>
  )
}

export default Digital
