import { ImageUpload, Input, Search, Submit } from 'Components/Form'
import PopUp from 'Components/PopUp'
import UpdateHock from 'helpers/UpdateHock'
import PhoneField from 'pages/Customers/components/PhoneField'
import { useTranslation } from 'react-i18next'
import { Button, Col, Container, Row } from 'reactstrap'

const UpdateModal = ({ close, show, onSuccess, itemId }: any) => {
  const { t } = useTranslation()

  type moderatortype = {
    id: number | null
    name: string
    lastname: string
    email: string
    role_id: number
    image?: string | null
    dialing_code: string
    phone: number | null
    mobile_image?: string
    role?: {
      id: string
      name: string
    }
  }

  // prepare hock
  const {
    register,
    handleSubmit,
    errors,
    loading,
    getLoading,
    closeUploading,
    openUploading,
    getValues,
    update
  } = UpdateHock<moderatortype>({
    path: '/moderators',
    initValue: {
      id: null,
      name: '',
      lastname: '',
      email: '',
      role_id: 0,
      image: null,
      dialing_code: '',
      phone: null
    },
    editId: itemId
  })

  const submit = async (data: moderatortype) => {
    const response = await update(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  return (
    <PopUp title={t('Update Moderator')} show={show} close={close} loading={getLoading}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={12}>
              <ImageUpload
                label={t('Image')}
                model={register('image')}
                error={errors.image}
                onUpload={openUploading}
                onFinishUpload={closeUploading}
                value={getValues('mobile_image')}
              />
            </Col>
            <Col md={6}>
              <Input
                label={t('First Name')}
                placeholder={t('Enter first name')}
                model={register('name', {
                  required: t('First name is required'),
                  minLength: {
                    value: 3,
                    message: t('First name must be bigger than or equal 3')
                  }
                })}
                error={errors.name}
              />
            </Col>
            <Col md={6}>
              <Input
                label={t('Last Name')}
                placeholder={t('Enter last name')}
                model={register('lastname', {
                  required: t('Last name is required'),
                  minLength: {
                    value: 3,
                    message: t('Last name must be bigger than or equal 3')
                  }
                })}
                error={errors.lastname}
              />
            </Col>
            <PhoneField register={register} errors={errors} getValues={getValues}>
              <Col md={3}>
                <PhoneField.Code />
              </Col>
              <Col md={9}>
                <PhoneField.Number />
              </Col>
            </PhoneField>
            <Col md={12}>
              <Input
                label={t('Email')}
                type="email"
                placeholder={t('Enter email')}
                model={register('email', {
                  required: t('Email is required'),
                  minLength: {
                    value: 3,
                    message: t('Email must be bigger than or equal 3')
                  }
                })}
                error={errors.email}
              />
            </Col>
            <Col md={12}>
              <Search
                label={t('Role')}
                placeholder={t('Enter role')}
                model={register('role_id', {
                  required: t('Role is required')
                })}
                value={getValues('role')}
                error={errors.role_id}
                path="role"
              />
            </Col>
          </Row>
        </Container>
        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Update')} />
        </div>
      </form>
    </PopUp>
  )
}
export default UpdateModal
