const RatingComponent = ({ value }: { value: number }) => {
  return (
    <>
      {' '}
      <label className="form-check-label" htmlFor={'rating' + value}>
        <span className="text-muted">
          {[...Array(value)].map((_, index) => (
            <i key={index} className="mdi mdi-star text-warning"></i>
          ))}
          {[...Array(5 - value)].map((_, index) => (
            <i key={index} className="mdi mdi-star"></i>
          ))}
        </span>
      </label>
    </>
  )
}

export default RatingComponent
