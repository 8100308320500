// bootstrap elements
import { Card, CardBody, ListGroup, ListGroupItem, CardHeader, Row } from 'reactstrap'

import Empty from 'Components/IndexTable/partials/Empty'

interface input {
  id: number
  name: string
  placeholder: string
  type: 'text' | 'number' | 'textarea' | 'radio' | 'checkbox' | 'date' | 'time' | 'color'
  required: 1 | 0
  sort: number
}

const FormPreview = ({ form }: { form: any }) => {
  console.log(form)
  if (!form) return <Empty />
  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          {/* card title */}
          <div className="col">
            <h5 className="card-title mb-0">{form?.name}</h5>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        <ListGroup className="d-flex gap-3">
          {form?.children?.map((input: any, index: number) => (
            <ListGroupItem className="border-0 py-0" key={index}>
              <label className="form-label"> {input?.name}</label>

              {(() => {
                switch (input?.type) {
                  case 'text':
                    return (
                      <input className="form-control" placeholder={input?.placeholder} readOnly />
                    )
                  case 'number':
                    return (
                      <input
                        type="number"
                        className="form-control"
                        placeholder={input?.placeholder}
                        readOnly
                      />
                    )
                  case 'textarea':
                    return (
                      <textarea
                        className="form-control"
                        placeholder={input?.placeholder}
                        readOnly></textarea>
                    )
                  case 'date':
                    return (
                      <input
                        type="date"
                        className="form-control"
                        placeholder={input?.placeholder}
                        readOnly
                      />
                    )
                  case 'time':
                    return (
                      <input
                        type="time"
                        className="form-control"
                        placeholder={input?.placeholder}
                        readOnly
                      />
                    )
                  case 'color':
                    return (
                      <input
                        type="color"
                        className="form-control"
                        placeholder={input?.placeholder}
                        readOnly
                      />
                    )
                  case 'checkbox':
                    return (
                      <>
                        {input?.options.map((option: any, index: number) => (
                          <div className="form-check mb-2" key={index}>
                            <input className="form-check-input" type="checkbox" readOnly />
                            <label className="form-check-label">{option?.name}</label>
                          </div>
                        ))}
                      </>
                    )
                  case 'radio':
                    return (
                      <>
                        {input.options.map((option: any, index: number) => (
                          <div className="form-check mb-2" key={index}>
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inputs"
                              readOnly
                            />
                            <label className="form-check-label">{option.name}</label>
                          </div>
                        ))}
                      </>
                    )
                }
              })()}
            </ListGroupItem>
          ))}
        </ListGroup>
        {form.children.length === 0 && <Empty />}
      </CardBody>
    </Card>
  )
}

export default FormPreview
