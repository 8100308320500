import { Input } from 'Components/Form'

import { useTranslation } from 'react-i18next'

function IBANInput({ isWallet, register, errors }) {
  const { t } = useTranslation()
  const label = isWallet ? t('Phone') : t('IBAN')
  const validationRules = {
    required: label + ' ' + t('is required'),
    ...(!isWallet && {
      pattern: {
        value: /^([A-Z]{2})(\d{2})([A-Z0-9]{1,30})$/,
        message: t('IBAN must be valid')
      }
    })
  }
  return (
    <Input
      type={isWallet ? 'number' : 'text'}
      label={label}
      placeholder={t('Enter') + ' ' + label}
      model={register('code', validationRules)}
      error={errors?.code}
    />
  )
}

export default IBANInput
