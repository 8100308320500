import {
  Card,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap'
import ReactApexChart from 'react-apexcharts'
import FetchHock from 'helpers/FetchHock'
import ViewDataWithLoading from 'Components/ViewDataWithLoading'
import { useTranslation } from 'react-i18next'

const StoreVisitsCharts = ({ data }) => {
  const chartData = data?.map((method) => ({
    x: method.title,
    y: method.percent
  }))

  var options = {
    labels: chartData.map((data) => data.x),
    chart: {
      height: 333,
      type: 'donut'
    },
    legend: {
      position: 'bottom'
    },
    stroke: {
      show: false
    },
    dataLabels: {
      dropShadow: {
        enabled: false
      }
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + '%'
        }
      }
    }
  }
  return (
    <ReactApexChart
      dir="ltr"
      options={options}
      series={chartData?.map((data) => data.y)}
      type="donut"
      height="333"
      className="apex-charts"
    />
  )
}

const StoreVisits = () => {
  const { t } = useTranslation()
  // store orders payment methods
  const { result, loading, error } = FetchHock<any>({
    path: '/dashboard/payment-methods'
  })
  return (
    <Card className="card-height-100">
      <CardHeader className="align-items-center d-flex">
        <h4 className="card-title mb-0 flex-grow-1">{t('Payment Methods')}</h4>
        <div className="flex-shrink-0">
          <UncontrolledDropdown className="card-header-dropdown" direction="start">
            <DropdownToggle tag="a" className="text-reset dropdown-btn" role="button">
              <span className="text-muted">
                {t('Report')}
                <i className="mdi mdi-chevron-down ms-1"></i>
              </span>
            </DropdownToggle>
            <DropdownMenu container={'body'} className="dropdown-menu-end">
              <DropdownItem>Download Report</DropdownItem>
              <DropdownItem>Export</DropdownItem>
              <DropdownItem>Import</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </CardHeader>

      <div
        className="card-body"
        style={{
          display: 'flex'
        }}>
        <div
          dir="ltr"
          style={{
            margin: 'auto'
          }}>
          <ViewDataWithLoading items={result} loading={loading} error={error}>
            <StoreVisitsCharts data={result} />
          </ViewDataWithLoading>
        </div>
      </div>
    </Card>
  )
}

export default StoreVisits
