// react elements
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
// bootstrap elements
import { Collapse, Row, Col, Card, CardBody, CardHeader } from 'reactstrap'
import ICities from './ICity'
import Regions from './regions'
import UpdateCity from './updateCity'

const City = ({
  city,
  branchId,
  getBranchData
}: {
  city: ICities
  branchId: number
  getBranchData: () => void
}) => {
  const { t } = useTranslation()

  // clolabs logic
  const [colabs, setClabs] = useState<boolean>(false)
  const toggleColabs = () => setClabs(!colabs)

  // edit city
  const [showUpdate, changeShowUpdate] = useState<boolean>(false)
  const openUpdate = () => changeShowUpdate(true)
  const closeUpdate = () => changeShowUpdate(false)

  // city gitters
  const getActiveAll = () => {
    let active = false
    city.regions.map((el) => {
      if (el.status) active = true
    })
    return active
  }

  const getSippingCost = (): number | false => {
    let shippingCost: number | false = city.regions[0].shipping_cost
    city.regions.map((el) => {
      if (el.shipping_cost != shippingCost) shippingCost = false
    })
    return shippingCost
  }

  const getShippingTime = (): number | false => {
    let shippingTime: number | false = city.regions[0].delivery_in
    city.regions.map((el) => {
      if (el.delivery_in != shippingTime) shippingTime = false
    })
    return shippingTime
  }

  const getShippingType = (): string | false => {
    let ShippingType: string | false = city.regions[0].delivery_in_types
    city.regions.map((el) => {
      if (el.delivery_in_types != ShippingType) ShippingType = false
    })
    return ShippingType
  }

  return (
    <Card className="mb-2">
      <CardHeader>
        <Row>
          <Col sm={3}>
            <h6 role="button" onClick={toggleColabs}>
              <i className="ri-arrow-down-s-line fs-18 me-2 mt-2"></i> {city.title}
            </h6>
          </Col>
          <Col sm={3}>
            {t('Shipping cost')}:
            <strong className="ms-2">
              {!getActiveAll() ? '--' : ''}
              {getActiveAll() && !!getSippingCost() && getSippingCost()}
              {getActiveAll() && !getSippingCost() && t('According to the region')}
            </strong>
          </Col>
          <Col sm={3}>
            {t('Shipping Time')}:
            <strong className="ms-2">
              {!getActiveAll() ? '--' : ''}
              {getActiveAll() &&
                !!getShippingTime() &&
                !!getShippingType() &&
                getShippingTime() + ' ' + t(getShippingType() as string)}
              {getActiveAll() &&
                (!getShippingTime() || !getShippingType()) &&
                t('According to the region')}
            </strong>
          </Col>
          <Col sm={2}>
            {t('Status')}:
            {!!getActiveAll() && (
              <i className="ri-checkbox-circle-line align-middle text-success ms-2"></i>
            )}
            {!getActiveAll() && (
              <i className="ri-close-circle-line align-middle text-danger ms-2"></i>
            )}
          </Col>
          <Col sm={1}>
            <button className="btn p-0 link-success fs-15" onClick={() => openUpdate()}>
              <i className="ri-edit-2-line"></i>
            </button>
          </Col>
        </Row>
      </CardHeader>
      <Collapse isOpen={colabs}>
        <CardBody>
          <div className="table-responsive table-card mb-1">
            <table className="table table-hover table-responsive table-striped align-middle table-nowrap mb-0">
              <thead>
                <tr>
                  <th scope="col">{t('Region')}</th>
                  <th scope="col">{t('Shipping cost')}</th>
                  <th scope="col">{t('Shipping Time')}</th>
                  <th scope="col">{t('Status')}</th>
                  <th scope="col">{t('Action')}</th>
                </tr>
              </thead>
              <tbody>
                {city.regions.map((region, index) => (
                  <Regions
                    region={region}
                    key={index}
                    branchId={branchId}
                    getBranchData={getBranchData}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Collapse>
      {showUpdate && (
        <UpdateCity
          close={closeUpdate}
          show={showUpdate}
          onSuccess={getBranchData}
          branchId={branchId}
          city={city}
          value={{
            shipping_cost: getSippingCost() ? getSippingCost() : null,
            delivery_in_types: getShippingType() ? getShippingType() : null,
            delivery_in: getShippingTime() ? getShippingTime() : null,
            status: getActiveAll() ? getActiveAll() : 0
          }}
        />
      )}
    </Card>
  )
}

export default City
