import { Button, Spinner } from 'reactstrap'

type DomainButtonProps = {
  loading: boolean
  loadingLabel: string
  label: string
  disabled: boolean
  onClick?: () => void
  color?: 'primary' | 'secondary'
  type?: 'button' | 'submit'
}

function DomainButton({
  loading,
  loadingLabel,
  label,
  disabled,
  onClick,
  color = 'primary',
  type = 'button'
}: DomainButtonProps) {
  return (
    <Button color={color} onClick={onClick} disabled={disabled} type={type}>
      {loading ? <Spinner size="sm" className="me-2" /> : null}
      {loading ? loadingLabel : label}
    </Button>
  )
}

export default DomainButton
