import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import showError from 'helpers/ErorrNotfication'
import linkGenerator from 'helpers/linkGenerator'
import showSuccess from 'helpers/SuccessNotfication'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import DomainButton from './DomainButton'

function CheckAvailabilityButton({ setIsAvailable = (data) => {} }) {
  const { t } = useTranslation()
  const { getValues, watch } = useFormContext()

  const { mutate: checkDomainMutation, isPending: checking } = useMutation({
    mutationFn: async () => {
      const response = await axios.get(linkGenerator('/check-domain'), {
        params: {
          domain: getValues('domain'),
          tld: getValues('tld')
        }
      })
      return response.data
    },
    onSuccess: (data) => {
      setIsAvailable?.(data?.result?.available)
      if (data?.result?.available) {
        showSuccess(t('Domain is available'))
      } else {
        showError(t('Domain is not available'))
      }
    }
  })

  const checkAvailability = async () => {
    if (!getValues('domain')) return
    checkDomainMutation()
  }
  return (
    <DomainButton
      loading={checking}
      loadingLabel={t('Checking')}
      label={t('Check availability')}
      disabled={checking || !watch('domain') || !watch('tld')}
      onClick={checkAvailability}
      color="secondary"
    />
  )
}

export default CheckAvailabilityButton
