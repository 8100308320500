// react depindant
import { Fragment } from 'react'
// botstrap elemnts
import { Row } from 'reactstrap'
// translation pakage
import { useTranslation } from 'react-i18next'

const Pagination = ({ options, totalPages, totalItems, showedItems, pagenate }: any) => {
  const { t } = useTranslation()

  // perpare params
  let pages: (string | number)[] = []

  // init pages function
  pages = []
  const current = options.page
  const total = totalPages

  if (total > 9) {
    if (current > 4) {
      pages.push(1, 2, '...')
    }

    if (current <= 4) {
      pages.push(1, 2, 3, 4, 5)
    } else if (current > 4 && current < total - 3) {
      pages.push(current - 2, current - 1, current, current + 1, current + 2)
    } else if (current >= total - 3) {
      pages.push(total - 4, total - 3, total - 2, total - 1, total)
    }

    if (current < total - 3) {
      pages.push('... ', total - 1, total)
    }
  } else {
    for (let i = 1; i <= total; i++) {
      pages.push(i)
    }
  }

  return (
    <Row className="align-items-center mt-2 g-3 text-center text-sm-start px-3">
      <div className="col-sm">
        <div className="text-muted">
          {t('Showing')}
          <span className="fw-semibold ms-1">{showedItems}</span> {t('of')}{' '}
          <span className="fw-semibold">{totalItems}</span> {t('Results')}
        </div>
      </div>
      <div className="col-sm-auto">
        <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
          <li className={options.page <= 1 ? 'page-item disabled' : 'page-item'}>
            <button
              className="page-link"
              disabled={options.page <= 1}
              onClick={() => pagenate(options.page - 1)}
            >
              {t('Previous')}
            </button>
          </li>
          {pages.map((item: string | number, key: number) => (
            <Fragment key={key}>
              <li className="page-item">
                <button
                  className={options.page === item ? 'page-link active' : 'page-link'}
                  disabled={options.page === item}
                  onClick={() => pagenate(item)}
                >
                  {item}
                </button>
              </li>
            </Fragment>
          ))}
          <li className={options.page >= totalPages ? 'page-item disabled' : 'page-item'}>
            <button
              className="page-link"
              disabled={options.page >= totalPages}
              onClick={() => pagenate(options.page + 1)}
            >
              {t('Next')}
            </button>
          </li>
        </ul>
      </div>
    </Row>
  )
}

export default Pagination
