import clsx from 'clsx'

export const categoryLists = [
  {
    text: 'Products',
    value: 'products',
    icon: 'mdi mdi-package-variant-closed'
  },
  {
    text: 'Orders',
    value: 'orders',
    icon: 'mdi mdi-cart'
  },
  {
    text: 'Customers',
    value: 'customers',
    icon: 'mdi mdi-account-group'
  }
]

function CategoryButtons({
  onChangeType,
  type
}: {
  onChangeType: (option: any) => void
  type: string
}) {
  return (
    <>
      {categoryLists.map((option) => {
        return (
          <button
            type="button"
            key={option.value}
            onClick={() => onChangeType(option)}
            className={clsx('btn btn-soft-primary btn-sm btn-rounded m-1', {
              active: type === option.value
            })}>
            <i className={clsx('ms-1', option.icon)}></i> {option.text}
          </button>
        )
      })}
    </>
  )
}

export default CategoryButtons
