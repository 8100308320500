import axios from 'axios'
import showError from 'helpers/ErorrNotfication'
import linkGenerator from 'helpers/linkGenerator'
import showSuccess from 'helpers/SuccessNotfication'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from 'reactstrap'

const EditLabel = ({
  label,
  path,
  onSuccess
}: {
  label: string
  path: string
  onSuccess?: any
}) => {
  const [edit, setEdit] = useState<boolean>(false)
  const { t } = useTranslation()

  const onUpdate = (value) => {
    return axios
      .put(linkGenerator(path), { title: value })
      .then(() => {
        showSuccess(t('Label updated successfully'))
        onSuccess()
      })
      .catch((err) => {
        showError(err)
      })
  }

  const saveValue = (e: any) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const formValues = Object.fromEntries(formData.entries())
    const title = formValues?.title

    setEdit(false)
    if (onUpdate) onUpdate(title)
  }

  const closeEdit = () => {
    setEdit(false)
    // if (onClose) onClose(value)
  }

  return (
    <>
      {edit ? (
        <EditLabelForm label={label} onSubmit={saveValue} onClose={closeEdit} />
      ) : (
        <>
          <p className="cursor-pointer" onClick={() => setEdit(true)}>
            <i className="ri-edit-2-line me-1"></i>
            {label}
          </p>
        </>
      )}
    </>
  )
}

export default EditLabel

function EditLabelForm({ label, onSubmit, onClose }) {
  return (
    <form
      onSubmit={onSubmit}
      style={{
        position: 'relative'
      }}>
      <Input name="title" defaultValue={label} />
      <button
        type="submit"
        className="btn p-0 text-success"
        style={{ position: 'absolute', top: '5px', right: '30px', fontSize: '20px' }}>
        <i className="ri-check-fill me-2"></i>
      </button>
      <button
        type="button"
        className="btn p-0 text-danger"
        style={{ position: 'absolute', top: '5px', right: '5px', fontSize: '20px' }}
        onClick={onClose}>
        <i className="ri-close-fill me-2"></i>
      </button>
    </form>
  )
}
