import Details from './Details'
import Info from './Info'
import Shipping from './Shipping'
import Stock from './Stock'
import Similar from './Similar'
import Seo from './Seo'
import { CustomFeatures } from './PaidFeatures'

interface ComponentConfig {
  Details?: boolean
  Stock?: boolean
  StockEdit?: boolean
  Similar?: boolean
  Shipping?: boolean
  Info?: boolean
  Seo?: boolean
  CustomFeatures?: boolean
}

interface ProductSidebarProps {
  isEdit?: boolean
  components?: ComponentConfig
}

function ProductSidebar({ isEdit, components = {} }: ProductSidebarProps) {
  const defaultConfig: ComponentConfig = {
    Details: true,
    StockEdit: !isEdit,
    Stock: true,
    Similar: true,
    Shipping: true,
    Info: true,
    Seo: true,
    CustomFeatures: true
  }

  const config = { ...defaultConfig, ...components }

  return (
    <>
      {config.Details && <Details />}
      {config.Stock && <Stock isEdit={isEdit} />}
      {config.Similar && <Similar />}
      {config.Shipping && <Shipping />}
      {config.Info && <Info />}
      {config.Seo && <Seo />}
      {config.CustomFeatures && <CustomFeatures />}
    </>
  )
}

export default ProductSidebar
