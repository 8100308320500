import { Fieldset } from 'Components/Form/Fieldset'
import { Legend } from 'Components/Form/Legend'
import { useContext, useState } from 'react'
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { useAddToCart } from 'services/pos/cart'
import { usePOSProduct } from 'services/pos/products'
import { POSContext } from '../POSContext'
import { POSProductForm } from './POSProductForm'

export const POSPopup = () => {
  const { popupIsOpen, setPopupIsOpen, selectedProduct, setSelectedProduct } =
    useContext(POSContext)
  const { data: productData } = usePOSProduct(selectedProduct?.id)
  const { mutateAsync: addToCart, isPending: addToCartIsPending } = useAddToCart()

  const [selectedFeatures, setSelectedFeatures] = useState<{ [key: number]: number }>({})
  const [form, setForm] = useState<{ [key: string]: number | string }>({})

  const selectFeature = (featureId: number, optionValue: number) => {
    setSelectedFeatures((prev) => ({
      ...prev,
      [featureId]: optionValue
    }))
  }

  const handleCancelClick = () => {
    setSelectedProduct(null)
    setPopupIsOpen(false)
  }

  const handleAddToCartClick = async () => {
    await addToCart({
      product_id: selectedProduct.id,
      quantity: 1,
      ...(Object.values(selectedFeatures).length && {
        product_feature_option_ids: Object.values(selectedFeatures)
      }),
      ...(form && {
        form: {
          id: selectedProduct?.form?.id,
          values: form
        }
      })
    })
  }

  const renderFeatures = () => {
    if (!productData?.featureOptionsFree.length && !productData?.featureOptionsPaid.length) return

    return (
      <>
        {[...productData?.featureOptionsFree, ...productData?.featureOptionsPaid].map((feature) => (
          <Fieldset key={feature.id}>
            <Legend>{feature.title}</Legend>
            {feature?.items?.map((option) => (
              <div key={option.id} className="form-check">
                <Input
                  id={'feature-' + feature.id + '-' + option.id}
                  className="form-check-input"
                  type="radio"
                  checked={selectedFeatures[feature.id] === option.id}
                  onChange={() => selectFeature(feature.id, option.id)}
                />
                <Label
                  htmlFor={'feature-' + feature.id + '-' + option.id}
                  className="form-check-label">
                  {`${option.title} ${option.price ? `(+${option.price} KD)` : ''}`}
                </Label>
              </div>
            ))}
          </Fieldset>
        ))}
      </>
    )
  }

  return (
    <Modal isOpen={popupIsOpen} centered unmountOnClose>
      <ModalHeader>
        {selectedProduct?.title} ({selectedProduct?.final_price} KD)
      </ModalHeader>
      <ModalBody>
        {renderFeatures()}
        {productData?.form &&<POSProductForm form={productData?.form} onSubmit={(values) => setForm(values)} />}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleCancelClick} disabled={addToCartIsPending}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleAddToCartClick} disabled={addToCartIsPending}>
          Add to cart
        </Button>
      </ModalFooter>
    </Modal>
  )
}
