import React, { useState } from 'react'
import { Container } from 'reactstrap'

import ChapterAssets from 'pages/Products/ChapterAssets/list'
import ChaptersView from './ChaptersView'
import DetailContent from './DetailContent'

const CourseChapters = () => {
  document.title = 'Course chapters | Sallatk'

  const [sidebarData, setSidebarData] = useState<any>()

  const [chaptersId, setChaptersId] = useState(null)
  const [menubarShow, setMenubarShow] = useState(false)
  const toggleMenubar = () => setMenubarShow(!menubarShow)

  // SideBar Open
  function sidebarOpen(value) {
    const element = document.getElementsByTagName('body')[0]
    element.classList.add(value)
  }

  // SideBar Close
  function sidebarClose(value) {
    const element = document.getElementsByTagName('body')[0]
    element.classList.remove(value)
  }

  const fileSidebar = () => {
    document.getElementById('file-overview').style.display = 'block'
  }

  const openSide = (item) => {
    setSidebarData(item)
    fileSidebar()
    sidebarOpen('file-detail-show')
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className=" d-lg-flex gap-1 mx-n4 mt-n4 p-1">
            <ChaptersView
              setChaptersId={setChaptersId}
              chaptersId={chaptersId}
              menubarShow={menubarShow}
              setMenubarShow={setMenubarShow}
            />

            {chaptersId ? (
              <ChapterAssets
                chaptersId={chaptersId}
                handleOpen={openSide}
                toggleMenubar={toggleMenubar}
              />
            ) : (
              <div className="d-flex flex-column w-100 justify-content-center align-items-center">
                <i className="ri-error-warning-line display-5"></i>
                <p className="fw-semibold">Please select a chapter</p>
              </div>
            )}
            <DetailContent sidebarData={sidebarData} sidebarClose={sidebarClose} />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CourseChapters
