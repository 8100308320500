import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Col, Container, Row } from 'reactstrap'

import BreadCrumb from 'Components/Common/BreadCrumb'
import IndexTable, { FilterItem, HeaderColumn } from 'Components/IndexTable'
import StatusBadge from './StatusBadge'
import PaymentMethodTableCell from 'Components/PaymentMethodTableCell'
import FetchHock from 'helpers/FetchHock'

const Transactions = () => {
  const { t } = useTranslation()

  document.title = 'Transactions | Sallatk'

  const fetchHook = FetchHock<Transaction, any>({ path: '/transactions' })

  const tabs = [
    {
      title: t('All'),
      icon: 'ri-store-fill',
      action: () => fetchHook.filter('status', '')
    },
    {
      title: t('Paid'),
      icon: 'ri-checkbox-circle-line',
      action: () => fetchHook.filter('status', 'paid')
    },
    {
      title: t('Pending'),
      icon: 'ri-time-line',
      action: () => fetchHook.filter('status', 'pending')
    },
    {
      title: t('Refused'),
      icon: 'ri-close-circle-line',
      action: () => fetchHook.filter('status', 'refused')
    }
  ]

  const filterItems: FilterItem[] = [
    {
      type: 'asyncSelect',
      col: 2,
      placeholder: t('Order number'),
      path: 'orders',
      action: (value) => fetchHook.filter('order_id', value)
    },
    {
      type: 'asyncSelect',
      col: 2,
      placeholder: t('Payment method'),
      path: 'dashboard/payment-methods',
      action: (value) => fetchHook.filter('payment_method_id', value)
    },
    {
      type: 'select',
      col: 2,
      options: [
        {
          label: 'Pay',
          value: 'pay'
        },
        {
          label: 'Charge',
          value: 'charge'
        },
        {
          label: 'Refund',
          value: 'refund'
        }
      ],
      placeholder: t('Transaction type'),
      action: (value) => fetchHook.filter('transaction_type', value)
    },
    {
      type: 'search',
      col: 4,
      placeholder: t('Search ...'),
      action: (value) => fetchHook.search(value)
    },
  ]

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <Row>
          <Col lg={12}>
            <TransactionsTable fetchHook={fetchHook} tabs={tabs} filterItems={filterItems} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Transactions

export const TransactionsTable = ({
  title = '',
  fetchHook = {},
  tabs = [],
  filterItems = [],
  data
}: any) => {
  const { t } = useTranslation()

  const headerColumns: HeaderColumn[] = [
    {
      title: 'Id',
      accessor: 'id'

    },
    {
      title: 'Status',
        accessor: 'status'
    },
    {
      title: 'User',
       accessor: 'created_by'
    },
    {
      title: 'Payment Method',
      accessor:"payment_method_id"
    },
    {
      title: 'Total',
      accessor: 'total'
    },
    {
      title: 'Payment ID',
      accessor: 'payment_id'
    },
    {
      title: 'Created at',
      accessor: 'created_at'
    },
    {
      title: 'View'
    }
  ]

  return (
    <IndexTable
      title={title || `${t('Transactions')} ${t('List')}`}
      headerColumns={headerColumns}
      {...fetchHook}
      tabs={tabs}
      filters={filterItems}>
      {(fetchHook?.items || data || [])?.map((item: Transaction, index: number) => (
        <tr key={index}>
          <td>
            <h5 className="fs-14 mb-1">
              <Link to={`/transactions/${item?.id}`} className="text-body text-uppercase">
                #{item.id}{' '}
              </Link>
            </h5>
          </td>

          <td>
            <StatusBadge status={item.status} />
          </td>

          <td>{`${item?.user?.name} ${item?.user?.lastname}`}</td>
          <td>
            <PaymentMethodTableCell
              title={item?.payment_method.title}
              image={item.payment_method.image_url}
              index={index}
            />
            {item?.payment_method?.company?.name &&
              item?.payment_method?.company?.name.toLowerCase() !== 'cod' && (
                <div className="fs-12">
                  <span className="text-muted">{t('Company')}</span>:{' '}
                  <span className="fw-bold">{item?.payment_method?.company?.name}</span>
                </div>
              )}
          </td>
          <td>{`${item?.total} ${item?.currency}`}</td>
          <td> {item.payment_id}</td>
          <td>{item.created_at}</td>
          <td>
            <div className="hstack gap-3 flex-wrap">
              <Link color="link" to={`/transactions/${item.id}`}>
                <i className="ri-eye-fill fs-16"></i>
              </Link>
            </div>
          </td>
        </tr>
      ))}
    </IndexTable>
  )
}
