import CountUp from 'react-countup'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col } from 'reactstrap'
import FetchHock from 'helpers/FetchHock'
import { useTranslation } from 'react-i18next'

const Widgets = () => {
  const { t } = useTranslation()
  // Total Paid Orders
  // Total Customers
  // Total Paid Transactions
  // Total Earning = price -cost
  const { result: data, loading, error } = FetchHock<any>({ path: '/dashboard/cards' })

  const ecomWidgets = [
    {
      id: 1,
      cardColor: 'primary',
      label: 'Earnings',
      badge: 'ri-arrow-right-up-line',
      badgeClass: 'success',
      percentage: '+16.24',
      counter: data?.earnings || 0,
      bgcolor: 'primary',
      icon: 'bx bx-dollar-circle',
      decimals: 2,
      prefix: data?.currency || '',
      suffix: '',
      link: '',
      linkText: ''
    },
    {
      id: 2,
      cardColor: 'secondary',
      label: 'Order Counts',
      badge: 'ri-arrow-right-down-line',
      badgeClass: 'danger',
      percentage: '-3.57',
      counter: data?.orderCounts || 0,
      bgcolor: 'primary',
      icon: 'bx bx-shopping-bag',
      decimals: 0,
      prefix: '',
      separator: ',',
      suffix: '',
      link: 'orders/list',
      linkText: 'Orders'
    },
    {
      id: 3,
      cardColor: 'success',
      label: 'Customers',
      badge: 'ri-arrow-right-up-line',
      badgeClass: 'success',
      percentage: '+29.08',
      counter: data?.customers || 0,
      bgcolor: 'primary',
      icon: 'bx bx-user-circle',
      decimals: 2,
      prefix: '',
      suffix: '',
      link: 'customers/list',
      linkText: 'Customers'
    },
    {
      id: 4,
      cardColor: 'info',
      label: 'Total Transactions',
      badgeClass: 'muted',
      percentage: '+0.00',
      counter: data?.total_transactions || 0,
      bgcolor: 'primary',
      icon: 'bx bx-wallet',
      decimals: 2,
      prefix: data?.currency || '',
      suffix: '',
      link: 'transactions/list',
      linkText: 'Transactions'
    }
  ]

  return (
    <>
      {ecomWidgets?.map((item, key) => (
        <Col xl={3} md={6} key={key}>
          <Card className="card-animate">
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    {t(item.label)}
                  </p>
                </div>
                {/* <div className="flex-shrink-0">
                  <h5 className={'fs-14 mb-0 text-' + item.badgeClass}>
                    {item.badge ? <i className={'fs-13 align-middle ' + item.badge}></i> : null}{' '}
                    {item.percentage} %
                  </h5>
                </div> */}
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    <span className="counter-value" data-target="559.25">
                      <CountUp
                        start={0}
                        prefix={item.prefix}
                        suffix={item.suffix}
                        separator={item.separator}
                        end={Number(item.counter)}
                        decimals={item.decimals}
                        duration={4}
                      />
                    </span>
                  </h4>
                  <Link to={item.link} className="text-decoration-underline link-secondary">
                    {item?.link ? `${t('View all')} ${t(item.linkText)}` : '\u00A0'}
                  </Link>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className={'avatar-title rounded fs-3 bg-' + item.bgcolor + '-subtle'}>
                    <i className={`text-${item.bgcolor} ${item.icon}`}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </>
  )
}

export default Widgets

const DisplayCount = ({ data, loading, error }) => {
  if (loading) {
    return <div>0</div>
  }

  if (error) {
    return <i className="ri-error-warning-line"></i>
  }
  return (
    <>
      <CountUp
        start={0}
        prefix={data.prefix}
        suffix={data.suffix}
        separator={data.separator}
        end={Number(data.counter)}
        decimals={data.decimals}
        duration={4}
      />
    </>
  )
}
