import { Col, Container, Row } from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import { useTranslation } from 'react-i18next'
import { Input, Submit } from 'Components/Form'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import linkGenerator from 'helpers/linkGenerator'
import showSuccess from 'helpers/SuccessNotfication'

type Metadata = {
  title?: string
  keywords?: string
  description?: string
}

const HomepageSEO = () => {
  document.title = 'SEO | Sallatk'
  const { t } = useTranslation()

  const { data, isLoading, error } = useQuery<{ result: Metadata }>({
    queryKey: ['seo', 'homepage'],
    queryFn: async () => {
      const response = await axios.get(linkGenerator('/seo/homepage'))
      return response.data
    }
  })

  if (isLoading) {
    return <div className="page-content">Loading</div>
  }
  if (error) {
    return <div className="page-content">Error</div>
  }
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <Row>
          <Col lg={12}>
            <HomepageSEOForm defaultValues={data.result} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default HomepageSEO

function HomepageSEOForm({ defaultValues }) {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues
  } = useForm<Metadata>({
    defaultValues
  })

  const { mutate, isPending } = useMutation({
    mutationKey: ['seo', 'homepage'],
    mutationFn: async (data: { meta: Metadata }) => {
      const response = await axios.post(linkGenerator('/seo/homepage'), data)
      return response.data
    },
    onSuccess: () => {
      showSuccess(t('Form submitted successfully'))
    }
  })
  const submit = async (data: Metadata) => {
    const payload: { meta: Metadata } = {
      meta: {
        title: data.title,
        keywords: data.keywords,
        description: data.description
      }
    }
    mutate(payload)
  }
  return (
    <>
      <form onSubmit={handleSubmit(submit)}>
        <Row>
          <Col md={12}>
            <Input
              label={t('Title')}
              placeholder={t('Enter title')}
              model={register('title', {
                required: t('Title is required')
              })}
              error={errors.title}
            />
          </Col>
          <Col md={12}>
            <Input
              label={t('Keywords')}
              placeholder={t('Enter keywords')}
              model={register('keywords', {
                required: t('keywords is required')
              })}
              error={errors.keywords}
            />
          </Col>
          <Col md={12}>
            <Input
              label={t('Description')}
              placeholder={t('Enter description')}
              model={register('description', {
                required: t('description is required')
              })}
              error={errors.description}
            />
          </Col>
        </Row>
        <Submit loading={isPending} label={t('Save')} />
      </form>
    </>
  )
}
