// react elements
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
// bootstrap elements
import { Spinner } from 'reactstrap'
// required components
import City from './city'
import ICities from './ICity'
import axios from 'axios'
import linkGenerator from 'helpers/linkGenerator'

const UiAccordions = ({ branchId }: { branchId: number }) => {
  const params = useParams()

  // cities hock
  const [cities, setCities] = useState<ICities[]>([])
  // get data from server
  const [loading, setLoading] = useState<boolean>(false)
  const getBranchData = (withoutLoading = true) => {
    if (!withoutLoading) setLoading(true)
    axios.get(linkGenerator(`/branchAreas/${branchId}/${params.id}`)).then((response) => {
      setCities(response.data.result)
      setLoading(false)
    })
  }
  useEffect(() => getBranchData(false), [branchId])

  return (
    <>
      {!!loading && (
        <div className="d-flex justify-content-center mx-2 mt-2">
          <Spinner color="primary" />
        </div>
      )}
      {!loading &&
        cities &&
        cities.map((city, index) => (
          <City key={index} city={city} branchId={branchId} getBranchData={getBranchData} />
        ))}
    </>
  )
}

export default UiAccordions
