// react includes
import { useState } from 'react'
// botstrap elemnts
import { Card, CardBody, Table } from 'reactstrap'
// Export Modal
import ExportCSVModal from '../Common/ExportCSVModal'
import DeleteModal from './partials/DeleteModal'
import TableHeader from './partials/TableHeader'
import SliderNav from './partials/SliderNav'
import Filter from './partials/Filter'
import Loader from './partials/Loader'
import Error from './partials/Error'
import Empty from './partials/Empty'
import ColumnsHeader from './partials/ColumnsHeader'
import Pagination from './partials/Pagination'
// filter item type
import FilterItem from 'Types/FilterItem'
import HeaderColumn from 'Types/HeaderCloumn'
import MultiRoutes from 'Types/MultiRoutes'

const IndexTable = ({
  title,
  CSVLink,
  addRoute,
  addAction,
  addMultiRoutes,
  tabs,
  filters,
  headerColumns,
  loading,
  error,
  options,
  totalPages,
  totalItems,
  showedItems,
  pagenate,
  addString,
  deleteId,
  closeDelete,
  deleteItem,
  sort,
  children
}: {
  title?: string
  CSVLink?: string
  addRoute?: string
  addAction?: () => void
  addMultiRoutes?: MultiRoutes[]
  tabs?: any
  filters?: FilterItem[]
  headerColumns: HeaderColumn[]
  loading?: boolean
  error?: boolean
  options?: any
  totalPages?: number
  totalItems?: number
  showedItems?: number
  pagenate?: (page: number) => void
  addString?: string
  deleteId?: number | null
  closeDelete?: () => void
  deleteItem?: () => void
  sort?: (name: string) => void
  children?: any
}) => {
  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState<boolean>(false)
  const showExportCSV = (): void => setIsExportCSV(true)
  const closeExportCSV = (): void => setIsExportCSV(false)

  return (
    <div>
      {/* export exel component */}
      {CSVLink != null && isExportCSV && (
        <ExportCSVModal show={isExportCSV} onCloseClick={closeExportCSV} data={CSVLink} />
      )}
      {/* delete component */}
      {deleteId && deleteId > 0 && (
        <DeleteModal
          show={deleteId}
          onDeleteClick={deleteItem}
          onCloseClick={() => {
            if (closeDelete) closeDelete()
          }}
        />
      )}

      <Card>
        {/* table header */}
        <TableHeader
          title={title}
          showExportCSV={showExportCSV}
          addMultiRoutes={addMultiRoutes}
          CSVLink={CSVLink}
          addString={addString}
          addRoute={addRoute}
          addAction={addAction}
          showFillter={filters && filters.length > 0 ? true : false}
        />

        <CardBody className="pt-0">
          <div>
            {/* top tabs */}
            {tabs && tabs.length > 0 && <SliderNav tabs={tabs} />}

            {/* filters show */}
            {filters && filters.length > 0 && <Filter filterItems={filters} />}
            {/*table-responsive*/}
            <div className="table-responsive table-card mb-1 table-container">
              <Table hover className="align-middle table-nowrap responsive-table">
                {/* table header */}
                <ColumnsHeader headerColumns={headerColumns} sort={sort} />
                {/* table body */}
                {!loading && <tbody>{children}</tbody>}
              </Table>
            </div>
            {/* loader */}
            {loading && <Loader />}
            {!loading && !!error && <Error />}
            {!loading && !error && totalItems == 0 && <Empty />}
            {/* pagination */}
            {totalPages > 1 && (
              <Pagination
                options={options}
                totalPages={totalPages}
                totalItems={totalItems}
                showedItems={showedItems}
                pagenate={pagenate}
              />
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export type { FilterItem, HeaderColumn }
export default IndexTable
