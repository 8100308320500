import IndexTable from 'Components/IndexTable'
import ViewDataWithLoading from 'Components/ViewDataWithLoading'
import FetchHock from 'helpers/FetchHock'
import ImageWithFallback from 'helpers/ImageComponent'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Nav, NavLink, NavItem, TabContent, TabPane, Row, Col } from 'reactstrap'

interface FeatureOption {
  id: number
  feature_id: number
  feature_title: string
  type: string
  title: string
  color: string
  total: number | null
}

interface OptionStock {
  feature_option: FeatureOption
  quantity: number
  stock_min_alert: number
}

interface Product {
  id: number
  is_favorite: boolean
  form_id: number | null
  title: string
  short_description: string
  web_main_image: string
}

interface ProductStock {
  id: number
  branch_name: string | null
  product: Product
  product_stock: number
  product_stock_min_alert: number
  option_stock: OptionStock[]
}

const ProductStock = () => {
  const { id } = useParams()
  const { result, loading, error } = FetchHock<CustomersReviews>({
    path: `/stocks-product/${id}`
  })

  const data: ProductStock[] = result || []
  return (
    <div className="page-content">
      <ViewDataWithLoading items={data} loading={loading} error={error}>
        <ProductStockTabs data={data} />
      </ViewDataWithLoading>
    </div>
  )
}

export default ProductStock

function ProductStockTabs({ data }) {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(data[0]?.id)

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }
  return (
    <div>
      <Nav className="mr-auto" tabs>
        {data?.map((item, index) => (
          <NavItem className="cursor-pointer" key={index}>
            <NavLink
              className={activeTab === item?.id ? 'active' : ''}
              onClick={() => {
                toggle(item?.id)
              }}>
              {item?.branch_name || '⚠️'}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {data?.map((item, index) => (
          <TabPane tabId={item?.id} key={index}>
            <Row>
              <Col lg={12}>
                <IndexTable
                  title={t('Product Details')}
                  headerColumns={[
                    {
                      title: 'Image',
                      accessor: 'image',
                      width: '20%'
                    },
                    {
                      title: 'Title',
                      accessor: 'title',
                      width: '30%'
                    },
                    {
                      title: 'Stock',
                      accessor: 'product_stock',
                      width: '20%'
                    },
                    {
                      title: 'Min Alert',
                      accessor: 'product_stock_min_alert'
                    }
                  ]}>
                  <tr key={index}>
                    <td>
                      <ImageWithFallback
                        className="avatar-sm rounded object-fit-contain bg-light"
                        src={item?.product?.web_main_image}></ImageWithFallback>
                    </td>
                    <td>{item?.product?.title || ''}</td>
                    <td>{item?.product_stock}</td>
                    <td>{item?.product_stock_min_alert}</td>
                  </tr>
                </IndexTable>
              </Col>
              <Col lg={12}>
                <IndexTable
                  title={t('Product Features Stocks')}
                  headerColumns={[
                    {
                      title: 'Color',
                      accessor: 'color',
                      width: '20%'
                    },
                    {
                      title: 'Title',
                      accessor: 'title',
                      width: '30%'
                    },
                    {
                      title: 'Stock',
                      accessor: 'quantity',
                      width: '20%'
                    },
                    {
                      title: 'Min Alert',
                      accessor: 'stock_min_alert'
                    }
                  ]}>
                  {item?.option_stock?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        {item.feature_option?.color ? (
                          <i
                            className="ri-circle-fill me-2"
                            style={{ color: item.feature_option?.color }}></i>
                        ) : null}
                      </td>
                      <td>{item?.feature_option?.title || ''}</td>
                      <td>{item?.quantity}</td>
                      <td>{item?.stock_min_alert}</td>
                    </tr>
                  ))}
                </IndexTable>
              </Col>
            </Row>
          </TabPane>
        ))}
      </TabContent>
    </div>
  )
}
