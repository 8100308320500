import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import Loader from 'Components/Common/Loader'
import Error from 'Components/IndexTable/partials/Error'
import linkGenerator from 'helpers/linkGenerator'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Alert, Button, Col, Container, Row } from 'reactstrap'
function PaymentSuccess() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const ref = searchParams.get('ref')
  const gateway_id = searchParams.get('paymentId')
  const id = searchParams.get('Id')

  if (!ref || !gateway_id || !id) {
    navigate('/')
  }

  const { isLoading, data } = useQuery({
    queryKey: ['complete'],
    queryFn: async () => {
      const response = await axios.post(linkGenerator('/package/renew/complete'), {
        ref,
        gateway_id,
        id
      })

      //   if (response?.data?.result?.result) {
      //     toast(
      //       <>
      //         <p>Payment Successful</p>
      //         <p>you will be redirected to pricing</p>
      //       </>,
      //       {
      //         position: 'top-center',
      //         hideProgressBar: true,
      //         autoClose: false,
      //         closeOnClick: false,
      //         className: 'bg-success text-white'
      //       }
      //     )
      //     navigate('/pricing')
      //   }
      return response
    }
  })
  if (isLoading) {
    return (
      <div className=" w-100 d-flex justify-content-center align-items-center vh-100">
        <Loader />
      </div>
    )
  }

  return (
    <div>
      {data?.data?.result?.result ? (
        <div className="w-100 d-flex justify-content-center align-items-center vh-100">
          <ThankYouComponent />
        </div>
      ) : (
        <div className="w-100 d-flex justify-content-center align-items-center vh-100">
          <Error />
        </div>
      )}
    </div>
  )
}

export default PaymentSuccess

const ThankYouComponent = () => {
  const { t } = useTranslation()
  return (
    <Container
      fluid
      className="text-center my-5"
      style={{
        maxWidth: '500px'
      }}>
      <Row>
        <Col>
          <Alert color="success">
            <h4 className="alert-heading">{t('thankYou')}</h4>
            <p>{t('paymentSuccess')}</p>

            <p className="mb-0">
              {t('contactUs')} <a href="/contact">{t('contactUsLink')}</a>.
            </p>
            <div className="d-flex justify-content-center mt-3">
              <Button color="success" href="/">
                {t('homeButton')}
              </Button>
            </div>
          </Alert>
        </Col>
      </Row>
    </Container>
  )
}
