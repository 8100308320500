import BreadCrumb from 'Components/Common/BreadCrumb'
import Loader from 'Components/Common/Loader'
import React from 'react'
import { FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Container, Form } from 'reactstrap'
import { FormButtons } from '../../edit/components/ProductsLayout'

function ProductsLayout({
  children,
  createMethods
}: {
  children: React.ReactNode
  createMethods: any
}) {
  const navigate = useNavigate()

  const { loading, getLoading, formMethods, handleSubmit, create, getValues } = createMethods

  const submit = async (data) => {
    const response = await create(data)
    if (response.status) navigate('/products/list')
  }
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />

        {getLoading ? (
          <Loader />
        ) : (
          <FormProvider {...formMethods}>
            <Form onSubmit={handleSubmit(submit)}>
              <FormButtons direction="end" loading={loading}></FormButtons>

              {children}

              <FormButtons direction="center" loading={loading}></FormButtons>
            </Form>
          </FormProvider>
        )}
      </Container>
    </div>
  )
}

export default ProductsLayout
