import React, { useEffect } from 'react'

//import Scss
import './assets/scss/themes.scss'

//imoprt Route
import Route from './Routes'

import AOS from 'aos'
import 'aos/dist/aos.css'

function App() {
  useEffect(() => {
    AOS.init({
      duration: 500
    })
  }, [])
  return <Route />
}

export default App
