import { useDefaultLanguage } from 'Components/DefaultLanguage'
import { TextEditor } from 'Components/Form'

import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'

export default function TranslateText({ input, parentLabel }) {
  const { t } = useTranslation()
  const lang = useDefaultLanguage()
  const { register, getValues } = useFormContext()
  return (
    <Row>
      <Col sm="12">
        <TextEditor
          label={t(input.title)}
          placeholder={t('Enter') + ' ' + t(input.title) + ' ' + t('in ' + lang)}
          model={register(parentLabel + input.label + '.' + lang)}
          value={getValues(parentLabel + input.label + '.' + lang)}
        />
      </Col>
    </Row>
  )
}
