import { useState } from 'react'
import { Col, Row, Accordion, AccordionItem } from 'reactstrap'
import CreateHook from 'helpers/CreateHook'
import MainDetails from './components/MainDetails'
import MainPrice from './components/MainPrice'
import Gallery from './components/Gallery'
import Specification from './components/Specification'
import OrderForm from './components/OrderForm'
import ProductSidebar from '../Components/ProductSidebar'
import PaidFeatures from '../Components/PaidFeatures'
import ProductsLayout from './components/ProductsLayout'

const WithPaidFeatures = () => {
  document.title = 'Create Product | Salltak'

  type readyProduct = {
    title: string
    slug: string
    short_description: string
    description: string
    sku: string
    MPN: string
    GTIN: string
    points: number | null
    brand_id: number | null
    published: number
    cost: number | null
    price: number | null
    weight_unit: string
    weight: number | null
    quantity: number | null
    badge_id: number | null
    categories: number[]
    fees: number[]
    main_image: string
    images: string[]
    labels: number[]
    form_id: number | null
    discount: number | null
    discount_type: string
    is_free_shipping: number | null
    cod_allowed: 0 | 1
    stock_min_alert: number | null
    shipping_allowed: 0 | 1
    ready_in: number | null
    ready_in_type: string
    similar_products: number[]
    meta: {
      title: ''
      keywords: ''
      description: ''
    }
    featureOption: {
      featureOptionId: number | null
      discount: number | null
      discount_type: string
      cost: number | null
      price: number | null
      quantity: number | null
    }[]
  }

  // prepare hock
  const createMethods = CreateHook<readyProduct>('/ready_product_plus_features', {
    title: '',
    slug: '',
    short_description: '',
    description: '',
    sku: '',
    MPN: '',
    GTIN: '',
    points: null,
    brand_id: null,
    published: 1,
    cost: null,
    price: null,
    weight_unit: '',
    weight: null,
    quantity: null,
    badge_id: null,
    categories: [],
    fees: [],
    main_image: '',
    images: [],
    labels: [],
    form_id: null,
    discount: null,
    discount_type: '',
    is_free_shipping: null,
    cod_allowed: 1,
    stock_min_alert: null,
    shipping_allowed: 1,
    ready_in: null,
    ready_in_type: '',
    similar_products: [],
    meta: {
      title: '',
      keywords: '',
      description: ''
    },
    featureOption: [
      {
        featureOptionId: null,
        discount: null,
        discount_type: 'perc',
        cost: null,
        price: null,
        quantity: null
      }
    ]
  })

  const [opend, setOpen] = useState<string>('')
  const selectLable = (label: string) => setOpen(label)

  const { openUploading, closeUploading } = createMethods
  return (
    <ProductsLayout createMethods={createMethods}>
      <Row>
        <Col lg={8}>
          <MainDetails />
          <MainPrice />

          <PaidFeatures isEdit={false} type="paid" />
          {/* <Features /> */}
          <Gallery openUploading={openUploading} closeUploading={closeUploading} />

          <Accordion
            toggle={() => {}}
            className="lefticon-accordion custom-accordionwithicon accordion-border-box"
            open="test">
            <AccordionItem>
              <Specification
                isOpen={opend == 'spicifcation'}
                toggleItem={() => selectLable('spicifcation')}
              />
            </AccordionItem>
            <AccordionItem>
              <OrderForm
                isOpen={opend == 'orderForm'}
                toggleItem={() => selectLable('orderForm')}
              />
            </AccordionItem>
          </Accordion>
        </Col>

        <Col lg={4}>
          <ProductSidebar />
        </Col>
      </Row>
    </ProductsLayout>
  )
}

export default WithPaidFeatures
