import WithCategory from './withCategory/Config'
import WithCategoryDynamicSlider from './withCategoryDynamicSlider/Config'

const config = [
  {
    title: 'With Category',
    key: 'withCategory',
    config: WithCategoryDynamicSlider
  },
  {
    title: 'With Category - Cusom',
    key: 'withCategoryCustom',
    config: WithCategory
  }
]

export default config
