import Formula from 'ThemeManager/types/Formula/index'

const formula: Formula = [
  {
    title: 'Style',
    label: 'style',
    type: 'select',
    options: [
      {
        label: 'Rounded',
        value: 'round'
      },
      {
        label: 'Square',
        value: 'square'
      }
    ]
  },
  {
    title: 'Section Heading',
    label: 'sectionHeading',
    type: 'translateString'
  }
]
export default formula
