import { Button, Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Submit, Switch, Select } from 'Components/Form'
import UpdateHock from 'helpers/UpdateHock'
import PopUp from 'Components/PopUp'
import useFormOptions from '../../../hooks/useMenuFormOptions'
import { useEffect } from 'react'
import ReactSelect from 'react-select'
import useStaticData from 'hooks/useStaticData'

const UpdateModal = ({ show, close, onSuccess, itemId }: any) => {
  const { t } = useTranslation()
  const { moduleNameOptions, styleOptions } = useStaticData()

  const {
    moduleIdOptions,
    moduleIdOptionsLoading,
    fetchModuleIds,
    parentOptions,
    parentOptionsLoading
  } = useFormOptions()

  const editResponseBeforeRegister = (response: any) => {
    return response
  }
  // prepare hock
  const { register, handleSubmit, errors, loading, update, getValues, getLoading, watch } =
    UpdateHock<any>({
      path: '/menus/header',
      editId: itemId,
      editResponseBeforeRegister: editResponseBeforeRegister
    })

  const submit = async (data: any) => {
    const response = await update(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  useEffect(() => {
    const moduleNameValue = getValues('module_name')

    if (moduleNameValue) {
      fetchModuleIds(moduleNameValue)
    }
  }, [watch('module_name')])

  return (
    <PopUp title={t('Update menu')} show={show} close={close} loading={getLoading}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={12}>
              {parentOptionsLoading ? (
                <SelectLoading />
              ) : (
                <Select
                  label={t('Parent')}
                  model={register('parent_id')}
                  error={errors.parent_id}
                  options={parentOptions.map((item: any) => {
                    return { label: item.title, value: item.id }
                  })}
                  disabled={parentOptionsLoading}
                  value={getValues('parent_id')}
                />
              )}
            </Col>
            <Col md={12}>
              <Select
                label={t('from')}
                model={register('module_name', { required: t('module_name is required') })}
                error={errors.module_name}
                options={moduleNameOptions}
                // onChange={fetchModuleIds}
                value={getValues('module_name')}
              />
            </Col>
            <Col md={12}>
              {moduleIdOptionsLoading ? (
                <SelectLoading />
              ) : (
                <Select
                  label={t('Item')}
                  model={register('module_id', { required: t('module_id is required') })}
                  error={errors.module_id}
                  options={moduleIdOptions?.map((item: any) => {
                    return { label: item.title, value: item.id }
                  })}
                  disabled={moduleIdOptionsLoading}
                  value={getValues('module_id')}
                />
              )}
            </Col>
            <Col md={12}>
              <Select
                label={t('style')}
                model={register('style', { required: t('style is required') })}
                error={errors.style}
                options={styleOptions}
                value={getValues('style')}
              />
            </Col>

            <Col md={12}>
              <Switch
                defaultChecked={getValues('published')}
                label={t('Published')}
                model={register('published')}
              />
            </Col>
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Update')} />
        </div>
      </form>
    </PopUp>
  )
}
export default UpdateModal

const SelectLoading = () => {
  const { t } = useTranslation()
  return (
    <>
      <label>{t('Item')}</label>
      <ReactSelect isDisabled={true} placeholder={t('Loading')} className="form-control p-0 mb-3" />
    </>
  )
}
