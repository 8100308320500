import { defaultImage } from 'ThemeManager/Templates/DefaultValues'

export default {
  content: [
    {
      title: {
        en: 'T-shirts Collection',
        ar: 'مجموعة تي شيرت'
      },

      slug: 't-shirts-collection',
      image: defaultImage,
      type: 'small'
    },
    {
      title: 'Holiday Offers',
      slug: 'jeans-collection',
      image: defaultImage,
      type: 'small'
    },
    {
      title: 'Holiday Offers',
      slug: 'new-backpack',
      image: defaultImage,
      type: 'large'
    }
  ]
}
