import ViewDataWithLoading from 'Components/ViewDataWithLoading'
import FetchHock from 'helpers/FetchHock'
import { useTranslation } from 'react-i18next'
import { Row, Progress } from 'reactstrap'

const Rating = () => {
  const { t } = useTranslation()
  const { result, loading, error } = FetchHock({
    path: '/dashboard/ratings-report'
  })
  const averageRating = Number(result?.average || 0)

  return (
    <div className="p-3">
      <h6 className="text-muted mb-3 text-uppercase fw-semibold">{t('Ratings Report')}</h6>
      <ViewDataWithLoading items={result} loading={loading} error={error}>
        <div className="bg-light px-3 py-2 rounded-2 mb-2">
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <div className="fs-16 align-middle text-warning">{getStarIcons(averageRating)}</div>
            </div>
            <div className="flex-shrink-0">
              <h6 className="mb-0">
                {averageRating.toFixed(1)} {t('out of')} 5
              </h6>
            </div>
          </div>
        </div>
        <div className="text-center">
          <div className="text-muted">
            {t('Total')} {t('reviews')}: <span className="fw-medium">{result?.total || 0}</span>
          </div>
        </div>

        <div className="mt-3">
          {result?.data?.map(
            (
              item: {
                rating: number | null
                total: number
              },
              index: number
            ) => (
              <Row key={index} className="align-items-center g-2">
                <div className="col-auto">
                  <div className="p-2">
                    <h6 className="mb-0">
                      {item?.rating !== null ? item.rating : 0} {t('star')}
                    </h6>
                  </div>
                </div>
                <div className="col">
                  <div className="p-2">
                    <Progress value={item?.rating || 0} min={0} max={5} />
                  </div>
                </div>
                <div className="col-auto">
                  <div className="p-2">
                    <h6 className="mb-0 text-muted">{item?.total || 0}</h6>
                  </div>
                </div>
              </Row>
            )
          )}
        </div>
      </ViewDataWithLoading>
    </div>
  )
}

export default Rating

const getStarIcons = (average) => {
  const fullStars = Math.floor(average)
  const halfStars = average % 1 >= 0.5 ? 1 : 0
  const emptyStars = 5 - fullStars - halfStars

  return (
    <>
      {[...Array(fullStars)].map((_, i) => (
        <i key={`full-${i}`} className="ri-star-fill"></i>
      ))}
      {halfStars === 1 && <i className="ri-star-half-fill"></i>}
      {[...Array(emptyStars)].map((_, i) => (
        <i key={`empty-${i}`} className="ri-star-line"></i>
      ))}
    </>
  )
}
