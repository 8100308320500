import clsx from 'clsx'
import Loader from 'Components/Common/Loader'
import FetchHock from 'helpers/FetchHock'
import React from 'react'
import CountUp from 'react-countup'
import { Row, Col, Card, CardBody } from 'reactstrap'

function OrdersCardsReport() {
  const { result, loading } = FetchHock({
    path: '/orders/cards/report'
  })

  if (loading)
    return (
      <div
        style={{
          height: '300px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <Loader />
      </div>
    )

  const getIcon = (code) => {
    const iconMap = {
      wait: 'mdi mdi-clock-outline',
      completed: 'mdi mdi-check-circle-outline',
      refused: 'mdi mdi-close-circle-outline',
      shipping: 'mdi mdi-truck-outline',
      processing: 'mdi mdi-cog-outline'
    }

    return iconMap[code] || null
  }

  const getDescriptionByCode = (code) => {
    const descriptionMap = {
      wait: 'Pending',
      completed: 'Order has been completed',
      refused: 'Order was cancelled',
      shipping: 'Order is being shipped',
      processing: 'Order is being processed'
    }

    return descriptionMap[code] || null
  }
  const items = result.map((item: any) => {
    return {
      id: 1,
      label: item.title,
      percentage: item.code,
      percentageClass: 'success',
      icon: getIcon(item.code),
      counter: item.total || 0,
      badge: '2,258',
      caption: getDescriptionByCode(item.code),
      feaIcon: 'file-text',
      decimals: 1,
      prefix: '',
      suffix: ''
    }
  })

  return (
    <Row>
      {items?.map((item, key) => (
        <React.Fragment key={key}>
          <Col xl={3} md={4}>
            <Card className="card-animate">
              <CardBody>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    <p className="text-uppercase fw-medium text-muted mb-0">{item.label}</p>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-4">
                  <div>
                    <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                      <CountUp
                        start={0}
                        prefix={item.prefix}
                        suffix={item.suffix}
                        end={item.counter}
                        duration={4}
                        className="counter-value"
                      />
                    </h4>
                    {/* <span className="badge bg-warning me-1">{invoicewidget.badge}</span>{' '} */}
                    <span className="text-muted"> {item.caption}</span>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title bg-primary-subtle rounded fs-3 ">
                      <i className={`text-primary ${item.icon}`}></i>
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </React.Fragment>
      ))}
    </Row>
  )
}

export default OrdersCardsReport
