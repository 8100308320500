import FetchHook from 'helpers/FetchHock'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap'
import ImageWithFallback from 'helpers/ImageComponent'
import { UseProfile } from 'helpers/UserHooks'

const ProfileDropdown = () => {
  const { t } = useTranslation()
  const { userProfile } = UseProfile()

  const [isProfileDropdown, setIsProfileDropdown] = useState(false)
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown)
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user">
        <DropdownToggle tag="button" type="button" className="btn">
          <ProfileImageWithName />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">{/* {t('Welcome')} {userName}! */}</h6>
          <DropdownItem className="p-0">
            <Link to="/profile" className="dropdown-item">
              <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle"> {t('Profile')}</span>
            </Link>
          </DropdownItem>
          {/* <DropdownItem className="p-0">
            <Link to="/apps-chat" className="dropdown-item">
              <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>{' '}
              <span className="align-middle">{t('Messages')}</span>
            </Link>
          </DropdownItem>
          <DropdownItem className="p-0">
            <Link to={'#'} className="dropdown-item">
              <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>{' '}
              <span className="align-middle">{t('Taskboard')}</span>
            </Link>
          </DropdownItem> */}
          <DropdownItem className="p-0">
            <Link to="/pages-faqs" className="dropdown-item">
              <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>{' '}
              <span className="align-middle">{t('Help')}</span>
            </Link>
          </DropdownItem>
          <div className="dropdown-divider"></div>
          <DropdownItem className="p-0">
            <Link to="/pricing" className="dropdown-item">
              <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>{' '}
              <span className="align-middle">
                {t('Expiry')} : <b>{userProfile?.package_remaining}</b>
              </span>
            </Link>
          </DropdownItem>
          <DropdownItem className="p-0">
            <Link to="/settings" className="dropdown-item">
              <span className="badge bg-success-subtle text-success mt-1 float-end">
                {t('New')}
              </span>
              <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{' '}
              <span className="align-middle">{t('Settings')}</span>
            </Link>
          </DropdownItem>
          <DropdownItem className="p-0">
            <Link to="/auth-lockscreen-basic" className="dropdown-item">
              <i className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>{' '}
              <span className="align-middle">{t('Lock screen')}</span>
            </Link>
          </DropdownItem>
          <DropdownItem className="p-0">
            <Link to="/logout" className="dropdown-item">
              <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{' '}
              <span className="align-middle" data-key="t-logout">
                {t('Logout')}
              </span>
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default ProfileDropdown

const ProfileImageWithName = () => {
  const {
    result: profile,
    loading,
    error
  } = FetchHook({
    path: '/profile'
  })

  if (loading) {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          minWidth: '100px'
        }}>
        <Spinner size={'sm'} color="primary" />
      </div>
    )
  }

  return (
    <span className="d-flex align-items-center">
      <img
        className="rounded-circle header-profile-user object-fit-contain"
        src={profile?.image || '/img-error.png'}
        alt="Header Avatar"
      />
      <span className="text-start ms-xl-2">
        <span className=" ms-1 fw-medium user-name-text">{profile?.name}</span>
        <span className="d-none d-xl-block ms-1 fs-13 text-muted user-name-sub-text">
          {profile?.email}
        </span>
      </span>
    </span>
  )
}
