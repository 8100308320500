import { setUserAction, removeUseAction } from './reducer'

export const setUser = (userData: any) => async (dispatch: any) => {
  try {
    dispatch(setUserAction(userData))
  } catch (error) {
    console.log(error)
  }
}

export const removeUse = () => async (dispatch: any) => {
  try {
    dispatch(removeUseAction())
  } catch (error) {
    console.log(error)
  }
}
