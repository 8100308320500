import parse from 'html-react-parser'

const ReadOnlyContent = ({ label, value }: { label: string; value: string }) => {
  const data = value && parse(value)
  return (
    <div className="mb-3">
      <label className="form-label">{label || ''}</label>
      <div className="form-control ">{data}</div>
    </div>
  )
}

export default ReadOnlyContent
