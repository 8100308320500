import { Button, ButtonGroup, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Input, Submit } from 'Components/Form'
import { Controller } from 'react-hook-form'
import UpdateHock from 'helpers/UpdateHock'
import PopUp from 'Components/PopUp'
type UpdateStock = {
  id?: number
  quantity?: number
  quantity_direction?: 1 | -1
}
const UpdateModal = ({ show, close, onSuccess, itemId }: any) => {
  const { t } = useTranslation()

  const { register, handleSubmit, errors, loading, update, getLoading, control } =
    UpdateHock<UpdateStock>({
      path: '/stocks',
      initValue: {
        quantity: null,
        quantity_direction: 1
      },
      editId: itemId,
      allowGet: false
    })

  const submit = async (data: UpdateStock) => {
    const quantity = data?.quantity * data.quantity_direction
    const response = await update({ quantity })
    if (response.status) {
      const translationKey =
        data.quantity_direction > 0 ? 'inventory_increase_success' : 'inventory_decrease_success'
      const message = t(translationKey, { quantity: Math.abs(quantity) })
      close()
      onSuccess()
    }
  }
  return (
    <PopUp title={t('Update') + ' ' + t('Quantity')} show={show} close={close} loading={getLoading}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <div className="d-flex gap-2">
              <Input
                type="number"
                wrapperClassName="flex-grow-1"
                placeholder={`${t('Enter')} ${t('Quantity')}`}
                model={register('quantity', {
                  required: t('Quantity is required')
                })}
                error={errors.quantity}
              />
              <Controller
                control={control}
                name={'quantity_direction'}
                rules={{
                  required: t('is required')
                }}
                render={({ field }) => (
                  <div>
                    <ButtonGroup className="align-items-baseline">
                      <Button
                        color="success"
                        outline
                        onClick={() => field.onChange(1)}
                        active={field.value === 1}>
                        <i className="ri ri-arrow-up-line"></i>
                      </Button>
                      <Button
                        color="danger"
                        outline
                        onClick={() => field.onChange(-1)}
                        active={field.value === -1}>
                        <i className="ri ri-arrow-down-line"></i>
                      </Button>
                    </ButtonGroup>
                  </div>
                )}
              />
            </div>
          </Row>
        </Container>
        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Update')} />
        </div>
      </form>
    </PopUp>
  )
}
export default UpdateModal
