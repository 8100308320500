// for use-advanced-marker warning you can use
// https://visgl.github.io/react-google-maps/docs/api-reference/components/marker

import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import clsx from 'clsx'
import showError from 'helpers/ErorrNotfication'
import { useCallback, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button } from 'reactstrap'

const mapOptions = {
  streetViewControl: false
}

function GoogleMapComponent({ value, onChange, error }) {
  const { t } = useTranslation()
  const { getValues, watch } = useFormContext()

  const [map, setMap] = useState(null)

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''
  })

  const onLoad = useCallback(
    (map) => {
      setMap(map)
      const address = getValues('address')

      if (value && value?.lat && value?.lng) {
        map?.panTo(value)
        return
      } else if (address) {
        getLatLngFromAddress(address)
        return
      } else {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              }
              map.panTo(pos)
            },
            () => {
              console.error('Error getting location rollback to Kuwait location')
              map.panTo({ lat: 29.378586, lng: 47.990341 })
            }
          )
        }
      }
    },
    [value]
  )

  const handleMapClick = (event) => {
    const lat = event.latLng.lat()
    const lng = event.latLng.lng()
    const latLng = { lat, lng }
    onChange(latLng)
  }

  if (loadError) {
    return <div>Error loading Google Maps</div>
  }

  const getLatLngFromAddress = (addr) => {
    if (!addr) {
      showError(t('Please enter address'))
      return
    }
    const geocoder = new google.maps.Geocoder()

    geocoder.geocode({ address: addr }, function (results, status) {
      if (status === 'OK') {
        const lat = results[0].geometry.location.lat()
        const lng = results[0].geometry.location.lng()
        const latLng = { lat, lng }
        onChange(latLng)
        map?.panTo(latLng)
      } else {
        showError(t('Cannot find address for ') + addr)
      }
    })
  }

  return isLoaded ? (
    <>
      <div className="d-flex gap-2 mb-3">
        <input
          className={clsx('form-control', error ? 'is-invalid' : '')}
          onChange={(e) => {
            const lat = e.target.value
            onChange({
              lat: lat ? parseFloat(lat) : undefined,
              lng: value?.lng
            })
          }}
          value={value?.lat}
          placeholder={`${t('Enter')} ${t('Latitude')}`}
        />
        <input
          value={value?.lng}
          className={clsx('form-control', error ? 'is-invalid' : '')}
          placeholder={`${t('Enter')} ${t('Longitude')}`}
          onChange={(e) => {
            const lng = e.target.value
            onChange({
              lat: value?.lat,
              lng: lng ? parseFloat(lng) : undefined
            })
          }}
        />
      </div>
      <Button
        className="mb-2"
        type="button"
        onClick={() => getLatLngFromAddress(getValues('address'))}>
        <i className="ri-map-pin-2-line"></i> {watch('address')}
      </Button>
      <div style={{ height: '400px', width: '100%', border: error ? '1px solid #f06548' : '' }}>
        <GoogleMap
          mapContainerStyle={{
            width: '100%',
            height: '100%'
          }}
          options={mapOptions}
          center={{
            lat: value?.lat || '',
            lng: value?.lng || ''
          }}
          zoom={10}
          onLoad={onLoad}
          onClick={handleMapClick}>
          {value && value.lat && value.lng && (
            <Marker
              draggable
              onDragEnd={(event) =>
                onChange({
                  lat: event.latLng.lat(),
                  lng: event.latLng.lng()
                })
              }
              position={value}
            />
          )}
        </GoogleMap>
      </div>
      <div
        style={{
          marginTop: '.25rem',
          fontSize: '0.875em',
          color: '#f06548'
        }}>
        {error?.message?.toString()}
      </div>
    </>
  ) : (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{
        height: '100%',
        width: '100%'
      }}>
      Loading Google Maps...
    </div>
  )
}

export default GoogleMapComponent
