// react imports
import { useParams } from 'react-router-dom'
// botstrap elemnts
import { Col, Container, Row } from 'reactstrap'
// page elements
// translation
// static files
import { PaymentCard, UserCard } from './Cards'
import DetailsCard from './Cards/Details'
import FetchHock from 'helpers/FetchHock'
import Loader from 'Components/Common/Loader'
import clsx from 'clsx'
import BreadCrumb from 'Components/Common/BreadCrumb'

const TransactionDetails = () => {
  document.title = 'Tickets Details | Sallatk'
  const { id } = useParams()

  const {
    result: items,
    loading,
    error,
    fetch,
    isFetching
  } = FetchHock<any>({
    path: `/transactions/${id}`
  })
  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center vh-100">
        <Loader />
      </div>
    )
  }
  return (
    <div className={clsx('page-content', isFetching && 'opacity-75')}>
      <Container fluid>
        <BreadCrumb />
        <Row>
          <Col xl={6}>
            <div data-aos="fade-up">
              <DetailsCard data={items} fetch={fetch} isFetching={isFetching} />
            </div>
          </Col>
          <Col xl={6}>
            <div data-aos="fade-up">
              <PaymentCard data={items} />
            </div>
          </Col>
          <Col xl={6}>
            <div data-aos="fade-up">
              <UserCard data={items} />
              {/* <DataCard data={items} /> */}
              {/* <StoreCard data={items} /> */}
              {/* <PackageCard data={items} /> */}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default TransactionDetails
