// notfications helper
import { toast, ToastPosition } from 'react-toastify'

const showError = (message: string, position?: ToastPosition) => {
  toast(message, {
    position: position || 'top-right',
    hideProgressBar: true,
    closeOnClick: false,
    className: 'bg-danger text-white'
  })
}

export default showError
