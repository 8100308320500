import Formula from 'ThemeManager/types/Formula/index'

const formula: Formula = [
  {
    title: 'Section Heading',
    label: 'sectionHeading',
    type: 'translateString'
  }
]
export default formula
