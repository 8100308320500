// react elements
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// bootstrap elements
import { Card, CardBody, Col, Container, CardHeader, Row } from 'reactstrap'
// reqired componnent
import BreadCrumb from 'Components/Common/BreadCrumb'
import { ImageUpload, Search, Submit, Switch, TextEditor, Textarea, Input } from 'Components/Form'
import CreateHook from 'helpers/CreateHook'
// import model type
import Create from '../models/Create'
import { withErrorBoundary } from 'react-error-boundary'

const AddArticle = () => {
  document.title = 'Create Article | Sallatk'

  const { t } = useTranslation()
  const history = useNavigate()
  const handleTitleChange = (title: string): string => title.replace(/[^\p{L}0-9 _-]/gu, '_')

  // prepare hock
  const {
    register,
    handleSubmit,
    errors,
    create,
    loading,
    closeUploading,
    openUploading,
    setValue,
    getValues
  } = CreateHook<Create>('/article', {
    title: '',
    slug: '',
    img_url: '',
    published: 0,
    content: '',
    category_id: undefined,
    meta: {
      title: '',
      keywords: '',
      description: ''
    }
  })

  const submit = async (data: Create) => {
    const response = await create(data)
    if (response.status) history(-1)
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <form onSubmit={handleSubmit(submit)}>
          <Row>
            <Col lg={8}>
              <Card>
                <CardBody>
                  <div className="mt-3 mb-3">
                    <Input
                      label={t('Title')}
                      placeholder={t('Enter title')}
                      model={register('title', {
                        required: t('Title is required'),
                        minLength: {
                          value: 3,
                          message: t('Title must be bigger than or equal 3')
                        }
                      })}
                      error={errors.title}
                      onChange={(value) => setValue('slug', handleTitleChange(value))}
                    />
                    <Input
                      label={t('Slug')}
                      placeholder={t('Enter slug')}
                      model={register('slug', {
                        required: t('Slug is required'),
                        minLength: {
                          value: 3,
                          message: t('Slug must be bigger than or equal 3')
                        }
                      })}
                      error={errors.slug}
                    />
                  </div>
                  <div>
                    <TextEditor
                      label={t('Content')}
                      placeholder={t('Enter content')}
                      model={register('content', {
                        required: t('Content is required'),
                        minLength: {
                          value: 3,
                          message: t('Content must be bigger than or equal 3')
                        }
                      })}
                      error={errors.content}
                      value={getValues('content')}
                    />
                  </div>
                </CardBody>
              </Card>
              <div className="hstack gap-2 justify-content-start">
                <Submit loading={loading} label={t('Create')} />
              </div>
            </Col>

            <Col lg={4}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">{t('Info')}</h5>
                </CardHeader>
                <CardBody>
                  <Switch
                    defaultChecked={false}
                    label={t('Published')}
                    model={register('published')}
                  />
                  <hr />
                  <Search
                    label={t('Category')}
                    placeholder={t('Enter category')}
                    model={register('category_id', {
                      required: t('Category is required')
                    })}
                    error={errors.category_id}
                    path="article_category"
                    propTitle="title"
                  />
                  <hr />
                  <ImageUpload
                    label={t('Image')}
                    model={register('img_url', {
                      required: t('Image is required')
                    })}
                    error={errors.img_url}
                    onUpload={openUploading}
                    onFinishUpload={closeUploading}
                  />
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">{t('SEO data')}</h5>
                </CardHeader>
                <CardBody>
                  <Input
                    label={t('Title')}
                    placeholder={t('Enter title')}
                    model={register('meta.title')}
                  />
                  <hr />
                  <Input
                    label={t('Keywords')}
                    placeholder={t('Enter keywords')}
                    model={register('meta.keywords')}
                  />
                  <hr />
                  <Textarea
                    label={t('Short description')}
                    placeholder={t('Enter short description')}
                    model={register('meta.description')}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </Container>
    </div>
  )
}

export default withErrorBoundary(AddArticle, {
  fallback: <div>Something went wrong</div>
})
