import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardHeader } from 'reactstrap'

function BranchDetails({ data }) {
  const { t } = useTranslation()
  return (
    <Card>
      <CardHeader>
        <h5 className="card-title mb-0">
          <i className="ri-map-pin-line align-middle me-1 text-muted"></i> {t('Branch Details')}
        </h5>
      </CardHeader>
      <CardBody>
        <ul className="list-unstyled vstack gap-2 fs-13 mb-0">
          <li className="fw-medium fs-14">{data?.name}</li>
          <li>
            <span className="fw-medium">{t('Address')}:</span> {data?.address}
          </li>
          <li>
            <span className="fw-medium">{t('City')}:</span> {data?.city_name}
          </li>
        </ul>
      </CardBody>
    </Card>
  )
}

export default BranchDetails
