import { TransactionsTable } from 'pages/Transactions/list'
import { useTranslation } from 'react-i18next'

function TransactionDetails({ data }) {
  const { t } = useTranslation()

  return <TransactionsTable data={data} title={t('Transaction Details')} />
}

export default TransactionDetails
