// bootstrap elements
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
// react link
import { Link } from 'react-router-dom'
// reqired componnent
import BreadCrumb from 'Components/Common/BreadCrumb'
// fetch hock logic
import FetchHock from 'helpers/FetchHock'
// translation pakage
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import Loader from 'Components/IndexTable/partials/Loader'
import Error from 'Components/IndexTable/partials/Error'
import Empty from 'Components/IndexTable/partials/Empty'
import Pagination from 'Components/IndexTable/partials/Pagination'
import CreateModal from '../components/CreateModal'
import UpdateModal from '../components/UpdateModal'
import DeleteModal from 'Components/IndexTable/partials/DeleteModal'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'

type brand = {
  web_image: string
  id: number
  name: string
  mobile_image: string
  published: boolean
}

const BrandsList = () => {
  document.title = 'Brands | Sallatk'

  const { t } = useTranslation()

  // create modal logic
  const [itemIdUpdate, changeItemIdUpdate] = useState<number>()
  const [showUpdate, changeShowUpdate] = useState<boolean>(false)
  const [showCreate, changeShowCreate] = useState<boolean>(false)
  const [index, setIndex] = useState<any>(-1)

  const openCreate = () => changeShowCreate(true)
  const closeCreate = () => changeShowCreate(false)
  const openUpdate = () => changeShowUpdate(true)
  const closeUpdate = () => changeShowUpdate(false)

  const {
    items,
    loading,
    error,
    options,
    totalPages,
    totalItems,
    pagenate,
    openDelete,
    closeDelete,
    deleteItem,
    showedItems,
    deleteId,
    fetch
  } = FetchHock<brand>({ path: '/brands' })
  const slideGallery = items?.map((item) => ({ src: item.mobile_image }))

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <CardHeader className="card-header border-0">
          <Row className="align-items-center gy-3 mb-3">
            {/* card title */}
            <div className="col-sm"></div>
            {/* space auto */}
            <div className="col-sm-auto">
              {/* action buttons */}
              <div className="d-flex gap-1 flex-wrap">
                <button type="button" className="btn btn-secondary add-btn" onClick={openCreate}>
                  <i className="ri-add-circle-line align-middle me-1"></i> {t('Add new brand')}
                </button>
              </div>
            </div>
          </Row>
        </CardHeader>
        <Row>
          <Col lg={12}>
            <div className="">
              <CardBody>
                <Row>
                  {items?.map((item, index) => (
                    <Col
                      xl={3}
                      lg={3}
                      md={4}
                      xs={6}
                      className="element-item project designing development"
                      key={index}>
                      <Card className="gallery-box">
                        <div className="gallery-container">
                          <Link to="#" className="image-popup" onClick={() => setIndex(index)}>
                            <div className="image-container">
                              <img src={item.mobile_image} />
                            </div>
                            <div className="gallery-overlay">
                              <h5 className="overlay-caption">{item.name}</h5>
                            </div>
                          </Link>
                        </div>

                        <div className="box-content">
                          <div className="d-flex align-items-center justify-content-between mt-1">
                            <div className="flex-shrink-0">
                              <div className="d-flex gap-3">
                                <button
                                  type="button"
                                  onClick={() => {
                                    openUpdate()
                                    changeItemIdUpdate(item.id)
                                  }}
                                  className="btn btn-sm fs-14 btn-link text-decoration-none px-0 text-success">
                                  <i className="ri-edit-2-line align-bottom me-1 text-success"></i>
                                </button>

                                <button
                                  type="button"
                                  onClick={() => openDelete(item.id)}
                                  className="btn btn-sm fs-14 btn-link text-decoration-none px-0 text-danger">
                                  <i className="ri-delete-bin-line align-bottom me-1  text-danger"></i>
                                </button>
                              </div>
                            </div>

                            <div className="text-muted">
                              {item.published ? (
                                <span className="badge rounded-pill bg-success-subtle text-success">
                                  <i className="ri-checkbox-circle-line align-middle text-success"></i>{' '}
                                  {t('Published')}
                                </span>
                              ) : (
                                <span className="badge rounded-pill bg-danger-subtle text-danger">
                                  <i className="ri-close-circle-line align-middle text-danger"></i>{' '}
                                  {t('Not Published')}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </CardBody>
            </div>
          </Col>
        </Row>
        {/* loader */}
        {loading && <Loader />}
        {!loading && !!error && <Error />}
        {!loading && !error && totalItems == 0 && <Empty />}
        {/* pagination */}
        {totalPages > 1 && (
          <Pagination
            options={options}
            totalPages={totalPages}
            totalItems={totalItems}
            showedItems={showedItems}
            pagenate={pagenate}
          />
        )}
      </Container>
      {showCreate && <CreateModal close={closeCreate} show={openCreate} onSuccess={fetch} />}
      {showUpdate && (
        <UpdateModal
          close={closeUpdate}
          show={openUpdate}
          onSuccess={fetch}
          itemId={itemIdUpdate}
        />
      )}
      {/* delete component */}
      {deleteId && deleteId > 0 && (
        <DeleteModal
          show={deleteId}
          onDeleteClick={deleteItem}
          onCloseClick={() => {
            if (closeDelete) closeDelete()
          }}
        />
      )}
      <Lightbox index={index} slides={slideGallery} open={index >= 0} close={() => setIndex(-1)} />
    </div>
  )
}

export default BrandsList
