function CardItem({ title, value, Component }: any) {
  return (
    <div className="d-flex my-2 align-items-center">
      <div className="flex-shrink-0">
        <p className="text-muted mb-0">{title}</p>
      </div>
      {value && (
        <div className=" ms-2">
          <h6 className="mb-0">{value}</h6>
        </div>
      )}
      <div className="ms-2">{Component && <Component />}</div>
    </div>
  )
}

export default CardItem
