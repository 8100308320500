import { MultiImageUpload } from 'Components/Form'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const ProductFeaturesMultiImageUpload = () => {
  const { t } = useTranslation()
  const {
    register,
    setValue,
    getValues,
    formState: { errors }
  } = useFormContext()
  function handleChangeImages(files: any) {
    setValue('selectedFiles', files)
  }
  return (
    <MultiImageUpload
      label={t('Images')}
      model={register(`images`)}
      placeholder={t('Drop files here or click to upload')}
      onChange={handleChangeImages}
      value={
        getValues('selectedFiles')?.map((file) => file.name) ||
        getValues('images')?.map((image: any) => image.image)
      }
      images={
        getValues('selectedFiles')?.map((file) => file.preview) ||
        getValues('images')?.map((image: any) => image.web_image)
      }
    />
  )
}
export default ProductFeaturesMultiImageUpload
