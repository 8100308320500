import Pagination from 'Components/IndexTable/partials/Pagination'
import ViewDataWithLoading from 'Components/ViewDataWithLoading'
import FetchHock from 'helpers/FetchHock'
import ImageWithFallback from 'helpers/ImageComponent'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardHeader } from 'reactstrap'

const RecentOrders = () => {
  const { t } = useTranslation()

  const {
    items: data,
    loading,
    error,
    options,
    totalPages,
    totalItems,
    showedItems,
    pagenate
  } = FetchHock<any>({
    path: '/dashboard/last-week-orders',
    limit: 5
  })

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">{t('Last Week Orders')}</h4>
          <div className="flex-shrink-0">
            <button type="button" className="btn btn-soft-info btn-sm">
              <i className="ri-file-list-3-line align-middle"></i> Generate Report
            </button>
          </div>
        </CardHeader>

        <CardBody>
          <div className="table-responsive table-card">
            <ViewDataWithLoading loading={loading} items={data} error={error}>
              <table className="table table-borderless table-centered align-middle table-nowrap mb-0">
                <thead className="text-muted table-light">
                  <tr>
                    <th scope="col">{t('Order ID')}</th>
                    <th scope="col">{t('User')}</th>
                    <th scope="col">{t('Order Status')}</th>
                    <th scope="col">{t('Total')}</th>
                  </tr>
                </thead>
                <tbody>
                  {(data || []).map(
                    (
                      item: {
                        id: number
                        user: {
                          name: string
                          lastname: string
                          image: string
                        }
                        order_status: {
                          id: number
                          code: string
                          title: string
                        }
                        total: number
                        currency: string
                        created_at: string
                      },
                      key
                    ) => (
                      <tr key={key}>
                        <td>
                          <Link
                            to="/apps-ecommerce-order-details"
                            className="fw-medium link-primary">
                            {item?.id}
                          </Link>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0 me-2">
                              <ImageWithFallback
                                src={item?.user?.image}
                                className="avatar-xs rounded-circle"
                              />
                            </div>
                            <div className="flex-grow-1">
                              {item?.user?.name} {item?.user?.lastname}
                            </div>
                          </div>
                        </td>
                        <td>
                          <CodeBadge order_status={item.order_status} />
                        </td>
                        <td>
                          <span className="text-success">
                            {item?.currency}
                            {item?.total}
                          </span>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </ViewDataWithLoading>
          </div>
          {totalPages > 1 && (
            <Pagination
              options={options}
              totalPages={totalPages}
              totalItems={totalItems}
              showedItems={showedItems}
              pagenate={pagenate}
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default RecentOrders

const CodeBadge = ({ order_status }: any) => {
  const { t } = useTranslation()
  switch (order_status?.code) {
    case 'completed':
      return <span className="badge bg-success-subtle text-success">{order_status?.title}</span>
    case 'wait':
      return <span className="badge bg-warning-subtle text-warning">{order_status?.title}</span>
    default:
      return <span className="badge bg-light"></span>
  }
}
