import BreadCrumb from 'Components/Common/BreadCrumb'
import { Switch } from 'Components/Form'
import ViewDataWithLoading from 'Components/ViewDataWithLoading'
import FetchHock from 'helpers/FetchHock'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap'

import ImageWithFallback from 'helpers/ImageComponent'
import UpdateHock from 'helpers/UpdateHock'
import CreateModal from '../Components/CreateModal'

export type SettingsItem = {
  key: string
  required: 0 | 1
  type: 'number' | 'text'
  value: string | null
}

export type SettingsGroup = {
  id: number
  group: string
  logo: string
  enabled: 0 | 1
  items: SettingsItem[]
}

const ApiIntegrationsList = () => {
  document.title = 'Payment methods | Sallatk'

  const { t } = useTranslation()

  const { items, loading, error, invalidate, fetch } = FetchHock<SettingsGroup>({
    path: '/settings-cstm'
  })

  const [updateItem, changeUpdateItem] = useState(null)
  const [showCreate, changeShowCreate] = useState<boolean>(false)
  const openCreate = (it) => {
    changeUpdateItem(it)
    changeShowCreate(true)
  }
  const closeCreate = () => {
    changeShowCreate(false)
    changeUpdateItem(null)
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <ViewDataWithLoading items={items} loading={loading} error={error}>
          <Row className="mt-4">
            {items.map((item, key) => (
              <Col key={key} xl={3} lg={4} md={6} sm={6} className="col">
                <Card className="ribbon-box right overflow-hidden">
                  <CardBody className=" text-center p-4 d-flex flex-column align-items-center">
                    <ImageWithFallback src={item.logo} alt={item.group} height={55} />
                    <h5 className="my-3">{item.group}</h5>

                    <ChangeStatus item={item} onSuccess={invalidate} />
                    <Button
                      size="sm"
                      color="primary"
                      onClick={() => {
                        openCreate(item)
                      }}>
                      {t('Settings')}
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </ViewDataWithLoading>
      </Container>
      {showCreate && (
        <CreateModal data={updateItem} close={closeCreate} show={openCreate} onSuccess={fetch} />
      )}
    </div>
  )
}

export default ApiIntegrationsList

function ChangeStatus({ item, onSuccess }) {
  const { t } = useTranslation()
  const { update, loading } = UpdateHock({
    path: `/settings-cstm/${item.id}/status`,
    allowGet: false
  })
  const updateStatus = async () => {
    const response = await update({})
    if (response.status) {
      await onSuccess()
    }
  }
  return (
    <Switch loading={loading} label={''} checked={item.enabled} onChange={updateStatus} />
  )
}
