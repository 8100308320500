import { Button, Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Checkbox, ImageUpload, Input, Select, Submit, Textarea } from 'Components/Form'
import UpdateHock from 'helpers/UpdateHock'
import PopUp from 'Components/PopUp'
import { useParams } from 'react-router-dom'

const UpdateModal = ({ show, close, onSuccess, data }: any) => {
  const { t } = useTranslation()

  type bannertype = {
    id?: number
    title: string
    description: string
    free: 0 | 1
    active: 0 | 1
    duration: string
    url_show?: string
    type: 'video' | 'doc' | 'audio' | 'file' | null
    url: string
  }

  // init value
  const initForm: bannertype = {
    title: '',
    description: '',
    free: 0,
    active: 0,
    duration: '',
    type: null,
    url: ''
  }

  // prepare hock
  const { register, handleSubmit, errors, loading, update, getLoading, getValues } =
    UpdateHock<bannertype>({
      path: `/chapter_course_product/${data.product_course_chapter_id}/assets`,
      initValue: initForm,
      editId: data.id
    })

  const submit = async (data: bannertype) => {
    const response = await update(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  return (
    <PopUp title={t('Update shipping company')} show={show} close={close} loading={getLoading}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={12}>
              <ImageUpload
                label={t('File')}
                placeholder={t('Select file')}
                model={register('url')}
                value={getValues('url_show')}
                error={errors.url}
                anyFiles
              />
            </Col>
            <Col md={12}>
              <Input
                label={t('Title')}
                type="text"
                placeholder={t('Enter title')}
                model={register('title', {
                  required: t('Title is required'),
                  minLength: {
                    value: 3,
                    message: t('Title must be bigger than or equal 3')
                  }
                })}
                error={errors.title}
              />
            </Col>
            <Col md={12}>
              <Textarea
                label={t('Description')}
                placeholder={t('Enter description')}
                model={register('description')}
                error={errors.description}
              />
            </Col>

            <Col md={12}>
              <Input
                label={t('Duration')}
                type="text"
                placeholder={t('Enter duration')}
                model={register('duration')}
                error={errors.duration}
              />
            </Col>
            <Col md={12}>
              <Select
                label={t('Type')}
                placeholder={t('Select type')}
                model={register('type')}
                options={[
                  {
                    label: t('Video'),
                    value: 'video'
                  },
                  {
                    label: t('Doc'),
                    value: 'doc'
                  },
                  {
                    label: t('Audio'),
                    value: 'audio'
                  },
                  {
                    label: t('File'),
                    value: 'file'
                  }
                ]}
                value={getValues('type')}
                error={errors.type}
              />
            </Col>
            <Col md={6}>
              <Checkbox label={t('Free')} model={register('free')} error={errors.free} />
            </Col>
            <Col md={6}>
              <Checkbox label={t('Active')} model={register('active')} error={errors.active} />
            </Col>
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Update')} />
        </div>
      </form>
    </PopUp>
  )
}
export default UpdateModal
