import { useTranslation } from 'react-i18next'
import { CardBody, CardHeader, Col, Row } from 'reactstrap'

export default function Features({ item, my }: { item: Package; my: MyType }) {
  const { t } = useTranslation()
  return (
    <CardBody className="border-start mt-4 mt-lg-0">
      <CardHeader className="bg-light">
        <h5 className="fs-15 mb-0">{t('Plan Features')}</h5>
      </CardHeader>
      <CardBody className="pb-0">
        <Row className="row-gap-3 ">
          {item?.features.map((feature, index) => (
            <Col md={6} key={index} className="">
              <div className="d-flex">
                <div className={`flex-shrink-0 text-${'success'} me-1`}>
                  <i className={`ri-${'checkbox'}-circle-fill fs-15 align-middle`}></i>
                </div>
                <div className="flex-grow-1 text-start">{feature?.title}</div>
              </div>
            </Col>
          ))}
        </Row>
      </CardBody>
    </CardBody>
  )
}
