import { useTranslation } from 'react-i18next'

const StatusBadge = ({ status }: { status: string }) => {
  const { t } = useTranslation()

  switch (status) {
    case 'paid':
      return <span className="badge text-uppercase bg-primary">{t('Paid')}</span>
    case 'pending':
      return <span className="badge text-uppercase bg-warning ">{t('Pending')}</span>
    case 'refused':
      return <span className="badge text-uppercase bg-danger ">{t('Refused')}</span>
    case 'canceled':
      return (
        <span className="badge text-uppercase bg-danger-subtle text-danger">{t('Canceled')}</span>
      )
  }
}

export default StatusBadge
