import { useQuery } from '@tanstack/react-query'
import { queryString } from 'helpers/queryString'
import http from 'services/axios'

const fetchProducts = async (queryObj: Object) => {
  try {
    const res = await http.get(`/products${queryString(queryObj)}`)

    return res.data.result
  } catch (error) {
    console.error('Error fetching products:', error)
  }
}

export const usePOSProducts = (queryObj: Object) => {
  return useQuery({
    queryKey: ['pos/products'],
    queryFn: () => fetchProducts(queryObj)
  })
}

const fetchProduct = async (productId: number) => {
  try {
    const res = await http.get(`/products/${productId}`)

    return res.data.result
  } catch (error) {
    console.error('Error fetching product:', error)
  }
}

export const usePOSProduct = (productId: number) => {
  return useQuery({
    queryKey: ['pos/product', productId],
    queryFn: () => fetchProduct(productId)
  })
}