// react elements
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// bootstrap elements
import { Col, Container, Row, Card, CardBody, CardHeader, Nav, NavItem, NavLink } from 'reactstrap'
// reqired componnent
import BreadCrumb from 'Components/Common/BreadCrumb'
import ShippingCompanyCard from './components/shippingCompanyCard'
import Areas from './components/areas'
import axios from 'axios'
import linkGenerator from 'helpers/linkGenerator'

const AddArticle = () => {
  document.title = 'Shipping Priceing | Sallatk'

  const { t } = useTranslation()
  // Custom Tabs Bordered
  const [customNav, setcustomNav] = useState<number>()
  const customNavtoggle = (tab: number) => setcustomNav(tab)

  // branches logic
  const [branches, setBranches] = useState<{ id: number; name: string }[]>([])
  const [selectedBranch, setSelectedBranch] = useState<number>()
  // get data from server
  const [branchesLoading, setBranchesLoading] = useState<boolean>(false)
  useEffect(() => {
    setBranchesLoading(true)
    axios.get(linkGenerator('/branches')).then((response) => {
      setBranches(response.data.result.data)
      customNavtoggle(response.data.result.data[0].id)
      setSelectedBranch(response.data.result.data[0].id)
      setBranchesLoading(false)
    })
  }, [])

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <Row>
          <Col md={3}>
            <ShippingCompanyCard />
          </Col>
          <Col md={9}>
            {!branchesLoading && (
              <Nav className="nav-tabs mb-0 border nav-justified">
                {branches.map((brnach, index) => (
                  <NavItem key={index}>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={customNav === brnach.id ? 'active rounded-0' : ''}
                      onClick={() => {
                        customNavtoggle(brnach.id)
                        setSelectedBranch(brnach.id)
                      }}>
                      <i className="las la-code-branch me-2"></i>
                      {brnach.name}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            )}
            <Card className="radius-top-0">
              <CardHeader>
                <h5 className="mb-0">{t('Shipping prices')}</h5>
              </CardHeader>
              <CardBody>{selectedBranch && <Areas branchId={selectedBranch} />}</CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AddArticle
