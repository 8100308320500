import BreadCrumb from 'Components/Common/BreadCrumb'
import Error from 'Components/IndexTable/partials/Error'
import FetchHock from 'helpers/FetchHock'
import { useState } from 'react'
import { useLocation } from 'react-router'
import { Container, Spinner } from 'reactstrap'
import { TabsData } from '../models/types'
import SettingsTabs from './SettingsTabs'

function GeneralSettings() {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const groupParam = searchParams.get('group')

  const { items, loading, error } = FetchHock<TabsData>({
    path: `/settings`,
    initFilter: { group_name: groupParam ? groupParam : '' }
  })

  const [refresh, setRefresh] = useState<boolean>(false)
  const refreshReport = () => setRefresh(!refresh)

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 250px)'
        }}>
        <Spinner color="primary" />
      </div>
    )
  }
  if (error) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 250px)'
        }}>
        <Error />
      </div>
    )
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        {items ? <SettingsTabs data={items} /> : null}
      </Container>
    </div>
  )
}

export default GeneralSettings
