import { useTranslation } from 'react-i18next'
import { Progress } from 'reactstrap'

function CardComponent({ lang }: any) {
  const { t } = useTranslation()
  return (
    <div className="card-animate card mb-md-0">
      <div className="card-body">
        <div className="d-flex align-items-end justify-content-between">
          <div>
            <h4 className="fs-22 fw-semibold ff-secondary">
              <span className="counter-value" data-target="559.25">
                <span>{lang?.code}</span>
              </span>
            </h4>
          </div>
          <div className="avatar-sm flex-shrink-0">
            {lang?.default === 'true' ? (
              <span className="avatar-title rounded fs-3 bg-success-subtle">
                <i className="text-success bx bx-check"></i>
              </span>
            ) : null}
          </div>
        </div>
        <div className="mt-3">
          <p className="mb-1">
           &nbsp; <span className="float-end">{lang?.percentage}%</span>
          </p>
          <Progress
            striped
            className="mt-2"
            color={lang?.percentage === 100 ? 'success' : 'danger'}
            style={{ height: '6px' }}
            value={lang?.percentage}
            animated
            max="100"></Progress>
        </div>
      </div>
    </div>
  )
}

export default CardComponent
