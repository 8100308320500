import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import ProfileForm from './ProfileForm'
import ChangePasswordForm from './ChangePasswordForm'

function ProfileInfoTabs({ profile }: any) {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState('1')
  const tabChange = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  return (
    <Card className="mt-xxl-n5">
      <CardHeader>
        <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === '1'
              })}
              onClick={() => {
                tabChange('1')
              }}
              type="button">
              {t('Personal Details')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to="#"
              className={classnames({
                active: activeTab === '2'
              })}
              onClick={() => {
                tabChange('2')
              }}
              type="button">
              {t('Change Password')}
            </NavLink>
          </NavItem>
        </Nav>
      </CardHeader>
      <CardBody className="p-4">
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <ProfileForm profile={profile} />
          </TabPane>
          <TabPane tabId="2">
            <ChangePasswordForm />
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  )
}

export default ProfileInfoTabs
