// bootstrap elements
import { Card, CardHeader, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
// translation pakage
import { useTranslation } from 'react-i18next'

const Header = ({ showType, setShowType, openFilter }: any) => {
  const { t } = useTranslation()

  return (
    <Card className="categories-slider">
      <CardHeader className="card-header border-0">
        <Row className="align-items-center gy-3">
          {/* card title */}
          <div className="col-sm">
            <h5 className="card-title mb-0">
              <ol className="breadcrumb p-0 m-0">
                <li className="breadcrumb-item">
                  <Link to="#">{t('All')}</Link>
                </li>
                {/* <li className="breadcrumb-item active">{t('Chairs')}</li> */}
              </ol>
            </h5>
          </div>
          {/* space auto */}
          <div className="col-sm-auto">
            {/* action buttons */}
            <div className="d-flex gap-1 flex-wrap">
              {showType == 'card' && (
                <button type="button" className="btn btn-secondary" onClick={openFilter}>
                  <i className="mdi mdi-filter-outline"></i>
                </button>
              )}
              {showType == 'card' && (
                <button type="button" className="btn btn-soft-danger">
                  <i className="mdi mdi-filter-remove-outline"></i>
                </button>
              )}
              <button
                type="button"
                className={
                  showType == 'card' ? 'btn btn-soft-secondary' : 'btn btn-secondary add-btn'
                }
                onClick={() => setShowType('card')}>
                <i className="mdi mdi-border-all fs-16"></i>
              </button>
              <button
                type="button"
                className={
                  showType == 'table' ? 'btn btn-soft-secondary' : 'btn btn-secondary add-btn'
                }
                onClick={() => setShowType('table')}>
                <i className="mdi mdi-table"></i>
              </button>
            </div>
          </div>
        </Row>
      </CardHeader>
    </Card>
  )
}

export default Header
