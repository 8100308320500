// translation pakage
import headerColumn from 'Types/HeaderCloumn'
import { useTranslation } from 'react-i18next'

const ColumnsHeader = ({
  headerColumns,
  sort
}: {
  headerColumns: headerColumn[]
  sort?: (name: string) => void
}) => {
  const { t } = useTranslation()

  return (
    <thead className="table-light text-muted text-uppercase">
      <tr role="row">
        {headerColumns.map((column: any, index: number) => (
          <th
            title={column.accessor ? 'toggle SortBy' : ''}
            key={index}
            style={{ cursor: column.accessor ? 'pointer' : 'auto' , width: column.width ? column.width : 'auto'}}
            onClick={() => {
              if (column.accessor && sort) sort(column.accessor)
            }}
          >
            {!!column.accessor && <i className="las la-arrows-alt-v me-2"></i>} {t(column.title)}
          </th>
        ))}
      </tr>
    </thead>
  )
}

export default ColumnsHeader
