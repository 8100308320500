import { Card, CardHeader } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { withErrorBoundary } from 'react-error-boundary'
import FilterComponents from '../FilterComponents'
import { useProductContext } from '../../Context'

const Filter = () => {
  const { t } = useTranslation()
  // const [selectedMulti, setselectedMulti] = useState<any>(null)
  // const handleMulti = (selectedMulti: any) => setselectedMulti(selectedMulti)
  const { products } = useProductContext()
  return (
    <Card>
      {/* filter header */}
      <CardHeader>
        <div className="d-flex ">
          <div className="flex-grow-1">
            <h5 className="fs-16">{t('Filters')}</h5>
          </div>
          <div className="flex-shrink-0">
            <Link
              to="#"
              onClick={() => products.resetFilter()}
              className="text-decoration-underline">
              {t('Clear All')}
            </Link>
          </div>
        </div>

        {/* <div className="filter-choices-input">
          <Select
            value={selectedMulti}
            isMulti={true}
            onChange={(selectedMulti: any) => handleMulti(selectedMulti)}
            options={SingleOptions}
          />
        </div> */}
      </CardHeader>

      <FilterComponents />
    </Card>
  )
}

export default withErrorBoundary(Filter, {
  FallbackComponent: () => <div>Something went wrong</div>
})
