import { useMutation, useQuery } from '@tanstack/react-query'
import { POSContext } from 'pages/POS/POSContext'
import { useContext } from 'react'
import { toast } from 'react-toastify'
import http from 'services/axios'
import { CartItem } from 'types'

type CartRequest = {
  product_id: number
  product_feature_option_ids?: number[]
  form?: Object
  quantity: number
}

const addToCart = async (cartData: CartRequest) => {
  try {
    const res = await http.post(`/cart`, cartData)

    return res.data.result
  } catch (error) {
    console.error('Error posting cart:', error)
  }
}

export const useAddToCart = () => {
  const { setPopupIsOpen, setSelectedProduct } = useContext(POSContext)
  const { refetch } = usePOSGetCart()

  return useMutation({
    mutationFn: addToCart,
    onSuccess(data, variables, context) {
      refetch()
      console.log(data)
      if (!data) {
        return
      }
      toast.success('Product added to cart', { autoClose: 500 })
      setPopupIsOpen(false)
      setSelectedProduct(null)
    },
    onError(error, variables, context) {
      console.error('Error adding product to cart:', error)
    }
  })
}

const getCart = async () => {
  try {
    const res = await http.get(`/cart`)

    return res.data.result
  } catch (error) {
    console.error('Error getting cart:', error)
  }
}

export const usePOSGetCart = () => {
  return useQuery({
    queryKey: ['cart'],
    queryFn: getCart
  })
}

const removeItemFromCart = async (itemId: number) => {
  try {
    const res = await http.delete(`/cart/${itemId}`)

    return res.data.result
  } catch (error) {
    console.error('Error removing item from cart:', error)
  }
}

export const useRemoveItemFromCart = () => {
  const { refetch } = usePOSGetCart()
  return useMutation({
    mutationFn: (item: CartItem) => removeItemFromCart(item.id),
    onSuccess(data, variables, context) {
      refetch()
      toast.success('Item removed from cart', { autoClose: 500 })
    }
  })
}

type UpdateCartItemRequest = {
  quantity: number
  itemId: number
}
const updateCartItem = async (itemId: number, quantity: number): Promise<CartItem> => {
  try {
    const res = await http.put(`/cart/${itemId}`, { quantity })

    return res.data.result
  } catch (error) {
    console.error('Error updating cart item:', error)
  }
}

export const useUpdateCartItemMutation = () => {
  const { refetch } = usePOSGetCart()
  return useMutation({
    mutationFn: ({ itemId, quantity }: UpdateCartItemRequest) => updateCartItem(itemId, quantity),
    onSuccess: () => {
      refetch()
    }
  })
}
