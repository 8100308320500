import DeleteModal from 'Components/Common/DeleteModal'
import Loader from 'Components/Common/Loader'
import FetchHock from 'helpers/FetchHock'
import DynamicContent from 'pages/Products/Chapters/list/DynamicContent'
import { useState } from 'react'
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown
} from 'reactstrap'
import CreateModal from './components/CreateModal'
import UpdateModal from './components/UpdateModal'

type AssetItem = {
  id: number
  product_course_chapter_id: number
  title: string
  description: string
  sort: number
  free: number
  stream: string
  url: string
  duration: string
  type: string
  active: number
}

const ChapterAssets = ({ chaptersId, handleOpen, toggleMenubar }) => {
  const [itemIdUpdate, changeItemIdUpdate] = useState({})
  const [showUpdate, changeShowUpdate] = useState<boolean>(false)
  const [showCreate, changeShowCreate] = useState<boolean>(false)
  const openCreate = () => changeShowCreate(true)
  const closeCreate = () => changeShowCreate(false)
  const openUpdate = () => changeShowUpdate(true)
  const closeUpdate = () => changeShowUpdate(false)
  const {
    items: assets,
    loading,
    error,
    options,
    totalPages,
    totalItems,
    pagenate,
    openDelete,
    closeDelete,
    deleteItem,
    showedItems,
    deleteId,
    fetch
  } = FetchHock<AssetItem>({
    path: `/chapter_course_product/${chaptersId}/assets`
  })

  if (loading) {
    return <Loader />
  }
  return (
    <>
      <div className="file-manager-content w-100 p-3 py-0 border">
        <div className="mx-n3 pt-4 px-4 file-manager-content-scroll">
          <div id="folder-list" className="mb-2">
            <Row className="justify-content-beetwen g-2 mb-3 mb-4">
              <Col>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 me-2 d-block d-lg-none">
                    <button
                      onClick={() => toggleMenubar()}
                      type="button"
                      className="btn btn-soft-success btn-icon btn-sm fs-16 file-menu-btn">
                      <i className="ri-menu-2-fill align-bottom"></i>
                    </button>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="fs-16 mb-0">Assets</h5>
                  </div>
                </div>
              </Col>
              <Col className="col-auto">
                <div className="d-flex gap-2 align-items-start">
                  <button
                    className="btn btn-primary text-nowrap create-folder-modal flex-shrink-0"
                    // onClick={() => handleFolderClicks()}
                    onClick={() => openCreate()}>
                    <i className="ri-add-line align-bottom me-1"></i> Create Asset
                  </button>
                </div>
              </Col>
            </Row>
            <Row id="folderlist-data">
              {/* folders */}
              {assets.length > 0 ? (
                assets?.map((item, key) => (
                  <Col md={4} className="folder-card" key={key}>
                    <Card
                      className="bg-light shadow-none"
                      id={'folder-' + item?.id}
                      style={{
                        minWidth: 'fit-content'
                      }}>
                      <CardBody>
                        <div className="d-flex mb-1">
                          <div className="form-check form-check-danger mb-3 fs-15 flex-grow-1">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id={'folderlistCheckbox_' + item?.id}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={'folderlistCheckbox_' + item?.id}></label>
                          </div>

                          <UncontrolledDropdown>
                            <DropdownToggle
                              tag="button"
                              className="btn btn-ghost-primary btn-icon btn-sm dropdown">
                              <i className="ri-more-2-fill fs-16 align-bottom" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                              <DropdownItem
                                onClick={() => {
                                  handleOpen(item)
                                }}
                                className="view-item-btn">
                                View
                              </DropdownItem>
                              <DropdownItem
                                className="edit-folder-list"
                                onClick={() => {
                                  openUpdate()
                                  changeItemIdUpdate({
                                    id: item.id,
                                    product_course_chapter_id: item.product_course_chapter_id
                                  })
                                }}>
                                Edit
                              </DropdownItem>
                              <DropdownItem onClick={() => openDelete(item.id)}>
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>

                        <div className="text-center">
                          <div className="mb-2">
                            {/* <img src={item.stream} alt="" className="avatar-md object-cover " />
                        <video width={400} controls>
                          <source src={item.stream} type="video/mp4" />
                        </video> */}
                            <DynamicContent content={item} />
                            {/* <i className="ri-folder-2-fill align-bottom text-warning display-5"></i> */}
                          </div>
                          <h6 className="fs-15 folder-name">{item?.title}</h6>
                          <p>{item?.description}</p>
                        </div>
                        <div className="hstack mt-4 text-muted">
                          <span className="me-auto">
                            <b>{item?.type}</b>
                          </span>
                          <span>{item?.duration}</span>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))
              ) : (
                <div className="d-flex flex-column w-100 justify-content-center align-items-center">
                  <i className="ri-error-warning-line display-5"></i>
                  <p className="fw-semibold">No Data</p>
                </div>
              )}
            </Row>
          </div>
        </div>
      </div>

      {showCreate && (
        <CreateModal
          close={closeCreate}
          show={openCreate}
          onSuccess={fetch}
          data={{ chaptersId }}
        />
      )}
      {showUpdate && (
        <UpdateModal close={closeUpdate} show={openUpdate} onSuccess={fetch} data={itemIdUpdate} />
      )}
      {/* delete component */}
      {deleteId && deleteId > 0 && (
        <DeleteModal
          show={deleteId}
          onDeleteClick={deleteItem}
          onCloseClick={() => {
            if (closeDelete) closeDelete()
          }}
        />
      )}
    </>
  )
}

export default ChapterAssets
