import { useParams } from 'react-router-dom'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import Loader from 'Components/Common/Loader'
import FetchHock from 'helpers/FetchHock'
import RatingComponent from '../components/RatingComponent'
import ImageWithFallback from 'helpers/ImageComponent'
import ChatOnWhatsappLink from 'Components/ChatOnWhatsappLink'
import { PublishToggle } from '../list'
import BreadCrumb from 'Components/Common/BreadCrumb'

const CustomerReviewDetails = () => {
  document.title = 'Customer Review Details | Sallatk'
  const { t } = useTranslation()
  const { id } = useParams()

  const {
    result: data,
    loading,
    invalidate
  } = FetchHock<CustomersReviews>({
    path: `/comments/${id}`
  })

  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center min-vh-100">
        <Loader />
      </div>
    )
  // console.log(data)
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <Row>
          <Col xl={3}>
            <Card className="">
              <CardHeader>
                <h5 className="card-title mb-0">{t('Customer Details')}</h5>
              </CardHeader>
              <CardBody className="p-4">
                <div className="text-center d-flex justify-content-center gap-2 flex-column align-items-center">
                  <div className="profile-user position-relative d-inline-block mx-auto ">
                    <ImageWithFallback
                      src={data?.user?.image ?? 'https://picsum.photos/200'}
                      className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                      alt="user-profile"
                    />
                  </div>
                  <h5 className="fs-16 mb-1">{data?.user?.name + ' ' + data?.user?.lastname}</h5>
                  <p className="mb-0">{data?.user?.email}</p>
                  <p className="mb-0">{data?.user?.dialing_code + ' ' + data?.user?.phone}</p>
                  <div className="d-flex ">
                    <ChatOnWhatsappLink
                      phoneNumber={`${data?.user?.dialing_code}${data?.user?.phone}`}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">{t('Product')}</h5>
              </CardHeader>
              <CardBody>
                <div className="d-flex align-items-center flex-column">
                  <ImageWithFallback
                    className="mb-3 rounded-circle avatar-xl img-thumbnail user-profile-image object-fit-cover"
                    src={data?.product?.web_main_image}
                  />
                  <div className="d-flex align-items-center ">
                    <div className="flex-grow-1">
                      <h5 className="card-title mb-0">{data?.product.title || '...'}</h5>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={9}>
            <Card className="">
              <CardHeader>
                <h5 className="card-title mb-0">{t('Comment')}</h5>
              </CardHeader>
              <CardBody>
                <p>{data?.comment || '...'}</p>
                <p>
                  <strong>Rating: </strong>
                  <RatingComponent value={data.rating} />
                </p>
                <p>
                  <div className="d-flex gap-2 align-items-center">
                    <strong>published: </strong>
                    {data?.published === 1 ? '✅' : '❌'}
                    <PublishToggle
                      id={data?.id}
                      published={data?.published}
                      invalidateData={invalidate}
                    />
                  </div>
                </p>
                <p className="mb-0">
                  <strong>Created: </strong>
                  {data?.created_at || '...'}{' '}
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CustomerReviewDetails
