import clsx from 'clsx'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

const BreadCrumb = ({ title, pageTitle, pageTitleUrl = '#' }: any) => {
  const location = useLocation()
  const { t } = useTranslation()

  if (!title) {
    const breadcrumb = findBreadcrumb(pageMap, location.pathname)
    if (!breadcrumb || breadcrumb.length === 0) {
      return null
    }
    const lastItem = breadcrumb[breadcrumb?.length - 1]
    return (
      <Row>
        <Col xs={12}>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0">{t(lastItem?.title)}</h4>

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                {breadcrumb.map((page, index) => {
                  if (
                    page.path === location.pathname ||
                    matchDynamicPath(page.path, location.pathname)
                  ) {
                    return (
                      <li key={index} className={clsx('breadcrumb-item active')}>
                        {t(page?.title)}
                      </li>
                    )
                  }
                  return (
                    <li key={index} className={clsx('breadcrumb-item')}>
                      <Link to={page.path}>{t(page.title)}</Link>
                    </li>
                  )
                })}
              </ol>
            </div>
          </div>
        </Col>
      </Row>
    )
  }
  return (
    <Row>
      <Col xs={12}>
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-sm-0">{title}</h4>

          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item">
                <Link to={pageTitleUrl}>{pageTitle}</Link>
              </li>
              <li className="breadcrumb-item active">{title}</li>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default BreadCrumb
const matchDynamicPath = (pattern: string, path: string): boolean => {
  const regex = new RegExp(`^${pattern.replace(/:[^\s/]+/g, '([\\w-]+)')}$`)
  return regex.test(path)
}

const findBreadcrumb = (pages, pathname) => {
  for (const page of pages) {
    if (page.path === pathname || matchDynamicPath(page.path, pathname)) {
      return [page]
    }

    if (page.children) {
      const childBreadcrumb = findBreadcrumb(page.children, pathname)
      if (childBreadcrumb) {
        return [page, ...childBreadcrumb]
      }
    }
  }

  return null
}

const pageMap = [
  {
    title: 'Home',
    path: '/',
    children: [
      {
        title: 'Basic Settings',
        path: '/basic-settings',
        children: [
          {
            path: '/api-integrations',
            title: 'API Integrations'
          },
          {
            title: 'SEO',
            path: '/homepage-seo'
          },
          { title: 'Payment methods', path: '/payment-methods' },
          {
            title: 'Shipping companies',
            path: '/shipping',
            children: [
              {
                title: 'Cost',
                path: '/shipping/:id/cost'
              }
            ]
          },
          {
            title: 'Branches',
            path: '/branches',
            children: [
              {
                title: 'Contacts',
                path: '/branches/:id/contacts'
              },
              {
                title: 'Working hours',
                path: '/branches/:id/workingHours'
              },
              {
                title: 'areas',
                path: '/branches/:id/areas'
              },
              {
                title: 'Create',
                path: '/branches/create'
              },
              {
                title: 'Update',
                path: '/branches/:id'
              }
            ]
          },
          {
            title: 'Languages',
            path: '/languages'
          },
          {
            title: 'Currencies',
            path: '/currencies'
          }
        ]
      },
      {
        title: 'Advanced Settings',
        path: '/advanced-settings',
        children: [
          { title: 'Payment methods', path: '/payment-methods' },
          {
            title: 'Articles',
            path: '/articles/list',
            children: [
              {
                title: 'Create',
                path: '/articles/create'
              },
              {
                title: 'Update',
                path: '/articles/:id'
              }
            ]
          },
          {
            title: 'Articles Categories',
            path: '/articlesCategories/list'
          },
          {
            title: 'Static Pages',
            path: '/staticPages/list',
            children: [
              {
                title: 'Update',
                path: '/staticPages/117'
              }
            ]
          },
          {
            title: 'Stocks Management',
            path: '/stocks/list'
          },
          {
            title: 'Translations',
            path: '/translation',
            children: [
              {
                title: 'Update',
                path: '/translation/:type/edit/:lang'
              }
            ]
          },
          {
            title: 'Moderators',
            path: '/moderators/list'
          },
          {
            title: 'Roles',
            path: '/roles/list',
            children: [
              {
                title: 'Create',
                path: '/roles/create'
              },
              {
                title: 'Update',
                path: '/roles/:id'
              }
            ]
          },
          {
            title: 'Extra Fees',
            path: '/products/settings/extraFees/list'
          },
          {
            title: 'Partners',
            path: '/partners/list'
          },
          {
            title: 'Menu Custom Links',
            path: '/MenuCustomLinks/list'
          }
        ]
      },
      {
        title: 'Themes',
        path: '/themes',
        children: [
          { title: 'Header', path: '/menus/header' },
          {
            title: 'Footer',
            path: '/footer'
          },
          {
            title: 'Customize',
            path: '/themes/customize/:id'
          }
        ]
      },
      {
        title: 'Products',
        path: '/products/list',
        children: [
          {
            title: 'Details',
            path: '/products/view/:type/:id'
          },
          {
            title: 'Update',
            path: '/products/edit/:type/:id'
          },
          {
            title: 'Settings',
            path: '/products/settings',
            children: [
              {
                title: 'Labels',
                path: '/products/settings/labels/list',
                children: [
                  {
                    title: 'Values',
                    path: '/products/settings/labels/values/list/:id'
                  }
                ]
              },
              {
                title: 'Badges',
                path: '/products/settings/badges/list'
              },
              {
                title: 'Tags',
                path: '/products/settings/tags/list'
              },
              {
                title: 'Features',
                path: '/products/settings/features/list',
                children: [
                  {
                    title: 'Options',
                    path: '/products/settings/features/options/list/:id'
                  }
                ]
              },
              {
                title: 'Order forms',
                path: '/products/settings/forms/list',
                children: [
                  {
                    title: 'Preview',
                    path: '/products/settings/forms/preview/:id'
                  }
                ]
              }
            ]
          },
          {
            title: 'Create',
            path: '/products/create/:type'
          }
        ]
      },
      {
        title: 'Gallery',
        path: '/gallery'
      },
      {
        title: 'Banners',
        path: '/banners/list'
      },
      {
        title: 'Sliders',
        path: '/sliders/list'
      },
      {
        title: 'Categories',
        path: '/categories/list'
      },
      {
        title: 'Orders',
        path: '/orders/list',
        children: [
          {
            title: 'Create',
            path: '/orders/create'
          },
          {
            title: 'View',
            path: '/orders/:id'
          }
        ]
      },
      {
        title: 'Transactions',
        path: '/transactions/list',
        children: [
          {
            title: 'Details',
            path: '/transactions/:id'
          }
        ]
      },
      {
        title: 'Brands',
        path: '/brands/list'
      },
      {
        title: 'Sallatk Tickets',
        path: '/tickets/list'
      },
      {
        title: 'General Settings',
        path: '/settings'
      },
      {
        title: 'Basic Settings',
        path: '/basic-settings'
      },
      {
        title: 'Advanced Settings',
        path: '/advanced-settings'
      },
      {
        title: 'Newsletter Subscribers',
        path: '/news-letter-subscribers/list'
      },
      {
        title: 'Packages',
        path: '/pricing'
      },
      {
        title: 'Customers',
        path: '/customers/list'
      },
      {
        title: 'Customers Reviews',
        path: '/customers-reviews/list',
        children: [
          {
            title: 'Details',
            path: '/customers-reviews/:id'
          }
        ]
      },
      {
        title: 'Activities Log',
        path: '/activities/list',
        children: [
          {
            title: 'Details',
            path: '/activities/:id'
          }
        ]
      }
    ]
  }
]
