import BreadCrumb from 'Components/Common/BreadCrumb'
import IndexTable, { FilterItem, HeaderColumn } from 'Components/IndexTable'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'
import {
  Badge,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown
} from 'reactstrap'

import FetchHock from 'helpers/FetchHock'
import ImageWithFallback from 'helpers/ImageComponent'
import { useEffect, useState } from 'react'
import CreateModal from '../components/CreateModal'
import UpdateModal from '../components/UpdateModal'
import UpdatePasswordModal from '../components/UpdatePasswordModal'

const CustomersList = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const [itemIdUpdate, changeItemIdUpdate] = useState<number>()
  const [showUpdate, changeShowUpdate] = useState<boolean>(false)

  const [itemIdUpdatePassword, changeItemIdUpdatePassword] = useState<number>()
  const [showUpdatePassword, changeShowUpdatePassword] = useState<boolean>(false)

  const [showCreate, changeShowCreate] = useState<boolean>(false)

  const openCreate = () => changeShowCreate(true)
  const closeCreate = () => changeShowCreate(false)
  const openUpdate = () => changeShowUpdate(true)
  const closeUpdate = () => changeShowUpdate(false)
  const openUpdatePassword = () => changeShowUpdatePassword(true)
  const closeUpdatePassword = () => changeShowUpdatePassword(false)

  document.title = 'Customers | Sallatk'

  const id = searchParams.get('id') || ''
  const fetchHook = FetchHock<Customers, any>({
    path: '/customers'
  })
  const { search } = fetchHook

  useEffect(() => {
    if (id) {
      fetchHook.filter('id', id)
    }
  }, [id])

  const { openDelete, closeDelete, deleteItem, deleteId } = fetchHook

  const headerColumns: HeaderColumn[] = [
    {
      title: 'Id',
      accessor: 'id'
    },
    {
      title: 'Image'
    },

    {
      title: 'Name',
      accessor: 'name'
    },
    {
      title: 'Active',
      accessor: 'is_active'
    },
    {
      title: 'Email',
      accessor: 'email'
    },
    {
      title: 'Phone',
      accessor: 'phone'
    },

    {
      title: 'Options'
    }
  ]
  const filterItems: FilterItem[] = [
    {
      type: 'search',
      col: 6,
      placeholder: t('Search ...'),
      action: (value) => search(value)
    }
  ]
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <Row>
          <Col lg={12}>
            <IndexTable
              addString={t('Add new Customer')}
              addAction={openCreate}
              deleteId={deleteId}
              closeDelete={closeDelete}
              deleteItem={deleteItem}
              title={t('Customers List')}
              headerColumns={headerColumns}
              {...fetchHook} 
              filters={filterItems}>
              
              {fetchHook?.items?.map((item, index: number) => (
                <tr key={index}>
                  <td data-label="Id">
                    <h5 className="fs-14 mb-1">#{item?.id} </h5>
                  </td>
                  <td data-label="Image" className="">
                    <div className="avatar-sm bg-light rounded">
                      <ImageWithFallback
                        src={item.web_image}
                        alt={item.name}
                        className="img-fluid d-block"
                      />
                    </div>
                  </td>
                  <td data-label="Name">{`${item?.name} ${item?.lastname}`}</td>
                  <td data-label="Active">
                    {item?.is_active === 1 ? (
                      <Badge color="success">{t('Active')}</Badge>
                    ) : (
                      <Badge color="danger">{t('Not active')}</Badge>
                    )}
                  </td>
                  <td data-label="Email">{item.email}</td>
                  <td data-label="Phone">{`${item?.dialing_code || ''} ${item?.phone || ''}`}</td>

                  <td data-label="Options">
                    <div className="hstack gap-3 flex-wrap">
                      <UncontrolledDropdown>
                        <DropdownToggle href="#" className="btn btn-secondary btn-sm" tag="button">
                          <i className="ri-more-fill" />
                        </DropdownToggle>
                        <DropdownMenu container={'body'} className="dropdown-menu-end">
                          <DropdownItem
                            tag={Link}
                            onClick={() => {
                              openUpdate()
                              changeItemIdUpdate(item.id)
                            }}>
                            <i className="ri-edit-2-line me-2"></i>
                            {t('Edit')}
                          </DropdownItem>
                          <DropdownItem
                            tag={Link}
                            onClick={() => {
                              openUpdatePassword()
                              changeItemIdUpdatePassword(item.id)
                            }}>
                            <i className="ri-lock-password-line me-2"></i>
                            {t('Update password')}
                          </DropdownItem>

                          <DropdownItem divider />
                          <DropdownItem onClick={() => openDelete(item.id)}>
                            <i className="ri-delete-bin-line me-2"></i>

                            {t('Delete')}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </td>
                </tr>
              ))}
            </IndexTable>
          </Col>
        </Row>
      </Container>
      {showCreate && (
        <CreateModal close={closeCreate} show={openCreate} onSuccess={fetchHook.fetch} />
      )}
      {showUpdate && (
        <UpdateModal
          close={closeUpdate}
          show={openUpdate}
          onSuccess={fetchHook.fetch}
          itemId={itemIdUpdate}
        />
      )}
      {showUpdatePassword && (
        <UpdatePasswordModal
          close={closeUpdatePassword}
          show={openUpdatePassword}
          onSuccess={fetchHook.fetch}
          itemId={itemIdUpdatePassword}
        />
      )}
    </div>
  )
}

export default CustomersList
