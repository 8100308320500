import FeatherIcon from 'feather-icons-react'

import { useContext } from 'react'
import { Button, Input } from 'reactstrap'
import { usePOSGetPaymentMethods } from 'services/pos/payments'
import { PaymentMethods } from 'types'
import { POSContext } from '../POSContext'
import ImageWithFallback from 'helpers/ImageComponent'

type Props = {}

export const POSPaymentMethod = (props: Props) => {
  const { data: methods } = usePOSGetPaymentMethods() as { data: PaymentMethods }
  const { paymentMethod, setPaymentMethod, setInvoiceId, invoiceId } = useContext(POSContext)

  if (!methods) return
  console.log('methods', methods)
  return (
    <div className="">
      <hr />

      {Object.entries(methods).map(([key, value]) => {
        return (
          <div key={key} className="row px-2">
            {value.map((method) =>
              method.methods.map((item) => (
                <div className="col-4 px-1 mb-2">
                  <Button
                    onClick={() => setPaymentMethod(item)}
                    block
                    className={`bg-light text-dark border-2 ${
                      item.id === paymentMethod?.id ? 'border-primary' : 'border'
                    }`}>
                    <div key={item.id} className="d-flex align-items-center gap-2">
                      <div className="flex-grow-0 d-flex align-items-center justify-content-start gap-2">
                        <FeatherIcon
                          icon={paymentMethod?.id === item?.id ? 'check-circle' : 'circle'}
                        />
                        {item.image_url && (
                          <ImageWithFallback
                            alt=""
                            src={item.image_url}
                            style={{
                              width: '25px',
                              height: '25px',
                              objectFit: 'contain',
                              backgroundColor: 'white'
                            }}
                          />
                        )}
                      </div>

                      <p className="mb-0 text-truncate">{item.title}</p>
                    </div>
                  </Button>
                </div>
              ))
            )}
          </div>
        )
      })}

      {paymentMethod && paymentMethod?.company?.type !== 'cod' && (
        <Input
          type="text"
          placeholder="Invoice Reference"
          className="my-2"
          onChange={(e) => setInvoiceId(e.target.value)}
          value={invoiceId}
        />
      )}
    </div>
  )
}
