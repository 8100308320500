export const queryString = (data: Record<string, any>) => {
  if (!data) return ''
  return (
    '?' +
    Object.keys(data)
      .filter((key) => ![null, undefined].includes(data[key]))
      .map((key) => `${key}=${data[key]}`)
      .join('&')
  )
}
