// react elements
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
// requerd components
import UpdateRegion from './updateRegion'
interface IRegion {
  branch_region_id: number
  delivery_in: number
  delivery_in_types: 'day' | 'hour' | 'minutes'
  id: number
  shipping_cost: number
  status: number
  title: string
}

const Regions = ({
  region,
  branchId,
  getBranchData
}: {
  region: IRegion
  branchId: number
  getBranchData: () => void
}) => {
  const { t } = useTranslation()

  const [showUpdate, changeShowUpdate] = useState<boolean>(false)
  const openUpdate = () => changeShowUpdate(true)
  const closeUpdate = () => changeShowUpdate(false)

  return (
    <tr>
      <td>{region.title}</td>
      <td>{region.shipping_cost ? region.shipping_cost : '--'}</td>
      <td>
        {region.delivery_in} {t(region.delivery_in_types)}{' '}
        {!region.delivery_in && !region.delivery_in_types ? '--' : ''}
      </td>
      <td>
        {!!region.status && (
          <i className="ri-checkbox-circle-line align-middle text-success me-2"></i>
        )}
        {!region.status && <i className="ri-close-circle-line align-middle text-danger me-2"></i>}
        {t(region.status ? 'Active' : 'Not active')}
      </td>
      <td>
        <div className="hstack gap-3 flex-wrap">
          <button className="btn p-0 link-success fs-15" onClick={() => openUpdate()}>
            <i className="ri-edit-2-line"></i>
          </button>
        </div>
      </td>
      {showUpdate && (
        <UpdateRegion
          close={closeUpdate}
          show={showUpdate}
          onSuccess={getBranchData}
          branchId={branchId}
          region={region}
        />
      )}
    </tr>
  )
}

export default Regions
