// react elements
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// bootstrap elements
import { Container, Card, CardBody, CardHeader, Row, ListGroup, ListGroupItem } from 'reactstrap'
// fetch hock logic
import FetchHock from 'helpers/FetchHock'
// required components
import ReactDragListView from 'react-drag-listview'
import CreateModal from './components/CreateModal'
import UpdateModal from './components/UpdateModal'
// index hock
import BreadCrumb from 'Components/Common/BreadCrumb'
import Loader from 'Components/IndexTable/partials/Loader'
import Error from 'Components/IndexTable/partials/Error'
import Empty from 'Components/IndexTable/partials/Empty'
import DeleteModal from 'Components/IndexTable/partials/DeleteModal'
import axios from 'axios'
import linkGenerator from 'helpers/linkGenerator'
import showSuccess from 'helpers/SuccessNotfication'

interface input {
  id: number
  name: string
  placeholder: string
  type: 'text' | 'number' | 'textarea' | 'radio' | 'checkbox' | 'date' | 'time' | 'color'
  required: 1 | 0
  sort: number
}

const preview = () => {
  const { t } = useTranslation()
  const params = useParams()

  const fetchHock = FetchHock<input>({
    path: `/form/${params.id}/inputs`,
    sortBy: 'form_inputs.sort',
    sortDirection: 'asc'
  })
  // fetchHock.sort('order')

  // create modal logic
  const [showCreate, changeShowCreate] = useState<boolean>(false)
  const openCreate = () => changeShowCreate(true)
  const closeCreate = () => changeShowCreate(false)

  // update modal logic
  const [itemIdUpdate, changeItemIdUpdate] = useState<number>()
  const [showUpdate, changeShowUpdate] = useState<boolean>(false)

  const openUpdate = () => changeShowUpdate(true)
  const closeUpdate = () => changeShowUpdate(false)

  const changeOrder = async (fromIndex: number, toIndex: number) => {
    const movedItem = fetchHock.items[fromIndex]
    const toItem = fetchHock.items[toIndex]
    const newSort = toItem.sort

    const response = await axios.post(linkGenerator(`/form/${params.id}/inputs/sort`), {
      id: movedItem.id,
      sort: newSort >= 1 ? newSort - 1 : 1
    })

    if (response.status) {
      showSuccess(t('Input moved successfully'))
      fetchHock.fetch()
    }
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <Card>
          <CardHeader>
            <Row className="align-items-center">
              {/* card title */}
              <div className="col">
                <h5 className="card-title mb-0">{t('Form elements')}</h5>
              </div>
              {/* space auto */}
              <div className="col-auto">
                <button type="button" className="btn btn-secondary add-btn" onClick={openCreate}>
                  <i className="ri-add-circle-line align-middle me-1"></i>
                  {t('Add input')}
                </button>
              </div>
            </Row>
          </CardHeader>
          <CardBody>
            {!fetchHock.loading && (
              <ListGroup flush>
                <ReactDragListView onDragEnd={changeOrder} nodeSelector="li" handleSelector="li">
                  {fetchHock.items?.map((input: any, index: number) => (
                    <ListGroupItem className="mb-3 border border-1" key={index}>
                      <div className="d-flex justify-content-between">
                        <div className="d-flex">
                          <i className="ri-drag-move-line align-middle me-2"></i>
                          <label className="form-label"> {input.name}</label>
                        </div>
                        <div className="d-flex gap-2">
                          <button
                            type="button"
                            className="btn btn-sm fs-14 btn-link text-decoration-none p-0 text-success"
                            onClick={() => {
                              changeItemIdUpdate(input.id)
                              openUpdate()
                            }}>
                            <i className="ri-edit-2-line align-bottom me-1 text-success"></i>
                          </button>

                          <button
                            type="button"
                            className="btn btn-sm fs-14 btn-link text-decoration-none p-0 text-danger"
                            onClick={() => fetchHock.openDelete(input.id)}>
                            <i className="ri-delete-bin-line align-bottom me-1  text-danger"></i>
                          </button>
                        </div>
                      </div>
                      {(() => {
                        switch (input.type) {
                          case 'text':
                            return (
                              <input
                                className="form-control"
                                placeholder={input.placeholder}
                                readOnly
                              />
                            )
                          case 'number':
                            return (
                              <input
                                type="number"
                                className="form-control"
                                placeholder={input.placeholder}
                                readOnly
                              />
                            )
                          case 'textarea':
                            return (
                              <textarea
                                className="form-control"
                                placeholder={input.placeholder}
                                readOnly></textarea>
                            )
                          case 'date':
                            return (
                              <input
                                type="date"
                                className="form-control"
                                placeholder={input.placeholder}
                                readOnly
                              />
                            )
                          case 'time':
                            return (
                              <input
                                type="time"
                                className="form-control"
                                placeholder={input.placeholder}
                                readOnly
                              />
                            )
                          case 'color':
                            return (
                              <input
                                type="color"
                                className="form-control"
                                placeholder={input.placeholder}
                                readOnly
                              />
                            )
                          case 'checkbox':
                            return (
                              <>
                                {input.options.map((option: any, index: number) => (
                                  <div className="form-check mb-2" key={index}>
                                    <input className="form-check-input" type="checkbox" readOnly />
                                    <label className="form-check-label">{option.name}</label>
                                  </div>
                                ))}
                              </>
                            )
                          case 'radio':
                            return (
                              <>
                                {input.options.map((option: any, index: number) => (
                                  <div className="form-check mb-2" key={index}>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="inputs"
                                      readOnly
                                    />
                                    <label className="form-check-label">{option.name}</label>
                                  </div>
                                ))}
                              </>
                            )
                        }
                      })()}
                    </ListGroupItem>
                  ))}
                </ReactDragListView>
              </ListGroup>
            )}
            {/* loader */}
            {fetchHock.loading && <Loader />}
            {!fetchHock.loading && !!fetchHock.error && <Error />}
            {!fetchHock.loading && !fetchHock.error && fetchHock.totalItems == 0 && <Empty />}
          </CardBody>
        </Card>
      </Container>
      {showCreate && (
        <CreateModal close={closeCreate} show={showCreate} onSuccess={fetchHock.fetch} />
      )}
      {showUpdate && (
        <UpdateModal
          close={closeUpdate}
          show={showUpdate}
          onSuccess={fetchHock.fetch}
          itemId={itemIdUpdate}
        />
      )}
      {/* delete component */}
      {fetchHock.deleteId && fetchHock.deleteId > 0 && (
        <DeleteModal
          show={fetchHock.deleteId}
          onDeleteClick={fetchHock.deleteItem}
          onCloseClick={() => {
            if (fetchHock.closeDelete) fetchHock.closeDelete()
          }}
        />
      )}
    </div>
  )
}

export default preview
