import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import React from 'react'

const PopUp = ({
  close = null,
  show,
  loading,
  title = null,
  size,
  children
}: {
  close?: () => void
  show: boolean
  loading?: boolean
  title?: string
  children: any
  size?: string
}) => {
  return (
    <React.Fragment>
      {loading && loading == true ? (
        <div
          className="modal fade show"
          aria-modal="true"
          role="dialog"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(56, 65, 74, 0.15)'
          }}>
          <Spinner
            color="light"
            style={{
              height: '80px',
              width: '80px',
              color: 'white !important'
            }}
          />
        </div>
      ) : (
        <Modal
          size={size}
          isOpen={!!show}
          backdrop={'static'}
          id="staticBackdrop"
          centered
          className="zoomIn">
          {(close || title) && <ModalHeader toggle={close && close}>{title}</ModalHeader>}
          <ModalBody className="p-3">{children}</ModalBody>
        </Modal>
      )}
    </React.Fragment>
  )
}
export default PopUp
