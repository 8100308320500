export const defaultImage = {
  url: '/img-not-found.png',
  mobile: null,
  width: 450,
  height: 140
}

export const defaultValues = {
  image: '/placeholder-image.jpg'
}
