import { Fieldset } from 'Components/Form/Fieldset'
import { Legend } from 'Components/Form/Legend'
import { capitalizeFirstLetter } from 'helpers/string'
import { useRef } from 'react'
import { FormGroup, Input, Label } from 'reactstrap'
import { FormItem, Product } from 'types'

export const POSProductForm = ({
  form,
  onSubmit
}: {
  form: Product['form']
  onSubmit?: (values: any) => void
}) => {
  const formRef = useRef<HTMLFormElement>(null)

  const checkFieldsValidation = () => {
    if (!formRef.current) return false
    return formRef.current.checkValidity()
  }

  const onSubmitHandler = () => {
    const formValues = {} as {
      [key: string]: string | number | boolean | string[] | any
    }
    const formIsValid = checkFieldsValidation()
    if (!formIsValid || !formRef.current) return
    const formData = new FormData(formRef.current)
    const formProps = Object.fromEntries(formData)
    Object.keys(formProps).forEach((key: string) => {
      const isRadioOrCheckbox = form.children.find(
        (item) => item.name === key && (item.type === 'radio' || item.type === 'checkbox')
      )
      formValues[key] = isRadioOrCheckbox
        ? formData.getAll(key).map(Number)
        : (formData.get(key) as string)
    })

    const tempForm: {
      id: number
      value: string | number | boolean | string[]
    }[] = []

    Object.keys(formValues).forEach((key) => {
      const formItemId = form.children.find((item) => item.name === key)?.id as number
      if (!formValues[key]) return
      tempForm.push({
        id: formItemId,
        value: formValues[key]
      })
    })

    onSubmit?.(tempForm)
  }

  if (!form) return null

  return (
    <form ref={formRef} className="">
      <Fieldset>
        <Legend>{capitalizeFirstLetter(form?.name)}</Legend>
        {form?.children
          ?.sort((a, b) => a.sort - b.sort)
          ?.map((field) => (
            <Field key={field.id} field={field} onChange={onSubmitHandler} />
          ))}
      </Fieldset>
    </form>
  )
}

const Field = ({ field, onChange }: { field: FormItem; onChange: () => void }) => {
  const sharedProps = {
    id: `input-${field.id}`,
    placeholder: `${capitalizeFirstLetter(field.name)} ${field.required === 1 ? '*' : ''}`,
    name: field.name,
    required: field.required === 1,
    className: '',
    onChange
  }
  switch (field.type) {
    case 'textarea':
    case 'text':
    case 'number':
    case 'date':
    case 'time':
    case 'color':
      return (
        <FormGroup floating>
          <Input
            {...sharedProps}
            className={`${sharedProps.className}`}
            inputClassName={`${field.type === 'color' && '!p-0 '}`}
            type={field.type}
            {...(field.type === 'color' && {
              onBlur: onChange,
              onChange: undefined
            })}
            {...(field.type === 'textarea' && {
              style: { height: '100px' }
            })}
          />
          <Label for={`input-${field.id}`}>{sharedProps.placeholder}</Label>
        </FormGroup>
      )
    case 'radio':
    case 'checkbox':
      return (
        <div {...sharedProps} className={`${sharedProps.className} mb-3`}>
          <p className="mb-0">{capitalizeFirstLetter(sharedProps.placeholder)}</p>
          <div className="flex flex-wrap gap-6">
            {field.options?.map((option, index) =>
              field.type === 'radio' ? (
                <div key={option.id} className="form-check">
                  <Input
                    key={option.id}
                    id={'option-' + field.id + '-' + option.id}
                    type="radio"
                    label={option.name}
                    name={field.name}
                    value={option.id}
                    defaultChecked={index === 0}
                  />
                  <Label
                    htmlFor={'option-' + field.id + '-' + option.id}
                    className="form-check-label">
                    {option.name}
                  </Label>
                </div>
              ) : (
                <div key={option.id} className="form-check">
                  <Input
                    id={'option-' + field.id + '-' + option.id}
                    key={option.id}
                    type="checkbox"
                    label={option.name}
                    name={`${field.name}`}
                    value={option.id}
                  />
                  <Label
                    htmlFor={'option-' + field.id + '-' + option.id}
                    className="form-check-label">
                    {option.name}
                  </Label>
                </div>
              )
            )}
          </div>
        </div>
      )
    default:
      return <div>Field type: {field.type} not supported yet</div>
  }
}
