import Image from 'ThemeManager/types/Formula/inputs/Image'
import Link from 'ThemeManager/types/Formula/inputs/Link'

export const ImageInputFormula: Image = {
  title: 'Image',
  label: 'image',
  type: 'image'
}

export const LinkInputFormula: Link = {
  title: 'URL',
  label: 'slug',
  type: 'link'
}
