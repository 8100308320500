import { ImageInputFormula, LinkInputFormula } from 'ThemeManager/Templates/defaultFormula'
import Formula from 'ThemeManager/types/Formula/index'

const formula: Formula = [
  {
    title: 'Content',
    label: 'content',
    type: 'objectList',
    length: 3,
    inputs: [
      {
        title: 'Title',
        label: 'title',
        type: 'translateString'
      },
      LinkInputFormula,
      ImageInputFormula
    ]
  }
]
export default formula
