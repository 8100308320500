import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import clsx from 'clsx'
import linkGenerator from 'helpers/linkGenerator'
import { getSearchResultLink } from 'pages/SearchResults'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, Dropdown, DropdownItem, DropdownMenu, Input } from 'reactstrap'
import SimpleBar from 'simplebar-react'
import Loader from './Loader'
import CategoryButtons, { categoryLists } from './SearchCategories'

const SearchOption = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [term, setTerm] = useState('')
  const [type, setType] = useState(categoryLists[0].value)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const onChangeTerm = (value: any) => {
    setTerm(value)
    if (value) {
      setDropdownOpen(true)
    } else {
      setDropdownOpen(false)
    }
  }
  const onChangeType = (option) => {
    const value = option.value
    setType(value)
  }

  const handleAllResultClick = () => {
    navigate(`/search?term=${term}&type=${type}`)
    setDropdownOpen(false)
  }

  return (
    <React.Fragment>
      <form className="app-search d-none d-md-block">
        <div className="d-flex">
          <div className="position-relative">
            <Input
              autoComplete="off"
              type="text"
              className="form-control"
              placeholder="Search..."
              id="search-options"
              value={term}
              onChange={(e) => {
                onChangeTerm(e.target.value)
              }}
              onFocus={() => {
                setDropdownOpen(true)
              }}
              // onBlur={() => {
              //   setDropdownOpen(false)
              // }}
            />
            <span
              className={clsx(
                'mdi mdi-close-circle search-widget-icon search-widget-icon-close',
                term ? 'd-block' : 'd-none'
              )}
              onClick={() => {
                setDropdownOpen(false)
                setTerm('')
              }}></span>
            <Dropdown isOpen={dropdownOpen} toggle={() => {}}>
              <DropdownMenu className="dropdown-menu-lg">
                <DropdownItem header className="d-flex justify-content-between">
                  <p className="text-overflow mb-0 text-uppercase">Search in</p>

                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setDropdownOpen(false)}
                    aria-label="Close"></button>
                </DropdownItem>

                <DropdownItem tag="div" className="bg-transparent text-wrap">
                  <CategoryButtons onChangeType={onChangeType} type={type} />
                </DropdownItem>
                <DropdownItem header className="mt-2">
                  <p className="text-overflow mb-1 text-uppercase">{t('Results')}</p>
                </DropdownItem>
                <SimpleBar style={{ height: '220px' }}>
                  <ResultList
                    term={term}
                    type={type}
                    onChoose={() => {
                      setDropdownOpen(false)
                      setTerm('')
                    }}
                  />
                </SimpleBar>

                <div className="text-center pt-3 pb-1">
                  <button onClick={handleAllResultClick} className="btn btn-primary btn-sm">
                    View All Results <i className="ri-arrow-right-line ms-1"></i>
                  </button>
                </div>
              </DropdownMenu>
            </Dropdown>
          </div>
          <Button type="button" color="primary">
            <i className="mdi mdi-magnify"></i>
          </Button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default SearchOption

type ResultType = {
  id: number
  product_course_id: number
  product_type: string
  short_description: string
  title: string
  type: string
  web_main_image: string
}

const ResultList = ({ term, type, onChoose }) => {
  const navigate = useNavigate()
  const { isLoading, data } = useAppSearch({ term, type })

  if (isLoading) {
    return <Loader />
  }
  if (!type) {
    return <h6 className="text-center text-muted">Please select search type</h6>
  }

  const results = data?.data?.result?.data || []

  if (!results?.length) {
    return <h6 className="text-center text-muted">No results found</h6>
  }

  return results?.map((result: ResultType) => {
    const type = result?.type?.toLowerCase()
    const id = result?.id
    return (
      <DropdownItem
        key={result.id}
        onClick={() => {
          navigate(getSearchResultLink(type, id))
          onChoose()
        }}>
        <i className="mdi mdi-dot align-middle fs-18 me-2"></i>
        <span>{result?.title}</span>
      </DropdownItem>
    )
  })
}

export const useAppSearch = ({ term, type }) => {
  return useQuery({
    queryKey: ['search', term, type],
    queryFn: () => {
      if (!term && !type) {
        return
      }
      return axios.get(linkGenerator('/search'), {
        params: {
          term,
          type
        }
      })
    },
    enabled: Boolean(term && type),
    refetchOnWindowFocus: false
  })
}
