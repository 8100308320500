import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledDropdown
} from 'reactstrap'
import ImageWithFallback from 'helpers/ImageComponent'
import BreadCrumb from 'Components/Common/BreadCrumb'
import IndexTable, { FilterItem, HeaderColumn } from 'Components/IndexTable'
import FetchHock from 'helpers/FetchHock'
import List from '../models/List'
import axios from 'axios'
import linkGenerator from '../../../helpers/linkGenerator'
import showSuccess from '../../../helpers/SuccessNotfication'

const StaticPages = () => {
  const { t } = useTranslation()

  document.title = 'Static Pages | Sallatk'

  const fetchHock = FetchHock<List>({ path: '/staticPages' })

  const headerColumns: HeaderColumn[] = [
    {
      title: 'Title',
      accessor: 'title'
    },
    {
      title: 'Slug',
      accessor: 'slug'
    },
    {
      title: 'Published',
      accessor: 'published'
    },
    {
      title: 'Action'
    }
  ]

  const filterItems: FilterItem[] = [
    {
      type: 'search',
      col: 6,
      placeholder: t('Search ...'),
      action: (value) => fetchHock.search(value)
    }
  ]
  const published = async (id: number) => {
    const response = await axios.put(linkGenerator(`/staticPages/published/${id}`))
    if (response.status) showSuccess(t('Static page status updated successfully'))
  }
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <Row>
          <Col lg={12}>
            <IndexTable
              title={t('Static Pages List')}
              headerColumns={headerColumns}
              {...fetchHock}
              filters={filterItems}>
              {fetchHock?.items?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm bg-light rounded p-1">
                          <ImageWithFallback
                            src={item.mobile_image}
                            alt={item.title}
                            className="img-fluid d-block"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="fs-14 mb-1">{item.title}</h5>
                      </div>
                    </div>
                  </td>
                  <td>{item.slug ? item.slug : ''}</td>
                  <td>
                    <div className="form-check form-switch-md form-switch form-switch-secondary">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        onChange={() => published(item.id)}
                        defaultChecked={item.published}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="hstack gap-3 flex-wrap">
                      <UncontrolledDropdown>
                        <DropdownToggle href="#" className="btn btn-secondary btn-sm" tag="button">
                          <i className="ri-more-fill" />
                        </DropdownToggle>
                        <DropdownMenu container={'body'} className="dropdown-menu-end">
                          <DropdownItem tag={Link} to={`/staticPages/${item.id}`}>
                            <i className="ri-edit-2-line me-2"></i>
                            {t('Edit')}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </td>
                </tr>
              ))}
            </IndexTable>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default StaticPages
