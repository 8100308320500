import Formula from 'ThemeManager/types/Formula/index'

const formula: Formula = [
  {
    title: 'Section heading',
    label: 'sectionHeading',
    type: 'translateString'
  },
  {
    title: 'Show Section header',
    label: 'showSectionHeader',
    type: 'checkBoox'
  },
  {
    title: 'Show name',
    label: 'showName',
    type: 'checkBoox'
  }
]
export default formula
