import { Spinner } from 'reactstrap'

const Submit = ({
  label,
  loading = false,
  className
}: {
  label: string | JSX.Element | any
  loading?: boolean
  className?: string
}) => {
  return (
    <div className="text-end">
      <button type="submit" className={`btn btn-secondary ${className} `} disabled={loading}>
        {loading ? <Spinner color="light" size="sm" /> : label}
      </button>
    </div>
  )
}
export default Submit
