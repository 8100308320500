import { Button } from 'reactstrap'
import { usePOSGetCart, useRemoveItemFromCart, useUpdateCartItemMutation } from 'services/pos/cart'
import { CartItem } from 'types'

export const POSCheckout = () => {
  const { mutateAsync: removeProductFromCart } = useRemoveItemFromCart()
  const { mutateAsync: updateQuantity } = useUpdateCartItemMutation()

  const { data: cartItems } = usePOSGetCart() as { data: CartItem[] }
  console.log('cartItems', cartItems)

  const total = cartItems?.reduce(
    (total, cartItem) => total + cartItem.final_price * cartItem.quantity,
    0
  )

  const values = [
    {
      title: 'divider',
      price: 0
    },
    {
      title: 'Total',
      price: total
    }
  ]

  const renderItems = () => {
    if (!cartItems?.length) return
    return cartItems.map((item) => (
      <>
        <div className="d-flex align-items-center gap-2 mt-3">
          <Button
            className="p-1 rounded-circle"
            size="sm"
            color="danger"
            onClick={() => removeProductFromCart(item)}
            style={{
              width: '25px',
              height: '25px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <i className="ri-close-line fs-3"></i>
          </Button>
          <p className="mb-0">
            {item.product.title} ({item.product.raw_price})
          </p>
          <div className="ms-auto d-flex gap-1 align-items-center">
            <Button
              className="p-1 rounded-circle"
              size="sm"
              onClick={() => updateQuantity({ itemId: item.id, quantity: -1 })}
              style={{
                width: '25px',
                height: '25px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <i className="ri-subtract-fill fs-3"></i>
            </Button>
            <p className="fw-bold px-1 mb-0 text-center" style={{ minWidth: '25px' }}>
              {item.quantity}
            </p>
            <Button
              className="p-1 rounded-circle"
              size="sm"
              onClick={() => updateQuantity({ itemId: item.id, quantity: 1 })}
              style={{
                width: '25px',
                height: '25px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <i className="ri-add-fill fs-3"></i>
            </Button>
            <p className="mb-0 fw-bold text-end ms-2" style={{ minWidth: '70px' }}>
              {item.quantity * item.final_price} KWD
            </p>
          </div>
        </div>
        {/* features */}
        <div className="text-muted text-sm ps-5 py-0">
          {item.feature_options?.map((feature) => (
            <div className="d-flex align-items-center gap-2 mb-2">
              <p className="mb-0">
                {feature.title} ({feature.final_price}) KWD
              </p>
            </div>
          ))}
        </div>
      </>
    ))
  }

  if (!cartItems?.length) return <p className="text-center fs-6 text-muted my-5">Cart is empty</p>

  return (
    <>
      {renderItems()}
      {values.map((value: any) => {
        if (value.title === 'divider') {
          return <hr />
        }
        return (
          <div className="d-flex gap-2 mb-2 align-items-center">
            {value?.id ? (
              <Button
                className="p-1 rounded-circle"
                size="sm"
                color="danger"
                onClick={() => removeProductFromCart(value)}
                style={{
                  width: '30px',
                  height: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <i className="ri-close-line fs-2"></i>
              </Button>
            ) : null}
            <p className="mb-0">{value.title}</p>
            {value?.price ? (
              <p className="mb-0 fw-bold text-end ms-auto" style={{ minWidth: '70px' }}>
                {value.price} KWD
              </p>
            ) : null}
          </div>
        )
      })}
    </>
  )
}
