import { useTranslation } from 'react-i18next'

import { Card, CardBody, CardHeader } from 'reactstrap'
import { Select, Input, Checkbox, Switch } from 'Components/Form'
import { useFormContext } from 'react-hook-form'

const Shipping = () => {
  const { t } = useTranslation()

  const {
    register,
    formState: { errors },
    getValues
  } = useFormContext()

  return (
    <Card>
      <CardHeader className="">
        <h5 className="card-title mb-0">{t('COD & Shipping')}</h5>
      </CardHeader>
      <CardBody>
      <Switch label={t('Allow COD')} model={register('cod_allowed')} />
        <Switch label={t('Allow Shipping')} model={register('shipping_allowed')} />
      
        <div className="mb-3 m-2">
          <label className="form-label" htmlFor="product-price-input">
            {t('Ready In')}
          </label>
          <div className="d-flex p-0">
            <Select
              placeholder={t('Type')}
              className="rounded-start-1"
              options={[
                {
                  label: t('Minutes'),
                  value: 'minutes'
                },
                {
                  label: t('Hours'),
                  value: 'hours'
                },
                {
                  label: t('Days'),
                  value: 'days'
                }
              ]}
              model={register('ready_in_type')}
              value={getValues && getValues('ready_in_type')}
              error={errors.discount_type}
            />
            <Input
              placeholder={t('Ready in')}
              className="rounded-end-1"
              type="number"
              model={register('ready_in')}
              error={errors.ready_in}
            />
          </div>
        </div>

      </CardBody>
    </Card>
  )
}

export default Shipping
