import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import withRouter from '../Components/Common/withRouter'

//import Components
import Header from './Header'
import Sidebar from './Sidebar'
import Footer from './Footer'
import RightSidebar from '../Components/Common/RightSidebar'

//import actions
import {
  changeLayout,
  changeSidebarTheme,
  changeLayoutMode,
  changeLayoutWidth,
  changeLayoutPosition,
  changeTopbarTheme,
  changeLeftsidebarSizeType,
  changeLeftsidebarViewType,
  changeSidebarVisibility
} from '../slices/thunks'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

const Layout = (props: any) => {
  const [headerClass, setHeaderClass] = useState('')
  const dispatch = useDispatch<any>()

  const selectLayoutState = (state: any) => state.Layout
  const selectLayoutProperties = createSelector(selectLayoutState, (layout) => ({
    layoutType: layout.layoutType,
    leftSidebarType: layout.leftSidebarType,
    layoutModeType: layout.layoutModeType,
    layoutWidthType: layout.layoutWidthType,
    layoutPositionType: layout.layoutPositionType,
    topbarThemeType: layout.topbarThemeType,
    leftsidbarSizeType: layout.leftsidbarSizeType,
    leftSidebarViewType: layout.leftSidebarViewType,
    leftSidebarImageType: layout.leftSidebarImageType,
    preloader: layout.preloader,
    sidebarVisibilitytype: layout.sidebarVisibilitytype
  }))
  // Inside your component
  const {
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    leftSidebarImageType,
    preloader,
    sidebarVisibilitytype
  } = useSelector(selectLayoutProperties)
  // class add remove in header
  useEffect(() => {
    window.addEventListener('scroll', scrollNavigation, true)
  })
  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop
    if (scrollup > 50) {
      setHeaderClass('topbar-shadow')
    } else {
      setHeaderClass('')
    }
  }
  /*
    layout settings
    */
  useEffect(() => {
    if (
      layoutType ||
      leftSidebarType ||
      layoutModeType ||
      layoutWidthType ||
      layoutPositionType ||
      topbarThemeType ||
      leftsidbarSizeType ||
      leftSidebarViewType ||
      sidebarVisibilitytype ||
      leftSidebarImageType ||
      preloader
    ) {
      window.dispatchEvent(new Event('resize'))
      dispatch(changeLeftsidebarViewType(leftSidebarViewType))
      dispatch(changeLeftsidebarSizeType(leftsidbarSizeType))
      dispatch(changeSidebarTheme(leftSidebarType))
      dispatch(changeLayoutMode(layoutModeType))
      dispatch(changeLayoutWidth(layoutWidthType))
      dispatch(changeLayoutPosition(layoutPositionType))
      dispatch(changeTopbarTheme(topbarThemeType))
      dispatch(changeLayout(layoutType))
      dispatch(changeSidebarVisibility(sidebarVisibilitytype))
    }
  }, [
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    sidebarVisibilitytype,
    leftSidebarImageType,
    preloader,
    dispatch
  ])
  /*
    call dark/light mode
    */
  const onChangeLayoutMode = (value: any) => {
    if (changeLayoutMode) {
      dispatch(changeLayoutMode(value))
      dispatch(changeSidebarTheme(value))
    }
  }

  useEffect(() => {
    if (
      sidebarVisibilitytype === 'show' ||
      layoutType === 'vertical' ||
      layoutType === 'twocolumn'
    ) {
      document.querySelector('.hamburger-icon')?.classList.remove('open')
    } else {
      document.querySelector('.hamburger-icon')?.classList.add('open')
    }
  }, [sidebarVisibilitytype, layoutType])

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header
          headerClass={headerClass}
          layoutModeType={layoutModeType}
          onChangeLayoutMode={onChangeLayoutMode}
        />
        <Sidebar layoutType={layoutType} />
        <div className="main-content">
          {props.children}
          <Footer />
        </div>
      </div>
      <RightSidebar />
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.object
}

export default withRouter(Layout)
