import { defaultValues } from 'ThemeManager/Templates/DefaultValues'

export default {
  content: [
    {
      id: 1,
      icon: defaultValues.image,
      title: {
        ar: 'أكثر من 1000 منتج بسعر التكلفة',
        en: '1000+ products priced at cost'
      },
      description: {
        ar: 'إذا لم تقم بتحويل رسوم عضويتك إلى مدخرات، فسنقوم برد الفرق',
        en: 'If you don’t make your membership fee in savings, we’ll refund the difference'
      }
    },
    {
      id: 2,
      icon: defaultValues.image,
      title: {
        ar: 'تسليم سريع للغاية',
        en: 'Super Fast Delivery'
      },
      description: {
        ar: 'إذا لم تقم بتحويل رسوم عضويتك إلى مدخرات، فسنقوم برد الفرق',
        en: 'If you don’t make your membership fee in savings, we’ll refund the difference'
      }
    },
    {
      id: 3,
      icon: defaultValues.image,
      title: {
        ar: 'جربها خالية من المخاطر',
        en: 'Try it risk-free'
      },
      description: {
        ar: 'إذا لم تقم بتحويل رسوم عضويتك إلى مدخرات، فسنقوم برد الفرق',
        en: 'If you don’t make your membership fee in savings, we’ll refund the difference'
      }
    },
    {
      id: 4,
      icon: defaultValues.image,
      title: {
        ar: 'التوفير المضمون',
        en: 'Guaranteed Savings'
      },
      description: {
        ar: 'إذا لم تقم بتحويل رسوم عضويتك إلى مدخرات، فسنقوم برد الفرق',
        en: 'If you don’t make your membership fee in savings, we’ll refund the difference'
      }
    }
  ]
}
