import { Button, Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Input, Submit, ImageUpload, Switch, Textarea } from 'Components/Form'
import CreateHook from 'helpers/CreateHook'
import PopUp from '../../../Components/PopUp'

const CreateModal = ({ close, show, onSuccess }: any) => {
  const { t } = useTranslation()

  type brandtype = {
    id?: number
    name: string
    image: string | null
    published: boolean
    description: string | null
  }

  // prepare hock
  const { register, handleSubmit, errors, create, loading, closeUploading, openUploading } =
    CreateHook<brandtype>('/brands', {
      name: '',
      published: false,
      image: null,
      description: ''
    })

  const submit = async (data: brandtype) => {
    const response = await create(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  return (
    <PopUp title={t('Create new brand')} show={show} close={close}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={12}>
              <ImageUpload
                label={t('Image')}
                model={register('image', {
                  required: t('Image is required')
                })}
                error={errors.image}
                onUpload={openUploading}
                onFinishUpload={closeUploading}
              />
            </Col>
            <Col md={12}>
              <Input
                label={t('Title')}
                type="text"
                placeholder={t('Enter title')}
                model={register('name', {
                  required: t('Title is required'),
                  minLength: {
                    value: 3,
                    message: t('Title must be bigger than or equal 3')
                  }
                })}
                error={errors.name}
              />
            </Col>
            <Col md={12}>
              <Textarea
                label={t('Description')}
                placeholder={t('Enter description')}
                model={register('description')}
                error={errors.description}
              />
            </Col>
            <Col md={12}>
              <Switch defaultChecked={false} label={t('Published')} model={register('published')} />
            </Col>
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Create')} />
        </div>
      </form>
    </PopUp>
  )
}
export default CreateModal
