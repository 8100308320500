import { defaultImage } from 'ThemeManager/Templates/DefaultValues'

export default {
  content: [
    {
      title: 'Holiday Offers',
      slug: '',
      image: defaultImage
    },
    {
      title: 'Holiday Offers',
      slug: '',
      image: defaultImage
    },
    {
      title: 'Holiday Offers',
      slug: '',
      image: defaultImage
    }
  ]
}
