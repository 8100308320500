import { createSlice } from '@reduxjs/toolkit'

type ShowType = 'card' | 'table'

export const initialState: ShowType | null = 'card'

const productsShowTypeKey = 'productsShowType'

const getInitialState = () => {
  const storedShowType = localStorage.getItem(productsShowTypeKey)
  return storedShowType || initialState
}

const productsSlice = createSlice({
  name: 'products',
  initialState: {
    showType: getInitialState()
  },
  reducers: {
    setShowTypeAction: (state, action) => {
      state.showType = action.payload
      localStorage.setItem(productsShowTypeKey, action.payload)
    }
  }
})

export const { setShowTypeAction } = productsSlice.actions
export default productsSlice.reducer
