import Loader from 'Components/Common/Loader'
import { useContext, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'
import { usePOSProducts } from 'services/pos/products'
import { POSContext } from '../POSContext'
import { POSSingleProduct } from './POSSingleProduct'

type Props = {}

export const POSProducts = (props: Props) => {
  const { selectedCategory, searchInput } = useContext(POSContext)
  const { data, refetch, isFetching } = usePOSProducts({
    ...(selectedCategory?.id ? { 'filter[categories][]': selectedCategory?.id } : {}),
    ...(searchInput ? { term: searchInput } : {})
  })
  useEffect(() => {
    refetch()
  }, [selectedCategory?.id, searchInput])

  const products = data?.data || []

  const renderData = () => {
    if (isFetching) return <Loader container />
    if (!products.length) return <p className="text-center w-100">No data in this category</p>
    if (products.length) {
      return products.map((product: any, index: number) => (
        <POSSingleProduct key={`${product.id}-${product.title}-${index}`} product={product} />
      ))
    }
  }
  return (
    <Card className="flex-grow-1 mb-0" style={{ height: '1px' }}>
      <CardBody
        className="d-flex gap-3 flex-wrap overflow-auto align-items-start"
        style={{ placeContent: 'start' }}>
        {renderData()}
      </CardBody>
    </Card>
  )
}
