import { Badge, Card, CardBody, CardHeader } from 'reactstrap'
import CardItem from './CardItem'
import StatusBadge from 'pages/Transactions/list/StatusBadge'
import { useTranslation } from 'react-i18next'

function Payment({ data }: any) {
  const { t } = useTranslation()
  if (!data) return null
  return (
    <Card>
      <CardHeader>
        <h5 className="card-title mb-0">
          <i className="ri-bank-card-line me-1 text-muted"></i> {t('Payment Details')}
        </h5>
      </CardHeader>
      <CardBody>
        <CardItem
          title={t('Active')}
          Component={() =>
            data?.payment_method?.active ? (
              <Badge color="success">{t('Active')}</Badge>
            ) : (
              <Badge color="danger">{t('Not active')}</Badge>
            )
          }
        />
        <CardItem
          title={t('Method')}
          Component={() => (
            <div className="d-flex align-items-center gap-2 ">
              <p className="mb-0">{data?.payment_method?.title}</p>
              <img height={30} src={data?.payment_method?.image_url} alt="" />
            </div>
          )}
        />
        <CardItem title={t('Company')} value={data?.payment_method?.company?.name} />
      </CardBody>
    </Card>
  )
}

export default Payment
