import { FileUpload, Input } from 'Components/Form'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap'

function Assets({
  openUploading,
  closeUploading
}: {
  openUploading: () => void
  closeUploading: () => void
}) {
  const { t } = useTranslation()
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    watch,
    control
  } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'assets'
  })

  return (
    <Card>
      <CardHeader className="">
        <h5 className="card-title mb-0">{t('Assets')}</h5>
      </CardHeader>
      <CardBody>
        {fields.map((field, index) => {
          const url = getValues(`assets.${index}.url`)
          const token = getValues(`assets.${index}.token`)
          return (
            <div key={field.id}>
              <Row className="mb-3">
                <Row>
                  <Col md={5}>
                    <Input
                      type="text"
                      model={register(`assets.${index}.title`, { required: true })}
                      label={t('title')}
                    />
                  </Col>
                  <Col md={5}>
                    <FileUpload
                      label={t('File')}
                      placeholder={t('Select file')}
                      model={register(`assets.${index}.file`, { required: true })}
                      error={errors?.assets?.[index]?.file}
                      value={watch(`assets.${index}.file`)}
                      onUpload={openUploading}
                      onFinishUpload={closeUploading}
                      anyFiles
                    />
                  </Col>
                  <Col md={2} className="d-flex align-items-end">
                    <Button className="mb-3" color="danger" size="sm" onClick={() => remove(index)}>
                      {t('Remove')}
                    </Button>
                  </Col>
                </Row>
                {token ? <AssetsURL url={url} /> : null}
              </Row>
            </div>
          )
        })}
        <Row>
          <Col>
            <Button
              color="primary"
              size="sm"
              onClick={() => append({ id: '-1', title: '', file: null })}>
              {t('Add Asset')}
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default Assets

function AssetsURL({ url }: { url: string }) {
  const { token } = UseProfile()
  return (
    <div>
      <a href={url} target="_blank">
        {url}
      </a>
      <ContentViewer url={url} />
    </div>
  )
}

import { useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { UseProfile } from 'helpers/UserHooks'

const ContentViewer = ({ url }: { url: string }) => {
  const { data, isLoading, isError } = useGetFile(url)

  if (isLoading) return <div>Loading...</div>
  if (isError) return <div>Error</div>
  const content = data?.url
  const type = data?.type

  if (type?.includes('application/pdf')) {
    return (
      <>
        <Button
          color="secondary"
          size="sm"
          type="button"
          className="mb-2"
          onClick={() => handleDownload(content)}>
          Download
        </Button>
        <embed src={content} type="application/pdf" width="100%" height="600px" />
      </>
    )
  } else if (type?.includes('image')) {
    return <img src={content} alt="Content" style={{ maxWidth: '100%' }} />
  } else if (type?.includes('text')) {
    return <pre>{content}</pre>
  }
  return <div></div>
}

const fetchFile = async (url: string) => {
  const response = await axios.get(url, {
    responseType: 'blob'
  })
  return response
}

const getFileType = (response: AxiosResponse) => {
  const type = response.headers['content-type']
  return type
}

const createBlobUrl = (response: AxiosResponse, type: string) => {
  const blob = new Blob([response.data], { type: type })
  const url = URL.createObjectURL(blob)
  return url
}

const useGetFile = (url: string) =>
  useQuery({
    queryKey: ['content', url],
    queryFn: async () => {
      const response = await fetchFile(url)
      const type = getFileType(response)
      const blobUrl = createBlobUrl(response, type)
      return {
        type: type,
        url: blobUrl
      }
    },
    refetchOnWindowFocus: false,
    retry: 3
  })

const handleDownload = (url: string) => {
  if (url) {
    const link = document.createElement('a')
    link.href = url
    // Extract filename from URL or use a default name
    const filename = url.split('/').pop() || 'download'
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}
