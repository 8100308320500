// react elements
import { Link, useNavigate } from 'react-router-dom'
// botstrab elements
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown
} from 'reactstrap'
// page BreadCrumb
import BreadCrumb from 'Components/Common/BreadCrumb'
// translation
import { useTranslation } from 'react-i18next'
// themes imaages
import modern from 'assets/images/themes/modern.png'
import minimal from 'assets/images/themes/minimal.png'
import classic from 'assets/images/themes/classic.png'
import standerd from 'assets/images/themes/standerd.png'

import vintage from 'assets/images/themes/vintage.png'
import trendy from 'assets/images/themes/trendy.png'
import elegant from 'assets/images/themes/elegant.png'

import refined from 'assets/images/themes/refined.jpg'
import contemporary from 'assets/images/themes/contemporary.png'
import ancient from 'assets/images/themes/ancient.png'
import LogoStyleModal from './component/LogoStyleModal'
import { useState } from 'react'

const FileManager = () => {
  document.title = 'Themes | Sallatk'
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [showLogoStyleModal, setShowLogoStyleModal] = useState(false)

  const Themes = [
    // { title: t('Modern'), image: modern },
    { title: t('Minimal'), image: minimal }
    // { title: t('Classic'), image: classic },
    // { title: t('Standerd'), image: standerd },
    // { title: t('Vintage'), image: vintage },
    // { title: t('Trendy'), image: trendy },
    // { title: t('Elegant'), image: elegant },
    // { title: t('Refined'), image: refined },
    // { title: t('Ancient'), image: ancient }
  ]
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb />

          <div className="d-flex justify-content-between g-2 mb-4">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 me-2 d-block d-lg-none">
                <button
                  type="button"
                  className="btn btn-soft-success btn-icon btn-sm fs-16 file-menu-btn">
                  <i className="ri-menu-2-fill align-bottom"></i>
                </button>
              </div>
              <div className="flex-grow-1">
                <h5 className="fs-16 mb-0">{t('Themes')}</h5>
              </div>
            </div>

            <div className="d-flex gap-2 align-items-start">
              <button
                type="button"
                className="btn btn-outline-dark text-nowrap"
                onClick={() => navigate('/menus/header')}>
                {t('Header Menu')}
              </button>
              <button
                type="button"
                className="btn btn-outline-dark text-nowrap"
                onClick={() => navigate('/footer')}>
                {t('Footer Menu')}
              </button>
              <button
                type="button"
                className="btn btn-outline-dark text-nowrap"
                onClick={() => setShowLogoStyleModal(true)}>
                {t('Logo Style')}
              </button>
            </div>
          </div>
          <Row id="folderlist-data">
            {Themes.map((item, index) => (
              <Col xxl={3} lg={3} className="folder-card" key={index}>
                <Card className="bg-light shadow-none">
                  <CardBody className="p-0">
                    <div className="theme-image">
                      <img src={item.image} />
                    </div>
                    <div className="d-flex px-2 py-2">
                      <div className="flex-grow-1 mt-1">
                        <h6 className="fs-15 folder-name">{item.title}</h6>
                      </div>

                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="button"
                          className="btn btn-ghost-primary btn-icon btn-sm dropdown">
                          <i className="ri-more-2-fill fs-16 align-bottom" />
                        </DropdownToggle>
                        <DropdownMenu container={'body'} className="dropdown-menu-end">
                          <DropdownItem
                            className="view-item-btn"
                            tag={Link}
                            to={`/themes/customize/${index + 1}`}>
                            {t('Use theme')}
                          </DropdownItem>
                          <DropdownItem className="view-item-btn">{t('Preview')}</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      {showLogoStyleModal && (
        <LogoStyleModal close={() => setShowLogoStyleModal(false)} show={showLogoStyleModal} />
      )}
    </>
  )
}

export default FileManager
