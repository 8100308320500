import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Card, CardHeader, Col, Container, Input, Row, Spinner } from 'reactstrap'

import BreadCrumb from 'Components/Common/BreadCrumb'
import Loader from 'Components/Common/Loader'
import Error from 'Components/IndexTable/partials/Error'
import Empty from 'Components/IndexTable/partials/Empty'
import Pagination from 'Components/IndexTable/partials/Pagination'
import DeleteModal from 'Components/IndexTable/partials/DeleteModal'
import FetchHock from 'helpers/FetchHock'
import { BranchType } from '../types'

const BranchesList = () => {
  const { t } = useTranslation()

  document.title = 'Branches | Sallatk'

  const fetchHock = FetchHock<BranchType>({ path: '/branches' })

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />

        <CardHeader className="card-header border-0">
          <Row className="align-items-center gy-3 mb-3">
            <div className="col"></div>
            <div className="col-auto">
              <div className="d-flex gap-1 flex-wrap">
                <Link type="button" className="btn btn-secondary add-btn" to="/branches/create">
                  <i className="ri-add-circle-line align-middle me-1"></i> {t('Add Branch')}
                </Link>
              </div>
            </div>
          </Row>
        </CardHeader>
        <Row>
          {fetchHock.items?.map((item, index) => (
            <Col xl={3} lg={4} md={4} sm={6} xs={12} key={index}>
              <Card>
                <div className="card-body">
                  <div className="d-flex mb-4 align-items-center">
                    <div className="avatar-sm me-2">
                      <div className="avatar-title bg-light text-primary fs-17 rounded">
                        <i className="las la-code-branch"></i>
                      </div>
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <h5 className="card-title mb-1">
                        {item.name}
                        {!!item.main && (
                          <span className="badge bg-success align-middle fs-10 ms-2">
                            {t('Main')}
                          </span>
                        )}
                        {!item.main && (
                          <span className="badge bg-info align-middle fs-10 ms-2">{t('Sup')}</span>
                        )}
                      </h5>
                      <p className="text-muted mb-0">{item.address}</p>
                    </div>
                  </div>
                  <h6 className="mb-1">
                    {t('Max orders')} {item.max_order_day}
                  </h6>
                  <p className="card-text text-muted mb-1">
                    {t('Allow pickup')} :
                    {item.allow_pickup === 1 ? (
                      <span className="badge bg-success text-uppercase ms-2">{t('Allowed')}</span>
                    ) : (
                      <span className="badge bg-danger text-uppercase ms-2">
                        {t('Not Allowed')}
                      </span>
                    )}
                  </p>
                  <p className="card-text text-muted mb-0">
                    {t('Count of covered cities')} : {item.count_cities}
                  </p>
                  <p className="card-text text-muted mb-0">
                    {t('Count of covered regions')} : {item.count_regions}
                  </p>
                  <p className="card-text text-muted">
                    {t('Availablity')} :
                    <OptimisticInput item={item} optimisticMethods={fetchHock} />
                  </p>
                </div>
                <div className="card-footer text-center">
                  <ul className="list-inline mb-0 p-0">
                    <li className="list-inline-item">
                      <Link
                        title="Edit"
                        className="lh-1 align-middle link-success"
                        to={`/branches/${item.id}`}>
                        <i className="mdi mdi-pencil"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        title="Working Hours"
                        className="lh-1 align-middle link-success"
                        to={`/branches/${item.id}/workingHours`}>
                        <i className="mdi mdi-clock"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        title="Areas"
                        className="lh-1 align-middle link-success"
                        to={`/branches/${item.id}/areas`}>
                        <i className="mdi mdi-map-marker-multiple"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        title="Contacts"
                        className="lh-1 align-middle link-success"
                        to={`/branches/${item.id}/contacts`}>
                        <i className="mdi mdi-phone"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <a
                        title="Delete"
                        className="lh-1 align-middle link-danger"
                        onClick={() => fetchHock.openDelete(item.id)}>
                        <i className="mdi mdi-delete"></i>
                      </a>
                    </li>
                    {/* contacts */}
                  </ul>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      {/* loader */}
      {fetchHock.loading && <Loader />}
      {!fetchHock.loading && !!fetchHock.error && <Error />}
      {!fetchHock.loading && !fetchHock.error && fetchHock.items.length == 0 && <Empty />}
      {/* pagination */}
      {fetchHock.totalPages > 1 && <Pagination {...fetchHock} />}
      {/* delete component */}
      {fetchHock.deleteId && fetchHock.deleteId > 0 && (
        <DeleteModal
          show={fetchHock.deleteId}
          onDeleteClick={fetchHock.deleteItem}
          onCloseClick={() => fetchHock.closeDelete()}
        />
      )}
    </div>
  )
}

export default BranchesList

function OptimisticInput({ item, optimisticMethods = null }) {
  const Pending = optimisticMethods?.optimisticPending
  const variables = optimisticMethods?.optimisticVariables
  const update = optimisticMethods?.optimisticUpdate || null
  return (
    <span>
      {Pending && variables.id === item.id ? (
        <span>
          <Input
            className="form-check-input ms-2"
            type="checkbox"
            role="switch"
            defaultChecked={variables.status === 1}
            disabled
          />
          <Spinner color="primary ms-2" size={'sm'} />
        </span>
      ) : (
        <Input
          className="form-check-input ms-2"
          type="checkbox"
          role="switch"
          onChange={() =>
            update({
              link: `/branches/availability/${item.id}`,
              data: {
                id: item.id,
                status: item.available === 1 ? 0 : 1
              }
            })
          }
          defaultChecked={item.available === 1}
        />
      )}
    </span>
  )
}
