import { useTranslation } from 'react-i18next'

import { Card, CardBody, CardHeader } from 'reactstrap'
import { MultiSearch } from 'Components/Form'

const Details = ({
  register,
  errors,
  getValues
}: {
  register: any
  errors: any
  getValues: any
}) => {
  const { t } = useTranslation()

  return (
    <Card>
      <CardHeader>
        <h5 className="card-title mb-0">{t('Package products')}</h5>
      </CardHeader>
      <CardBody>
        <MultiSearch
          label={t('Package products')}
          placeholder={t('Enter package products')}
          model={register('packageProductIds')}
          propTitle="title"
          error={errors.packageProductIds}
          path="products"
          value={getValues('packageProducts')}
        />
      </CardBody>
    </Card>
  )
}

export default Details
