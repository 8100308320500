import { createContext, useContext } from 'react'

const ProductContext = createContext<{
  products: any
  filters: any
}>(undefined)

export const useProductContext = () => {
  const context = useContext(ProductContext)
  if (!context) {
    throw new Error('useProductContext must be used within a ProductFilterProvider')
  }
  return context
}

export const ProductProvider = ({ products, filters, children }) => {
  return <ProductContext.Provider value={{ products, filters }}>{children}</ProductContext.Provider>
}
