// bootstrap elements
import { Badge, Button, Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap'
import SimpleBar from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'

import Filter from './Filter'
import ImageWithFallback from 'helpers/ImageComponent'

import Pagination from 'Components/IndexTable/partials/Pagination'
import Loader from 'Components/IndexTable/partials/Loader'
import Error from 'Components/IndexTable/partials/Error'
import Empty from 'Components/IndexTable/partials/Empty'
import ProductActionButtons from '../ActionButtons'
import DeleteModal from 'Components/Common/DeleteModal'
import AddProductsDropdownButton from '../AddProductsDropdownButton'
import { useProductContext } from '../../Context'
import { useTranslation } from 'react-i18next'
import { useProductsMultiFilters } from '../FilterComponents/Brand'

interface products {
  id: number
  title?: string
  slug?: string
  hidden: boolean
  product_type?: {
    id: number
    title?: string
  }
  web_main_image?: string
  stock: number
  price: number
  ordered: number
  ratings?: string
  created_date?: string
  created_time?: string
  product_course_id: number
  raw_price: number
  cost: number
  currency: string
  final_price: number
  published: number
}

const ItemsCardsView = ({ showFilter, closeFilter }: { showFilter: any; closeFilter: any }) => {
  const { t } = useTranslation()
  const { products } = useProductContext()

  return (
    <Row>
      <Col sm={12}>
        <FilterByCategorySlider />
      </Col>
      <Col sm={12}>
        <Card>
          <CardHeader className="card-header">
            <Row className="align-items-center gy-3">
              <div className="col-sm d-flex gap-2 align-items-center">
                <h5 className="card-title mb-0">{t('All products')}</h5>
                <SortingButtons />
              </div>
              <div className="col-sm-auto">
                <AddProductsDropdownButton />
              </div>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <ProductsList />
            </Row>
          </CardBody>
          <CardFooter>
            {products.totalPages > 1 && (
              <Pagination
                options={products?.options}
                totalPages={products?.totalPages}
                totalItems={products?.totalItems}
                showedItems={products?.showedItems}
                pagenate={products?.pagenate}
              />
            )}
          </CardFooter>
        </Card>
      </Col>

      {showFilter && <Filter show={showFilter} close={closeFilter} />}
    </Row>
  )
}

export default ItemsCardsView

function ProductsList() {
  const { t } = useTranslation()
  const { products } = useProductContext()

  if (products.loading) <Loader />
  if (products.error) <Error />
  if (products.totalItems == 0) <Empty />
  const { closeDelete, deleteItem, deleteId, openDelete } = products
  return (
    <>
      {products?.items?.map((product: products, index: number) => (
        <Col xxl={2} xl={2} lg={3} md={3} sm={4} xs={6} key={index}>
          <Card className="explore-box bg-light" style={{ minHeight: '95%' }}>
            <Badge color="light" className="rounded-0 btn-secondary product-badge">
              {product?.product_type?.title}
            </Badge>
            <CardBody>
              <div className="overflow-hidden rounded">
                <ImageWithFallback
                  src={product.web_main_image as string}
                  alt={product.title}
                  style={{ width: '100%', aspectRatio: '1/1', objectFit: 'cover' }}
                  className="img-fluid"
                />
              </div>
              <div className="mt-3 text-center">
                <h5 className="mb-2">
                  {product.published == 0 ? (
                    <i className="text-danger ri-close-circle-line fs-17 align-middle"></i>
                  ) : (
                    <i className="text-success ri-checkbox-circle-line fs-17 align-middle"></i>
                  )}{' '}
                  <span className="text-dark fs-14">{product.title}</span>
                </h5>

                <Row className="mt-4 fs-14">
                  <Col lg={6} className="border-end-dashed border-end">
                    <span className="text-muted">{t('Cost')}</span>
                    <h6>
                      {product?.currency} {product?.cost || 0}
                    </h6>
                  </Col>
                  <Col lg={6}>
                    <span className="text-muted">{t('Price')}</span>
                    <h6>
                      {product?.currency} {product?.raw_price || 0}
                    </h6>
                  </Col>
                </Row>
                <span>
                  <span className="badge  bg-light text-body fs-12 fw-medium">
                    <i className="mdi mdi-star text-warning me-1"></i>
                    {product.ratings ?? 0}
                  </span>
                </span>
              </div>

              {/* // action buttons */}
            </CardBody>
            <div className="d-flex px-2 py-2">
              <div className="flex-grow-1 ">
                {/* <h6 className="fs-15 folder-name">{item.title}</h6> */}
              </div>
              <ProductActionButtons
                product={product}
                openDelete={openDelete}
                onSuccess={products?.fetch}
              />
            </div>
          </Card>
        </Col>
      ))}
      {deleteId && deleteId > 0 && (
        <DeleteModal
          show={deleteId}
          onDeleteClick={deleteItem}
          onCloseClick={() => {
            if (closeDelete) closeDelete()
          }}
        />
      )}
    </>
  )
}

function FilterByCategorySlider() {
  const { t } = useTranslation()

  const {
    list: categories,
    selected: selectedCategory,
    filterByAction: filterByCategory
  } = useProductsMultiFilters('categories')

  return (
    <Card className="bg-light">
      <CardHeader>
        <div className="d-flex justify-content-between">
          <h5 className="card-title mb-0">{t('All categories')}</h5>
        </div>
      </CardHeader>
      <CardBody className="pb-0">
        <SimpleBar autoHide={false} style={{ width: '100%' }}>
          <div
            style={{
              width: '1500px',
              maxWidth: 'auto',
              gap: '25px',
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'row'
            }}>
            {categories &&
              Array.isArray(categories) &&
              categories?.map((category, index: number) => (
                <Card
                  onClick={() => filterByCategory(category)}
                  className="explore-box category-card cursor-pointer"
                  style={{
                    width: '150px',
                    borderColor: selectedCategory.includes(category.id) ? '#25a0e2' : ''
                  }}
                  key={index}>
                  <CardBody>
                    <div
                      className="explore-place-bid-img rounded"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: '#eeee'
                      }}>
                      <img
                        src={category.image}
                        alt={category.title}
                        style={{ width: '100%' }}
                        className="img-fluid explore-img"
                      />
                    </div>
                    <div className="mt-3 text-center">
                      <h5 className="text-dark fs-14">{category.title}</h5>
                    </div>
                  </CardBody>
                </Card>
              ))}
          </div>
        </SimpleBar>
      </CardBody>
    </Card>
  )
}

function SortingButtons() {
  const { t } = useTranslation()
  const { products } = useProductContext()

  const sort_by = products.options.sort_by
  const sort_direction = products.options.sort_direction
  return (
    <div className="d-flex align-items-center gap-1 flex-wrap">
      {[
        {
          title: 'Name',
          filter_key: 'title'
        },
        {
          title: 'Price',
          filter_key: 'price'
        },
        {
          title: 'Rating',
          filter_key: 'ratings'
        },
        {
          title: 'Stock',
          filter_key: 'stock'
        },
        {
          title: 'Published',
          filter_key: 'published'
        },
        {
          title: 'Rating',
          filter_key: 'rating'
        }
      ].map((item, index) => {
        const title = item.title
        const filter_key = item.filter_key
        const active = sort_by === filter_key
        return (
          <Button
            type="button"
            active={active}
            color="secondary"
            key={index}
            size="sm"
            onClick={(e) => {
              e.preventDefault()
              products?.sort(filter_key)
            }}>
            {active && sort_direction === 'asc' ? (
              <i className="ri-sort-asc me-1"></i>
            ) : (
              <i className="ri-sort-desc me-1"></i>
            )}

            {t(title)}
          </Button>
        )
      })}
    </div>
  )
}
