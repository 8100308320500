import { useParams } from 'react-router-dom'
import { Card, CardBody, Col, Container, Input, Label, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import Loader from 'Components/Common/Loader'
import FetchHock from 'helpers/FetchHock'
import BreadCrumb from 'Components/Common/BreadCrumb'

type Activity = {
  action: string
  name: string
  causer: {
    email: string
    image: string
    name: string
    id: number
    store_name: string
  }
  created_at: string
  data: {
    email: string
  }
  id: number
  url: string
}

const ActivitiesDetails = () => {
  document.title = 'Activities Details | Sallatk'
  const { id } = useParams()

  const { result: items, loading } = FetchHock({
    path: `/activity/${id}`
  })

  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center min-vh-100">
        <Loader />
      </div>
    )

  const data: Activity = items

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <Row>
          <Col xl={3}>
            <Card className="">
              <CardBody className="p-4">
                <div className="text-center">
                  <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                    <img
                      src={data?.causer?.image ?? 'https://picsum.photos/200'}
                      className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                      alt="user-profile"
                    />
                  </div>
                  <h5 className="fs-16 mb-1">{data?.causer?.name || '...'}</h5>
                  <p className="text-muted mb-0">{data?.causer?.email || '...'} </p>
                  <p className="text-muted mb-0">{data?.causer?.store_name || '...'} </p>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <div className="d-flex align-items-center mb-5">
                  <div className="flex-grow-1">
                    <h5 className="card-title mb-0">
                      {data?.name || '...'} :: {data?.action || '...'}
                    </h5>
                    <p className="text-muted mb-0">{data?.created_at || '...'} </p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={9}>
            <Card className="">
              <CardBody>
                <Row>
                  {data?.data &&
                    Object.entries(data?.data || {}).map(([key, value]) => {
                      return (
                        <Col lg={12}>
                          <div className="mb-3">
                            <Label htmlFor={key} className="form-label">
                              {key}
                            </Label>
                            <Input
                              type="text"
                              disabled
                              className="form-control"
                              id={key}
                              defaultValue={value}
                            />
                          </div>
                        </Col>
                      )
                    })}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ActivitiesDetails
