import BreadCrumb from 'Components/Common/BreadCrumb'
import ButtonCards from 'pages/BasicSettings/component/ButtonCards'
import { Col, Container, Row } from 'reactstrap'

function Settings() {
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <ButtonCards
          data={[
            {
              name: 'Labels',
              url: '/products/settings/labels/list',
              icon: 'mdi mdi-label-outline'
            },
            {
              name: 'Badges',
              url: '/products/settings/badges/list',
              icon: 'mdi mdi-checkbox-blank-badge-outline'
            },
            { name: 'Tags', url: '/products/settings/tags/list', icon: 'mdi mdi-tag-outline' },
            {
              name: 'Features',
              url: '/products/settings/features/list',
              icon: 'mdi mdi-star-outline'
            },
            {
              name: 'Order forms',
              url: '/products/settings/forms/list',
              icon: 'mdi mdi-file-document-outline'
            }
          ]}
        />
      </Container>
    </div>
  )
}

export default Settings
