import { useTranslation } from 'react-i18next'

function useStaticData(): {
  moduleNameOptions: { label: string; value: string }[]
  productsModuleNameOptions: { label: string; value: string }[]
  styleOptions: { label: string; value: string }[]
  moduleMap: Record<string, string>
} {
  const { t } = useTranslation()

  const moduleNameOptions = [
    { label: 'Category', value: 'Category' },
    { label: 'Brand', value: 'Brand' },
    { label: 'Product', value: 'Product' },
    { label: 'MenuCustomLink', value: 'MenuCustomLink' },
    { label: 'Articles', value: 'Article' },
    { label: 'Pages', value: 'staticPages' }
  ]

  const productsModuleNameOptions = [
    { label: 'Category', value: 'Category' },
    { label: 'Brand', value: 'Brand' },
  ]

  const styleOptions = [
    { label: t('Mega'), value: 'mega' },
    { label: t('Standard'), value: 'standard' }
  ]

  // type ModuleNameOptionValue = (typeof moduleNameOptions)[number]['value']
  // const moduleMap: Record<ModuleNameOptionValue, string> = {
  const moduleMap: Record<string, string> = {
    Brand: 'brands',
    Category: 'category',
    Product: 'products',
    MenuCustomLink: 'menuCustomLinks',
    Article: 'article',
    staticPages: 'staticPages'
  }

  return {
    moduleNameOptions,
    styleOptions,
    moduleMap,
    productsModuleNameOptions
  }
}

export default useStaticData
