import { formatBytes } from 'helpers/FileSize'
import { useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import ImageFile from './ImageFile'

type file = any

const MultiImageUpload = ({
  label,
  placeholder,
  model,
  error,
  value,
  images,
  onUpload,
  onFinishUpload,
  onChange
}: {
  label: string
  placeholder?: string
  model?: any
  error?: any
  value?: any
  images?: any
  onUpload?: () => void
  onFinishUpload?: () => void
  onChange?: (files: any[]) => void
}) => {
  // base state
  const [selectedFiles, setSelectedFiles] = useState<file[]>([])

  // update file event
  const uploadFiles = (files: File[]) => {
    let filesList = [...selectedFiles]
    files.map((file: any) => {
      Object.assign(file, {
        FormatSize: formatBytes(file.size),
        preview: URL.createObjectURL(file),
        readyToLoad: true,
        loading: true
      })
      filesList.push(file)
    })
    setSelectedFiles(filesList)
  }

  // remove item from list
  const removeFile = (index: number) => {
    setSelectedFiles((prevFiles) => {
      const updatedFiles = [...prevFiles]
      updatedFiles.splice(index, 1)
      return updatedFiles
    })
  }

  const updateFile = (index: number, file: file) => {
    const oldSelectedFiles = [...selectedFiles]
    oldSelectedFiles[index] = file
    setSelectedFiles(oldSelectedFiles)
  }

  const updateData = () => {
    // Update value
    const name = model?.name
    const value = selectedFiles.map((file) => {
      return file?.name?.file ?? file?.name
    })
    model?.onChange({ target: { name, value } })
  }

  useEffect(() => {
    // sync with external state
    if (onChange) {
      onChange(selectedFiles) // Call the onChange callback whenever selectedFiles changes
    }
    updateData()
  }, [selectedFiles])

  useEffect(() => {
    if (value && value.length > 0) {
      const defaultItem: any = []
      value.map((el: any, index: number) => {
        defaultItem.push({
          name: el,
          preview: images[index],
          readyToLoad: false,
          loading: false
        })
      })
      setSelectedFiles(defaultItem)
    }
  }, [])

  return (
    <div>
      <h5 className="fs-14 mb-1">{label}</h5>
      <Dropzone onDrop={(files) => uploadFiles(files)}>
        {({ getRootProps, isDragActive, getInputProps }) => (
          <div
            {...getRootProps()}
            className="dropzone dz-clickable "
            style={{
              opacity: isDragActive ? '0.5' : '1'
            }}>
            <div className="dz-message">
              <input {...getInputProps()} />
              <div className="mb-3 mt-5">
                <i className="display-4 text-muted ri-upload-cloud-2-fill" />
              </div>
              <h5>{placeholder}.</h5>
            </div>
          </div>
        )}
      </Dropzone>

      <div className="list-unstyled mb-0">
        {selectedFiles.map((file, index) => (
          <ImageFile
            mainFile={file}
            key={index}
            removeFile={removeFile}
            index={index}
            onUpload={onUpload}
            onFinishUpload={onFinishUpload}
            updateFile={updateFile}
          />
        ))}
      </div>
    </div>
  )
}

export default MultiImageUpload
