import React, { useState } from 'react'
import { Badge, Col, Container, Input, Row } from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import FetchHock from 'helpers/FetchHock'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import linkGenerator from 'helpers/linkGenerator'
import showSuccess from 'helpers/SuccessNotfication'
import showError from 'helpers/ErorrNotfication'
import IndexTable, { FilterItem } from 'Components/IndexTable'
import EasyEdit from 'react-easy-edit'
import './style.css'

const CurrencyList = () => {
  document.title = 'Currencies | Sallatk'

  const { t } = useTranslation()
  const [optimisticItems, setOptimisticItems] = useState({})

  type Currency = {
    id: number
    disabled: 0 | 1
    is_default: 0 | 1
    code: string
    rate: string
  }

  // const {
  //   items,
  //   loading,
  //   error,
  //   options,
  //   totalPages,
  //   totalItems,
  //   pagenate,
  //   openDelete,
  //   closeDelete,
  //   deleteItem,
  //   showedItems,
  //   deleteId,
  //   fetch
  // } = FetchHock<Currency>({ path: '/currencies' })

  const fetchHook =  FetchHock<Currency>({ path: '/currencies' })

  
  const headerColumns: any[] = [
    {
      title: 'Id',
      accessor: 'id'
    },
    {
      title: 'Code',
      accessor: 'code'
    },
    {
      title: 'Rate (Editable)',
      accessor: 'rate'
    },
    {
      title: 'Disabled',
      accessor: 'disabled'
    },
    {
      title: 'Default'
    }
  ]
  const filterItems: FilterItem[] = [
    {
      type: 'search',
      col: 6,
      placeholder: t('Search ...'),
      action: (value) => fetchHook.search(value)
    }
  ]

  const update = async (id: number, payload: any) => {
    const previousState = optimisticItems[id]
    try {
      setOptimisticItems((prevState) => ({
        ...prevState,
        [id]: {
          ...fetchHook.items.find((item) => item.id === id),
          ...payload
        }
      }))

      const response = await axios.put(linkGenerator(`/currencies/${id}`), payload)
      if (response.status) {
        showSuccess(t('Currency status updated successfully'))
      }
    } catch (error) {
      showError(t('Error updating Currency'))
      // Revert state on error
      setOptimisticItems((prevState) => ({
        ...prevState,
        [id]: previousState
      }))
    }
  }

  const getItemState = (item) => {
    return optimisticItems[item.id] || item
  }
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />

        <Row>
          <Col lg={12}>
            <IndexTable
              title={t('Currencies')}
              filters={filterItems}
              headerColumns={headerColumns}
              {...fetchHook}>
              {fetchHook.items?.map((item, index) => {
                const currentItem = getItemState(item)
                return (
                  <tr key={index}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <h5 className="fs-14 mb-1">#{currentItem.id} </h5>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p className=" mb-1">{currentItem.code}</p>
                    </td>
                    <td>
                      <span className=" mb-1">
                        <EasyEdit
                          type="number"
                          value={currentItem.rate}
                          onSave={(value) => {
                            if (value === currentItem.rate) {
                              return
                            }
                            update(currentItem.id, {
                              disabled: currentItem.disabled,
                              rate: value
                            })
                          }}
                          attributes={{
                            className: 'form-control form-control-sm'
                          }}
                          saveOnBlur
                          // onCancel={() => {}}
                          // saveButtonLabel={t('Save')}
                          // cancelButtonLabel={t('Cancel')}
                          // saveButtonStyle="btn btn-primary btn-sm mx-1"
                          // cancelButtonStyle="btn btn-secondary btn-sm me-1"
                        />
                      </span>
                    </td>
                    <td>
                      <div className="form-check form-switch-md form-switch form-switch-secondary">
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          onChange={() =>
                            update(currentItem.id, {
                              disabled: currentItem.disabled === 1 ? 0 : 1,
                              rate: currentItem.rate
                            })
                          }
                          checked={currentItem.disabled === 1}
                        />
                      </div>
                    </td>
                    <td>
                      <Badge color={currentItem.is_default === 1 ? 'success' : 'danger'}>
                        {currentItem.is_default === 1 ? t('Default') : ''}
                      </Badge>
                    </td>
                  </tr>
                )
              })}
            </IndexTable>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CurrencyList
