import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import clsx from 'clsx'
import linkGenerator from 'helpers/linkGenerator'
import { useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { debounce } from 'lodash'

const fetchOptions = async (
  path: string,
  term = '',
  propTitle: string
): Promise<Array<{ label: string; value: string | number }>> => {
  const response = await axios.get(linkGenerator(path), {
    params: { term }
  })
  const items = response.data.result?.data || response.data.result
  return items?.map((item: any) => ({ ...item, label: String(item[propTitle]), value: item.id }))
}

const initializeValue = (
  defaultValue,
  isMulti,
  propTitle
): DefaultValue | DefaultValue[] | null => {
  if (!defaultValue) {
    return null
  }

  if (isMulti && Array.isArray(defaultValue)) {
    return defaultValue.map((item) => ({
      label: String(item[propTitle] || ''),
      value: item.id
    }))
  }

  return {
    label: propTitle in defaultValue ? String(defaultValue[propTitle]) : '',
    value: defaultValue?.id
  }
}

type DefaultValue = {
  label: string
  value: string | number
}

type LazySelectPropType = {
  label?: string
  placeholder: string
  model: {
    name: string
    onChange: (event: { target: { name: string; value: any } }) => void
  }
  error?: any
  path: string
  defaultValue?: any[] | any
  className?: string
  disabled?: boolean
  propTitle?: string
  isMulti?: boolean
  onChange?: (selectedOption: any) => void
}

// pass the value you want to submit to server
// pass to defaultValue the value you want to display in the select contain id and propTitle
// you wand to pass propTitle in edit mode to display default value in the select either in isMulti or not

function LazySelect({
  label,
  placeholder,
  model,
  error,
  path,
  defaultValue,
  className,
  disabled,
  propTitle = 'name',
  isMulti = false,
  onChange
}: LazySelectPropType) {
  const { t } = useTranslation()
  const [term, setTerm] = useState('')
  const [value, setValue] = useState<DefaultValue | DefaultValue[] | null>(
    initializeValue(defaultValue, isMulti, propTitle)
  )
  const [shouldFetch, setShouldFetch] = useState(false)

  const randomId = useMemo(() => Math.random().toString(36).substring(7), [])

  const {
    data: options = [],
    isLoading,
    refetch
  } = useQuery({
    queryKey: [path, term],
    queryFn: () => fetchOptions(path, term, propTitle),
    enabled: shouldFetch
  })

  const handleMenuOpen = useCallback(() => {
    if (!shouldFetch) {
      setShouldFetch(true)
      refetch()
    }
  }, [shouldFetch, refetch])

  const handleInputChange = useMemo(
    () =>
      debounce((newValue: string, actionMeta: { action: string }) => {
        if (actionMeta.action === 'input-change') {
          setTerm(newValue)
          if (!shouldFetch) {
            setShouldFetch(true)
          }
        }
      }, 300),
    [shouldFetch]
  )

  const handleChange = useCallback(
    (selectedOption: any) => {
      setValue(selectedOption)
      const name = model?.name
      model?.onChange({
        target: {
          name,
          value: isMulti
            ? selectedOption.map((option: DefaultValue) => option.value)
            : selectedOption.value ?? selectedOption
        }
      })
      onChange && onChange(selectedOption)
    },
    [model, isMulti, onChange]
  )

  const selectStyles = useMemo(
    () => ({
      multiValueLabel: (styles: any) => ({
        ...styles,
        color: 'white'
      })
    }),
    [error]
  )

  return (
    <div className={clsx('mb-3', className)}>
      {label && (
        <label htmlFor={randomId} className="form-label">
          {label}
        </label>
      )}
      <Select
        id={randomId}
        isMulti={isMulti}
        isDisabled={disabled}
        className={error ? 'form-control is-invalid p-0' : 'form-control p-0'}
        options={options}
        isLoading={isLoading}
        placeholder={placeholder ?? `${t('Enter')} ${label}`}
        onMenuOpen={handleMenuOpen}
        onInputChange={handleInputChange}
        value={value}
        onChange={handleChange}
        styles={selectStyles}
      />
      {error && <div className="invalid-feedback">{error.message}</div>}
    </div>
  )
}

export default LazySelect
