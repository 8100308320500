import { useTranslation } from 'react-i18next'

import { Card, CardBody, CardHeader } from 'reactstrap'
import { Input } from 'Components/Form'
import { useFormContext } from 'react-hook-form'

const Stock = ({isEdit}) => {
  const { t } = useTranslation()
  const {
    register,
    formState: { errors },
    getValues
  } = useFormContext()

  return (
    <Card>
      <CardHeader className="">
        <h5 className="card-title mb-0">{t('Stock')}</h5>
      </CardHeader>
      <CardBody>
      {!isEdit && (
        <Input
        label={t('Quantity')}
        placeholder={t('Enter quantity')}
        type="number"
        model={register('quantity', {
          required: t('Quantity is required')
        })}
        error={errors.quantity} 
      />
      )}
          <Input
          label={t('Stock minimum alert')}
          placeholder={t('Enter stock minimum alert')}
          type="number"
          model={register('stock_min_alert', {
            valueAsNumber: true,
            validate: (value: number) => Number.isInteger(value) || t('Must be an integer')
          })}
          error={errors.stock_min_alert}
          atBeginIcon="mdi mdi-alert"
        />
      </CardBody>
    </Card>
  )
}

export default Stock
