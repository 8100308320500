import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'

const TextEditor = ({
  label,
  placeholder = '',
  model,
  error,
  value = '',
  disabled = false
}: {
  label?: string
  placeholder?: string
  model?: any
  error?: any
  value?: string
  disabled?: boolean
}) => {
  const randomId = Math.random().toString(36).substring(7)

  // name of input
  const name = model?.name

  if (ClassicEditor) {
    return (
      <div className="mb-3">
        {!!label && (
          <label htmlFor={randomId} className="form-label">
            {label}
          </label>
        )}
        <div className={error ? 'is-invalid' : ''}>
          <CKEditor
            disabled={disabled}
            id={randomId}
            editor={ClassicEditor}
            data={value}
            config={{
              placeholder: placeholder,
              toolbar: [
                'heading',
                'bold',
                'italic',
                'link',
                'bulletedList',
                'numberedList',
                'blockQuote',
                'undo',
                'redo'
              ]
            }}
            onChange={(event, editor) => {
              const data = editor?.getData()
              model?.onChange &&
                model?.onChange({
                  target: {
                    name,
                    value: data
                  }
                })
            }}
            onError={(error, { willEditorRestart }) => {
              console.log(error, 'CKEditor error')
            }}
          />
        </div>
        <div className="invalid-feedback">{error?.message}</div>
      </div>
    )
  }
}

export default TextEditor
