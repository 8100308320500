import AsyncReactSelect from 'react-select/async'
// fetch hock logic
import linkGenerator from 'helpers/linkGenerator'
import axios from 'axios'
import { useMemo } from 'react'

const MultiSearch = ({
  label,
  placeholder,
  model,
  error,
  value,
  path,
  propTitle = 'name',
  className,
  onChange
}: {
  label?: string
  placeholder: string
  model?: any
  error?: any
  value?: any
  path: string
  propTitle?: string
  className?: string
  onChange?: (value: { label: string; value: string }[]) => void
}) => {
  // id for input focus
  const randumId = Math.random().toString(36).substring(7)
  // name of input
  const name = model.name
  // options logic
  const loadOptions = (
    inputValue: string,
    callback: (options: { label: string; value: string }[]) => void
  ) => {
    axios
      .get(linkGenerator('/' + path), {
        params: {
          term: inputValue,
          page: 1,
          limit: 5,
          sort_direction: 'desc',
          sort_by: 'id'
        }
      })
      .then((response) => {
        const items = response.data.result?.data
        callback(
          items?.map((item: any) => {
            return { label: item[propTitle], value: item.id }
          })
        )
      })
      .catch(() => {
        callback([])
      })
  }

  // change logic
  const changeSelect = (select: any) => {
    model.onChange({
      target: {
        name,
        value: select.map((el: any) => el.value)
      }
    })
    if (onChange) onChange(select)
  }

  let defaultValue
  if (value) {
    const values: any = []
    value.map((el: any) => {
      values.push({
        label: el[propTitle],
        value: el.id
      })
    })
    defaultValue = values
  }

  const selectStyles = useMemo(
    () => ({
      multiValueLabel: (styles: any) => ({
        ...styles,
        color: 'white'
      })
    }),
    [error]
  )

  return (
    <div className={'mb-3 ' + className}>
      {label && (
        <label htmlFor={randumId} className="form-label">
          {label}
        </label>
      )}
      <AsyncReactSelect
        isMulti={true}
        loadOptions={loadOptions}
        placeholder={placeholder}
        className={error ? 'form-control is-invalid p-0' : 'form-control p-0'}
        defaultValue={defaultValue}
        onChange={changeSelect}
        defaultOptions
        styles={selectStyles}
      />
      <div className="invalid-feedback">{error?.message}</div>
    </div>
  )
}

export default MultiSearch
