import { defaultImage } from 'ThemeManager/Templates/DefaultValues'

export default {
  content: [
    {
      slug: '',
      image: defaultImage,
      title: {
        ar: 'حياكة شتوية جديدة',
        en: 'New Winter Knits'
      },
      description: {
        ar: 'أنماط جديدة متعددة الاستخدامات إلى ما لا نهاية تقول نعم للربيع. الموسم يبدو مشرقا.',
        en: 'Endlessly versatile new styles that say yes to spring. The season’s looking bright.'
      }
    },
    {
      slug: '',
      image: defaultImage,
      title: {
        ar: 'حياكة الربيع الجديدة',
        en: 'New Spring Knits'
      },
      description: {
        ar: 'أنماط جديدة متعددة الاستخدامات إلى ما لا نهاية تقول نعم للربيع. الموسم يبدو مشرقا.',
        en: 'Endlessly versatile new styles that say yes to spring. The season’s looking bright.'
      }
    },
    {
      slug: '',
      image: defaultImage,
      title: {
        ar: 'وصولا الى جوهر',
        en: 'Down To The Core'
      },
      description: {
        ar: 'أنماط جديدة متعددة الاستخدامات إلى ما لا نهاية تقول نعم للربيع. الموسم يبدو مشرقا.',
        en: 'Endlessly versatile new styles that say yes to spring. The season’s looking bright.'
      }
    }
  ],
  className: 'mb-12 md:mb-14 xl:mb-16 lg:pt-1 xl:pt-0',
  variant: 'default',
  sectionHeading: {
    en: 'Descover our new collection',
    ar: 'اكتشف مجموعتنا الجديدة'
  }
}
