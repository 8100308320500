import { useTranslation } from 'react-i18next'

import { Card, CardBody, CardHeader } from 'reactstrap'
import { Input, Textarea } from 'Components/Form'
import { useFormContext } from 'react-hook-form'

const Info = () => {
  const { t } = useTranslation()
  const { register } = useFormContext()

  return (
    <Card>
      <CardHeader className="">
        <h5 className="card-title mb-0">{t('Product SEO')}</h5>
      </CardHeader>
      <CardBody>
        <Input label={t('Title')} placeholder={t('Enter title')} model={register('meta.title')} />
        <Textarea
          label={t('Description')}
          placeholder={t('Enter description')}
          model={register('meta.description')}
        />
        <Input
          label={t('Keywords')}
          placeholder={t('Enter keywords')}
          model={register('meta.keywords')}
        />
      </CardBody>
    </Card>
  )
}

export default Info
