import { useContext } from 'react'
import { Button, Input } from 'reactstrap'
import { POSContext } from '../POSContext'

export const POSSearch = () => {
  const { searchInput, setSearchInput } = useContext(POSContext)

  return (
    <div className="mb-3" style={{ position: 'relative' }}>
      <Input
        className="mb-0"
        placeholder={`search...`}
        value={searchInput}
        onChange={(e: any) => {
          setSearchInput(e.target.value)
        }}
      />
      {searchInput && (
        <Button
          className="p-1 rounded-circle"
          size="sm"
          color="danger"
          onClick={() => setSearchInput('')}
          style={{
            width: '30px',
            height: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            right: 10,
            top: '50%',
            zIndex: 1,
            transform: 'translateY(-50%)'
          }}>
          <i className="ri-close-line fs-2"></i>
        </Button>
      )}
    </div>
  )
}
