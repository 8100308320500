import { useDefaultCurrency } from 'Components/DefaultCurrency'
import PopUp from 'Components/PopUp'
import { useEffect, useState } from 'react'
import { useFieldArray, UseFieldArrayReturn, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Table,
  UncontrolledAccordion
} from 'reactstrap'
import { usePaidFeaturesContext } from '.'
import AvatarStack from '../AvatarStack'
import PaidOptionsForm from './PaidOptionsForm'

import { useContainerQuery } from 'react-container-query'

// import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
// import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

import { Col, Row } from 'reactstrap'

interface FieldArrayItem {
  id: string
  value: string
  images:
    | {
        image: string
        web_image: string
      }[]
    | string[]
  selectedFiles: any
}

interface FormValues {
  [key: string]: FieldArrayItem[]
}
const query = {
  smallContainer: {
    minWidth: 0,
    maxWidth: 600
  },
  'medium-container': {
    minWidth: 501,
    maxWidth: 768
  },
  'large-container': {
    minWidth: 769
  }
}

function PaidFeaturesTable() {
  const { control } = useFormContext()
  const { t } = useTranslation()
  const [params, containerRef] = useContainerQuery(query, {})
  const [open, setOpen] = useState<any>(false)
  const openPopup = () => setOpen(true)
  const closePopup = () => setOpen(false)
  const { name, inputs } = usePaidFeaturesContext()

  const defaultCurrency = useDefaultCurrency()

  const [editValues, setEditValues] = useState<any>(null)

  const { fields, append, remove, update }: UseFieldArrayReturn<FormValues, string, 'id'> =
    useFieldArray({
      control,
      name: name
    })
  const addNewOption = (values) => {
    append(values)
    closePopup()
  }

  const editOption = (values) => {
    update(editValues?.index, values)
    setEditValues(null)
    closePopup()
  }

  const removeOption = (index: number) => {
    remove(index)
  }

  const handleEdit = (index: number) => {
    setEditValues({
      index: index,
      values: fields[index]
    })
    openPopup()
  }

  const openNewPopup = () => {
    setEditValues(null)
    openPopup()
  }

  // this hook is used because the api accept images as array of strings not array of objects as it received
  useEffect(() => {
    if (fields && fields.length > 0) {
      fields.map((field, index) => {
        if (field.selectedFiles) return

        const valuesFromImagesObj = field?.images?.map((i) => {
          if (typeof i === 'string') return i
          if (i?.web_image && i?.image) {
            return {
              preview: i?.web_image,
              name: i?.image
            }
          }
        })
        update(index, {
          ...field,
          selectedFiles: valuesFromImagesObj,
          images: field?.images?.map((i) => {
            if (i?.image) return i.image
          })
        })
      })
    }
  }, [])

  return (
    <div ref={containerRef}>
      {params?.smallContainer ? (
        <div className="m-3 mb-0 ">
          <UncontrolledAccordion toggle={(id) => console.log(id)}>
            {(fields as any).map((field, index) => (
              <AccordionItem key={index}>
                <AccordionHeader targetId={index}>
                  {field.feature_name} / {field.title}
                </AccordionHeader>
                <AccordionBody accordionId={index}>
                  <Row>
                    {inputs?.map((input, inputIndex) => {
                      if (input?.noPreview) return null
                      const content = getContent({ field, input })
                      return (
                        <Col key={inputIndex} xs="6" className="mb-2">
                          <Row>
                            <Col className="fw-bold">{input?.label}:</Col>
                            <Col>{content}</Col>
                          </Row>
                        </Col>
                      )
                    })}
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <ActionsComponent remove={removeOption} edit={handleEdit} index={index} />
                    </Col>
                  </Row>
                </AccordionBody>
              </AccordionItem>
            ))}
          </UncontrolledAccordion>
        </div>
      ) : (
        <div className="table-responsive">
          <Table responsive>
            <thead>
              <tr>
                {inputs?.map((input, index) => {
                  if (input?.noPreview) return null
                  return <th key={index}>{input?.label}</th>
                })}
                <th>{t('Actions')}</th>
              </tr>
            </thead>
            <tbody>
              {(fields as any).map((field, index) => {
                return (
                  <tr key={index}>
                    {inputs?.map((input, index) => {
                      if (input?.noPreview) return null
                      const content = getContent({ field, input })

                      return <td key={index}>{content}</td>
                    })}
                    <td>
                      <ActionsComponent remove={removeOption} edit={handleEdit} index={index} />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      )}

      <div>
        <PopUp title={t('Add New Option')} show={open} close={closePopup} size="lg">
          <PaidOptionsForm
            syncValueToForm={editValues?.values ? editOption : addNewOption}
            defaultValues={editValues?.values || {}}
          />
        </PopUp>

        <button className="btn btn-secondary  m-3" type="button" onClick={openNewPopup}>
          <i className="ri-add-line align-middle"></i> {t('Add option')}
        </button>
      </div>
    </div>
  )
}

export default PaidFeaturesTable

function getContent({ field, input }) {
  if (input?.name === 'featureOptionId') {
    return field?.title
  } else if (input?.name === 'images') {
    return <ImageComponent field={field} />
  } else if (input?.name === 'discount') {
    return <DiscountComponent field={field} />
  } else if (input?.name === 'featuresType') {
    return field?.feature_name
  } else {
    return input.name in field ? field[input.name] : '...'
  }
}

{
  /* after add item or edit item selectedFiles will present */
}
function ImageComponent({ field }) {
  if (field?.selectedFiles?.length) {
    return <AvatarStack values={field?.selectedFiles} />
  } else if (field?.images?.length) {
    return (
      <AvatarStack
        values={field?.images.map((item) => ({
          ...item,
          preview: item.web_image
        }))}
      />
    )
  }

  return null
}

function DiscountComponent({ field }) {
  const defaultCurrency = useDefaultCurrency()

  const discountType = field?.discount_type
  const symbol = discountType === 'perc' ? '%' : discountType === 'net' ? defaultCurrency : ''
  return (
    <>
      {field?.discount}
      {symbol}
    </>
  )
}

function ActionsComponent({ remove, edit, index }) {
  return (
    <div className="d-flex gap-2">
      <button className="btn btn-danger btn-sm" type="button" onClick={() => remove(index)}>
        <i className="ri-delete-bin-line align-middle"></i>
      </button>
      <button className="btn btn-primary btn-sm" type="button" onClick={() => edit(index)}>
        <i className="ri-edit-2-line align-middle"></i>
      </button>
    </div>
  )
}
