// products
import ProductsList from 'pages/Products/Products/list/index'

import CreateCard from 'pages/Products/Products/create/Card'
import CreateCourse from 'pages/Products/Products/create/Course'
import CreateDefault from 'pages/Products/Products/create/Default'
import CreateDigital from 'pages/Products/Products/create/Digital'
import CreateDonation from 'pages/Products/Products/create/Donation'
import CreateMulti from 'pages/Products/Products/create/Multi'
import CreateWithFeatures from 'pages/Products/Products/create/WithFeatures'
import CreateWithPaidFeatures from 'pages/Products/Products/create/WithPaidFeatures'

import EditCard from 'pages/Products/Products/edit/Card'
import EditCourse from 'pages/Products/Products/edit/Course'
import EditDefault from 'pages/Products/Products/edit/Default'
import EditDigital from 'pages/Products/Products/edit/Digital'
import EditDonation from 'pages/Products/Products/edit/Donation'
import EditMulti from 'pages/Products/Products/edit/Multi'
import EditWithFeatures from 'pages/Products/Products/edit/WithFeatures'
import EditWithPaidFeatures from 'pages/Products/Products/edit/WithPaidFeatures'

import ViewCard from 'pages/Products/Products/view/Card'
import ViewCourse from 'pages/Products/Products/view/Course'
import ViewDefault from 'pages/Products/Products/view/Default'
import ViewDigital from 'pages/Products/Products/view/Digital'
import ViewDonation from 'pages/Products/Products/view/Donation'
import ViewMulti from 'pages/Products/Products/view/Multi'
import ViewWithFeatures from 'pages/Products/Products/view/WithFeatures'
import ViewWithPaidFeatures from 'pages/Products/Products/view/WithPaidFeatures'

// categories
import CategoriesList from 'pages/Products/Categories/list'

import ChaptersList from 'pages/Products/Chapters/list'

import ProductStock from 'pages/Products/Products/stock'
import Settings from 'pages/Products/Settings'
import BadgesList from 'pages/Products/Settings/Badges/list'
import ExtraFeesList from 'pages/Products/Settings/ExtraFees/list'
import FeaturesList from 'pages/Products/Settings/Features/list'
import FeaturesOptionsList from 'pages/Products/Settings/FeaturesOptions/list'
import FormsList from 'pages/Products/Settings/Forms/list'
import FormsPreview from 'pages/Products/Settings/Forms/preview'
import LabelsList from 'pages/Products/Settings/Labels/list'
import LabelValuesList from 'pages/Products/Settings/LabelsValues/list'
import TagsList from 'pages/Products/Settings/Tags/list'

const ProductsRoute = [
  // products
  { path: '/products/list', component: <ProductsList /> },
  // create routes
  { path: '/products/create/default', component: <CreateDefault /> },
  { path: '/products/create/withFeatures', component: <CreateWithFeatures /> },
  { path: '/products/create/WithPaidFeatures', component: <CreateWithPaidFeatures /> },
  { path: '/products/create/card', component: <CreateCard /> },
  { path: '/products/create/digital', component: <CreateDigital /> },
  { path: '/products/create/multi', component: <CreateMulti /> },
  { path: '/products/create/donation', component: <CreateDonation /> },
  { path: '/products/create/course', component: <CreateCourse /> },
  // update routes
  { path: '/products/edit/default/:id', component: <EditDefault /> },
  { path: '/products/edit/withFeatures/:id', component: <EditWithFeatures /> },
  { path: '/products/edit/WithPaidFeatures/:id', component: <EditWithPaidFeatures /> },
  { path: '/products/edit/card/:id', component: <EditCard /> },
  { path: '/products/edit/digital/:id', component: <EditDigital /> },
  { path: '/products/edit/multi/:id', component: <EditMulti /> },
  { path: '/products/edit/donation/:id', component: <EditDonation /> },
  { path: '/products/edit/course/:id', component: <EditCourse /> },
  //view routes
  { path: '/products/view/default/:id', component: <ViewDefault /> },
  { path: '/products/view/withFeatures/:id', component: <ViewWithFeatures /> },
  { path: '/products/view/WithPaidFeatures/:id', component: <ViewWithPaidFeatures /> },
  { path: '/products/view/card/:id', component: <ViewCard /> },
  { path: '/products/view/digital/:id', component: <ViewDigital /> },
  { path: '/products/view/multi/:id', component: <ViewMulti /> },
  { path: '/products/view/donation/:id', component: <ViewDonation /> },
  { path: '/products/view/course/:id', component: <ViewCourse /> },

  // categories
  { path: '/categories/list', component: <CategoriesList /> },

  // chapters
  { path: '/products/chapters/list/:id/:courseId', component: <ChaptersList /> },
  // { path: '/products/Chapters/assets/list/:id', component: <ChaptersAssetsList /> },

  // SETTINGS
  { path: '/products/settings', component: <Settings /> },

  //labels
  { path: '/products/settings/labels/list', component: <LabelsList /> },
  // labels values
  { path: '/products/settings/labels/values/list/:id', component: <LabelValuesList /> },
  // features
  { path: '/products/settings/features/list', component: <FeaturesList /> },
  // features options
  { path: '/products/settings/features/options/list/:id', component: <FeaturesOptionsList /> },
  // forms
  { path: '/products/settings/forms/list', component: <FormsList /> },
  { path: '/products/settings/forms/preview/:id', component: <FormsPreview /> },
  // badges
  { path: '/products/settings/badges/list', component: <BadgesList /> },
  // extra fees
  { path: '/products/settings/extraFees/list', component: <ExtraFeesList /> },
  // tags
  { path: '/products/settings/tags/list', component: <TagsList /> },
  // product stock
  { path: '/products/stock/:id', component: <ProductStock /> }
]

export default ProductsRoute
