import React, { useState } from 'react'
import { Badge, Col, Container, Input, Row } from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import FetchHock from 'helpers/FetchHock'
import { useTranslation } from 'react-i18next'
import IndexTable from 'Components/IndexTable'
import axios from 'axios'
import linkGenerator from 'helpers/linkGenerator'
import showSuccess from 'helpers/SuccessNotfication'
import showError from 'helpers/ErorrNotfication'

const LanguagesList = () => {
  document.title = 'Languages | Sallatk'

  const { t } = useTranslation()
  const [optimisticItems, setOptimisticItems] = useState<{ [key: number]: any }>({})

  type Language = {
    id: number
    active: 0 | 1
    default: 0 | 1
    title: string
  }

  const { items, loading, error, options, totalPages, totalItems, pagenate, showedItems, fetch } =
    FetchHock<Language>({ path: '/languages' })

  const headerColumns: any[] = [
    {
      title: 'Id',
      accessor: 'id'
    },
    {
      title: 'Title',
      accessor: 'title'
    },
    {
      title: 'Active'
    },
    {
      title: 'Default'
    }
  ]

  const update = async (id: number, payload: any) => {
    const previousState = optimisticItems[id]
    try {
      setOptimisticItems((prevState) => ({
        ...prevState,
        [id]: { ...prevState[id], ...payload }
      }))

      const response = await axios.put(linkGenerator(`/languages/${id}`), payload)
      if (response.status) {
        showSuccess(t('Language status updated successfully'))
      }
    } catch (error) {
      showError(t('Error updating language'))
      // Revert state on error
      setOptimisticItems((prevState) => ({
        ...prevState,
        [id]: previousState
      }))
    }
  }

  const getItemState = (item: Language) => {
    return optimisticItems[item.id] ? { ...item, ...optimisticItems[item.id] } : item
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />

        <Row>
          <Col lg={12}>
            <IndexTable
              title={t('Languages')}
              headerColumns={headerColumns}
              loading={loading}
              error={error}
              options={options}
              totalPages={totalPages}
              totalItems={totalItems}
              showedItems={showedItems}
              pagenate={pagenate}>
              {items?.map((item, index) => {
                const currentItem = getItemState(item)
                return (
                  <tr key={index}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <h5 className="fs-14 mb-1">#{currentItem.id} </h5>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p className="text-muted mb-1">{currentItem.title}</p>
                    </td>
                    <td>
                      <div className="form-check form-switch-md form-switch form-switch-secondary">
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          onChange={() =>
                            update(currentItem.id, {
                              active: currentItem.active === 1 ? 0 : 1
                            })
                          }
                          checked={currentItem.active === 1}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-check form-switch-md form-switch form-switch-secondary">
                        <Badge color={currentItem.default === 1 ? 'success' : 'danger'}>
                          {currentItem.default === 1 ? t('Default') : t('Not default')}
                        </Badge>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </IndexTable>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default LanguagesList
