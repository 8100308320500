// react imports
import { useEffect, useState } from 'react'
// botstrap elemnts
import { Card, CardBody, Col } from 'reactstrap'
// translation
import { useTranslation } from 'react-i18next'
// debependat components
import fetch from 'helpers/server'
import CountUp from 'react-countup'

const Widgets = ({ refresh }: { refresh: boolean }) => {
  const { t } = useTranslation()
  // report type
  type ticketsReports = {
    status: number
    count: number
  }
  const [items, setItems] = useState<ticketsReports[]>([])

  // get content from server
  const fetchData = async () => {
    const response = await fetch<ticketsReports[]>('/allTickets/reports', null, 'get')
    if (response.status && response.data) {
      const data = response.data
      setItems(data)
    }
  }

  // watch refres
  useEffect(() => {
    fetchData()
  }, [refresh])

  return (
    <>
      {items?.map((item: any, key: number) => (
        <Col xxl={3} lg={3} sm={6} xs={6} key={key}>
          <Card className="card-animate">
            <CardBody>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="fw-medium text-muted mb-0">{t(item.status + ' tickets')}</p>
                  <h2 className="mt-4 ff-secondary fw-semibold">
                    <span className="counter-value" data-target={item.count}>
                      <CountUp start={0} end={item.count} duration={1} />
                    </span>
                  </h2>
                </div>
                <div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="btn btn-secondary rounded-circle fs-4">
                      {(() => {
                        switch (item.status) {
                          case 'closed':
                            return <i className="las la-window-close"></i>
                          case 'processing':
                            return <i className="mdi mdi-timer-sand"></i>
                          case 'opened':
                            return <i className=" las la-book-open"></i>
                          default:
                            return <i className="ri-ticket-2-line"></i>
                        }
                      })()}
                    </span>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </>
  )
}

export default Widgets
