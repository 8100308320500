import DeleteModal from 'Components/Common/DeleteModal'
import ViewDataWithLoading from 'Components/ViewDataWithLoading'
import FetchHock from 'helpers/FetchHock'
import ImageWithFallback from 'helpers/ImageComponent'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Row, Col, Card, CardBody, Button } from 'reactstrap'
import UpdatePaymentSwitch from './UpdatePaymentSwitch'
import UpdateModal from '../../Components/UpdateModal'
import CreateModalOffline from '../../Components/CreateModalOffline'

function OfflinePaymentMethodTabBody({ company }) {
  const type = company.type
  const isCOD = type == 'cod'

  const { t } = useTranslation()

  const [showCreate, changeShowCreate] = useState<boolean>(false)
  const openCreate = () => changeShowCreate(true)
  const closeCreate = () => changeShowCreate(false)

  const [itemIdUpdate, changeItemIdUpdate] = useState<number>()
  const [showUpdate, changeShowUpdate] = useState<boolean>(false)
  const openUpdate = () => changeShowUpdate(true)
  const closeUpdate = () => changeShowUpdate(false)

  const { items, openDelete, deleteItem, deleteId, closeDelete, loading, error, fetch } =
    FetchHock<any>({
      path: `/offline-payment`,
      initFilter: {
        company_id: company.id,
        company_type: company.code
      }
    })

  return (
    <>
      <Row className="mt-4">
        <div className="mb-2 d-flex justify-content-end">
          <button type="button" className="btn btn-secondary add-btn " onClick={openCreate}>
            <i className="ri-add-line align-middle me-1"></i> {t('Add')}
          </button>
        </div>

        <ViewDataWithLoading items={items} loading={loading} error={error}>
          {items?.map((item, key: number) => {
            return (
              <React.Fragment key={key}>
                <Col xl={isCOD ? 12 : 3} lg={isCOD ? 12 : 6}>
                  <Card className="ribbon-box right overflow-hidden">
                    <CardBody className="text-center p-4">
                      <ImageWithFallback src={item?.image_web} alt="" height={45} />
                      <h5 className="mb-1 mt-4">
                        <Link to="#" className="link-primary">
                          {item?.title}
                        </Link>
                      </h5>
                      <div className="mt-4 d-flex justify-content-center ">
                        <UpdatePaymentSwitch
                          checkedParam={'status'}
                          item={item}
                          onSuccess={fetch}
                          type={type}
                        />
                      </div>

                      <div className="d-flex gap-2 justify-content-center">
                        <Button
                          size="sm"
                          color="primary"
                          className="mt-3"
                          onClick={() => {
                            changeItemIdUpdate(item.id)
                            openUpdate()
                          }}>
                          {t('Edit')}
                        </Button>
                        <Button
                          size="sm"
                          color="danger"
                          className="mt-3"
                          onClick={() => {
                            openDelete(item.id)
                          }}>
                          {t('Delete')}
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </React.Fragment>
            )
          })}
        </ViewDataWithLoading>
      </Row>
      {deleteId && deleteId > 0 && (
        <DeleteModal
          show={deleteId}
          onDeleteClick={() => {
            deleteItem().then(() => {
              fetch()
            })
          }}
          onCloseClick={() => {
            if (closeDelete) closeDelete()
          }}
        />
      )}
      {showCreate && (
        <CreateModalOffline
          data={{ companyId: company.id, code: company.code }}
          close={closeCreate}
          show={openCreate}
          onSuccess={fetch}
        />
      )}
      {showUpdate && (
        <UpdateModal
          close={closeUpdate}
          show={showUpdate}
          onSuccess={fetch}
          itemId={itemIdUpdate}
        />
      )}
    </>
  )
}

export default OfflinePaymentMethodTabBody
