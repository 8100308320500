// react imports
import { useTranslation } from 'react-i18next'
// bootstrap elements
import { Col, Container, Row } from 'reactstrap'
// reqired componnent
import BreadCrumb from 'Components/Common/BreadCrumb'
import BranchCard from '../components/branchCard'
import WorkHours from './components/workHours'

const EcommerceOrders = () => {
  const { t } = useTranslation()

  document.title = 'Branches working hours| Sallatk'

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <Row>
          <Col md={3}>
            <BranchCard />
          </Col>
          <Col md={9}>
            <WorkHours />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default EcommerceOrders
