const Radio = ({
  label,
  model,
  error,
  value,
  noErrorMessage,
  labelComponent
}: {
  label?: string
  model?: any
  error?: any
  value?: string
  noErrorMessage?: boolean // if true, no error message is shown because we use multiple radios
  labelComponent?: any
}) => {
  const randumId = Math.random().toString(36).substring(7)
  return (
    <div className="form-check mb-3">
      <input
        className={error ? 'form-check-input is-invalid' : 'form-check-input'}
        type="radio"
        id={randumId}
        value={value}
        {...model}
      />
      {!!label && (
        <label className="form-check-label" htmlFor={randumId}>
          {label}
        </label>
      )}
      {!!labelComponent && (
        <label className="form-check-label" htmlFor={randumId}>
          {labelComponent}
        </label>
      )}
      {noErrorMessage ? null : <div className="invalid-feedback">{error?.message}</div>}
    </div>
  )
}
export default Radio
