import { useTranslation } from 'react-i18next'
// bootstrap elements
import { Card, CardBody, Col, CardHeader, Row, Label } from 'reactstrap'
// required components
import { Select, Input, LazySelect } from 'Components/Form'
import { useFormContext } from 'react-hook-form'
import { useDefaultCurrency } from 'Components/DefaultCurrency'

const MainPrice = () => {
  const { t } = useTranslation()
  const defaultCurrency = useDefaultCurrency()

  const {
    register,
    formState: { errors },
    getValues
  } = useFormContext()

  return (
    <Card>
      <CardHeader className="">
        <h5 className="card-title mb-0">{t('Product Prices')}</h5>
      </CardHeader>
      <CardBody>
        <Row>
          <Col sm={4}>
            <Input
              label={t('Price')}
              placeholder={t('Enter price')}
              type="number"
              model={register('price', {
                required: t('Price is required')
              })}
              error={errors.price}
              atBegin={getValues('currency')}
            />
          </Col>
          <Col sm={4}>
            <Input
              label={t('Cost')}
              placeholder={t('Enter cost')}
              type="number"
              model={register('cost', {
                required: t('Price is required')
              })}
              error={errors.cost}
              atBegin={getValues('currency')}
            />
          </Col>
          <Col sm={4}>
            <div>
              <Label className="form-label" htmlFor="product-title-input">
                {t('Discount')}
              </Label>
              <div className="d-flex">
                <Select
                  placeholder={t('Type')}
                  className="rounded-start-1"
                  options={[
                    {
                      label: '%',
                      value: 'perc'
                    },
                    {
                      label: defaultCurrency,
                      value: 'net'
                    }
                  ]}
                  model={register('discount_type')}
                  error={errors.discount_type}
                  value={getValues('discount_type')}
                />
                <Input
                  placeholder={t('Enter discount')}
                  className="rounded-end-1"
                  type="number"
                  model={register('discount')}
                  error={errors.discount}
                />
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default MainPrice
