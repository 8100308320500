import { useTranslation } from 'react-i18next'
import { Container, Row, Col, Label } from 'reactstrap'
import { useNavigate, useParams } from 'react-router-dom'

import BreadCrumb from 'Components/Common/BreadCrumb'
import { Input, Submit } from 'Components/Form'
import UpdateHock from 'helpers/UpdateHock'
import Loader from 'Components/Common/Loader'
import PermissionsCard from '../components/PermissionsCard'

const UpdateRole = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id } = useParams()

  const { register, handleSubmit, errors, update, loading, setValue, getValues, getLoading } =
    UpdateHock<any>({
      path: '/role',
      initValue: {
        name: '',
        permissions: []
      },
      editId: +id
    })

  const onSubmit = async (data) => {
    const response = await update(data)
    if (response.status) navigate('/roles/list')
  }
  const handleChangePermissions = (value) => {
    setValue('permissions', value)
  }
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />

        {getLoading ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Submit loading={loading} label={t('Save')} />
            <Row className="justify-content-center">
              <Col lg={12}>
                <Label htmlFor="colFormLabelSm" className="col-sm-12 col-form-label ">
                  {t('Role Name')}
                </Label>

                <Input
                  placeholder={t('Name')}
                  model={register('name', { required: `${t('Name')} ${t('is required')}` })}
                  error={errors?.name}
                />
              </Col>
            </Row>

            <PermissionsCard
              value={getValues('permissions').map((item) => item.id)}
              onChange={handleChangePermissions}
            />
          </form>
        )}
      </Container>
    </div>
  )
}

export default UpdateRole
