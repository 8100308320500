import { Button, Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Input, Submit, ImageUpload, Switch, Textarea, Select } from 'Components/Form'
import CreateHook from 'helpers/CreateHook'
import PopUp from '../../../Components/PopUp'
import useStaticData from 'hooks/useStaticData'
import useMenuFormOptions from 'hooks/useMenuFormOptions'

type CreateFooterItem = Omit<FooterItem, 'id'>

const AddItemModal = ({ close, show, onSuccess, parentId }: any) => {
  const { t } = useTranslation()
  const { moduleNameOptions, styleOptions } = useStaticData()
  const { moduleIdOptions, moduleIdOptionsLoading, fetchModuleIds } = useMenuFormOptions()

  // prepare hock
  const { register, handleSubmit, errors, create, loading, watch } = CreateHook<CreateFooterItem>(
    '/menus/footer',
    {
      module_name: '',
      module_id: 0,
      published: false,
      style: 'standard',
      group_id: parentId
    }
  )

  const submit = async (data: CreateFooterItem) => {
    const response = await create(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  return (
    <PopUp title={t('Create new footer item')} show={show} close={close}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={12}>
              <Select
                label={t('from')}
                model={register('module_name', { required: t('module_name is required') })}
                error={errors.module_name}
                options={moduleNameOptions}
                onChange={fetchModuleIds}
              />
            </Col>
            <Col md={12}>
              <Select
                label={t('Item')}
                model={register('module_id', { required: t('module_id is required') })}
                error={errors.module_id}
                options={moduleIdOptions.map((item: any) => {
                  return { label: item.title, value: item.id }
                })}
                placeholder={!watch('module_name') ? t('Please select from') : undefined}
                disabled={moduleIdOptionsLoading || !watch('module_name')}
              />
            </Col>
            {/* <Col md={12}>
              <Select
                label={t('style')}
                model={register('style', { required: t('style is required') })}
                error={errors.style}
                options={styleOptions}
              />
            </Col> */}
            <Col md={12}>
              <Switch defaultChecked={false} label={t('Published')} model={register('published')} />
            </Col>
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Create')} />
        </div>
      </form>
    </PopUp>
  )
}
export default AddItemModal
