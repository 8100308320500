import useStaticData from 'hooks/useStaticData'
import useFormOptions from 'hooks/useMenuFormOptions'
import ReactSelect from 'react-select'
import { UseProfile } from 'helpers/UserHooks'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

function SelectProductLinkWithPreview({ parentLabel, input }) {
  const { setValue } = useFormContext()
  const { t } = useTranslation()

  const { productsModuleNameOptions   } = useStaticData()
  const { moduleIdOptions, moduleIdOptionsLoading, fetchModuleIds } = useFormOptions()

  const [from, setFrom] = useState<{ label: string; value: string }>()
  const [item, setItem] = useState<{ label: string; value: string }>()
  const [fullUrl, setFullUrl] = useState({
    from: null,
    item: null
  })
  const [UrlPreview, setUrlPreview] = useState(null)
  const { userProfile } = UseProfile()
  const storeUrl = userProfile?.store?.url || 'your-store-url'

  const changeFullUrl = (fullUrlObj) => {
    const fromValue = fullUrlObj?.from ? `${fullUrlObj?.from}` : ''
    const slash = fullUrlObj?.from && fullUrlObj?.item ? '/' : ''
    const itemValue = fullUrlObj?.item ? `${fullUrlObj?.item}` : ''

    const url = `${storeUrl}/${fromValue}${slash}${itemValue}`
    setValue(parentLabel + input.label, url)
    setFullUrl(fullUrlObj)
    setUrlPreview(url)
  }
  const changeFrom = (v) => {
    fetchModuleIds(v.value)
    setFrom(v)
    changeFullUrl({ ...fullUrl, from: v.value })
  }
  const changeItem = (v) => {
    setItem(v)
    changeFullUrl({ ...fullUrl, item: v.value })
  }
  return (
    <div>
      <label className="form-label">{t(input.title)}</label>
      <div className="d-flex gap-3 align-items-baseline">
        <ReactSelect
          className={'form-control p-0 '}
          onChange={changeFrom}
          options={productsModuleNameOptions}
        />
        <h6>/</h6>
        <ReactSelect
          key={from?.value}
          className={'form-control p-0 '}
          onChange={changeItem}
          options={moduleIdOptions.map((item: any) => {
            return { label: item.title, value: item.id }
          })}
          disabled={moduleIdOptionsLoading || !from?.value}
        />
      </div>
      <br />
      <p className="d-flex justify-content-center">{UrlPreview}</p>
    </div>
  )
}

export default SelectProductLinkWithPreview
