import GridBlock from './GridBlock/Config'
import SingleBanner from './SingleBanner/Config'

const config = [
  {
    title: 'Grid block',
    key: 'GridBlock',
    config: GridBlock
  },
  {
    title: 'Single Banner',
    key: 'SingleBanner',
    config: SingleBanner
  }
]

export default config
