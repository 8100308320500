import BreadCrumb from 'Components/Common/BreadCrumb'
import { Container } from 'reactstrap'
import ItemsCardsView from './components/CardView'
import Header from './components/Header'
import ItemsTablesView from './components/TableView'

import FetchHock from 'helpers/FetchHock'
import { Filter } from './filterType'

import clsx from 'clsx'
import Loader from 'Components/Common/Loader'
import Error from 'Components/IndexTable/partials/Error'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { setShowTypeAction } from 'slices/products/reducer'
import { ProductProvider } from './Context'

interface products {
  id: number
  title?: string
  slug?: string
  hidden: boolean
  product_type?: {
    id: number
    title?: string
  }
  web_main_image?: string
  stock: number
  price: number
  ordered: number
  ratings?: string
  created_date?: string
  created_time?: string
  product_course_id: number
}

interface filter {
  hidden: string
  id: string
}

const ProductsList = () => {
  document.title = 'Products | Sallatk'

  const [searchParams] = useSearchParams()

  const [showFilter, setShowFilter] = useState<boolean>(false)
  const openFilter = () => setShowFilter(true)
  const closeFilter = () => setShowFilter(false)

  const dispatch = useDispatch()
  const showType = useSelector((state: any) => state.products.showType)

  const handleShowTypeChange = (type: any) => {
    dispatch(setShowTypeAction(type))
  }
  const id = searchParams.get('id') || ''
  const products = FetchHock<products, filter>({
    path: '/products',
    initFilter: {
      hidden: ''
    },
    limit: 12
  })

  useEffect(() => {
    if (id) {
      products.filter('id', id)
    }
  }, [id])

  const filters = FetchHock<any, Filter>({
    path: '/products/getFilters'
  })

  if (products?.loading || filters?.loading) {
    return (
      <div className="vh-100 d-flex align-items-center justify-content-center">
        <Loader />
      </div>
    )
  }

  if (products?.error) {
    return (
      <div className="page-content">
        <Error message={products?.error?.message} />
      </div>
    )
  }

  if (filters?.result) {
    return (
      <div
        className={clsx('page-content', {
          // 'opacity-50': products.isFetching
        })}>
        <Container fluid>
          <BreadCrumb />
          <Header showType={showType} setShowType={handleShowTypeChange} openFilter={openFilter} />
          {showType == 'card' && (
            <ProductProvider products={products} filters={filters?.result}>
              <ItemsCardsView showFilter={showFilter} closeFilter={closeFilter} />
            </ProductProvider>
          )}
          {showType == 'table' && (
            <ProductProvider products={products} filters={filters?.result}>
              <ItemsTablesView />
            </ProductProvider>
          )}
        </Container>
      </div>
    )
  }
}

export default ProductsList
