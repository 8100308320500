import BreadCrumb from 'Components/Common/BreadCrumb'
import Loader from 'Components/Common/Loader'
import clsx from 'clsx'
import FetchHock from 'helpers/FetchHock'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Card, CardBody, Col, Container, Row, Table } from 'reactstrap'
import Features from './Features'
import TitleAndPrice from './TitleAndPrice'

interface Plan {
  id: number
  price: number
  duration: number
}

interface Feature {
  order: number
  title: string
  description: string
  icon: string | null
}

interface Package {
  id: number
  title: string
  description: string
  plans: Plan[]
  features: Feature[]
}

type MyType = {
  duration: number
  duration_human: string
  end_at: string
  id: number
  package: string
  price: number
  start_at: string
  settings: {
    setting_key: string
    max_count: number
  }[]
}

const Pricing = () => {
  const { t } = useTranslation()
  const location = useLocation()
  document.title = 'Pricing | Sallatk'

  const { items: packages, loading } = FetchHock<Package>({
    path: '/packages',
    siteUrl: 'front'
  })
  const { result: my, loading: myLoading } = FetchHock<MyType>({
    path: '/package/my'
  })
  if (loading || myLoading)
    return (
      <div className="d-flex justify-content-center align-items-center min-vh-100">
        <Loader />
      </div>
    )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb />
          <Row className="justify-content-center">
            <Col className='className="d-flex justify-content-center"'>
              <Card className="bg-light" style={{ maxWidth: '500px', width: '100%' }}>
                <CardBody>
                  <div className="text-center">
                    <h4 className="fw-semibold fs-22">{t('Package Info')}</h4>

                    <Table responsive className="table-responsive">
                      <tbody>
                        <tr>
                          <td className="fw-medium">{t('Package')}</td>
                          <td>{my?.package}</td>
                          <td className="fw-medium">{t('Price')}</td>
                          <td>{my?.price}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">{t('Duration')}</td>
                          <td>{my?.duration_human}</td>
                          <td className="fw-medium">{t('End Date')}</td>
                          <td>{my?.end_at}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col className="d-flex justify-content-center">
              <Card className="bg-light" style={{ maxWidth: '500px', width: '100%' }}>
                <CardBody>
                  <div className="text-center">
                    <h4 className="fw-semibold fs-22">{t('Package Settings')}</h4>

                    <Table responsive className="table-responsive">
                      <tbody>
                        {my?.settings.map((item, key) => (
                          <tr key={key}>
                            <td colSpan={2} className="fw-medium">
                              {t(item.setting_key)}
                            </td>
                            <td colSpan={2}>{item.max_count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={4}>
              <div className="text-center mb-4 pb-2">
                <h4 className="fw-semibold fs-22">{t('Packages List')}</h4>
                {/* <p className="text-muted mb-4 fs-15">
                  Simple pricing. No hidden fees. Advanced features for you business.
                </p> */}
              </div>
            </Col>
          </Row>

          <Row>
            {(packages || []).map((item, key) => {
              const isMyPackage = item?.title === my?.package

              return (
                <Col lg={12} key={key}>
                  <Card
                    color={isMyPackage ? 'primary' : 'light'}
                    outline
                    style={{
                      backgroundColor: isMyPackage ? '#e3f3fb' : ''
                    }}
                    className={clsx(
                      'pricing-box ribbon-box ribbon-fill text-center ',
                      isMyPackage && 'border-5'
                    )}>
                    {/* {item.ribbon === true ? (
                          <div className="ribbon ribbon-success shadow-none">New</div>
                        ) : (
                          ''
                        )} */}
                    <Row className="g-0">
                      <Col lg={6}>
                        <TitleAndPrice item={item} my={my} isMyPackage={isMyPackage} />
                      </Col>
                      <Col lg={6}>
                        <Features item={item} my={my} />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              )
            })}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Pricing
