export default {
  content: {
    title: {
      ar: 'احصل على نصائح الخبراء في صندوق الوارد الخاص بك',
      en: 'Get Expert Tips In Your Inbox'
    },
    description: {
      ar: 'اشترك في النشرة الإخبارية لدينا وابق على اطلاع دائم.',
      en: 'Subscribe to our newsletter and stay updated.'
    },
    buttonText: {
      ar: 'الاشتراك',
      en: 'Subscribe'
    },
    placeholder: {
      ar: 'أدخل بريدك الألكتروني',
      en: 'Enter your email'
    }
  }
}
