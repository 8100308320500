import Pagination from 'Components/IndexTable/partials/Pagination'
import ViewDataWithLoading from 'Components/ViewDataWithLoading'
import FetchHock from 'helpers/FetchHock'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap'

const BestSellingProducts = () => {
  const { t } = useTranslation()
  // top sold products last week
  const {
    items: data,
    loading,
    error,
    totalPages,
    options,
    totalItems,
    showedItems,
    pagenate
  } = FetchHock<{
    id: number
    title: string | null
    web_main_image: string
    final_price: number
    stock: number
    total: number
    count: number
    currency: string
    created_at: string | null
  }>({
    path: '/dashboard/top-sell-products'
  })

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">{t('Top Sold Products')}</h4>
          <div className="flex-shrink-0">
            <UncontrolledDropdown className="card-header-dropdown" direction="start">
              <DropdownToggle tag="a" className="text-reset" role="button">
                <span className="fw-semibold text-uppercase fs-12">Sort by: </span>
                <span className="text-muted">
                  Today<i className="mdi mdi-chevron-down ms-1"></i>
                </span>
              </DropdownToggle>
              <DropdownMenu container={'body'} className="dropdown-menu-end">
                <DropdownItem>Today</DropdownItem>
                <DropdownItem>Yesterday</DropdownItem>
                <DropdownItem>Last 7 Days</DropdownItem>
                <DropdownItem>Last 30 Days</DropdownItem>
                <DropdownItem>This Month</DropdownItem>
                <DropdownItem>Last Month</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </CardHeader>

        <CardBody>
          <div className="table-responsive table-card">
            <ViewDataWithLoading
              style={{ height: '300px' }}
              items={data}
              loading={loading}
              error={error}>
              <table className="table table-hover table-centered align-middle table-nowrap mb-0">
                <tbody>
                  {(data || []).map((item, key) => (
                    <tr key={key}>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="avatar-sm bg-light rounded p-1 me-2">
                            <img src={item.web_main_image} alt="" className="img-fluid d-block" />
                          </div>
                          <div>
                            <h5 className="fs-14 my-1">
                              <Link to="/apps-ecommerce-product-details" className="text-reset">
                                {item?.title || '...'}
                              </Link>
                            </h5>
                            <span className="text-muted">
                              {item.final_price} {item.currency}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <h5 className="fs-14 my-1 fw-normal">{item?.total}</h5>
                        <span className="text-muted">{t('Total')}</span>
                      </td>
                      {/* <td>
                        <h5 className="fs-14 my-1 fw-normal">{item?.stock}</h5>
                        <span className="text-muted">{t('Stock')}</span>
                      </td> */}
                      <td>
                        <h5 className="fs-14 my-1 fw-normal">{item?.count}</h5>
                        <span className="text-muted">{t('Count')}</span>
                      </td>
                      <td>
                        <h5 className="fs-14 my-1 fw-normal">
                          {item.stock ? (
                            item.stock
                          ) : (
                            <span className="badge badge-soft-danger">{t('Out of stock')}</span>
                          )}{' '}
                        </h5>
                        <span className="text-muted">{t('Stock')}</span>
                      </td>
                      {/* <td>
                          <h5 className="fs-14 my-1 fw-normal">${item.amount}</h5>
                          <span className="text-muted">Amount</span>
                        </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </ViewDataWithLoading>
          </div>

          {totalPages > 1 && (
            <Pagination
              options={options}
              totalPages={totalPages}
              totalItems={totalItems}
              showedItems={showedItems}
              pagenate={pagenate}
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default BestSellingProducts
