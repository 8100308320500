// botstrap elemnts
import { Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
// translation
import { useTranslation } from 'react-i18next'
// page elements

import PopUp from 'Components/PopUp'
import { useState } from 'react'
import clsx from 'clsx'
import { EditTranslation } from '../models/details'
import LanguageForm from './LanguageForm'

const UpdateModal = ({
  close,
  show,
  onSuccess,
  modalData
}: {
  close: () => void
  show: boolean
  onSuccess: () => void
  modalData: {
    formValues: EditTranslation
    formFields: any
  }
}) => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(1)

  const toggleTab = (tab: number) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  if (!modalData) return null
  return (
    <PopUp title={t('translate')} show={show} close={close} size="lg">
      <Nav tabs pills fill>
        <NavItem>
          <NavLink
            className={clsx(activeTab === 1 && 'active bg-secondary', 'cursor-pointer')}
            onClick={() => toggleTab(1)}>
            {t('Source')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={clsx(activeTab === 2 && 'active bg-secondary', 'cursor-pointer')}
            onClick={() => toggleTab(2)}>
            {t('Translation')}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} className="pt-3">
        <TabPane tabId={1}>
          <LanguageForm data={modalData} accessorKey="default_" readOnly />
        </TabPane>
        <TabPane tabId={2}>
          <LanguageForm
            data={modalData}
            accessorKey="target_"
            onSuccess={() => {
              onSuccess()
              close()
            }}
          />
        </TabPane>
      </TabContent>
    </PopUp>
  )
}
export default UpdateModal
