// react elements
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
// bootstrap elements
import { Accordion, AccordionItem, Collapse } from 'reactstrap'
// required components
import ICities from './ICity'

const City = ({
  city,
  toggleRegon,
  checkRegon,
  toggleRegons,
  checkRegons
}: {
  city: ICities
  toggleRegon: (id: number) => void
  checkRegon: (id: number) => boolean
  toggleRegons: (ids: number[]) => void
  checkRegons: (ids: number[]) => boolean
}) => {
  const { t } = useTranslation()
  // Default Accordion
  const [colabs, setClabs] = useState<boolean>(false)
  const toggleColabs = () => setClabs(!colabs)

  return (
    <Accordion toggle={() => {}} open={'1'}>
      <AccordionItem>
        <div className="d-flex">
          <div className=" px-3 border-left-1 border-right-0 border-top-0 border-bottom-0 border-light border me-2">
            <input
              className="form-check-input mt-3 x-3 border-1"
              onChange={() => toggleRegons(city.regions.map((el) => el.id))}
              type="checkbox"
              checked={checkRegons(city.regions.map((el) => el.id))}
            />
          </div>
          <h2 className="accordion-header w-100">
            <button
              className="accordion-button collapsed ps-2 pe-3"
              type="button"
              onClick={toggleColabs}
              style={{ cursor: 'pointer' }}
            >
              {city.title}
            </button>
          </h2>
        </div>
        <Collapse isOpen={colabs} className="accordion-collapse">
          <div className="list-group">
            {city.regions.map((region, index) => (
              <label className="list-group-item " key={index}>
                -
                <input
                  className="form-check-input mx-2"
                  onChange={() => toggleRegon(region.id)}
                  type="checkbox"
                  value=""
                  checked={checkRegon(region.id)}
                />
                {region.title}
              </label>
            ))}
          </div>
        </Collapse>
      </AccordionItem>
    </Accordion>
  )
}

export default City
