import { useTranslation } from 'react-i18next'
import { UncontrolledCollapse } from 'reactstrap'
import { useProductContext } from '../../Context'
import { useState } from 'react'

type Brand = {
  id: number
  web_image: string
  mobile_image: string
  published: number
  name: string
  description: string
  total: number
  filter_key: string
}

function Brand() {
  const { t } = useTranslation()

  const { list, selected, filterByAction } = useProductsMultiFilters('brands')
  return (
    <div className="accordion-item">
      <h2 className="accordion-header">
        <button
          className="accordion-button bg-transparent shadow-none"
          type="button"
          id="flush-headingBrands">
          <span className="text-muted text-uppercase fs-12 fw-medium">{t('Brands')}</span>
        </button>
      </h2>
      <UncontrolledCollapse toggler="#flush-headingBrands" defaultOpen>
        <div
          id="flush-collapseBrands"
          className="accordion-collapse collapse show"
          aria-labelledby="flush-headingBrands">
          <div className="accordion-body text-body pt-0">
            <div className="d-flex flex-column gap-2">
              {list?.map((brand, index) => (
                <div className="form-check" key={index}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={'brand' + brand.id}
                    checked={selected?.includes(brand.id)}
                    onChange={(e) => filterByAction(brand)}
                  />
                  <label className="form-check-label" htmlFor={'brand' + brand.id}>
                    {brand.name}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </UncontrolledCollapse>
    </div>
  )
}

export default Brand

export function useProductsMultiFilters(key) {
  const { filters, products } = useProductContext()
  const list: any = filters[key]?.items
  const info = filters[key]
  const selected: number[] = products?.options?.filter[key] || []

  const filterBy = (filter: any, filterKey = null) => {
    if ((filter?.filter_key || filterKey) && filter?.id) {
      const current = selected
      if (selected.includes(filter.id)) {
        const index = current.indexOf(filter.id)
        current.splice(index, 1)
      } else {
        current.push(filter.id)
      }
      products.filter(filter?.filter_key || filterKey, current)
    }
  }

  const clearAll = () => {
    products.resetFilter()
  }
  return {
    list,
    info,
    selected,
    filterByAction: filterBy,
    clearAll
  }
}

function useFilter(filters, selectedFilters, onFilterChange) {
  const [currentFilters, setCurrentFilters] = useState(selectedFilters)

  const handleFilterChange = (filter) => {
    if (filter?.id && filter?.filter_key) {
      const updatedFilters = [...currentFilters]
      const filterIndex = updatedFilters.indexOf(filter.id)

      if (filterIndex > -1) {
        // Remove filter if it's already selected
        updatedFilters.splice(filterIndex, 1)
      } else {
        // Add filter if it's not selected
        updatedFilters.push(filter.id)
      }

      setCurrentFilters(updatedFilters)
      if (filter?.filter_key) {
        onFilterChange(filter.filter_key, updatedFilters)
      }
    }
  }

  return {
    currentFilters,
    handleFilterChange
  }
}
