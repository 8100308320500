import React from 'react'
import { Spinner } from 'reactstrap'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Loader = (props: any) => {
  return (
    <React.Fragment>
      <div className={`d-flex justify-content-center mx-2 mt-2 ${props.container && 'w-100 py-5'}`}>
        <Spinner color="primary"> Loading... </Spinner>
      </div>
      {toast.error(props.error, {
        position: 'top-right',
        hideProgressBar: false,
        progress: undefined,
        toastId: ''
      })}
    </React.Fragment>
  )
}

export default Loader
