export const getEditRoute = (type: string | undefined, id: number, baseUrl: string): string => {
  switch (type) {
    case 'product':
      return `/products/${baseUrl}/default/${id}`
    case 'cards':
      return `/products/${baseUrl}/card/${id}`
    case 'digital':
      return `/products/${baseUrl}/digital/${id}`
    case 'donation':
      return `/products/${baseUrl}/donation/${id}`
    case 'multi_products':
      return `/products/${baseUrl}/multi/${id}`
    case 'course_product':
      return `/products/${baseUrl}/course/${id}`
    case 'product_features':
      return `/products/${baseUrl}/withFeatures/${id}`
    case 'product_plus_features':
      return `/products/${baseUrl}/WithPaidFeatures/${id}`
    default:
      return ``
  }
}
