// react imports
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
// botstrap elemnts
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
// page elements
import TicketDescription from './TicketDescription'
import fetch from 'helpers/server'
// translation
import { useTranslation } from 'react-i18next'
// static files
import img from 'assets/images/companies/img-4.png'

const TicketsDetails = () => {
  document.title = 'Tickets Details | Sallatk'
  const { t } = useTranslation()
  const { id } = useParams()

  // fetch ticket details
  type ticketDetails = {
    id: number
    number: string
    subject: string
    status: string
    priority: string
    created_at: string
    category: {
      id: number
      image: string
      name: string
    }
    comments: [
      {
        id: number
        content: string
        user_id: number
        user: string
        attachment: [
          {
            id: number
            attachment: string
          }
        ]
      }
    ]
  }
  const [items, setItems] = useState<ticketDetails | undefined>()
  const fetchData = async () => {
    const response = await fetch<ticketDetails>(`/tickets/${id}`, null, 'get')
    const data = response.data
    setItems(data)
  }

  useEffect(() => {
    fetchData()
  }, [])

  if (items) {
    return (
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card className="mt-n4 mx-n4 mb-n5 border-0">
                <div className="bg-primary-subtle">
                  <CardBody className="pb-4 mb-5">
                    <Row>
                      <div className="col-md">
                        <Row className="align-items-center">
                          <div className="col-md-auto">
                            <div className="avatar-md mb-md-0 mb-4">
                              <div className="avatar-title bg-white rounded-circle">
                                <img src={img} alt="" className="avatar-sm" />
                              </div>
                            </div>
                          </div>
                          <div className="col-md">
                            <h4 className="fw-semibold">
                              #{items.number} - {items.subject}
                            </h4>
                            <div className="hstack gap-3 flex-wrap">
                              <div className="text-muted">
                                <i className="ri-building-line align-bottom me-1"></i>{' '}
                                {items.category.name}
                              </div>
                              <div className="vr"></div>
                              <div className="text-muted">
                                {t('Create Date')} :{' '}
                                <span className="fw-medium">
                                  {(() => {
                                    return new Date(items.created_at).toLocaleString()
                                  })()}
                                </span>
                              </div>
                              <div className="vr"></div>
                              {(() => {
                                switch (items.status) {
                                  case 'closed':
                                    return (
                                      <span className="badge bg-danger text-uppercase">
                                        {t(items.status)}
                                      </span>
                                    )
                                  case 'processing':
                                    return (
                                      <span className="badge bg-warning text-uppercase">
                                        {t(items.status)}
                                      </span>
                                    )
                                  case 'opened':
                                    return (
                                      <span className="badge bg-success text-uppercase">
                                        {t(items.status)}
                                      </span>
                                    )
                                  default:
                                    return (
                                      <span className="badge bg-success text-uppercase">
                                        {t(items.status)}
                                      </span>
                                    )
                                }
                              })()}

                              {(() => {
                                switch (items.priority) {
                                  case 'High':
                                    return (
                                      <span className="badge bg-danger-subtle text-danger text-uppercase">
                                        {t(items.priority)}
                                      </span>
                                    )
                                  case 'Medium':
                                    return (
                                      <span className="badge bg-warning-subtle text-warning text-uppercase">
                                        {t(items.priority)}
                                      </span>
                                    )
                                  case 'Low':
                                    return (
                                      <span className="badge bg-success-subtle text-success text-uppercase">
                                        {t(items.priority)}
                                      </span>
                                    )
                                  default:
                                    return (
                                      <span className="badge bg-success-subtle text-uppercase">
                                        {t(items.priority)}
                                      </span>
                                    )
                                }
                              })()}
                            </div>
                          </div>
                        </Row>
                      </div>
                    </Row>
                  </CardBody>
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xxl={12}>
              <TicketDescription
                comments={items.comments}
                id={items.id}
                fetchData={fetchData}
                status={items.status}
              />
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default TicketsDetails
