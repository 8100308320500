import { useQuery } from '@tanstack/react-query'
import { queryString } from 'helpers/queryString'
import http from 'services/axios'

const fetchCategoriesTree = async (queryObj: Object) => {
  try {
    const res = await http.get(`/categories/tree${queryString(queryObj)}`)

    return res.data.result
  } catch (error) {
    console.error('Error fetching categories:', error)
  }
}

export const usePOSCategoriesTree = (queryObj: Object) => {
  return useQuery({
    queryKey: ['pos/categories/tree'],
    queryFn: () => fetchCategoriesTree(queryObj)
  })
}
