import { Badge, Button, CardBody, CardHeader, Col, Container, Label, Row } from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import FetchHock from 'helpers/FetchHock'
import { useTranslation } from 'react-i18next'
import { Fragment, useState } from 'react'
import Loader from 'Components/IndexTable/partials/Loader'
import Error from 'Components/IndexTable/partials/Error'
import CreateModal from '../components/CreateModal'
import axios from 'axios'
import linkGenerator from 'helpers/linkGenerator'
import showSuccess from 'helpers/SuccessNotfication'
import clsx from 'clsx'
import UpdateModal from '../components/UpdateModal'
import { useDebouncedCallback } from 'use-debounce'

const MenusHeader = () => {
  document.title = 'Partners | Sallatk'

  const { t } = useTranslation()

  const [itemIdUpdate, changeItemIdUpdate] = useState<number>()
  const [showCreate, changeShowCreate] = useState<boolean>(false)
  const [showUpdate, changeShowUpdate] = useState<boolean>(false)

  const openCreate = () => changeShowCreate(true)
  const closeCreate = () => changeShowCreate(false)
  const openUpdate = () => changeShowUpdate(true)
  const closeUpdate = () => changeShowUpdate(false)

  const { items, loading, error, fetch } = FetchHock<MenuCustomLink>({
    path: '/menus/header?filter[parent_id]=-1',
    limit: 1000
  })

  const change = async (id: number) => {
    const response = await axios.post(linkGenerator(`/menu/header/published`), {
      id
    })

    if (response.status) {
      showSuccess(t('Input updated successfully'))
      fetch()
    }
  }

  const sort = async (id: number, sortOrder: number) => {
    const response = await axios.post(linkGenerator(`/menu/header/sort`), {
      id,
      sort: sortOrder
    })

    if (response.status) {
      showSuccess(t('Input updated successfully'))
      fetch()
    }
  }

  const debouncedSort = useDebouncedCallback(async (id, sortOrder) => {
    if (!id || !sortOrder) return
    await sort(id, sortOrder)
  }, 1000)

  const handleDelete = async (id: number) => {
    const response = await axios.delete(linkGenerator(`/menus/header/${id}`))

    if (response.status) {
      showSuccess(t('Input removed successfully'))
      fetch()
    }
  }

  if (error) return <Error />

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <CardHeader className="card-header border-0">
          <Row className="align-items-center gy-3 mb-3">
            <div className="col-sm"></div>
            <div className="col-sm-auto">
              <div className="d-flex gap-1 flex-wrap">
                <button type="button" className="btn btn-secondary add-btn" onClick={openCreate}>
                  <i className="ri-add-circle-line align-middle me-1"></i> {t('Add menu')}
                </button>
              </div>
            </div>
          </Row>
        </CardHeader>
        <div className="border-bottom">
          <h6>{t('Preview')}</h6>
          <div className="d-flex gap-2 flex-wrap mb-3">
            {items
              ?.filter((i) => i.published === 1)
              ?.toSorted((a, b) => a.sort - b.sort)
              ?.map((i) => {
                return (
                  <Badge className="fw-medium fs-14" color="primary" pill key={i.id}>
                    {i.title}
                  </Badge>
                )
              })}
          </div>
        </div>

        <Row>
          <Col lg={4}>
            <CardBody>
              {loading && <Loader />}

              <table className="table table-borderless">
                <tbody>
                  {items?.map((item, index) => {
                    if (item.code !== 'dynamic')
                      return (
                        <Fragment key={index}>
                          <ItemRow
                            item={item}
                            action={change}
                            sortAction={debouncedSort}
                            isSwitchOnly
                          />
                          {item?.children?.map((child, index: number) => {
                            return (
                              <ItemRow
                                key={index}
                                item={child}
                                action={change}
                                sortAction={debouncedSort}
                                isChildItem
                                isSwitchOnly
                              />
                            )
                          })}
                        </Fragment>
                      )
                  })}

                  {items?.map((item, index) => {
                    if (item.code == 'dynamic') {
                      return (
                        <Fragment key={index}>
                          <ItemRow
                            key={index}
                            item={item}
                            sortAction={debouncedSort}
                            DeleteAction={() => handleDelete(item.id)}
                            EditAction={() => {
                              changeItemIdUpdate(item.id)
                              openUpdate()
                            }}
                            action={change}
                          />

                          {item?.children?.map((child: any) => {
                            return (
                              <ItemRow
                                key={index}
                                item={child}
                                sortAction={debouncedSort}
                                DeleteAction={() => handleDelete(child.id)}
                                EditAction={() => {
                                  changeItemIdUpdate(child.id)
                                  openUpdate()
                                }}
                                isChildItem
                                action={change}
                              />
                            )
                          })}
                        </Fragment>
                      )
                    }
                  })}
                </tbody>
              </table>
            </CardBody>
          </Col>
        </Row>
      </Container>
      {showCreate && <CreateModal close={closeCreate} show={openCreate} onSuccess={fetch} />}
      {showUpdate && (
        <UpdateModal
          close={closeUpdate}
          show={openUpdate}
          onSuccess={fetch}
          itemId={itemIdUpdate}
        />
      )}
    </div>
  )
}

export default MenusHeader

const ItemRow = ({
  item,
  DeleteAction = () => {},
  EditAction = () => {},
  isChildItem = false,
  sortAction,
  action,
  isSwitchOnly = false
}) => {
  const { t } = useTranslation()

  return (
    <tr>
      <td className={clsx(isChildItem && 'py-0')}>
        <input
          placeholder={t('')}
          className="form-control form-control-sm"
          style={{ width: '50px' }}
          type="number"
          onChange={(e) => sortAction(item.id, e.target.value)}
          defaultValue={item.sort}
        />
      </td>
      <td className={clsx(isChildItem && 'py-0')}>
        <label
          htmlFor={item.id.toString()}
          className={clsx('form-check-label fs-14 form-label', isChildItem && 'ms-3')}>
          {isChildItem && <i className="ri-corner-down-right-fill fs-10 me-1"></i>}
          {item.title || '⚠️'}
        </label>
      </td>
      <td className={clsx(isChildItem && 'py-0')}>
        <div className="d-flex gap-2">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id={item.id.toString()}
              value="1"
              onChange={() => action(item.id)}
              defaultChecked={item.published === 1}
            />
          </div>
          {!isSwitchOnly && (
            <>
              <Button size="sm" onClick={EditAction}>
                <i className="ri-edit-line"></i>
              </Button>
              <Button size="sm" color="danger" onClick={DeleteAction}>
                <i className="ri-delete-bin-line"></i>
              </Button>
            </>
          )}
        </div>
      </td>
    </tr>
  )
}
