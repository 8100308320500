import clsx from 'clsx'

const Checkbox = ({
  label,
  model,
  error,
  value,
  wrapperClassName
}: {
  label?: string
  model?: any
  error?: any
  value?: any
  wrapperClassName?: string
}) => {
  const randumId = Math.random().toString(36).substring(7)
  return (
    <div className={clsx('form-check', wrapperClassName ?? 'mb-3')}>
      <input
        className={error ? 'form-check-input is-invalid' : 'form-check-input'}
        type="checkbox"
        id={randumId}
        value={value}
        {...model}
      />
      {!!label && (
        <label className="form-check-label" htmlFor={randumId}>
          {label}
        </label>
      )}
      <div className="invalid-feedback">{error?.message}</div>
    </div>
  )
}
export default Checkbox
