import { Button, Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Input, Submit, Search } from 'Components/Form'
import CreateHook from 'helpers/CreateHook'
import PopUp from 'Components/PopUp'
import { useState } from 'react'
import FetchHock from 'helpers/FetchHock'

const CreateModal = ({ close, show, onSuccess, featureId }: any) => {
  const { t } = useTranslation()
  type featureOptionType = {
    id?: number
    title: string
    color?: string
    feature_id: number | null | undefined | string
  }

  // prepare hock
  const { register, handleSubmit, errors, create, loading } = CreateHook<featureOptionType>(
    '/featureOptions',
    {
      title: '',
      color: '',
      feature_id: featureId
    }
  )

  const { result: featureOptionData, loading: featureOptionLoading } = FetchHock({
    path: `/features/${featureId}`
  })

  // use state typeofOptions
  const [typeofOptions, setTypeofOptions] = useState<string>('')
  const submit = async (data: featureOptionType) => {
    const response = await create(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  // detect typeofOptions changed value

  return (
    <PopUp
      title={t('Create new feature option')}
      show={show}
      close={close}
      loading={featureOptionLoading}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={12} className="d-none">
              <Search
                label={t('Feature')}
                placeholder={t('Enter feature')}
                model={register('feature_id')}
                error={errors.feature_id}
                path="features"
              />
            </Col>
            <Col md={12}>
              <Input
                label={t('Title')}
                placeholder={t('Title ')}
                model={register('title', {
                  required: t('Title is required'),
                  minLength: {
                    value: 3,
                    message: t('Title must be bigger than or equal 3')
                  }
                })}
                error={errors.title}
              />
            </Col>
            {featureOptionData?.type === 'color' && (
              <Col md={12}>
                <Input
                  type={'color'}
                  label={t('Color')}
                  placeholder={t('Enter Color')}
                  model={register('color')}
                  error={errors.color}
                  className={'form-control form-control-color w-100 form-control'}
                />
              </Col>
            )}
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Create')} />
        </div>
      </form>
    </PopUp>
  )
}
export default CreateModal
