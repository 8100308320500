import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

type Action = {
  type: string
  onClick?: () => void
}

type IndexTableActionsDropdownProps = {
  actions: Action[]
}

const indexTableActionsDropdownItemIconsMap = {
  edit: 'ri-edit-2-line',
  delete: 'ri-delete-bin-line'
}

const indexTableActionsDropdownItemLabelsMap = {
  edit: 'Edit',
  delete: 'Delete'
}

const IndexTableActionsDropdown = ({ actions }: IndexTableActionsDropdownProps) => {
  const { t } = useTranslation()

  return (
    <div className="hstack gap-3 flex-wrap">
      <UncontrolledDropdown>
        <DropdownToggle className="btn btn-secondary btn-sm" tag="button">
          <i className="ri-more-fill" />
        </DropdownToggle>
        <DropdownMenu container={'body'} className="dropdown-menu-end">
          {actions.map((action: any, index: number) => {
            if (action.type === 'divider') {
              return <DropdownItem divider key={index} />
            }
            return (
              <DropdownItem key={index} tag={Link} onClick={action.onClick}>
                <i
                  className={clsx(' me-2', indexTableActionsDropdownItemIconsMap[action.type])}></i>
                {t(indexTableActionsDropdownItemLabelsMap[action.type])}
              </DropdownItem>
            )
          })}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  )
}

export default IndexTableActionsDropdown
