// react elements
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
// bootstrap elements
import { Container, Card, CardBody, CardHeader, Row } from 'reactstrap'
// reqired componnent
import BreadCrumb from 'Components/Common/BreadCrumb'
// fetch hock logic
import FetchHock from 'helpers/FetchHock'
// dependant components
import CreateModal from '../components/CreateModal'
import UpdateModal from '../components/UpdateModal'
import { categoryItem } from '../components/CategoryTree'
import DragZone from '../components/DragZone'
// index table components
import DeleteModal from 'Components/IndexTable/partials/DeleteModal'
import Loader from 'Components/IndexTable/partials/Loader'
import Error from 'Components/IndexTable/partials/Error'
import Empty from 'Components/IndexTable/partials/Empty'
import Pagination from 'Components/IndexTable/partials/Pagination'
// requierd for order
import axios from 'axios'
import linkGenerator from 'helpers/linkGenerator'
import showSuccess from 'helpers/SuccessNotfication'

const CategoriesList = () => {
  document.title = 'Categories | Sallatk'

  const { t } = useTranslation()

  const {
    items,
    loading,
    error,
    options,
    totalPages,
    totalItems,
    pagenate,
    openDelete,
    closeDelete,
    deleteItem,
    showedItems,
    deleteId,
    fetch
  } = FetchHock<categoryItem>({ path: '/category', initFilter: { parent_id: -1 } })

  // create modal logic
  const [showUpdate, changeShowUpdate] = useState<number | null>(null)
  const [showCreate, changeShowCreate] = useState<boolean>(false)
  const openCreate = () => changeShowCreate(true)
  const closeCreate = () => changeShowCreate(false)
  const openUpdate = (id: number) => changeShowUpdate(id)
  const closeUpdate = () => changeShowUpdate(null)

  // order items
  const orderItem = async (id: number, parentId: number, position: number) => {
    const response = await axios.post(linkGenerator('/category/sort'), {
      id: id,
      new_parent_id: parentId,
      number: position
    })
    if (response.status) showSuccess(t('Category updated successfully'))
    fetch()
  }

  // drag and drop logic

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <Card>
          <CardHeader>
            <Row className="align-items-center">
              <div className="col-sm">
                <h4 className="card-title mb-0">{t('Categories')}</h4>
              </div>
              <div className="col-sm-auto">
                {/* action buttons */}
                <div className="d-flex gap-1 flex-wrap">
                  <button type="button" className="btn btn-secondary add-btn" onClick={openCreate}>
                    <i className="ri-add-circle-line align-middle me-1"></i> {t('Add new category')}
                  </button>
                </div>
              </div>
            </Row>
          </CardHeader>
          <CardBody>
            <DragZone
              items={items}
              openUpdate={openUpdate}
              openDelete={openDelete}
              orderItem={orderItem}
            />

            {/* loader */}
            {loading && <Loader />}
            {!loading && error && <Error />}
            {!loading && !error && totalItems == 0 && <Empty />}
            {/* pagination */}
            {totalPages > 1 && (
              <Pagination
                options={options}
                totalPages={totalPages}
                totalItems={totalItems}
                showedItems={showedItems}
                pagenate={pagenate}
              />
            )}
          </CardBody>
        </Card>
      </Container>
      {showCreate && <CreateModal close={closeCreate} show={showCreate} onSuccess={fetch} />}
      {showUpdate && (
        <UpdateModal close={closeUpdate} show={openUpdate} onSuccess={fetch} itemId={showUpdate} />
      )}
      {/* delete component */}
      {deleteId && deleteId > 0 && (
        <DeleteModal
          show={deleteId}
          onDeleteClick={deleteItem}
          onCloseClick={() => {
            if (closeDelete) closeDelete()
          }}
        />
      )}
    </div>
  )
}

export default CategoriesList
