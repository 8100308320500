import { useTranslation } from 'react-i18next'
import { Container, Row, Col, Label } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import BreadCrumb from 'Components/Common/BreadCrumb'
import { Input, Submit } from 'Components/Form'
import CreateHook from 'helpers/CreateHook'
import PermissionsCard from '../components/PermissionsCard'

const AddRole = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { register, handleSubmit, errors, create, loading, setValue } = CreateHook<any>('/role', {
    name: '',
    permissions: []
  })

  const onSubmit = async (data) => {
    const response = await create(data)
    if (response.status) navigate('/roles/list')
  }

  const handleChangePermissions = (value) => {
    setValue('permissions', value)
  }
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />

        <form onSubmit={handleSubmit(onSubmit)}>
          <Submit loading={loading} label={t('Save')} />

          <Col xxl={12}>
            <Row>
              <Label htmlFor="colFormLabelSm" className="col-sm-12 col-form-label ">
                {t('Role Name')}
              </Label>
              <div className="col-sm-12">
                <Input
                  placeholder={t('Name')}
                  model={register('name', { required: `${t('Name')} ${t('is required')}` })}
                  error={errors?.name}
                />
              </div>
              <div className="col-sm-2"></div>
            </Row>
          </Col>
          <PermissionsCard onChange={handleChangePermissions} />
        </form>
      </Container>
    </div>
  )
}

export default AddRole
