import OfflinePaymentMethodTabBody from './OfflinePaymentMethodTabBody'
import OnlinePaymentMethodTabBody from './OnlinePaymentMethodTabBody'

function PaymentMethodTabBody({ companyData }) {
  const type = companyData.type
  const isOffline = type == 'offline'

  return (
    <>
      {isOffline ? (
        <OfflinePaymentMethodTabBody company={companyData} />
      ) : (
        <OnlinePaymentMethodTabBody company={companyData} />
      )}
    </>
  )
}

export default PaymentMethodTabBody
