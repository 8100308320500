import { useTranslation } from 'react-i18next'
import { Button, Col, Container, Row } from 'reactstrap'
import { Input, Submit } from 'Components/Form'
import CreateHook from 'helpers/CreateHook'
import PopUp from 'Components/PopUp'
import { useParams } from 'react-router-dom'

const CreateModal = ({ close, show, onSuccess }: any) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { register, handleSubmit, errors, create, loading } = CreateHook<any>(
    `/product/${id}/cards`,
    {
      title: '',
      slug: '',
      img_url: '',
      published: 0
    }
  )

  const submit = async (data) => {
    const response = await create(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  return (
    <PopUp title={t('Create') + ' ' + t('Card')} show={show} close={close}>
      <form
        onSubmit={(e) => {
          e.stopPropagation()
          return handleSubmit(submit)(e)
        }}>
        <Container fluid>
          <Row>
            <Col md={12}>
              <Input
                label={t('Card Name')}
                placeholder={t('Enter') + ' ' + t('Card Name')}
                model={register('card', {
                  required: t('Card') + ' ' + t('is required')
                })}
                error={errors?.card}
              />
            </Col>
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Create')} />
        </div>
      </form>
    </PopUp>
  )
}
export default CreateModal
