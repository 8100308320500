import { createContext } from 'react'
import { Category, Method, Product } from 'types'

export const POSContext = createContext<{
  searchInput: string
  setSearchInput: (searchInput: string) => void
  selectedCategory: Category
  setSelectedCategory: (category: Category) => void
  selectedProduct: Product
  setSelectedProduct: (product: Product) => void
  selectedProducts: Product[]
  setSelectedProducts: (products: Product[]) => void
  addProductToCart: (product: Product) => void
  removeProductFromCart: (product: Product) => void
  popupIsOpen: boolean
  setPopupIsOpen: (isOpen: boolean) => void
  paymentMethod: Method['methods'][0]
  setPaymentMethod: (method: Method['methods'][0]) => void
  invoiceId?: string
  setInvoiceId?: (id: string) => void
}>({
  searchInput: '',
  setSearchInput: () => {},
  selectedCategory: null,
  setSelectedCategory: () => {},
  selectedProduct: null,
  setSelectedProduct: () => {},
  selectedProducts: [],
  setSelectedProducts: () => {},
  addProductToCart: () => {},
  removeProductFromCart: () => {},
  popupIsOpen: false,
  setPopupIsOpen: () => { },
  paymentMethod: null,
  setPaymentMethod: () => { },
  invoiceId: undefined,
  setInvoiceId: undefined
})
