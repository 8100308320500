import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { logout } from 'helpers/Auth'
import showError from 'helpers/ErorrNotfication'
import Loader from 'Components/IndexTable/partials/Loader'

const Logout = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const handleLogout = async () => {
      const success = await logout()
      if (success) {
        navigate('/login')
      } else {
        showError('Failed to log out. Please try again.')
        navigate('/')
      }
    }
    handleLogout()
  }, [navigate])

  return (
    <>
      {loading && (
        <div className="d-flex justify-content-center align-items-center min-vh-100">
          <Loader />
        </div>
      )}
    </>
  )
}

Logout.propTypes = {
  history: PropTypes.object
}

export default Logout
