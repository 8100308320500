import {
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button
} from 'reactstrap'
import { useTranslation } from 'react-i18next'

import { Input, ImageUpload, Checkbox, Select } from 'Components/Form'
import { createContext, useContext, useState } from 'react'
import classNames from 'classnames'
import { useFormContext } from 'react-hook-form'
import TranslateText from './components/TranslateText'
import SelectLinkWithPreview from './components/SelectLinkWithPreview'
import SelectProductLinkWithPreview from './components/SelectProductLinkWithPreview'

const ThemeFormContext = createContext(undefined)

export const useThemeForm = () => {
  const context = useContext(ThemeFormContext)
  if (!context) {
    throw new Error('useThemeForm must be used within a ThemeFormContext.Provider')
  }
  return context
}

const FormParent = ({ inputs, data }: any) => {
  const sectionHeading = inputs.find((input: any) => input.label === 'sectionHeading')
  const restOfInputs = inputs.filter((input: any) => input.label !== 'sectionHeading')

  return (
    <ThemeFormContext.Provider value={data}>
      {sectionHeading && <FormBody input={sectionHeading} />}
      <FormInputs inputs={restOfInputs} />
    </ThemeFormContext.Provider>
  )
}

const FormInputs = ({ inputs, parentLabel = '' }: any) => {
  return (
    <>
      {inputs.map((input: any, index: number) => {
        return <FormBody key={index} input={input} parentLabel={parentLabel} />
      })}
    </>
  )
}

const FormBody = ({ parentLabel = '', input }: any) => {
  const { t } = useTranslation()
  const { register, getValues } = useFormContext()
  const { type } = useThemeForm()

  switch (input?.type) {
    case 'objectList':
      const [activeTab, setActiveTab] = useState(0)
      const [inputLength, setInputLength] = useState(input.length)
      const toggle = (tab) => {
        if (activeTab !== tab) {
          setActiveTab(tab)
        }
      }

      return (
        <Col md={12}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5>{t(input.title)}</h5>
            <Button size="sm" type="button" onClick={() => setInputLength(inputLength + 1)}>
              <i className="ri-add-circle-line"></i> {t('Add') + ' ' + t(input.title)}
            </Button>
          </div>

          <Nav className="mb-3" pills tabs fill>
            {[...Array(inputLength)].map((_, index) => {
              return (
                <NavItem key={index}>
                  <NavLink
                    href="#"
                    className={classNames({ active: activeTab === index })}
                    onClick={() => toggle(index)}>
                    {input.label + ' ' + (index + 1)}
                  </NavLink>
                </NavItem>
              )
            })}
          </Nav>
          <TabContent activeTab={activeTab}>
            {[...Array(inputLength)].map((_number, index) => (
              <TabPane tabId={index} key={index}>
                <FormInputs
                  inputs={input.inputs}
                  parentLabel={parentLabel + input.label + '.' + index + '.'}
                />
              </TabPane>
            ))}
          </TabContent>
        </Col>
      )
  }

  const validTranslateTypes = ['translateString', 'translateText']

  if (type === 'translate' && !validTranslateTypes.includes(input.type)) {
    return null
  }
  if (input?.show === false) {
    return null
  }
  switch (input?.type) {
    case 'string':
      return (
        <Col md={12}>
          <Input
            label={t(input.title)}
            type="text"
            placeholder={t('Enter') + ' ' + t(input.title)}
            model={register(parentLabel + input.label)}
          />
        </Col>
      )
    case 'number':
      return (
        <Col md={12}>
          <Input
            label={t(input.title)}
            type="number"
            placeholder={t('Enter') + ' ' + t(input.title)}
            model={register(parentLabel + input.label)}
          />
        </Col>
      )
    case 'checkBoox':
      return (
        <Col md={12}>
          <Checkbox label={t(input.title)} model={register(parentLabel + input.label)} />
        </Col>
      )
    case 'select':
      return (
        <Col md={12}>
          <Select
            label={t(input.title)}
            options={input.options}
            value={getValues(parentLabel + input.label)}
            placeholder={t('Enter') + ' ' + t(input.title)}
            model={register(parentLabel + input.label)}
          />
        </Col>
      )
    case 'link':
      return (
        <Col md={12}>
          <SelectLinkWithPreview input={input} parentLabel={parentLabel} />
        </Col>
      )
    case 'productLink':
      return (
        <Col md={12}>
          <SelectProductLinkWithPreview input={input} parentLabel={parentLabel} />
        </Col>
      )
    case 'image':
      console.log(input)
      return (
        <Col md={12}>
          <ImageUpload
            label={t(input.title)}
            theme={true}
            placeholder={t('Select') + ' ' + t(input.title)}
            model={register(parentLabel + input.label)}
            value={getValues(parentLabel + input.label)?.url}
          />
        </Col>
      )
    case 'translateString':
      const { lang } = useThemeForm()
      return (
        <Col md={12}>
          <Input
            label={`${t(input.title)} (${t(lang)})`}
            type="text"
            placeholder={t('Enter') + ' ' + t(input.title)}
            model={register(parentLabel + input.label + '.' + lang)}
          />
        </Col>
      )
    case 'translateText':
      return <TranslateText input={input} parentLabel={parentLabel} />
    case 'object':
      return (
        <Col md={12}>
          <Card>
            <CardHeader>
              <h5 className="card-title mb-0">{t(input.title)}</h5>
            </CardHeader>
            <CardBody>
              <FormInputs inputs={input.inputs} parentLabel={parentLabel + input.label + '.'} />
            </CardBody>
          </Card>
        </Col>
      )
  }
}

export default FormParent
